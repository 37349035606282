/*--------------------------------------------------
    [Animsition]
----------------------------------------------------*/

.animsition,
.animsition-overlay {
  	position: relative;
  	opacity: 0;
  	-webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/* Overlay Option */
.animsition-overlay-slide {
	@include position(fixed);
    @include size(100%);
    z-index: 9999;
    @include bg-opacity($color-white, 1);
}

/* Loading Option */
.animsition-loading,
.animsition-loading:after {
	@include position(fixed, $top: 50%, $left: 50%);
  	@include size(60px);
  	z-index: 100;
  	margin: -30px 0 0 -30px;
  	border-radius: 50%;
}

/* Animsition Loading */
.animsition-loading {
	background: transparent;
	border-left: 2px solid $color-base;
	@include translate3d(0,0,0);
	@include animation-iteration-count(infinite);
	@include animation-timing-function(linear);
	@include animation-duration(.8s);
    @include animation-name(animsition-loading);
}

@include keyframes(animsition-loading) {
    0% { @include rotate(0deg); }
    100% { @include rotate(360deg); }
}

@include keyframes(fade-in) {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/* Fade In */
.fade-in {
  	@include animation-name(fade-in);
}

@include keyframes(fade-out) {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

/* Fade Out */
.fade-out {
  	@include animation-name(fade-out);
}

@include keyframes(fade-in-up) {
    0% { 
        @include translate3d(0,500px,0);
        opacity: 0;
    }
    100% {
        @include translate3d(0,0,0);
        opacity: 1;
    }
}
