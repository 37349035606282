/*------------------------------------------------------------------
    [Green Color Theme]
------------------------------------------------------------------ */

a {
    color: $color-green;

    &:focus,
    &:hover,
    &:active {
        color: $color-green;
    }
}

/* Heading */
h1, h2, h3, h4, h5, h6 {
    > a {
        &:hover {
            color: $color-green;
        }
    }
}

/* Selection */
selection {
    background: $color-green;
}
webkit-selection {
    background: $color-green;
}
moz-selection {
    background: $color-green;
}

/* Blog Classic */
.blog-classic {
    .blog-classic-content {
        .blog-classic-share {
            .blog-classic-share-item {
                .blog-classic-share-link {
                    &:hover {
                        .blog-share-icon {
                            color: $color-green;
                        }
                    }
                }
            }
        }

        .blog-classic-publisher {
            .blog-classic-publisher-name {
                > a {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }
}

/* Blog Grid */
/* Supplemental */
.blog-grid {
    .blog-grid-supplemental-category {
        color: $color-green;
    }
}

/* Blog Grid Quote */
.blog-grid-quote {
    .blog-grid-quote-text {
        &:hover {
            color: $color-green;
        }
    }
}

/* Blog Grid Quote v2 */
.blog-grid-quote-v2 {
    background: $color-green;
}

/* Blog Grid Thumb */
.blog-grid-thumb {
    .blog-grid-thumb-body {
        &:before {
            @include bg-opacity($color-green, .7);
        }
    }
}

/* Twitter Feed */
.twitter-feed {
    .twitter-feed-item {
        .twitter-feed-content {
            .twitter-feed-link {
                color: $color-green;
            }
        }
    }
}

/* Featured Article */
.featured-article {
    .featured-article-content {
        .featured-article-content-title {
            > a {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Footer Tags */
.blog-sidebar-tags {
    > li {
        > a {
            &:hover {
                background: $color-green;
                border-color: $color-green;
            }
        }
    }
}

/* Blog Teaser Content */
.blog-teaser {
    &:hover {
        .blog-teaser-overlay {
            @include bg-opacity($color-green, .8);
        }
    }
}


/* Blog Teaser V2 */
.blog-teaser-v2 {
    &:hover {
        .blog-teaser-v2-overlay {
            @include bg-opacity($color-green, .7);
        }
    }
}

/* News v1 */
/* Heading */
.news-v1 {
    .news-v1-heading {
        .news-v1-heading-tag {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Content */
.news-v1 {
    .news-v1-lists {
        .news-v1-lists-item {
            .news-v1-lists-link {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* News v3 */
.news-v3 {
    .news-v3-content {
        .news-v3-subtitle {
            color: $color-green;
        }

        .news-v3-element {
            &:before,
            &:after {
                border-bottom-color: $color-green;
            }

            .news-v3-element-icon {
                color: $color-green;
            }
        }
    }
}

/* News v4 */
.news-v4 {
    .news-v4-title-element-left {
        &:after {
            border-bottom-color: $color-green;
        }
    }

    .news-v4-title-element-right {
        &:after {
            border-bottom-color: $color-green;
        }
    }
}

/* News v5 */
.news-v5 {
    .news-v5-icon {
        color: $color-green;
    }
}

/* News v6 */
.news-v6 {
    .news-v6-subtitle {
        color: $color-green;
    }
}

/* News v8 Image Effect */
.news-v8 {
    &:hover {
        .news-v8-img-effect {
            &:before {
                @include bg-opacity($color-green, .5);
            }
        }
    }
}

/* News v8 Wrap */
.news-v8 {
    .news-v8-footer-list {
        .news-v8-footer-list-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* News v8 More */
.news-v8 {
    .news-v8-more {
        .news-v8-more-link {
            background: $color-green;
        }
    }
}

/* Accordion v1 */
.accordion-v1 {
    .panel-title {
        > a {
            &[aria-expanded="true"] {
                background: $color-green;
            }

            &:focus,
            &:hover {
                background: $color-green;
            }
        }
    }
}

/* Accordion v2 */
.accordion-v2 {
    .panel-title {
        > a {
            &:focus,
            &:hover {
                color: $color-green;

                &:after {
                    color: $color-green;
                }
            }
        }
    }
}

/* Accordion v3 */
.accordion-v3 {
    .panel-title {
        > a {
            &:focus,
            &:hover {
                color: $color-green;

                &:before {
                    color: $color-green;
                }
            }
        }
    }
}

/* Accordion v4 */
.accordion-v4 {
    .panel-title {
        > a {
            &:before {
                color: $color-green;
            }

            &:focus,
            &:hover {
                color: $color-green;

                &:before {
                    color: $color-green;
                }
            }
        }
    }
}

/* Accordion v5 */
.accordion-v5 {
    .panel-title {
        > a {
            &:focus,
            &:hover {
                color: $color-green;

                &:after {
                    color: $color-green;
                }
            }
        }
    }
}

/* Blockquotes v1 */
.breadcrumbs-v1 {
    .breadcrumbs-v1-links {
        > li {
            > a {
                &:hover {
                    color: $color-green;
                }
            }

            &.active {
                color: $color-green;
            }
        }
    }
}

/* Breadcrumbs v2 */
.breadcrumbs-v2 {
    .breadcrumbs-v2-links {
        > li {
            > a {
                &:hover {
                    color: $color-green;
                }
            }

            &.active {
                color: $color-green;
            }
        }
    }
}

/* Breadcrumbs v3 */
.breadcrumbs-v3 {
    .breadcrumbs-v3-links {
        > li {
            > a {

                &:hover {
                    color: $color-green;
                }
            }

            &.active {
                color: $color-green;
            }
        }
    }
}

/* Button Green Color */
.btn-base {
    @include buttons($color-green, $color-white, transparent, $color-green, 1px, transparent, $color-green, transparent);

    .btn-base-element-xs {
        @include buttons-element(transparent, darken($color-green, 4%), 4px 9px);
    }

    .btn-base-element-sm {
        @include buttons-element(transparent, darken($color-green, 4%), 7px 11px);
    }

    .btn-base-element-md {
        @include buttons-element(transparent, darken($color-green, 4%), 8px 13px);
    }

    .btn-base-element-lg {
        @include buttons-element(transparent, darken($color-green, 4%), 10px 15px);
    }
}

/* Buttons Border Green Color */
.btn-base-brd {
    @include buttons($color-green, $color-white, transparent, $color-green, 1px, $color-green, $color-green, transparent);

    .btn-base-element-xs {
        @include buttons-element(transparent, darken($color-green, 4%), 4px 9px);
    }

    .btn-base-element-sm {
        @include buttons-element(transparent, darken($color-green, 4%), 7px 11px);
    }

    .btn-base-element-md {
        @include buttons-element(transparent, darken($color-green, 4%), 8px 13px);
    }

    .btn-base-element-lg {
        @include buttons-element(transparent, darken($color-green, 4%), 10px 15px);
    }
}

/* Button Background Green Color */
.btn-base-bg {
    @include buttons($color-white, $color-white, $color-green, $color-green-light, 1px, transparent, transparent, transparent);

    .btn-base-element-xs {
        @include buttons-element(darken($color-green, 4%), transparent, 4px 9px);
    }

    .btn-base-element-sm {
        @include buttons-element(darken($color-green, 4%), transparent, 7px 11px);
    }

    .btn-base-element-md {
        @include buttons-element(darken($color-green, 4%), transparent, 8px 13px);
    }

    .btn-base-element-lg {
        @include buttons-element(darken($color-green, 4%), transparent, 11px 15px);
    }
}

/* Button Background White Color */
.btn-white-bg {
    @include buttons($color-dark, $color-white, $color-white, $color-green, 1px, $color-white, $color-green, transparent);

    .btn-base-element-xs {
        @include buttons-element(transparent, darken($color-white, 6%), 6px 9px);
    }

    .btn-base-element-sm {
        @include buttons-element(transparent, darken($color-white, 6%), 9px 11px);
    }

    .btn-base-element-md {
        @include buttons-element(transparent, darken($color-white, 6%), 12px 13px);
    }

    .btn-base-element-lg {
        @include buttons-element(transparent, darken($color-white, 6%), 15px 15px);
    }
}

/* Button Green Color Slide */
.btn-base-bg-slide {
    @include buttons($color-white, $color-white, $color-green, $color-green, 0, tranparent, tranparent, $color-green-light);

    .btn-base-element-xs {
        @include buttons-element(transparent, darken($color-base, 4%), 6px 9px);
    }

    .btn-base-element-sm {
        @include buttons-element(transparent, darken($color-base, 4%), 9px 11px);
    }

    .btn-base-element-md {
        @include buttons-element(transparent, darken($color-base, 4%), 12px 13px);
    }

    .btn-base-element-lg {
        @include buttons-element(transparent, darken($color-base, 4%), 15px 15px);
    }
}

/* Button White Color Slide */
.btn-white-bg-slide {
    @include buttons($color-dark, $color-white, $color-white, $color-white, 0, transparent, transparent, $color-green);

    .btn-base-element-xs {
        @include buttons-element(transparent, darken($color-white, 6%), 6px 9px);
    }

    .btn-base-element-sm {
        @include buttons-element(transparent, darken($color-white, 6%), 9px 11px);
    }

    .btn-base-element-md {
        @include buttons-element(transparent, darken($color-white, 6%), 12px 13px);
    }

    .btn-base-element-lg {
        @include buttons-element(transparent, darken($color-white, 6%), 15px 15px);
    }
}

/* Button Green Color Slide */
.btn-base-brd-slide {
    @include buttons($color-green, $color-white, transparent, transparent, 1px, $color-green, $color-green, $color-green);

    .btn-base-element-xs {
        @include buttons-element(transparent, darken($color-base, 4%), 6px 9px);
    }

    .btn-base-element-sm {
        @include buttons-element(transparent, darken($color-base, 4%), 9px 11px);
    }

    .btn-base-element-md {
        @include buttons-element(transparent, darken($color-base, 4%), 12px 13px);
    }

    .btn-base-element-lg {
        @include buttons-element(transparent, darken($color-base, 4%), 15px 15px);
    }
}

/* Button Background Green Color */
.btn-base-bg-anim {
    @include buttons($color-white, $color-white, $color-green, $color-green, 0, transparent, transparent, darken($color-green, 5%));

    .btn-base-element-xs {
        @include buttons-element(darken($color-base, 4%), transparent, 6px 9px);
    }

    .btn-base-element-sm {
        @include buttons-element(darken($color-base, 4%), transparent, 9px 11px);
    }

    .btn-base-element-md {
        @include buttons-element(darken($color-base, 4%), transparent, 12px 13px);
    }

    .btn-base-element-lg {
        @include buttons-element(darken($color-base, 4%), transparent, 15px 15px);
    }
}

/* Button Background White Color */
.btn-white-bg-anim {
    @include buttons($color-dark, $color-white, $color-white, $color-white, 0, transparent, transparent, $color-green);

    .btn-base-element-xs {
        @include buttons-element(transparent, darken($color-white, 6%), 6px 9px);
    }

    .btn-base-element-sm {
        @include buttons-element(transparent, darken($color-white, 6%), 9px 11px);
    }

    .btn-base-element-md {
        @include buttons-element(transparent, darken($color-white, 6%), 12px 13px);
    }

    .btn-base-element-lg {
        @include buttons-element(transparent, darken($color-white, 6%), 15px 15px);
    }
}

/* Button Border Green Color */
.btn-base-brd-anim {
    @include buttons($color-green, $color-white, transparent, transparent, 1px, $color-green, $color-green, $color-green);

    .btn-base-element-xs {
        @include buttons-element(darken($color-base, 4%), transparent, 6px 9px);
    }

    .btn-base-element-sm {
        @include buttons-element(darken($color-base, 4%), transparent, 9px 11px);
    }

    .btn-base-element-md {
        @include buttons-element(darken($color-base, 4%), transparent, 12px 13px);
    }

    .btn-base-element-lg {
        @include buttons-element(darken($color-base, 4%), transparent, 15px 15px);
    }
}

/* Divider */
%divider {
    %divider-element-bg {
        background: $color-green;
    }

    %divider-icon {
        color: $color-green;
    }
}

/* Dropcap Base Color */
.dropcap-base {
    color: $color-green;
}

/* Dropcap Base Background Color */
.dropcap-base-bg {
    background: $color-green;
}

/* Dropcap Base Color Bordered */
.dropcap-base-bordered {
    color: $color-green;
    border-color: $color-green;
}

/* Dropcap Base Color */
.dropcap-dark {
    color: $color-dark;
}

/* Heading v1 */
.heading-v1 {
    .heading-v1-subtitle {
        color: $color-green;
    }
}

/* Font Awesome Icons */
.bordered-icon {
    .bordered-icon-box {
        &:hover {
            background: $color-green;
        }
    }
}

/* Lists */
%lists {
    > li {
        > a {
            &:hover {
                color: $color-green;
            }
        }

        %lists-item-element {
            color: $color-green;
        }
    }
}


/* Lists Base */
.lists-base {
    .lists-item-element-brd {
        color: $color-green;
        border-color: $color-green;
    }

    .lists-item-element-bg {
        background: $color-green;
    }
}

/* Numeric List */
%numeric-lists {
    > li {
        > a {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Numeric Base Lists */
.numeric-base-lists {
    @extend %numeric-lists;
    > li {
        color: $color-green;
    }
}

/* Log In */
.login {
    .login-form-forgot {
        &:hover {
            color: $color-green;
        }
    }
}

/* Paginations v1 */
.paginations-v1-list {
    > li {
        > a,
        > span {
            &:hover {
                @include bg-opacity($color-green, .5);
            }
        }

        &.next,
        &.previous {
            > a,
            > span {
                &:hover {
                    color: $color-green;
                }
            }
        }

        &.active {
            > a,
            > span {
                background: $color-green;
            }
        }
    }
}

/* Paginations v2 */
.paginations-v2-list {
    > li {
        > a,
        > span {
            &:hover {
                color: $color-green;
                border-bottom-color: $color-green;
            }
        }

        &.active {
            > a,
            > span {
                color: $color-green;
                border-bottom-color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Paginations v3 */
.paginations-v3-list {
    > li {
        > a,
        > span {
            color: $color-green;

            &:hover {
                color: $color-green;
            }
        }

        &.active {
            > a,
            > span {
                background: $color-green;
                border-color: $color-green;

                &:hover {
                    background: $color-green;
                }
            }
        }
    }
}

/* Pagers v1 */
.pagers-v1 {
    &:hover {
        background: $color-green;
    }
}

/* Pagers v2 */
.pagers-v2 {
    li {
        > a,
        > span {

            &:hover {
                border-color: $color-green;
                background: $color-green;
            }
        }
    }
}

/* Tab v1 */
.tab-v1 {
    .nav-tabs {
        > li {
            &:hover {
                > a {
                    color: $color-green;
                }
            }

            &.active {
                > a {
                    color: $color-green;
                }
            }
        }

    }
}

/* Tab v3 */
.tab-v3 {
    .nav-tabs {
        > li {
            &:hover {
                > a {
                    color: $color-green;
                }
            }

            &.active {
                > a {
                    background: $color-green;
                }
            }
        }
    }
}

/* Tab v4 */
.tab-v4 {
    .nav-tabs {
        background: $color-green;

        &.nav-tabs-left {

            > li {
                &:hover {
                    > a {
                        background: darken($color-green, 8%);
                    }
                }

                &.active {
                    > a {
                        background: darken($color-green, 8%);
                    }
                }
            }
        }
    }
}

/* Tab v5 (left) */
.tab-v5 {
    .nav-tabs {
        &.nav-tabs-left {
            > li {
                &:hover {
                    > a {
                        color: $color-green;
                    }
                }

                &.active {
                    > a {
                        color: $color-green;
                    }
                }
            }
        }
    }
}

/* Tab v6 (right) */
.tab-v6 {
    .nav-tabs {
        &.nav-tabs-right {
            > li {
                &:hover {
                    > a {
                        color: $color-green;
                    }
                }

                &.active {
                    > a {
                        color: $color-green;
                    }
                }
            }
        }
    }
}

/* Theme Icons Base */
.theme-icons-wrap {
    > a {
        &[href] {
            > .theme-icons-base-hover {
                &:hover {
                    background: $color-green;
                }
            }
        }
    }
}

/* Theme Icons Base Background */
.theme-icons-base-bg {
    @include theme-icons($color-white, $color-green, 0, transparent);
}

.theme-icons-wrap {
    > a {
        &[href] {
            > .theme-icons-base-bg {
                &:hover {
                    background: $color-green-light;
                }
            }
        }
    }
}

/* Theme Icons White Background */
.theme-icons-wrap {
    > a {
        &[href] {
            > .theme-icons-white-bg {
                &:hover {
                    background: $color-green;
                }
            }
        }
    }
}

/* Theme Icons Grey Light Background */
.theme-icons-wrap {
    > a {
        &[href] {
            > .theme-icons-grey-light-bg {
                &:hover {
                    background: $color-green;
                }
            }
        }
    }
}

/* Theme Icons Base Bordered */
.theme-icons-base-brd {
    @include theme-icons($color-green, transparent, 1px, $color-green);
}

.theme-icons-wrap {
    > a {
        &[href] {
            > .theme-icons-base-brd {
                &:hover {
                    background: $color-green;
                    border-color: $color-green;
                }
            }
        }
    }
}

/* Animate Theme Icons Base */
.animate-theme-icons .animate-theme-icons-base {
    color: $color-green;
}

/* Animate Theme Icons Base Background */
.animate-theme-icons .animate-theme-icons-base-bg {
    background: $color-green;
}

/* Animate Theme Icons Base Bordered */
.animate-theme-icons .animate-theme-icons-base-brd {
    color: $color-green;
    border-color: $color-green;
}

/* Theme Icons Elegant */
.theme-icons-elegant {
    .theme-icons-element {
        .theme-icons-wrap {
            > a {
                &[href] {
                    > .theme-icons {
                        &:hover {
                            background: $color-green;
                        }
                    }
                }
            }
        }
    }
}

/* TimeLine Current Date */
.timeline-v1-current-date {
    .timeline-v1-current-day {
        color: $color-green;
    }

    .timeline-v1-current-time {
        color: $color-green;
    }
}

/* TimeLine v2 */
.timeline-v2 {
    .timeline-v2-news-title {
        > a {
            &:hover {
                color: $color-green;
            }
        }
    }

    .timeline-v2-current-day {
        color: $color-green;
    }

    .timeline-v2-current-time {
        color: $color-green;
    }
}

/* TimeLine v3 */
.timeline-v3 {
    .timeline-v3-list-item {
        .timeline-v3-badge-icon {
            background: $color-green;
        }
    }
}

/* TimeLine v4 */
.timeline-v4 {
    &:before {
        background: $color-green;
    }

    &:after {
        background: $color-green;
    }

    .timeline-v4-list-item {
        .timeline-v4-badge-icon {
            background: $color-green;
        }
    }

    .timeline-v4-subtitle {
        color: $color-green;
    }
}

/* Counters v1 */
.counters-v1 {
    .counters-v1-body {
        .counters-v1-number {
            color: $color-green;
        }
    }
}

/* Counters v3 */
.counters-v3 {
    .counters-v3-icon {
        color: $color-green;
    }
}

/* Icons Box v2 */
.icon-box-v2 {
    .icon-box-v2-icons {
        color: $color-green;
    }
}

/* Image Gallery */
.image-gallery {
    .image-gallery-overlay {
        @include bg-opacity($color-green, .6);
    }
}

/* Image Gallery v2 */
.image-gallery-v2 {
    .image-gallery-v2-overlay {
        @include bg-opacity($color-green, .6);
    }
}

/* Interactive Banner v2 */
.i-banner-v2 {
    .i-banner-v2-heading {
        .i-banner-v2-heading-month {
            color: $color-green;
        }
    }
}

/* Interactive Banner v3 */
.i-banner-v3 {
    .i-banner-v3-content {
        .i-banner-v3-subtitle {
            color: $color-green;
        }

        .i-banner-v3-element {
            .i-banner-v3-element-icon {
                color: $color-green;
            }
        }
    }
}

/* Newsletter v5 */
.newsletter-v5 {
    .newsletter-v5-btn {
        &:hover {
            color: $color-green;
        }
    }
}

/* Newsletter v8 */
.newsletter-v8 {
    &:hover {
        background: $color-green;
    }
}

/* Poster v1 */
.poster-v1 {
    .poster-v1-body {
        .poster-v1-author {
            color: $color-green;
        }
    }
}

/* Poster v2 */
.poster-v2 {
    .poster-v2-subtitle {
        color: $color-green;
    }
}

/* Pricing List v1 Active */
.pricing-list-v1-active {
    .pricing-list-v1-body {
        .pricing-list-v1-header-price,
        .pricing-list-v1-header-price-sign,
        .pricing-list-v1-header-price-info {
            color: $color-green;
        }
    }
}

/* Process v1 */
.process-v1 {
    .process-v1-back {
        background: $color-green;
    }
}

/* Process v2 */
.process-v2 {
    &:hover {
        .process-v2-element {
            background: $color-green;
        }
    }
}

/* Service Block v4 */
.service-block-v4 {
    .service-block-v4-content {
        .service-block-v4-content-subtitle {
            color: $color-green;
        }
    }
}

/* Service Block v6 */
.service-block-v6 {
    &.service-block-v6-color-three {
        &:before {
            @include bg-opacity($color-green, .85);
        }
    }
}

/* Service Block v8 */
.service-block-v8 {
    .service-block-v8-subtitle {
        color: $color-green;
    }
}

/* Slider Block v1 */
.slider-block-v1 {
    .slider-block-v1-subtitle {
        color: $color-green;
    }
}

/* Team v1 */
.team-v1 {
    .team-v1-content {
        .team-v1-socials {
            .team-v1-socials-link {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Team v2 */
.team-v2 {
    .team-v2-member-position {
        color: $color-green;
    }
}

/* Team v3 */
.team-v3 {
    .team-v3-header {
        .team-v3-member-position {
            color: $color-green;
        }
    }
}

/* Team v5 */
.team-v5 {
    .team-v5-content {
        .team-v5-author-contact {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Testimonials v1 */
.testimonials-v1 {
    .testimonials-v1-author {
        color: $color-green;
    }
}

/* Testimonials v4 */
.testimonials-v4 {
    .testimonials-v4-author {
        color: $color-green;
    }
}

/* Testimonials v5 */
.testimonials-v5 {
    .testimonials-v5-author {
        color: $color-green;
    }
}

/* Testimonials v6 */
.testimonials-v6 {
    .testimonials-v6-element {
        .testimonials-v6-author {
            color: $color-green;
        }
    }
}

/* Testimonials v7 */
.testimonials-v7 {
    .testimonials-v7-author {
        color: $color-green;

        &:before {
            background: $color-green;
        }
    }
}

/* Footer Top */
.footer {
    .footer-address {
        .footer-address-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Footer Media */
.footer {
    .footer-media {
        .footer-media-item {
            .footer-media-link {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Footer Tags */
.footer {
    .footer-tags {
        > li {
            > a {
                &:hover {
                    background: $color-green;
                    border-color: $color-green;
                }
            }
        }
    }
}

/* Footer News List */
.footer {
    .footer-news-list {
        .footer-news-list-item {
            .footer-news-list-link {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Footer Video Info */
.footer {
    .footer-video-title {
        .footer-video-title-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Footer Copyright */
.footer {
    .footer-copyright {
        .footer-copyright-item {
            > a {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Footer Toggle Content */
.footer {
    .footer-toggle {
        .footer-toggle-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Footer v2 */
.footer-v2 {
    background: $color-green;
}

/* Footer v4 */
.footer-v4 {
    .footer-v4-service {
        .footer-v4-service-item {
            .footer-v4-service-icon {
                color: $color-green;
            }

            .footer-v4-service-link {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }

    .footer-v4-news {
        .footer-v4-news-item {
            .footer-v4-news-link {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }

    .footer-v4-link {
        &:hover {
            color: $color-green;
        }
    }

    .footer-v4-copyright {
        .footer-v4-terms-list {
            .footer-v4-terms-link {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Footer v5 */
.footer-v5 {
    .footer-v5-content-subtitle {
        color: $color-green;
    }

    .footer-v5-list {
        .footer-v5-list-item {
            .footer-v5-list-link {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Copyright */
.footer-v5-copyright {
    .footer-v5-copright-socials {
        .footer-v5-copright-socials-item {
            .footer-v5-copright-socials-link {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Footer v6 */
.footer-v6 {
    .footer-link-v6-list {
        .footer-link-v6-list-item {
            .footer-link-v6-list-link {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Form Modal */
.form-modal {
    .form-modal-back-btn-message {
        .form-modal-back-btn-message-link {
            color: $color-green;
        }
    }

    .form-modal-input-group {
        .form-modal-hide-password {
            &:hover {
                color: $color-green;
            }
        }
    }

    .form-modal-close-form {
        &:hover {
            color: $color-green;
        }
    }
}

/* Navbar Toggle */
.header {
    .navbar-toggle {
        &:hover {
            .toggle-icon {
                background: $color-green;

                &:before,
                &:after {
                    background: $color-green;
                }
            }
        }
    }
}

/* Topbar Toggle Trigger */
.header {
    .topbar-toggle-trigger {
        &:hover {
            .topbar-toggle-trigger-style {
                background: $color-green;

                &:before,
                &:after {
                    background: $color-green;
                }
            }
        }
    }
}

/* Topbar Toggle Trigger Icon */
.header {
    .topbar-toggle-trigger-icon-style {
        &:hover {
            color: $color-green;
        }
    }
}

/* Shopping Cart */
.header {
    .shopping-cart {
        .shopping-cart-icon {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-amount {
            background: $color-green;
        }

        .shopping-cart-menu {
            border-top-color: $color-green;
        }

        .shopping-cart-menu-product-name {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-close {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-subtotal-price {
            color: $color-green;
        }

        .shopping-cart-subtotal-view-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Navbar Nav */
.header {
    .navbar-nav {
        .nav-item-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }

            &:hover,
            &:focus {
                color: $color-green;
            }
        }
    }
}

/* Dropdown Menu */
.header {
    .dropdown-menu {
        .dropdown-menu-item {
            .dropdown-menu-item-child {
                &.active {
                    color: $color-green;

                    &:hover {
                        color: $color-green;
                    }
                }

                &:hover {
                    background: $color-green;
                }
            }

            &.active {
                .dropdown-menu-item-child {
                    color: $color-green;
                }
            }
        }
    }
}

/* Dropdown Submenu */
.header {
    .dropdown-submenu {
        &:hover {
            .dropdown-submenu-child {
                color: $color-green;
            }
        }

        .dropdown-submenu-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;

                    &:after {
                        color: $color-green;
                    }
                }
            }

            &:hover {
                color: $color-white;
                background: $color-green;

                &:after {
                    color: $color-white;
                }
            }
        }

        &.active {
            .dropdown-submenu-child {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Mega Menu */
.header {
    .mega-menu-list {
        .mega-menu-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }

            &:hover {
                background: $color-green;
            }
        }
    }
}

/* Media Queries below 991px */
@media (max-width: $screen-sm-max) {
    /* Navbar Nav */
    .header {
        .navbar-nav {
            .nav-item-bg {
                border-color: $color-green;

                .nav-item-child {
                    background: $color-green;
                }
            }
        }
    }
}

/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    /* Navbar */
    .header {
        .navbar-nav {
            .nav-item {
                .dropdown-menu {
                    border-top-color: $color-green;
                }

                &:hover {
                    .nav-item-child {
                        color: $color-green;
                    }
                }
            }

            .nav-item-bg {
                border-color: $color-green;

                .nav-item-child {
                    background: $color-green;

                    &:hover {
                        color: $color-white;
                    }
                }
            }
        }
    }
}


/* Navbar Toggle */
.header-center-aligned {
    .navbar-toggle {
        &:hover {
            .toggle-icon {
                background: $color-green;

                &:before,
                &:after {
                    background: $color-green;
                }
            }
        }
    }
}

/* Topbar Toggle Trigger */
.header-center-aligned {
    .topbar-toggle-trigger {
        &:hover {
            .topbar-toggle-trigger-style {
                background: $color-green;

                &:before,
                &:after {
                    background: $color-green;
                }
            }
        }
    }
}

/* Topbar Toggle Trigger Icon */
.header-center-aligned {
    .topbar-toggle-trigger-icon-style {
        &:hover {
            color: $color-green;
        }
    }
}

/* Shopping Cart */
.header-center-aligned {
    .shopping-cart {
        .shopping-cart-icon {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-amount {
            background: $color-green;
        }

        .shopping-cart-menu {
            border-top-color: $color-green;
        }

        .shopping-cart-menu-product-name {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-close {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-subtotal-price {
            color: $color-green;
        }

        .shopping-cart-subtotal-view-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Navbar Nav */
.header-center-aligned {
    .navbar-nav {
        .nav-item-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }

            &:hover,
            &:focus {
                color: $color-green;
            }
        }
    }
}

/* Dropdown Menu */
.header-center-aligned {
    .dropdown-menu {
        .dropdown-menu-item {
            .dropdown-menu-item-child {
                &.active {
                    color: $color-green;

                    &:hover {
                        color: $color-green;
                    }
                }

                &:hover {
                    background: $color-green;
                }
            }

            &.active {
                .dropdown-menu-item-child {
                    color: $color-green;
                }
            }
        }
    }
}

/* Dropdown Submenu */
.header-center-aligned {
    .dropdown-submenu {
        &:hover {
            .dropdown-submenu-child {
                color: $color-green;
            }
        }

        .dropdown-submenu-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;

                    &:after {
                        color: $color-green;
                    }
                }
            }

            &:hover {
                color: $color-white;
                background: $color-green;

                &:after {
                    color: $color-white;
                }
            }
        }

        &.active {
            .dropdown-submenu-child {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Mega Menu */
.header-center-aligned {
    .mega-menu-list {
        .mega-menu-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }

            &:hover {
                background: $color-green;
            }
        }
    }
}

/* Media Queries below 991px */
@media (max-width: $screen-sm-max) {
    .header-center-aligned {
        .navbar-nav {
            .nav-item-bg {
                border-color: $color-green;

                .nav-item-child {
                    background: $color-green;
                }
            }
        }
    }
}

/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    /* Navbar */
    .header-center-aligned {
        .navbar-nav {
            .nav-item {
                .dropdown-menu {
                    border-top-color: $color-green;
                }

                &:hover {
                    .nav-item-child {
                        color: $color-green;
                    }
                }
            }

            .nav-item-bg {
                border-color: $color-green;

                .nav-item-child {
                    background: $color-green;

                    &:hover {
                        color: $color-white;
                    }
                }
            }
        }
    }
}

/* Navbar Toggle */
.header-center-aligned-transparent {
    .navbar-toggle {
        &:hover {
            .toggle-icon {
                background: $color-green;

                &:before,
                &:after {
                    background: $color-green;
                }
            }
        }
    }
}

/* Topbar Toggle Trigger */
.header-center-aligned-transparent {
    .topbar-toggle-trigger {
        &:hover {
            .topbar-toggle-trigger-style {
                background: $color-green;

                &:before,
                &:after {
                    background: $color-green;
                }
            }
        }
    }
}

/* Topbar Toggle Trigger Icon */
.header-center-aligned-transparent {
    .topbar-toggle-trigger-icon-style {
        &:hover {
            color: $color-green;
        }
    }
}

/* Shopping Cart */
.header-center-aligned-transparent {
    .shopping-cart {
        .shopping-cart-icon {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-amount {
            background: $color-green;
        }

        .shopping-cart-menu {
            border-top-color: $color-green;
        }

        .shopping-cart-menu-product-name {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-close {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-subtotal-price {
            color: $color-green;
        }

        .shopping-cart-subtotal-view-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Navbar Nav */
.header-center-aligned-transparent {
    .navbar-nav {
        .nav-item-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }

            &:hover,
            &:focus {
                color: $color-green;
            }
        }
    }
}

/* Dropdown Menu */
.header-center-aligned-transparent {
    .dropdown-menu {
        .dropdown-menu-item {
            .dropdown-menu-item-child {
                &.active {
                    color: $color-green;

                    &:hover {
                        color: $color-green;
                    }
                }

                &:hover {
                    background: $color-green;
                }
            }

            &.active {
                .dropdown-menu-item-child {
                    color: $color-green;
                }
            }
        }
    }
}

/* Dropdown Submenu */
.header-center-aligned-transparent {
    .dropdown-submenu {
        &:hover {
            .dropdown-submenu-child {
                color: $color-green;
            }
        }

        .dropdown-submenu-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;
                    background: $color-white;

                    &:after {
                        color: $color-green;
                    }
                }
            }

            &:hover {
                color: $color-white;
                background: $color-green;
            }
        }

        &.active {
            .dropdown-submenu-child {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Mega Menu */
.header-center-aligned-transparent {
    .mega-menu-list {
        .mega-menu-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }

            &:hover {
                background: $color-green;
            }
        }
    }
}

/* Media Queries below 991px */
@media (max-width: $screen-sm-max) {
    /* Navbar Nav */
    .header-center-aligned-transparent {
        .navbar-nav {
            .nav-item-bg {
                border-color: $color-green;

                .nav-item-child {
                    background: $color-green;
                }
            }
        }
    }
}

/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    /* Header Shrink */
    .header-shrink {
        &.header-center-aligned-transparent {
            .shopping-cart {
                .shopping-cart-icon {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }

    /* Navbar */
    .header-center-aligned-transparent {
        .navbar-nav {
            .nav-item {
                .dropdown-menu {
                    border-top-color: $color-green;
                }

                &:hover {
                    .nav-item-child {
                        color: $color-green;
                    }
                }
            }

            .nav-item-child {
                &.active {
                    color: $color-green;
                }
            }

            .nav-item-bg {
                border-color: $color-green;

                .nav-item-child {
                    color: $color-white;
                    background: $color-green;

                    &:hover {
                        color: $color-white;
                    }
                }
            }
        }
    }
}


/* Navbar */
.header-fullscreen {
    .header-fullscreen-nav-actions-left {
        .header-fullscreen-nav-trigger {
            &:hover {
                .header-fullscreen-nav-trigger-icon {
                    background: $color-green;

                    &:before,
                    &:after {
                        background: $color-green;
                    }
                }
            }
        }
    }

    .header-fullscreen-nav-actions-right {
        .nav-item-child {
            background: $color-green;
        }
    }
}

/* Nav Overlay */
.header-fullscreen {
    .header-fullscreen-nav-bg-overlay {
        .header-fullscreen-nav-close {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Menu */
.header-fullscreen {
    .header-fullscreen-menu {
        .nav-main-item-child,
        .nav-submenu-item-child {
            &.nav-main-item-child-dropdown,
            &.nav-submenu-item-child-dropdown {
                &.active,
                &:hover {
                    color: $color-green;

                    &:before,
                    &:after {
                        color: $color-green;
                    }
                }
            }
        }

        .nav-dropdown-menu {
            .nav-dropdown-menu-link {
                &:hover {
                    color: $color-green;
                }
            }

            .nav-dropdown-menu-child {
                &.active {
                    color: $color-green;
                }

                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Media Queries below 991px */
@media (max-width: $screen-sm-max) {
    /* Nav Trigger */
    .header-fullscreen {
        .header-fullscreen-nav-actions-left {
            .header-fullscreen-nav-trigger {
                &:hover {
                    .header-fullscreen-nav-trigger-icon {
                        background: $color-green;

                        &:before,
                        &:after {
                            background: $color-green;
                        }
                    }
                }
            }
        }
    }
}

/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    /* Header Shrink */
    .header-shrink {
        &.header-fullscreen {
            .header-fullscreen-nav-actions-left {
                .header-fullscreen-nav-trigger {
                    &:hover {
                        .header-fullscreen-nav-trigger-icon {
                            background: $color-green;

                            &:before,
                            &:after {
                                background: $color-green;
                            }
                        }
                    }
                }
            }
        }
    }
}


/* Navbar Toggle */
.header-section-scroll {
    .navbar-toggle {
        &:hover {
            .toggle-icon {
                background: $color-green;

                &:before,
                &:after {
                    background: $color-green;
                }
            }
        }
    }
}

/* Navbar Nav */
.header-section-scroll {
    .header-section-scroll-menu {
        .nav-item {
            &.active {
                .nav-item-child {
                    &:hover,
                    &:focus {
                        color: $color-green;
                    }
                }
            }
        }

        .nav-item-child {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Navbar Toggle */
.header-transparent {
    .navbar-toggle {
        &:hover {
            .toggle-icon {
                background: $color-green;

                &:before,
                &:after {
                    background: $color-green;
                }
            }
        }
    }
}

/* Topbar Toggle Trigger */
.header-transparent {
    .topbar-toggle-trigger {

        &:hover {
            .topbar-toggle-trigger-style {
                background: $color-green;

                &:before,
                &:after {
                    background: $color-green;
                }
            }
        }
    }
}

/* Topbar Toggle Trigger Icon */
.header-transparent {
    .topbar-toggle-trigger-icon-style {
        &:hover {
            color: $color-green;
        }
    }
}

/* Shopping Cart */
.header-transparent {
    .shopping-cart {
        .shopping-cart-icon {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-amount {
            background: $color-green;
        }

        .shopping-cart-menu {
            border-top-color: $color-green;
        }

        .shopping-cart-menu-product-name {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-close {
            &:hover {
                color: $color-green;
            }
        }

        .shopping-cart-subtotal-price {
            color: $color-green;
        }

        .shopping-cart-subtotal-view-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Navbar Nav */
.header-transparent {
    .navbar-nav {
        .nav-item-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }

            &:hover,
            &:focus {
                color: $color-green;
            }
        }
    }
}

/* Dropdown Menu */
.header-transparent {
    .dropdown-menu {
        .dropdown-menu-item {
            .dropdown-menu-item-child {
                &.active {
                    color: $color-green;

                    &:hover {
                        color: $color-green;
                    }
                }

                &:hover {
                    background: $color-green;
                }
            }

            &.active {
                .dropdown-menu-item-child {
                    color: $color-green;
                }
            }
        }
    }
}

/* Dropdown Submenu */
.header-transparent {
    .dropdown-submenu {
        &:hover {
            .dropdown-submenu-child {
                color: $color-green;
            }
        }

        .dropdown-submenu-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;

                    &:after {
                        color: $color-green;
                    }
                }
            }

            &:hover {
                color: $color-white;
                background: $color-green;
            }
        }

        &.active {
            .dropdown-submenu-child {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Mega Menu */
.header-transparent {
    .mega-menu-list {
        .mega-menu-child {
            &.active {
                color: $color-green;

                &:hover {
                    color: $color-green;
                }
            }

            &:hover {
                background: $color-green;
            }
        }
    }
}

/* Media Queries below 991px */
@media (max-width: $screen-sm-max) {
    /* Navbar Nav */
    .header-transparent {
        .navbar-nav {
            .nav-item-bg {
                border-color: $color-green;

                .nav-item-child {
                    background: $color-green;
                }
            }
        }
    }
}


/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    /* Header Shrink */
    .header-shrink {
        &.header-transparent {
            .shopping-cart {
                .shopping-cart-icon {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }

    /* Navbar */
    .header-transparent {
        .navbar-nav {
            .nav-item {
                .dropdown-menu {
                    border-top-color: $color-green;
                }

                &:hover {
                    .nav-item-child {
                        color: $color-green;
                    }
                }
            }

            .nav-item-child {
                &.active {
                    color: $color-green;
                }
            }

            .nav-item-bg {
                border-color: $color-green;
                .nav-item-child {
                    background: $color-green;

                    &:hover {
                        color: $color-white;
                        background: $color-green;
                    }
                }

                &:hover {
                    .nav-item-child {
                        color: $color-white;
                        background: $color-green;
                    }
                }
            }
        }
    }
}

/* Navbar Toggle */
.header-vertical {
    .navbar-toggle {
        &:hover {
            .toggle-icon {
                background: $color-green;

                &:before,
                &:after {
                    background: $color-green;
                }
            }
        }
    }
}

/* Navbar Nav */
.header-vertical {
    .header-vertical-menu {
        .nav-main-item-child,
        .nav-submenu-item-child {
            &.nav-main-item-child-dropdown,
            &.nav-submenu-item-child-dropdown {
                &.active,
                &:hover {
                    color: $color-green;

                    &:before,
                    &:after {
                        color: $color-green;
                    }
                }
            }
        }

        .nav-dropdown-menu {
            .nav-dropdown-menu-link {
                &:hover {
                    color: $color-green;
                }
            }

            .nav-dropdown-menu-child {
                &.active {
                    color: $color-green;
                }

                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Search */
.search {
    .search-btn {
        .search-btn-default,
        .search-btn-active {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Media Queries below 992px */
@media (max-width: $screen-md-min) {
    /* Search */
    .search {
        &.search-white {
            .search-btn {
                .search-btn-default,
                .search-btn-active {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }
}

/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    /* Search */
    .search {
        &.search-white {
            .search-btn {
                .search-btn-default,
                .search-btn-active {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }

    /* Header Shrink */
    .header-shrink {
        .search {
            &.search-white {
                .search-btn {
                    .search-btn-default,
                    .search-btn-active {
                        &:hover {
                            color: $color-green;
                        }
                    }
                }
            }
        }
    }
}

/* Search Classic */
.search-classic {
    .search-classic-btn {
        .search-classic-btn-default,
        .search-classic-btn-active {
            &:hover {
                color: $color-green;
            }
        }
    }

    .search-classic-field {
        border-top-color: $color-green;
    }
}

/* Media Queries below 992px */
@media (max-width: $screen-md-min) {
    /* Search Classic */
    .search-classic {
        &.search-classic-white {
            .search-classic-btn {
                .search-classic-btn-default,
                .search-classic-btn-active {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }
}

/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    /* Search Classic */
    .search-classic {
        &.search-classic-white {
            .search-classic-btn {
                .search-classic-btn-default,
                .search-classic-btn-active {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }

    /* Header Shrink */
    .header-shrink {
        .search-classic {
            &.search-classic-white {
                .search-classic-btn {
                    .search-classic-btn-default,
                    .search-classic-btn-active {
                        &:hover {
                            color: $color-green;
                        }
                    }
                }
            }
        }
    }
}

/* Search Fullscreen */
.search-fullscreen {
    .search-fullscreen-trigger-icon {
        &:hover {
            color: $color-green;
        }
    }

    .search-fullscreen-input-group {
        .search-fullscreen-search {
            &:hover {
                .search-fullscreen-search-icon {
                    color: $color-green;
                }
            }
        }
    }

    .search-fullscreen-bg-overlay {
        .search-fullscreen-close {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Media Queries below 992px */
@media (min-width: $screen-md-min) {
    /* Search Fullscreen */
    .search-fullscreen {
        &.search-fullscreen-trigger-white {
            .search-fullscreen-trigger-icon {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }

    /* Header Shrink */
    .header-shrink {
        .search-fullscreen {
            &.search-fullscreen-trigger-white {
                .search-fullscreen-trigger-icon {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }
}

/* Search On Header */
.search-on-header {
    .search-on-header-btn {
        .search-on-header-btn-default,
        .search-on-header-btn-active {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Media Queries below 992px */
@media (max-width: $screen-md-min) {
    /* Search On Header */
    .search-on-header {
        &.search-on-header-white {
            .search-on-header-btn {
                .search-on-header-btn-default,
                .search-on-header-btn-active {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }
}

/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    /* Search On Header */
    .search-on-header {
        &.search-on-header-white {
            .search-on-header-btn {
                .search-on-header-btn-default,
                .search-on-header-btn-active {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }

    /* Header Shrink */
    .header-shrink {
        .search-on-header {
            &.search-on-header-white {
                .search-on-header-btn {
                    .search-on-header-btn-default,
                    .search-on-header-btn-active {
                        &:hover {
                            color: $color-green;
                        }
                    }
                }
            }
        }
    }
}

/* Search Push */
.search-push {
    .search-push-btn {
        .search-push-btn-default,
        .search-push-btn-active {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Media Queries below 992px */
@media (max-width: $screen-md-min) {
    /* Search Push */
    .search-push {
        &.search-push-white {
            .search-push-btn {
                .search-push-btn-default,
                .search-push-btn-active {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }
}

/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    /* Search Push */
    .search-push {
        &.search-push-white {
            .search-push-btn {
                .search-push-btn-default,
                .search-push-btn-active {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }

    /* Header Shrink */
    .header-shrink {
        .search-push {
            &.search-push-white {
                .search-push-btn {
                    .search-push-btn-default,
                    .search-push-btn-active {
                        &:hover {
                            color: $color-green;
                        }
                    }
                }
            }
        }
    }
}

/* Sidebar Trigger */
.sidebar-trigger {
    &:hover {
        .sidebar-trigger-icon {
            background: $color-green;

            &:before,
            &:after {
                background: $color-green;
            }
        }
    }
}

/* Sidebar Nav */
.sidebar-nav {
    .sidebar-nav-trigger {
        &:hover {
            .sidebar-trigger-icon {
                &:before,
                &:after {
                    background: $color-green;
                }
            }
        }
    }

    .sidebar-nav-about-link {
        &:hover {
            color: $color-green;
        }
    }
}

/* Media Queries below 992px */
@media (min-width: $screen-md-min) {
    /* Sidebar Trigger */
    .sidebar-trigger {
        &.sidebar-trigger-style-white {
            &:hover {
                .sidebar-trigger-icon {
                    background: $color-green;

                    &:before,
                    &:after {
                        background: $color-green;
                    }
                }
            }
        }
    }

    /* Header Shrink */
    .header-shrink {
        .sidebar-trigger {
            &.sidebar-trigger-style-white {
                &:hover {
                    .sidebar-trigger-icon {
                        background: $color-green;

                        &:before,
                        &:after {
                            background: $color-green;
                        }
                    }
                }
            }
        }
    }
}


/* Topbar Contact */
.topbar-c {
    .topbar-c-contacts {
        .topbar-c-contacts-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Topbar v1 Actions */
.topbar-c {
    .topbar-c-actions {
        .topbar-c-actions-link {
            &:hover {
                color: $color-green;
            }
        }

        .topbar-c-actions-link-border {
            &:hover {
                background: $color-green;
                border-color: $color-green;
            }
        }
    }
}

/* Topbar Ecommerce */
.topbar-e {
    .topbar-e-list {
        .topbar-e-list-item {
            .topbar-e-list-dropdown {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Topbar v2 Actions */
.topbar-e {
    .topbar-e-actions {
        .topbar-e-actions-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Topbar v2 Shopping Cart */
.topbar-e {
    .topbar-e-sh-cart {
        .topbar-e-sh-cart-link {
            &:hover {
                color: $color-green;
            }
        }

        .topbar-e-sh-cart-menu-product-name {
            &:hover {
                color: $color-green;
            }
        }

        .topbar-e-sh-cart-close {
            &:hover {
                color: $color-green;
            }
        }

        .topbar-e-sh-cart-subtotal-price {
            color: $color-green;
        }

        .topbar-e-sh-cart-subtotal-view-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Topbar v3 List */
.topbar-t {
    .topbar-t-list {
        .topbar-t-list-item {
            .topbar-t-list-dropdown {
                &:hover {
                    color: $color-green;
                }
            }
        }

        .topbar-t-list-dropdown-menu {
            .topbar-t-list-dropdown-menu-link {
                &:hover {
                    background: $color-green;
                }
            }
        }
    }
}

/* Topbar v3 Shopping Cart */
.topbar-t {
    .topbar-t-sh-cart {
        .topbar-t-sh-cart-link {
            &:hover {
                color: $color-green;
            }
        }

        .topbar-t-sh-cart-menu-product-name {
            &:hover {
                color: $color-green;
            }
        }

        .topbar-t-sh-cart-close {
            &:hover {
                color: $color-green;
            }
        }

        .topbar-t-sh-cart-subtotal-price {
            color: $color-green;
        }

        .topbar-t-sh-cart-subtotal-view-link {
            &:hover {
                color: $color-green;
            }
        }
    }
}

/* Contact Us */
.contact-us {
    .contact-us-timeline-day {
        background: $color-green;
    }
}

/* Faq */
.faq {
    .faq-search {
        .faq-search-search {
            &:hover {
                .faq-search-search-icon {
                    color: $color-green;
                }
            }
        }
    }
}

/* Faq v2 */
.faq-v2 {
    .faq-v2-community {
        .faq-v2-community-icon {
            color: $color-green;
        }
    }
}

/* Animsition Loading */
.animsition-loading {
    border-left-color: $color-green;
}

/* Back To Top */
.back-to-top-theme {
    background: $color-green;

     &:hover {
        background: $color-green;
    }
}

/* Owl Dots */
.owl-dot,
.owl-dots {
    span {
        border-color: $color-green;
    }

    &.active {
        span {
            background: $color-green;
        }
    }
}

/* Testimonials v4 Arrows */
.testimonials-v4-arrows {
    .arrow-next,
    .arrow-prev {
        &:hover {
            @include bg-opacity($color-green, 1);
        }
    }
}

/* Owl Control Arrows v1 */
.owl-control-arrows-v1 {
    .owl-arrow {
        &:hover {
            background: $color-green;
        }
    }
}

/* Theme Portfolio Filter */
.theme-portfolio {
    .cbp-filter-item {
        &.cbp-filter-item-active {
            color: $color-green;
            border-color: $color-green;
        }

        &:hover {
            color: $color-green;
        }
    }

    .cbp-filter-counter {
        background: $color-green;

        &:after {
            border-top-color: $color-green;
        }
    }
}

/* Theme Portfolio Load More Button */
.theme-portfolio {
    .cbp-l-loadMore-button,
    .cbp-l-loadMore-text {
        .cbp-l-loadMore-link,
        .cbp-l-loadMore-button-link {
            color: $color-green;
            border-color: $color-green;

            &:hover {
                background: $color-green;
            }
        }
    }
}

/* Theme Portfolio Zoom Effect */
.theme-portfolio {
    .cbp-caption-zoom {
        .cbp-caption-activeWrap {
            @include bg-opacity($color-green, .6);
        }
    }
}

/* Theme Portfolio Item v2 */
.theme-portfolio {
    .theme-portfolio-item-v2 {
        &:hover {
            .theme-portfolio-active-wrap {
                &:before {
                    @include bg-opacity($color-green, .5);
                }
            }
        }
    }
}

/* Theme Portfolio Item v3 */
.theme-portfolio {
    .theme-portfolio-item-v3 {
        .theme-portfolio-lightbox {
            .theme-portfolio-item-v3-icon {
                &:hover {
                    &:before,
                    &:after {
                        background: $color-green;
                    }
                }
            }
        }
    }
}

/* Theme Portfolio Description */
.theme-portfolio-desc {
    .portfolio-item-category {
        &:hover {
            color: $color-green;
        }
    }
}

/* Theme Carousel Control v2 */
.theme-carousel-control-v2 {
    .carousel-control-arrows-v2 {
        &:hover {
            background: $color-green;
        }
    }
}

/* Theme Carousel Indicators v2 */
.theme-carousel-indicators-v2 {
    li {
        &.active {
            background: $color-green;
            border-color: $color-green;
        }
    }
}

/* Theme Carousel Indicators v3 */
.theme-carousel-indicators-v3 {
    li {
        &.active {
            color: $color-green;
            border-bottom-color: $color-green;
        }

        &:hover {
            border-bottom-color: $color-green;

            &.active {
                color: $color-green;
                border-bottom-color: $color-green;
            }
        }
    }
}

/* Theme Carousel Indicators v3 */
.theme-carousel-indicators-v4 {
    li {
        &.active {
            background: $color-green;
            border-color: $color-green;
        }
    }
}

/* Theme Carousel Indicators v5 */
.theme-carousel-indicators-v5 {
    li {
        &.active {
            .theme-carousel-indicators-item {
                border-color: $color-green;
            }
        }
    }
}

/* Revolution Slider */
/* Spinner */
.tp-loader {
    &.spinner2 {
        background: $color-green;
    }
}

/* Serviecs v6 */
.services-v6 {
    &:hover {
        &:before {
            @include bg-opacity($color-green, .8);
        }
    }
}

/* Serviecs v7 */
.services-v7 {
    .services-v7-link-icon {
        &:hover {
            background: $color-green;
            border-color: $color-green;
        }
    }
}

/* Serviecs v8 */
.services-v8 {
    &:hover {
        background: $color-green;
    }
}

/* Checkbox */
.checkbox {
    input[type="checkbox"],
    input[type="radio"] {
        &:checked {
            + {
                label {
                    &:before {
                        background: $color-green;
                        border-color: $color-green;
                    }
                }
            }
        }
    }
}


/* Checkbox */
.work-v1 {
    .work-v1-badge {
        background: $color-green;
    }

    .work-v1-list {
        .work-v1-category {
            .work-v1-category-link {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }
}

/* Promo Block v12 */
.promo-block-v12 {

    .promo-block-v12-form {
        &:focus {
            color: $color-green;
            @include placeholder($color-green);
        }
    }
}

/* Shopify Product */
.shopify-product {
    &:hover {
        .shopify-product-name {
            color: $color-green;
        }
    }
}

/* Language V1 */
.language-v1 {
    .language-v1-toggle {
        &:hover {
            color: $color-green;
        }
    }

    .language-v1-dropdown-link {
        &:hover {
            background: $color-green;
        }
    }
}

/* Language V1 White */
/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    .language-v1 {
        &.language-v1-style-white {
            .language-v1-toggle {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }

    /* Header Shrink */
    .header-shrink {
        .language-v1 {
            &.language-v1-style-white {
                .language-v1-toggle {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }
}

/* Language Push */
.language-push {
    .language-push-btn-icon {
        &:hover {
            color: $color-green;
        }
    }
}

/* Language Push White */
/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    .language-push {
        &.language-push-style-white {
            .language-push-btn-icon {
                &:hover {
                    color: $color-green;
                }
            }
        }
    }

    /* Header Shrink */
    .header-shrink {
        .language-push {
            &.language-push-style-white {
                .language-push-btn-icon {
                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }
}

/* Newsletter v6 */
.newsletter-v6 {
    .newsletter-v6-btn {
        &:hover {
            color: $color-green;
        }
    }
}

/* News v11 */
.news-v11 {
    .news-v11-subtitle {
        color: $color-green;
    }
}

/* Handle */
.image-comparison-container {
    .image-comparison-handle {
        background: $color-green;
    }
}

/* Testimonials v2 Arrows */
.testimonials-v2-arrows {
    .arrow-next,
    .arrow-prev {
        &:hover {
            @include bg-opacity($color-green, 1);
        }
    }
}

/* Background Icon */
.fuelux {
    .radio-checkbox-bg-icon {
        &.checked {
            &:before {
                background: $color-green;
                border-color: $color-green;
            }
        }
    }
}

/* Background Color */
.fuelux {
    .radio-checkbox-bg-color {
        &.checked {
            &:before {
                background: $color-green;
                border-color: $color-green;
            }
        }
    }
}

/* Border */
.fuelux {
    .radio-checkbox-brd {
        &.checked {
            &:before {
                border-color: $color-green;
            }
        }
    }
}

/* Border with Dot */
.fuelux {
    .radio-checkbox-brd-dot {
        &.checked {
            &:before {
                border-color: $color-green;
            }

            &:after {
                background: $color-green;
            }
        }
    }
}

.segment-controls {
    border-color: $color-green;
}


/* Label */
.segment-controls {
    .segment-controls-label {
        &:before {
            background: $color-green;
        }
    }
}

/* Color Green */
.color-base {
    color: $color-green !important;
}

/* Background Color Green */
.bg-color-base {
    background: $color-green !important;
}

