/*--------------------------------------------------
    [Pricing List v1]
----------------------------------------------------*/

.pricing-list-v1 {
    border: 2px solid $color-sky-light;
    padding: 10px;

    .pricing-list-v1-body {
        background: $color-sky-light;

        .pricing-list-v1-header {
            border-bottom: 2px solid $color-white;
            padding: 30px 0;
            margin: 0 30px;
        }

        .pricing-list-v1-header-title {
            @include font($size: $font-size-24, $weight: $font-weight-400);
            margin: 0 0 5px;
        }

        .pricing-list-v1-header-subtitle {
            @include font($size: $font-size-14, $style: italic, $family: $font-family-droid);
            color: $color-subtitle;
        }

        .pricing-list-v1-header-price-sign {
            @include position(relative, $top: -20px);
            @include font($size: $font-size-24, $weight: $font-weight-400);
            color: $color-dark;
        }

        .pricing-list-v1-header-price {
            @include font($size: 45px, $weight: $font-weight-500);
            color: $color-dark;

            &:after {
                @include font($size: $font-size-36, $weight: $font-weight-400);
                content: "/";
                margin-right: -5px;
            }
        }

        .pricing-list-v1-header-price-info {
            @include font($size: $font-size-22, $weight: $font-weight-400);
            color: $color-dark;
        }

        .pricing-list-v1-content {
            padding: 30px;
        }

        .pricing-list-v1-content-list {
            margin-bottom: 20px;

            > li {
                padding: 5px;
            }
        }
    }
}

/* Pricing List v1 Active */
.pricing-list-v1-active {
    z-index: 1;
    border-color: $color-sky-light;
    border-width: 2px;
    background: $color-white;

    .pricing-list-v1-body {
        background: $color-white;

        .pricing-list-v1-header {
            border-color: $color-sky-light;
        }

        .pricing-list-v1-header-price,
        .pricing-list-v1-header-price-sign,
        .pricing-list-v1-header-price-info {
            color: $color-base;
        }
    }
}

/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    .pricing-list-v1-active {
        @include transform-scale(1.01, 1.07);
    }
}


/*--------------------------------------------------
    [Pricing List v2]
----------------------------------------------------*/

.pricing-list-v2 {
    box-shadow: 0 2px 5px 3px lighten($color-tint-light, 1%);

    .pricing-list-v2-header {
        padding: 20px;
    }

    .pricing-list-v2-header-title {
        @include font($size: $font-size-20, $style: italic, $family: $font-family-droid);
        color: $color-white;
        text-transform: inherit;
        text-align: right;
        margin: 0;
    }

    .pricing-list-v2-header-price-sign {
        @include position(relative, $top: -22px);
        @include font($size: $font-size-20, $weight: $font-weight-400);
        color: $color-white;
    }

    .pricing-list-v2-header-price {
        @include font($size: $font-size-50, $weight: $font-weight-500);
        color: $color-white;

        &:after {
            @include font($size: $font-size-26, $weight: $font-weight-400);
            content: "/";
            margin-right: -5px;
        }
    }

    .pricing-list-v2-header-price-info {
        @include font($size: $font-size-18, $weight: $font-weight-500);
        color: $color-white;
    }

    .pricing-list-v2-content {
        background: $color-white;
        border: 1px solid $color-sky-light;
        padding: 30px;
    }

    .pricing-list-v2-content-list {
        margin-bottom: 20px;

        > li {
            padding: 5px;
        }
    }
}


/*--------------------------------------------------
    [Pricing List v3]
----------------------------------------------------*/

.pricing-list-v3 {
    text-align: center;
    padding: 80px 30px;

    .pricing-list-v3-border {
        border-right: 1px solid rgba(255,255,255,.7);
    }

    .pricing-list-v3-title {
        @include font($size: 40px);
        color: $color-white;
    }

    .pricing-list-v3-subtitle {
        display: block;
        @include font($size: $font-size-22, $weight: $font-weight-300);
        color: $color-white;
        margin-bottom: 10px;
    }

    .pricing-list-v3-text {
        display: block;
        @include font($size: $font-size-16, $style: italic, $family: $font-family-droid);
        color: $color-white;
        letter-spacing: $letter-spacing-1;
    }

    .pricing-list-v3-price-sign {
        @include position(relative, $top: -20px);
        @include font($size: $font-size-18);
        color: $color-white;
    }

    .pricing-list-v3-price {
        @include font($size: 40px, $weight: $font-weight-400);
        color: $color-white;
    }

    .pricing-list-v3-paragraph {
        color: $color-white;
    }
}

/* Media Queries below 480px */
@media (max-width: $screen-xs-min) {
    .pricing-list-v3 {
        .pricing-list-v3-border {
            border-right: none;
            border-bottom: 1px solid rgba(255,255,255,.7);
            padding-bottom: 30px;
        }
    }
}


/*--------------------------------------------------
    [Pricing List v4]
----------------------------------------------------*/

.pricing-list-v4 {
    text-align: center;
    background: $color-white;
    box-shadow: 15px 15px 15px 0 lighten($color-tint-light, 1%);

    .pricing-list-v4-header {
        border-bottom: 1px solid darken($color-tint-light, 2%);
        padding: 40px 20px 40px;
        margin: 10px 30px;

        .pricing-list-v4-title {
            @include font($size: $font-size-36);
            margin-bottom: 0;
        }

        .pricing-list-v4-subtitle {
            display: block;
            @include font($size: $font-size-16, $weight: $font-weight-300);
        }
    }

    .pricing-list-v4-content {
        padding: 40px 20px;

        .pricing-list-v4-price-sign {
            @include position(relative, $top: -30px);
            @include font($size: $font-size-20);
        }

        .pricing-list-v4-price {
            @include font($size: $font-size-70, $weight: $font-weight-400);
            line-height: .5;
            letter-spacing: -3px;
        }

        .pricing-list-v4-subprice {
            @include font($size: $font-size-30, $weight: $font-weight-400);
        }

        .pricing-list-v4-price-info {
            position: relative;
            display: block;
            letter-spacing: $letter-spacing-1;

            &:before {
                @include font($size: $font-size-22, $weight: $font-weight-300);
                content: "/";
                margin-right: 3px;
            }
        }
    }
}
