/*--------------------------------------------------
    [Header]
----------------------------------------------------*/

.header-transparent {
    border: none;
    background: transparent;
    @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));

    &.header-transparent-bb {
        border-bottom: 1px solid rgba(255,255,255,.5);
    }
}

/* Auto Hiding Navbar */
.header-transparent {
    &.auto-hiding-navbar {
        &.nav-up {
            top: -80px;
        }
    }
}

/* Navbar */
.header-transparent {
    .navbar {
        background: transparent;
        border: none;
        border-radius: 0;
        margin-bottom: 0;
    }
}

/* Navbar Toggle */
.header-transparent {
    .navbar-toggle {
        @include size(25px);
        float: left;
        border: none;
        padding: 0;
        margin: 32px 0;

        .toggle-icon {
            position: relative;
            @include size(15px, 1px);
            display: inline-block;
            background: $color-dark;
            @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));

            &:before,
            &:after {
                @include position(absolute, $left: 0);
                @include size(15px, 1px);
                background: $color-dark;
                content: " ";
            }
            &:before {
                bottom: 10px;
                @include rotate(0);
                @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
            }
            &:after {
                top: -5px;
                @include rotate(0);
                @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
            }

            &.is-clicked {
                @include bg-opacity($color-dark, 0);

                &:before,
                &:after {
                    background: $color-dark;
                }
                &:before {
                    bottom: 5px;
                    @include rotate(45deg);
                    @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                }
                &:after {
                    top: -5px;
                    @include rotate(-45deg);
                    @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                }
            }
        }

        &:hover {
            .toggle-icon {
                background: $color-base;

                &:before,
                &:after {
                    background: $color-base;
                }

                &.is-clicked {
                    @include bg-opacity($color-dark, 0);
                }
            }
        }
    }
}

/* Topbar Toggle Trigger */
.header-transparent {
    .topbar-toggle-trigger {
        float: left;
        @include size(27px);
        text-align: center;
        cursor: pointer;
        padding: 35px 0 55px;

        .topbar-toggle-trigger-style {
            @include position(relative, $top: 8px);
            display: block;
            @include size(4px);
            text-align: center;
            background: $color-dark;
            border-radius: 50%;
            content: " ";
            margin: 0 auto;

            &:before,
            &:after {
                @include position(absolute, $left: 50%);
                @include size(4px);
                background: $color-dark;
                border-radius: 50%;
                content: " ";
                margin-left: -2px;
            }
            &:before {
                top: -6px;
            }
            &:after {
                bottom: -6px;
            }
        }

        &:hover {
            .topbar-toggle-trigger-style {
                background: $color-base;

                &:before,
                &:after {
                    background: $color-base;
                }
            }
        }
    }
}

/* Topbar Toggle Trigger Icon */
.header-transparent {
    .topbar-toggle-trigger-icon-style {
        color: $color-dark;
        padding: 2px 5px;
        @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));

        &:hover {
            color: $color-base;
            @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
        }
    }
}

/* Navbar Actions */
.header-transparent {
    .navbar-actions {
        position: relative;
        float: right;

        .navbar-actions-shrink {
            line-height: 90px;
            float: left;
            @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
        }
    }
}

/* Shopping Cart */
.header-transparent {
    .shopping-cart {
        position: relative;

        .shopping-cart-icon {
            @include font($size: $font-size-18);
            color: $color-white;
            text-align: center;
            cursor: pointer;
            padding: 0 9px;
            @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));

            &:hover {
                color: $color-base;
            }
        }

        .shopping-cart-amount {
            @include position(absolute, $top: 20px, $right: -3px);
            @include size(20px);
            display: inline-block;
            @include font($size: $font-size-12, $weight: $font-weight-500);
            color: $color-white;
            line-height: $line-height-base;
            text-align: center;
            background: $color-base;
            vertical-align: middle;
            padding: 2px;
            @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
        }

        .shopping-cart-menu {
            @include position(absolute, $top: 100%, $right: 0);
            width: 310px;
            display: none;
            line-height: $line-height-base;
            z-index: 1;
            background: $color-white;
            border: 1px solid $color-tint-light;
            border-top: 2px solid $color-base;
        }

        .shopping-cart-menu-title {
            display: block;
            @include font($size: $font-size-20, $weight: $font-weight-400);
            color: $color-dark;
            border-bottom: 1px solid $color-tint-light;
            padding: 15px;
        }

        .shopping-cart-menu-content {
            display: block;
            padding: 15px;
            @include clearfix;
            border-bottom: 1px solid $color-tint-light;
        }

        .shopping-cart-menu-product-media {
            float: left;
            margin-right: 15px;

            .shopping-cart-menu-product-img {
                @include size(60px, auto);
            }
        }

        .shopping-cart-menu-product-wrap {
            float: left;
            margin-right: 15px;
            @include clearfix;
        }

        .shopping-cart-menu-product-name {
            display: block;
            @include font($size: $font-size-15);
            color: $color-dark;

            &:hover {
                color: $color-base;
                text-decoration: none;
            }
        }

        .shopping-cart-menu-product-price {
            display: block;
            @include font($size: $font-size-14);
            color: darken($color-subtitle, 5%);
            margin-top: 5px;
        }

        .shopping-cart-close {
            float: right;
            @include font($size: $font-size-16);
            color: darken($color-subtitle, 5%);
            @include clearfix;

            &:hover {
                color: $color-base;
                text-decoration: none;
            }
        }

        .shopping-cart-subtotal {
            padding: 15px;
        }

        .shopping-cart-subtotal-content {
            display: block;
            margin-bottom: 10px;
            @include clearfix;
        }

        .shopping-cart-subtotal-title {
            @include font($size: $font-size-16);
            color: $color-dark;
        }

        .shopping-cart-subtotal-price {
            @include font($size: $font-size-16);
            color: $color-base;
            float: right;
        }

        .shopping-cart-subtotal-view {
            @include font($size: $font-size-12);
            text-align: center;
            margin-bottom: 0;
        }

        .shopping-cart-subtotal-view-link {
            @include font($size: $font-size-13);
            color: $color-dark;

            &:hover {
                color: $color-base;
            }
        }

        &:hover {
            .shopping-cart-menu {
                display: block;
            }
        }
    }
}

/* Navbar Logo */
.header-transparent {
    .navbar-logo {
        @include size(100px);
        float: left;
        max-height: 90px;
        line-height: 60px;
        @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));

        .navbar-logo-wrap {
            display: inline-block;
            padding: 15px 0;

            &:focus,
            &:hover {
                text-decoration: none;
            }
        }

        .navbar-logo-img {
            display: inline-block;
            @include size(85px, auto);
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
            @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));

            &.navbar-logo-img-white {
                display: inline-block;
                @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
            }
            &.navbar-logo-img-dark {
                display: none;
                @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
            }
        }
    }
}

/* Navbar Nav */
.header-transparent {
    .navbar-nav {
        .nav-item-child {
            @include font($size: $font-size-13, $weight: $font-weight-500);
            color: $color-white;
            background: transparent;
            letter-spacing: $letter-spacing-1;
            text-transform: uppercase;
            padding: 5px 17px;
            @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));

            &.active {
                color: $color-base;
                @include bg-opacity($color-white, .9);

                &:hover {
                    color: $color-base;
                    @include bg-opacity($color-white, .9);
                }
            }

            &:hover,
            &:focus {
                color: $color-base;
                @include bg-opacity($color-white, .9);
            }
        }

        .open {
            .nav-item-child {
                &:hover,
                &:focus {
                    color: $color-base;
                    @include bg-opacity($color-white, .9);
                }
            }
        }
    }
}

/* Dropdown Menu */
.header-transparent {
    .dropdown-menu {
        min-width: 235px;
        line-height: $line-height-base;
        border: none;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        margin-top: 1px;

        .dropdown-menu-item {
            color: lighten($color-dark, 10%);

            .dropdown-menu-item-child {
                @include font($size: $font-size-13, $weight: $font-weight-400);
                color: lighten($color-dark, 10%);
                text-transform: uppercase;
                padding: 8px 20px;
                @include transition(all, .3s ease-in-out);

                &.active {
                    color: $color-base;

                    &:hover {
                        color: $color-base;
                        background: $color-white;
                    }
                }

                &:focus {
                    background: 0;
                }

                &:hover {
                    color: $color-white;
                    background: $color-base;
                    padding-left: 25px;
                    @include transition(all, .3s ease-in-out);
                }
            }

            &.active {
                .dropdown-menu-item-child {
                    color: $color-base;

                    &:hover {
                        background: 0;
                    }
                }
            }
        }
    }
}

/* Dropdown Submenu */
.header-transparent {
    .dropdown-submenu {
       position: relative;
       line-height: $line-height-base;

        &:hover {
            .dropdown-submenu-child {
                color: $color-base;
            }

            .dropdown-menu {
                display: block;
            }
        }

        .dropdown-submenu-child {
            display: block;
            @include font($size: $font-size-13, $weight: $font-weight-400);
            color: lighten($color-dark, 10%);
            text-transform: uppercase;
            cursor: pointer;
            padding: 8px 20px;
            margin: 0;
            @include transition(all, .3s ease-in-out);

            &:after {
                @include position(absolute, $top: 8px, $right: 12px);
                display: inline-block;
                @include font($size: $font-size-12, $family: $font-family-font-awesome);
                content: "\f105";
            }

            &.active {
                color: $color-base;

                &:hover {
                    color: $color-base;
                    background: $color-white;

                    &:after {
                        color: $color-base;
                    }
                }
            }

            &:hover {
                color: $color-white;
                background: $color-base;
                padding-left: 25px;
                @include transition(all, .3s ease-in-out);

                &:after {
                    color: $color-white;
                }
            }
        }

        .dropdown-menu {
            top: -1px;
            left: 100%;
            margin-left: -0.8px;

            &.dropdown-menu-left {
                left: -100%;
                margin-left: 0.5px;
            }
        }

        &.active {
            .dropdown-submenu-child {
                color: $color-base;

                &:hover {
                    color: $color-base;
                    background: $color-white;
                }
            }
        }
    }
}

/* Mega Menu */
.header-transparent {
    .mega-menu-list {
        .mega-menu-title {
            display: block;
            @include font($size: $font-size-14, $weight: $font-weight-500);
            color: $color-dark;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        .mega-menu-child {
            display: block;
            @include font($size: $font-size-13, $weight: $font-weight-400);
            color: lighten($color-dark, 10%);
            text-transform: uppercase;
            text-decoration: none;
            padding: 8px 15px 8px 0;
            margin: 0;
            @include transition(all, .3s ease-in-out);

            &.active {
                color: $color-base;

                &:hover {
                    color: $color-base;
                    background: $color-white;
                }
            }

            &:hover {
                color: $color-white;
                background: $color-base;
                text-decoration: none;
                padding-left: 10px;
                @include transition(all, .3s ease-in-out);
            }
        }
    }
}

/* Media Queries below 991px */
@media (max-width: $screen-sm-max) {
    /* Bootstrap collapse of navigation with a maximum width: 991px
    (Change it to any breakpoint you want to be collapsed) */
    .header-transparent {
        .navbar-toggle {
            display: block;
        }

        .navbar-collapse {
            &.collapse {
                display: none !important;

                &.in {
                    display: block !important;
                    overflow-y: auto !important;
                }
            }
        }

        .navbar-nav {
            margin: 0;
            float: none;

            .nav-item {
                float: none;
            }
        }
    }

    /* Navbar Fixed Top */
    .header-transparent {
        background: $color-white;

        &.navbar-fixed-top,
        &.navbar-fixed-top-boxed {
            position: relative;

            .nav-collapse {
                max-height: none;
                padding: 0;
                margin: 0;
            }
        }
    }

    /* Menu Container */
    .header-transparent {
        .menu-container {
            @include clearfix;
        }
    }

    /* Shopping Cart */
    .header-transparent {
        .shopping-cart {
            .shopping-cart-icon {
                color: $color-dark;
            }
        }
    }

    /* Navbar Logo */
    .header-transparent {
        .navbar-logo {
            @include size(auto, 100%);
            display: block;
            float: none;
            text-align: center;
            margin: 0 70px;

            .navbar-logo-img {
                &.navbar-logo-img-white {
                    display: none;
                    @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                }
                &.navbar-logo-img-dark {
                    display: inline-block;
                    @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                }
            }
        }
    }

    /* Navbar Nav */
    .header-transparent {
        .navbar-nav {
            .nav-item {
                border-top: 1px solid $color-tint-light;

                &.open {
                    .nav-item-child {
                        &:after {
                            @include rotate(90deg);
                            @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                        }
                    }
                }
            }

            .nav-item-child {
                color: $color-dark;
                line-height: $line-height-base;
                padding: 12px 12px 12px 0;
            }

            .dropdown-toggle {
                position: relative;

                &:after {
                    @include position(absolute, $top: 12px, $right: 0);
                    display: inline-block;
                    @include font($size: $font-size-12, $family: $font-family-font-awesome);
                    content: "\f105";
                    @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                }
            }

            .nav-item-bg {
                display: inline-block;
                border-top: none;
                border: 1px solid $color-base;
                margin: 10px 0 20px;
                @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));

                .nav-item-child {
                    color: $color-white;
                    line-height: 20px;
                    padding: 7px 15px;
                    background: $color-base;

                    &:hover {
                        color: $color-white;
                    }
                }
            }
        }
    }

    /* Dropdown Menu */
    .header-transparent {
        .dropdown-menu {
            position: static;
            float: none;

            .dropdown-menu-item {
                &:last-child {
                    margin-bottom: 20px;
                }
            }
        }
    }

    /* Dropdown Submenu */
    .header-transparent {
        .dropdown-submenu {
            .dropdown-submenu-child {
                @include font($weight: $font-weight-500);

                &:after {
                    display: none;
                }

                &:hover {
                    cursor: inherit;
                }
            }

            .dropdown-menu {
                display: block;
                padding-left: 20px;
                margin-bottom: 20px;
            }

            &:hover {
                .dropdown-submenu-child {
                    color: $color-dark;
                    background: 0;
                }
            }
        }
    }

    /* Mega Menu */
    .header-transparent {
        .mega-menu-list {
            margin-bottom: 20px;

            .mega-menu-title {
                @include font($size: $font-size-12);
                padding: 8px 20px;
                margin-bottom: 0;
            }

            .mega-menu-item {
                margin-left: 20px;
            }
        }
    }
}

/* Media Queries below 767px */
@media (max-width: $screen-xs-max) {
    /* Menu Container */
    .header-transparent {
        .menu-container {
            padding-left: 15px;
            padding-right: 15px;
            margin-left: 0;
            margin-right: 0;
        }

        .navbar {
            > .container {
                width: auto;
                padding-left: 0;
                padding-right: 0;
                margin-left: 0;
                margin-right: 0;

                > .nav-collapse {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    /* Theme Toggle Trigger */
    .header-transparent {
        .theme-toggle-trigger {
            display: block;
        }

        .theme-toggle-content {
            display: none;
        }
    }
}

/* Media Queries above 768px */
@media (min-width: $screen-sm-min) {
    /* Navbar Fixed Top Boxed */
    .header-transparent {
        &.navbar-fixed-top-boxed {
            &.navbar-fixed-top-boxed {
                @include position(fixed, $top: 0, $left: auto, $right: auto);
            }
        }
    }

    /* Theme Toggle Trigger */
    .header-transparent {
        .theme-toggle-trigger {
            display: none !important;
        }

        .theme-toggle-content {
            display: block !important;
        }
    }
}

/* Media Queries above 992px */
@media (min-width: $screen-md-min) {
    /* Header Shrink */
    .header-shrink {
        &.header-transparent {
            background: $color-white;
            @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));

            &:before,
            &:after {
                @include position(absolute, $top: 50%, $left: 10px, $right: 10px, $bottom: 0);
                z-index: -1;
                box-shadow: 0 0 15px lighten($color-dark-light, 67%);
                border-radius: 1000px / 30px;
                content: " ";
            }

            &:after {
                left: auto;
                right: 10px;
            }

            .navbar {
                background: $color-white;
            }

            .navbar-logo {
                @include size(85px);
                max-height: 70px;
                line-height: 35px;
                @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));

                .navbar-logo-wrap {
                    padding: 13px 0;
                }

                .navbar-logo-img {
                    &.navbar-logo-img-white {
                        display: none;
                        @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                    }
                    &.navbar-logo-img-dark {
                        display: inline-block;
                        @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                    }
                }
            }

            .navbar-actions {
                .navbar-actions-shrink {
                    line-height: 70px;
                    @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                }

                .shopping-cart-amount {
                    top: 10px;
                    @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                }
            }

            /* Shopping Cart */
            .shopping-cart {
                .shopping-cart-icon {
                    color: $color-dark;

                    &:hover {
                        color: $color-base;
                    }
                }
            }

            .navbar-nav {
                .nav-item-child {
                    color: $color-dark;
                    margin: 20px 5px;
                    @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                }

                .nav-item-bg {
                    margin: 0 15px 0 0;
                    @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));
                }
            }
        }
    }

    /* Navbar */
    .header-transparent {
        .navbar-nav {
            float: right;

            .nav-item {
                line-height: 90px;

                .dropdown-menu {
                    z-index: 9999;
                    min-width: 235px;
                    border: none;
                    border-radius: 0;
                    border-top: 2px solid $color-base;
                }

                .open {
                    > .dropdown-menu {
                        display: none;
                    }
                }

                &:hover {
                    .nav-item-child {
                        color: $color-base;
                        @include bg-opacity($color-white, .9);
                    }

                    > .dropdown-menu {
                        display: block;
                    }
                }
            }

            .nav-item-child {
                margin: 30px 5px;

                &.active {
                    color: $color-base;
                    @include bg-opacity($color-white, .9);
                }
            }

            .nav-item-bg {
                margin: 0 15px 0 0;
                @include cubic-transition($delay: 0, $duration: 400ms, $property: (all));

                .nav-item-child {
                    color: $color-white;
                    background: $color-base;

                    &:hover {
                        color: $color-white;
                        background: $color-base;
                    }
                }

                &:hover {
                    .nav-item-child {
                        color: $color-white;
                        background: $color-base;
                    }
                }
            }
        }
    }

    /* Mega Menu */
    .header-transparent {
        .mega-menu .nav,
        .mega-menu .collapse,
        .mega-menu .dropdown,
        .mega-menu .dropup {
            position: static;
        }

        .mega-menu {
            .container {
                position: relative;
            }
        }

        .dropdown-menu {
            left: auto;
        }

        .mega-menu-fullwidth {
            .dropdown-menu {
                left: 15px;
                right: 15px;
                padding: 25px 0;
            }
        }

        .mega-menu-content {
            padding: 0 25px;
        }

        .mega-menu-list {
            margin-bottom: 0;

            .mega-menu-item {
                padding: 0;
            }

            .mega-menu-title {
                display: block;
            }
        }
    }
}
