/**
 * Navbar
 */
 .header-transparent .navbar-nav .nav-item-child {
    font-weight: 300;
 }
.header .navbar-nav .nav-item-child {
    font-weight: 300;
}
.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child {
    font-weight: 300;
}
.header .dropdown-submenu .dropdown-submenu-child {
    font-weight: 300;
}



.tooltip-inner {
    width: 500px;
}

.impersonating {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 20px;
    background: #fcf8e3;
    border: 1px solid #faf2cc;
     z-index: 1051;

    & > .btn {
        margin-left: 15px;
    }
}

/**
 * General styles
 */
 h1, h2, h3, h4, h5, h6 {
    margin: 10px 0 10px;
 }
 p {
    margin: 0 0 15px;
}
 ol, ul {
    margin-bottom: 20px;
 }
 th em {
    font-weight: bold;
 }

 .lists-base > li {
    font-size: 15px;
}

.lists-base > li > a {
    color: $color-verdun-green !important;
    font-size: 15px;
    &:hover {
        text-decoration: underline;
    }
}
.list-unstyled .lists-child {
    margin-left: 30px;
}
h4 {
    margin:20px 0 10px;
}

.footer-v6-copyright a {
    color: #606060;
    margin-right: 20px;
    font-size: 20px;
}

.footer-v6-copyright img {
    margin-right: 20px;
}

/**
 * Home styles
 */
#earn-banner .heading-v1-title {
    color: #ffffff;
    font-size: 28px;
    font-style: italic;
    font-family: Droid Serif, serif;
}
#earn-banner h3 {
    font-weight: 300;
    font-family: Roboto, sans-serif;
    color: #ffffff;
    line-height: 1.4;
    margin: 0 0 15px;
}
#earn-banner .heading-v1-text {
    color: #ffffff;
    font-size: 18px;
    font-style: italic;
    margin-bottom: 0;
}

/**
 * Profile edit styles
 */
.table-wrap .panel-title a{
    font-size: 18px;
}

#profile-edit .panel-heading a {    
    color: #BAF402;
}

/**
 * Breadcrumbs
 */
.breadcrumbs-v5 {
     /**
     * 404 Page not found
     */
    &.breadcrumbs-v5-bg-img-page-not-found {
        background: url(../../app/assets/img/breadcrumbs/page_not_found_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
    /**
     * Join Nav
     */
     &.breadcrumbs-v5-bg-img-why-join {
        background: url(../../app/assets/img/breadcrumbs/why_join_saiba.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
    &.breadcrumbs-v5-bg-img-join {
        background: url(../../app/assets/img/breadcrumbs/join_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
    &.breadcrumbs-v5-bg-img-business-accountant-sa {
        background: url(../../app/assets/img/breadcrumbs/designation_basa_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
    &.breadcrumbs-v5-bg-img-certified-business-accountant-sa {
        background: url(../../app/assets/img/breadcrumbs/designation_cbasa_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 30%;
    }
    &.breadcrumbs-v5-bg-img-cert-fin-officer-sa {
        background: url(../../app/assets/img/breadcrumbs/designation_cfo_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
    &.breadcrumbs-v5-bg-img-business-accountant-in-practice-sa {
        background: url(../../app/assets/img/breadcrumbs/designation_bapsa_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
    &.breadcrumbs-v5-bg-img-fees {
        background: url(../../app/assets/img/breadcrumbs/fees_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 35%;
    }
    &.breadcrumbs-v5-bg-img-exemptions {
        background: url(../../app/assets/img/breadcrumbs/exemptions_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 30%;
    }
    &.breadcrumbs-v5-bg-img-professional-indemnity {
        background: url(../../app/assets/img/breadcrumbs/professional_indemnity.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 30%;
    }
    /**
     * About Nav
     */
    &.breadcrumbs-v5-bg-img-about {
        background: url(../../app/assets/img/breadcrumbs/about_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 70%;
    }
    &.breadcrumbs-v5-bg-img-team {
        background: url(../../app/assets/img/breadcrumbs/team_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
    &.breadcrumbs-v5-bg-img-partners {
        background: url(../../app/assets/img/breadcrumbs/partners_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 10%;
    }
    &.breadcrumbs-v5-bg-img-membership {
        background: url(../../app/assets/img/breadcrumbs/membership_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 30%;
    }
    &.breadcrumbs-v5-bg-img-members {
        background: url(../../app/assets/img/breadcrumbs/members_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 10%;
    }
    &.breadcrumbs-v5-bg-img-board {
        background: url(../../app/assets/img/breadcrumbs/board_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
    /**
     * CPD Nav
     */
    &.breadcrumbs-v5-bg-img-events-conferences {
        background: url(../../app/assets/img/breadcrumbs/events_conferences_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
    &.breadcrumbs-v5-bg-img-events-seminars {
        background: url(../../app/assets/img/breadcrumbs/events_seminars_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
    &.breadcrumbs-v5-bg-img-events-webinars {
        background: url(../../app/assets/img/breadcrumbs/events_webinars_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    &.breadcrumbs-v5-bg-img-events-partners {
        background: url(../../app/assets/img/breadcrumbs/events_partners_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
    &.breadcrumbs-v5-bg-img-certificate-courses {
        background: url(../../app/assets/img/breadcrumbs/certificate_courses_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    &.breadcrumbs-v5-bg-img-unisa-courses {
        background: url(../../app/assets/img/breadcrumbs/unisa_courses_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
    &.breadcrumbs-v5-bg-img-guide {
        background: url(../../app/assets/img/breadcrumbs/guide_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 30%;
    }
    /**
     * Publications Nav
     */
    &.breadcrumbs-v5-bg-img-black_friday {
        background: url(../../assets/img/black_friday.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 30%;
        padding: 450px 0 100px;
    }
     &.breadcrumbs-v5-bg-img-accounting-weekly {
        background: url(../../app/assets/img/breadcrumbs/accounting_weekly_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 30%;
    }
    &.breadcrumbs-v5-bg-img-bareview {
        background: url(../../app/assets/img/breadcrumbs/bareview_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 30%;
    }
    &.breadcrumbs-v5-bg-img-press-releases {
        background: url(../../app/assets/img/breadcrumbs/press_releases_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 30%;
    }
    &.breadcrumbs-v5-bg-img-volunteer{
        background: url(../../app/assets/img/breadcrumbs/mandela_day_11-min.jpg) no-repeat;
        background-size: cover;
        background-position: 0%;
    }
    &.breadcrumbs-v5-bg-img-practice-of-the-month{
        background: url(../../app/assets/img/breadcrumbs/practice_of_the_month_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 10%;
    }
    &.breadcrumbs-v5-bg-img-practice-of-the-month-ardent-accounting-tax-advisory{
        background: url(../../app/assets/img/breadcrumbs/practice_of_the_month_ardent_accounting_tax_advisory.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 10%;
    }
    &.breadcrumbs-v5-bg-img-woman-in-finance{
        background: url(../../app/assets/img/breadcrumbs/woman_in_finance_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 30%;
    }
    &.breadcrumbs-v5-bg-img-approved-training-center{
        background: url(../../app/assets/img/breadcrumbs/approved_training_center_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
    &.breadcrumbs-v5-bg-img-cipc-pilot-program{
        background: url(../../app/assets/img/breadcrumbs/cipc_pilot_program_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    &.breadcrumbs-v5-bg-img-npo-assist{
        background: url(../../app/assets/img/breadcrumbs/npo_assist_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    /**
    * Standards Nav
    */
    &.breadcrumbs-v5-bg-img-code-of-conduct {
        background: url(../../app/assets/img/breadcrumbs/code_of_conduct_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
    &.breadcrumbs-v5-bg-img-financial-reporting {
        background: url(../../app/assets/img/breadcrumbs/financial_reporting_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    &.breadcrumbs-v5-bg-img-engagements {
        background: url(../../app/assets/img/breadcrumbs/engagements_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    &.breadcrumbs-v5-bg-img-eme-affidavits {
        background: url(../../app/assets/img/breadcrumbs/eme_affidavits_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    &.breadcrumbs-v5-bg-img-eme-reports {
        background: url(../../app/assets/img/breadcrumbs/eme_reports_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    &.breadcrumbs-v5-bg-img-npos {
        background: url(../../app/assets/img/breadcrumbs/npos_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    &.breadcrumbs-v5-bg-img-accounting-officer {
        background: url(../../app/assets/img/breadcrumbs/accounting_officer_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
    &.breadcrumbs-v5-bg-img-commissioner-of-oaths {
        background: url(../../app/assets/img/breadcrumbs/commissioner_of_oaths_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
    &.breadcrumbs-v5-bg-img-independent-review {
        background: url(../../app/assets/img/breadcrumbs/independent_review_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
    &.breadcrumbs-v5-bg-img-tax {
        background: url(../../app/assets/img/breadcrumbs/tax_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 10%;
    }
    /**
     * Footer governance
     */
     &.breadcrumbs-v5-bg-img-company-rules {
        background: url(../../app/assets/img/breadcrumbs/company_rules_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    &.breadcrumbs-v5-bg-img-board-and-secreteriat{
        background: url(../../app/assets/img/breadcrumbs/board_and_secreteriat_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
    &.breadcrumbs-v5-bg-img-annual-reports {
        background: url(../../app/assets/img/breadcrumbs/annual_reports_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
    &.breadcrumbs-v5-bg-img-bbbee {
        background: url(../../app/assets/img/breadcrumbs/bbbee_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    &.breadcrumbs-v5-bg-img-paia {
        background: url(../../app/assets/img/breadcrumbs/paia_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 10%;
    }
    &.breadcrumbs-v5-bg-img-moi{
        background: url(../../app/assets/img/breadcrumbs/moi_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 30%;
    }
    &.breadcrumbs-v5-bg-img-complaints{
        background: url(../../app/assets/img/breadcrumbs/complaints_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }

    /**
     * Footer support
     */
     &.breadcrumbs-v5-bg-img-faq {
        background: url(../../app/assets/img/breadcrumbs/faq_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
    &.breadcrumbs-v5-bg-img-support {
        background: url(../../app/assets/img/breadcrumbs/support_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 10%;
    }
    &.breadcrumbs-v5-bg-img-terms {
        background: url(../../app/assets/img/breadcrumbs/terms_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 0%;
    }
    &.breadcrumbs-v5-bg-img-privacy {
        background: url(../../app/assets/img/breadcrumbs/privacy_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 20%;
    }
    &.breadcrumbs-v5-bg-img-technical {
        background: url(../../app/assets/img/breadcrumbs/technical_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
    &.breadcrumbs-v5-bg-img-technical {
        background: url(../../app/assets/img/iafei-articles.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }
}

.pricing-list-v1 {
    border: none;
    padding: 0;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-header {
    border-bottom: 2px solid rgb(247, 248, 250);
}

.pricing-list-v1 .pricing-list-v1-body {
    background: #ffffff;
}

#why-join .tab-v2 .nav-tabs > li > a {
    padding: 8px 8px;
}

#why-join .clients-v1 .clients-v1-item {
    width: 100px;
    height: 100px;
}

#why-join .clients-v1 .clients-v1-item .clients-v1-img {
    width: 100px;
    height: 100px;
}
 
.tab-content {
    text-align: left;
}

.tab-v5 .tab-content .tab-pane {
    padding: 0 25px;
}

.tab-v5 .tab-content .tab-pane p {
    margin-top: 10px;
}

.breadcrumbs-v1 .breadcrumbs-v1-title {
    font-size: 18px;
}

.blog-classic .blog-classic-title {
    font-size: 20px;
}

.blog-classic .blog-classic-subtitle {
    font-size: 15px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
}

.padding-all-10px {
    padding: 10px !important;
}
/*--------------------------------------------------
    [Account Breadcrumbs]
----------------------------------------------------*/
.breadcrumbs-account {

    position: relative;
    z-index: 1;
    background: url(../../assets/img/1920x1080/48.jpg) no-repeat;
    background-size: cover;
    background-position: 50% 20%;
    padding: 170px 0 100px;

    &:before {
        @include position(absolute, $top: 0, $left: 0);
        z-index: -1;
        @include size(100%);
        @include bg-opacity($color-dark, .5);
        content: " ";
    }

    &.breadcrumbs-account-bg-business-accountant-sa {
        background: url(../../app/assets/img/home/ba_designationblock.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

    &.breadcrumbs-account-bg-certified-business-accountant-sa {
        background: url(../../app/assets/img/home/cba_designationblock.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 100%;
    }

    &.breadcrumbs-account-bg-cert-fin-officer-sa {
        background: url(../../app/assets/img/home/cfo_designationblock.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

    &.breadcrumbs-account-bg-business-accountant-in-practice-sa {
        background: url(../../app/assets/img/home/bap_designationblock.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

    &.breadcrumbs-account-bg-none {
        background: url(../../app/assets/img/breadcrumbs/privacy_block.jpg) no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

    .breadcrumbs-account-picture {
        float: left;
        margin-right: 25px;
    }

    .breadcrumbs-account-title {
        @include font($size: $font-size-50, $weight: $font-weight-300);
        color: $color-white;
    }

    .breadcrumbs-account-subtitle {
        display: block;
        @include font($size: $font-size-18, $style: italic, $family: $font-family-droid);
        color: $color-white;
        opacity: .8;
    }

    .breadcrumbs-account-divider {
        @include font($size: $font-size-18);
        color: $color-white;
        padding: 0 10px;
    }

    .breadcrumbs-account-video {
        display: inline-block;
        @include font($size: $font-size-22, $weight: $font-weight-300);
        color: $color-white;
        border-bottom: 2px solid $color-white;
        padding-bottom: 3px;
        text-decoration: none;
    }
}

/*--------------------------------------------------
    [Call To Action v1]
----------------------------------------------------*/

.call-to-action-v1 {
    position: relative;
    z-index: 1;
    background: url(../../app/assets/img/home/join.jpg) no-repeat;
    background-size: cover;
    background-position: center top;
}


/*--------------------------------------------------
    [Table Content Alignment]
----------------------------------------------------*/

.table-align {
    vertical-align: top;
}


/*--------------------------------------------------
    [Clients v2]
----------------------------------------------------*/

.clients-v2 {
    position: relative;
    z-index: 1;
    text-align: center;
    background: url(../../app/assets/img/home/partners.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
}

/*--------------------------------------------------
    [Join Blocks]
----------------------------------------------------*/
.join-block-v1 {
    text-align: left;
    background: $color-white;
    box-shadow: 15px 15px 15px 0 lighten($color-tint-light, 1%);

    .join-block-v1-header {
        // border-bottom: 1px solid darken($color-tint-light, 2%);
        padding: 40px 20px 20px;
        margin: 10px 10px;
        
        .join-block-v1-title {
            @include font($size: $font-size-32);
            text-align: center;
            margin-bottom: 0;
        }

        .join-block-v1-subtitle {
            text-align: center;
            display: block;
            @include font($size: $font-size-16, $weight: $font-weight-300);
        }
    }

    .join-block-v1-content {
        padding: 10px 30px;        

        .join-block-v1-price-sign {
            @include position(relative, $top: -30px);
            @include font($size: $font-size-20);
        }

        .join-block-v1-price {
            @include font($size: $font-size-70, $weight: $font-weight-400);
            line-height: .5;
            letter-spacing: -3px;
        }

        .join-block-v1-subprice {
            @include font($size: $font-size-30, $weight: $font-weight-400);
        }

        .join-block-v1-price-info {
            position: relative;
            display: block;
            letter-spacing: $letter-spacing-1;
            
            &:before {
                @include font($size: $font-size-22, $weight: $font-weight-300);
                content: "/";
                margin-right: 3px;
            }
        }
    }
}

/*------------------------------------------------------------------
    [Promo Block v1]
------------------------------------------------------------------*/

.promo-block-v1 {
    position: relative;
    z-index: 1;
    background: url(../../app/assets/img/home/banner.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
}

/*--------------------------------------------------
    [Membership sidebar v1]
----------------------------------------------------*/

.membership-side-v1 {
    text-align: center;
    background: $color-white;
    box-shadow: 0 2px 5px 3px lighten($color-tint-light, 1%);
    padding: 20px;

    .membership-side-v1-icon-wrap {
        @include size(150px);
        padding: 22px;
        margin: 0 auto 50px;
        box-shadow: 0 5px 10px 0 lighten($color-tint-light, 3%);

        .membership-side-v1-icon {
            @include size(90px, auto);
        }
    }

    .membership-side-v1-subtitle {
        display: block;
        @include font($size: $font-size-16, $style: italic, $family: $font-family-droid);
        margin-bottom: 5px;
    }

    .membership-side-v1-title {
        @include font($size: $font-size-26);
    }

    .membership-side-v1-text {
        margin-bottom: 0;
    }
}

/*--------------------------------------------------
[Blockquotes v2]
----------------------------------------------------*/

.blockquotes-v2 {
    float: right;
    max-width: 350px;
    @include font($size: $font-size-20, $style: italic);
    color: $color-grey;
    text-align: right;
    border-left: none;
    border-right: 2px solid $color-grey;
    text-transform: uppercase;
    clear: right;
    padding: 10px 20px 10px 10px;
}

/*----------------------------------------------------------------
    [Service Block v7]
------------------------------------------------------------------*/
.services-v7 {

    &:before {
        @include position(absolute, $top: 0, $left: 0);
        @include size(100%);
        @include bg-opacity($color-dark, .45);
        content: " ";
    }

    &.services-v7-img-one,
    &.services-v7-img-two,
    &.services-v7-img-four,
    &.services-v7-img-three {
        @include size(100%, 450px);
        @include cubic-transition($delay: 0, $duration: 300ms, $property: (all));
    }

    &.services-v7-img-one {
        background: url(../../app/assets/img/home/ba_designationblock.jpg) no-repeat;
        background-size: cover;
        background-position: center center;
    }

    &.services-v7-img-two {
        background: url(../../app/assets/img/home/cba_designation_home_block.jpg) no-repeat;
        background-size: cover;
        background-position: center center;
    }

    &.services-v7-img-three {
        background: url(../../app/assets/img/home/cfo_designationblock.jpg) no-repeat;
        background-size: cover;
        background-position: center center;
    }

    &.services-v7-img-four {
        background: url(../../app/assets/img/home/bap_designationblock.jpg) no-repeat;
        background-size: cover;
        background-position: center center;
    }

    &:hover {
        &.services-v7-img-one,
        &.services-v7-img-two,
        &.services-v7-img-four,
        &.services-v7-img-three {
            @include size(100%, 450px);
            @include cubic-transition($delay: 0, $duration: 300ms, $property: (all));
        }
        &.services-v7-img-one {
            background: url(../../app/assets/img/home/ba_designationblock.jpg) no-repeat;
            background-size: cover;
            background-position: left center;
        }

        &.services-v7-img-two {
            background: url(../../app/assets/img/home/cba_designation_home_block.jpg) no-repeat;
            background-size: cover;
            background-position: left center;
        }

        &.services-v7-img-three {
            background: url(../../app/assets/img/home/cfo_designationblock.jpg) no-repeat;
            background-size: cover;
            background-position: left center;
        }

        &.services-v7-img-four {
            background: url(../../app/assets/img/home/bap_designationblock.jpg) no-repeat;
            background-size: cover;
            background-position: left center;
        }
    }
}

/**
 * Black Friday Stuff
 */

.black_friday_special {
    .title {
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 30px;
      color: #E90B18;
      font-weight: 300;
      font-size: 24px;
      letter-spacing: 1px;
    }

    .description {
      text-align: center;
      color: #666;
      margin-bottom: 30px;
    }
    input[type="text"],
    input[type="email"] {
      padding: 10px 20px;
      border: 1px solid #999;
      border-radius: 3px;
      display: block;
      width: 100%;
      margin-bottom: 20px;
      box-sizing: border-box;
      outline: none;
    }
    input[type="text"]:focus,
    input[type="email"]:focus {
      border-color: #E90B18;
    }

    input[type="radio"] {
      margin-right: 10px;
    }

    label {
      margin-bottom: 20px;
      display: block;
      font-size: 18px;
      color: #666;
      // border-top: 1px solid #ddd;
      // border-bottom: 1px solid #ddd;
      padding: 10px 0;
      cursor: pointer;
    }
    label:first-child {
      margin-bottom: 0;
      border-bottom: none;
    }

    .button,
    .rerun-button {
      padding: 10px 20px;
      border-radius: 3px;
      background: #E90B18;
      color: white;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: inline-block;
      cursor: pointer;
      border: none;
    }
    .button:hover,
    .rerun-button:hover {
      background: #d10a15;
    }
    .button.rerun-button,
    .rerun-button.rerun-button {
      border: 1px solid rgba(255, 255, 255, 0.6);
      margin-bottom: 50px;
      box-shadow: 0px 10px 15px -6px rgba(0, 0, 0, 0.2);
      display: none;
    }

    .text-center {
      text-align: center;
    }

    .modal-wrap {
      z-index: 10;
      max-width: 600px;
      margin: -350px auto;
      -webkit-transition: -webkit-transform 300ms ease-in-out;
      transition: -webkit-transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out;
      transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    }

    .modal-header {
      z-index: 10;
      height: 45px;
      background: white;
      border-bottom: 1px solid #ccc;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
    }
    .modal-header span {
      display: block;
      height: 12px;
      width: 12px;
      margin: 5px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);
    }
    .modal-header span.is-active {
      background: rgba(0, 0, 0, 0.4);
      background: #E90B18;
    }

    .modal-bodies {
      z-index: 10;
      position: relative;
      -webkit-perspective: 1000px;
              perspective: 1000px;
    }

    .modal-body {
      background: white;
      padding: 40px 100px;
      box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.3);
      margin-bottom: 50px;
      position: absolute;
      top: 0;
      display: none;
      box-sizing: border-box;
      width: 100%;
      -webkit-transform-origin: top left;
              transform-origin: top left;
    }
    .modal-body.is-showing {
      display: block;
    }

    .animate-out {
      -webkit-animation: out 600ms ease-in-out forwards;
              animation: out 600ms ease-in-out forwards;
    }

    .animate-in {
      -webkit-animation: in 500ms ease-in-out forwards;
              animation: in 500ms ease-in-out forwards;
      display: block;
    }

    .animate-up {
      -webkit-transform: translateY(-800px) rotate(30deg);
              transform: translateY(-800px) rotate(30deg);
    }

    @-webkit-keyframes out {
      0% {
        -webkit-transform: translateY(0px) rotate(0deg);
                transform: translateY(0px) rotate(0deg);
      }
      60% {
        -webkit-transform: rotate(60deg);
                transform: rotate(60deg);
      }
      100% {
        -webkit-transform: translateY(800px) rotate(10deg);
                transform: translateY(800px) rotate(10deg);
      }
    }

    @keyframes out {
      0% {
        -webkit-transform: translateY(0px) rotate(0deg);
                transform: translateY(0px) rotate(0deg);
      }
      60% {
        -webkit-transform: rotate(60deg);
                transform: rotate(60deg);
      }
      100% {
        -webkit-transform: translateY(800px) rotate(10deg);
                transform: translateY(800px) rotate(10deg);
      }
    }
    @-webkit-keyframes in {
      0% {
        opacity: 0;
        -webkit-transform: rotateX(-90deg);
                transform: rotateX(-90deg);
      }
      100% {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
      }
    }
    @keyframes in {
      0% {
        opacity: 0;
        -webkit-transform: rotateX(-90deg);
                transform: rotateX(-90deg);
      }
      100% {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
      }
    }
}

.path_to_designation_img {
    width:570px;
    height:2136px;
}
// BAP campaign page css
.bap-taxlicense-campaign .card-title {
    font-size: 38px;
    font-weight: 700;
}
.bap-taxlicense-campaign .card-text {
    font-size: 22px;
    font-weight: 700;
    color: #34343C;
    line-height: 28px;
    height: 160px;
}
.no-gutter-width {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.bap-taxlicense-campaign .option1 {
    height: 530px;
    background-color: #BAF402;
    padding: 36px 36px 0 36px;
}
.bap-taxlicense-campaign .option2 {
    height: 530px;
    background-color: #08C9C6;
    padding: 36px 36px 0 36px;
}
.bap-taxlicense-campaign #description {
    font-size: 18px;
    padding: 20px 40px;
    font-weight: 500;
    color: #34343C;
}
.bap-taxlicense-campaign .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    -webkit-box-shadow: 5px 5px 5px 5px rgba(67, 67, 67, 0.3);
    -moz-box-shadow: 5px 5px 5px 5px rgba(67, 67, 67, 0.3);
}
.remove-link {
    text-decoration: none !important;
}
.rounded-button{
    border-radius:25px;
}
.background-black {
    background:black;
}
.p-10px-all{
    padding:10px;
}
.bap-campaign-click-here-link{
    color: $color-dark !important;
    font-size:20px;
    font-weight: 900;
}
.bap-taxlicense-campaign .option3 {
    height: 530px;
    background-color: #0B0C50 ;
    padding: 36px 36px 0 36px;
}
.campaign-form-designation-border {
    border: 1px solid rgba(255, 255, 255, 0); 
}
.text-white {
    color: $color-white !important;
}
@media (max-width: 1024px) {
    .breadcrumbs-v5.breadcrumbs-v5-bg-img-black_friday {
        padding: 500px 0 100px;
    }

    .modal-wrap {
      margin: -390px auto;      
    }
}

@media (min-width: 1024px) and (max-width: 1899px) {
    .breadcrumbs-v5.breadcrumbs-v5-bg-img-black_friday {
        padding: 500px 0 100px;
    }

    .modal-wrap {
      margin: -390px auto;      
    }
}

@media (min-width: 1900px) {
    .breadcrumbs-v5.breadcrumbs-v5-bg-img-black_friday {
        padding: 500px 0 100px;
    }
}

@media (max-width: 400px) {
    .breadcrumbs-v5.breadcrumbs-v5-bg-img-black_friday {
        padding: 400px 0 100px;
    }

    .modal-wrap {
      margin: -390px auto;      
    }
}

@media (max-width: 600px) {
    .nav-tabs-left {
        float: none !important;
    }

    .path_to_designation_img {
        max-width: 100%;
        height: auto;
    }
    .apply-gutter-width {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@font-face {
    font-family: "futura";
    src: url("{{ public_path('fonts/futur.ttf') }}") format("truetype");
}

@font-face {
    font-family: futura bold;
    src: url("{{ public_path('fonts/Futura Bold font.ttf') }}") format("truetype");
}

@font-face {
    font-family: futura bold italic;
    src: url("{{ public_path('fonts/Futura Bold Italic font.ttf') }}") format("truetype");
    font-style: italic;
}

@font-face {
    font-family: futura book;
    src: url("{{ public_path('fonts/Futura Book font.ttf') }}") format("truetype");
}

@font-face {
    font-family: futura book italic;
    src: url("{{ public_path('fonts/Futura Book Italic font.ttf') }}") format("truetype");
    font-style: italic;
}

@font-face {
    font-family: futura condensed light;
    src: url("{{ public_path('fonts/Futura-CondensedLight.otf') }}") format("opentype");
    font-style: italic;
}

@font-face {
    font-family: futura extra-black;
    src: url("{{ public_path('fonts/Futura Extra Black font.ttf') }}") format("truetype");
}

@font-face {
    font-family: futura heavy;
    src: url("{{ public_path('fonts/Futura Heavy font.ttf') }}") format("truetype");
}

@font-face {
    font-family: futura heavy italic;
    src: url("{{ public_path('fonts/Futura Heavy Italic font.ttf') }}") format("truetype");
    font-style: italic;
}

@font-face {
    font-family: futura light bt;
    src: url("{{ public_path('fonts/futura light bt.ttf') }}") format("truetype");
}

@font-face {
    font-family: "futura light";
    src: url("{{ public_path('fonts/Futura Light font.ttf') }}") format("truetype");
}

@font-face {
    font-family: futura light italic;
    src: url("{{ public_path('fonts/Futura Light Italic font.ttf') }}") format("truetype");
    font-style: italic;
}

@font-face {
    font-family: futura medium bt;
    src: url("{{ public_path('fonts/futura medium bt.ttf') }}") format("truetype");
}

@font-face {
    font-family: futura medium condensed;
    src: url("{{ public_path('fonts/futura medium condensed bt.ttf') }}") format("truetype");
}

@font-face {
    font-family: futura medium italic;
    src: url("{{ public_path('fonts/Futura Medium Italic font.ttf') }}") format("truetype");
    font-style: italic;
}

@font-face {
    font-family: futura XBlk BT;
    src: url("{{ public_path('fonts/Futura XBlk BT.ttf') }}") format("truetype");
}

.body {
    font-family: "futura", sans-serif;
}

.pixelated {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
}

.crisp-edges {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

.img { 
    image-rendering: optimizeSpeed;             /*                     */
    image-rendering: -moz-crisp-edges;          /* Firefox             */
    image-rendering: -o-crisp-edges;            /* Opera               */
    image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
    image-rendering: pixelated;                 /* Chrome as of 2019   */
    image-rendering: optimize-contrast;         /* CSS3 Proposed       */
    -ms-interpolation-mode: nearest-neighbor;   /* IE8+                */
    }

.header .navbar-nav .nav-item-child a,
.header .navbar-nav .nav-item-child a:hover,
.header .navbar-nav .nav-item-child a:active {
    color: $color-verdun-green !important;
    background-color: #FFFFFF;
}

.color-verdun-green {
    color: $color-verdun-green !important;
}

.color-olivedrab {
    color: $color-olivedrab !important;
}

.tab-v5 .tab-content .tab-pane a:hover,
.tab-v5 .tab-content .tab-pane a:active {
    color: $color-olivedrab;
}

.blog-classic a:hover,
.blog-classic a:active {
    color: $color-olivedrab;
} 

ul a,
ol a,
p a,
tbody a,
span a {
    color: $color-olivedrab;
    &:hover {
        color: $color-olivedrab;
        text-decoration: underline;
    }
    &:active {
        color: $color-olivedrab;
    }
}

.blog-grid-title-lg a:hover,
.blog-grid-title-lg a:active {
    color: $color-olivedrab;
}

@media (min-width: 992px) {
    .header .navbar-nav .nav-item-child a,
    .header .navbar-nav .nav-item-child a:hover,
    .header .navbar-nav .nav-item-child a:active {
        color: $color-verdun-green !important;
        background-color: #FFFFFF;
    }   
}
