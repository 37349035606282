@charset "UTF-8";
/* =================================================================
    [Primary Sass files]
================================================================= */
/*--------------------------------------------------
  [Clearfix]
----------------------------------------------------*/
/* eg: @include clearfix; */
/*--------------------------------------------------
  [Font]
----------------------------------------------------*/
/* eg: @include font($size: 14px, $style: italic, $weight: 400, $variant: normal, $family: $font-family-base); */
/*--------------------------------------------------
  [Positioning]
----------------------------------------------------*/
/* eg: @include position(absolute, $top: 5px, $left: 5px); */
/*--------------------------------------------------
  [Sizing]
----------------------------------------------------*/
/* eg: @include size(100%);
   @include size(100%, 1px); */
/*--------------------------------------------------
  [Border Radius]
----------------------------------------------------*/
/* eg: @include border-radius(3px); */
/*--------------------------------------------------
  [Theme Icons]
----------------------------------------------------*/
/* eg: @include theme-icons($color-dark-light, $color-grey, 0, transparent); */
/* eg: @include theme-icons-size(35px, 35px, $font-size-16, 6px); */
/*--------------------------------------------------
  [Theme Buttons]
----------------------------------------------------*/
/* eg: @include buttons($color-base, $color-white, transparent, $color-base, 1px, $color-base, $color-base, $color-base); */
/* eg: @include button-size($font-size-14, $font-weight-400, 6px 12px); */
/* eg: @include @include buttons-animate(darken($color-gold, 4%), 10px 15px); */
/*--------------------------------------------------
  [Placeholder]
----------------------------------------------------*/
/* eg: @include placeholder($color-base); */
/*--------------------------------------------------
  [Box Shadow]
----------------------------------------------------*/
/* eg: @include box-shadow(0, 2px, 2px, #000, $inset: false); */
/*--------------------------------------------------
    [RGBA Background Opacity]
----------------------------------------------------*/
/* eg: @include bg-opacity($color-dark, .2); */
/*--------------------------------------------------
  [Visibility]
----------------------------------------------------*/
/* eg: @include visibility-backface(hidden); */
/*--------------------------------------------------
  [Transition]
----------------------------------------------------*/
/* eg: @include transition(all, .3s ease); */
/*--------------------------------------------------
  [Perspective]
----------------------------------------------------*/
/* eg: @include perspective(800px); */
/*--------------------------------------------------
  [Cubic Bezier Transition]
----------------------------------------------------*/
/* eg: @include cubic-transition($delay: 0, $duration: 200ms, $property: (all)); */
/*--------------------------------------------------
  [Transition]
----------------------------------------------------*/
/* eg: @include transition(opacity, .3s); */
/*--------------------------------------------------
  [Translate 3D Transition]
----------------------------------------------------*/
/* eg: @include translate3d(0,0,0); */
/*--------------------------------------------------
  [Preserve 3D]
----------------------------------------------------*/
/* eg: @include preserve3d(preserve-3d); */
/*--------------------------------------------------
  [Rotate]
----------------------------------------------------*/
/* eg: @include rotate(45deg); */
/*--------------------------------------------------
  [RotateY]
----------------------------------------------------*/
/* eg: @include rotateY(45deg); */
/*--------------------------------------------------
  [RotateX]
----------------------------------------------------*/
/* eg: @include rotateX(45deg); */
/*--------------------------------------------------
  [Transform Scale]
----------------------------------------------------*/
/* eg: @include transform-scale(1,1); */
/*--------------------------------------------------
  [Animation Duration]
----------------------------------------------------*/
/* eg: @include animation-duration(.3s); */
/*--------------------------------------------------
  [Animation Delay]
----------------------------------------------------*/
/* eg: @include animation-delay(.3s); */
/*--------------------------------------------------
  [Animation Timing Function]
----------------------------------------------------*/
/* eg: @include animation-timing-function(ease); */
/*--------------------------------------------------
  [Transform Origin]
----------------------------------------------------*/
/* eg: @include transform-origin(left center); */
/*--------------------------------------------------
  [Animation Iteraction Count]
----------------------------------------------------*/
/* eg: @include animation-iteration-count(infinite); */
/*--------------------------------------------------
  [Animation]
----------------------------------------------------*/
/* eg: @include animation(toTopFromBottom .3s forwards); */
/*--------------------------------------------------
  [Animation Name]
----------------------------------------------------*/
/* eg: @include animation-name(egample); */
/*--------------------------------------------------
  [Animation Direction]
----------------------------------------------------*/
/* eg: @include animation-direction(reverse); */
/*--------------------------------------------------
  [Keyframes]
----------------------------------------------------*/
/* eg: @include keyframes($keyframes); { @content; } */
/*--------------------------------------------------
  [Grayscale]
----------------------------------------------------*/
/* eg: @include grayscale(100%); */
/*--------------------------------------------------
  [Blur]
----------------------------------------------------*/
/* eg: @include blur(5px); */
/*--------------------------------------------------
  [Linear Gradient]
----------------------------------------------------*/
/* eg: @include gradient(rgba(255,255,255,0), rgba(255,255,255,.9)); */
/*--------------------------------------------------
  [User Select]
----------------------------------------------------*/
/* eg: @include user-select(none); */
/*--------------------------------------------------
  [Box Sizing]
----------------------------------------------------*/
/* eg: @include box-sizing(border-box); */
/*--------------------------------------------------
  [If, For, Each, While]
----------------------------------------------------*/
/*--------------------------------------------------
	[Layout Settings & Variables]
----------------------------------------------------*/
/*------------------------------------------------------------------
  	[The "base.scss" contains basic default element styles
  		(colors, typography, margins & padding).]
------------------------------------------------------------------*/
html {
  overflow-x: hidden;
}

html, html a, body {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: Roboto, sans-serif;
}

p {
  font-size: 15px;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  color: #606060;
  line-height: 1.4;
}

em,
li,
li a {
  font-size: 15px;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  color: #34343c;
}

a {
  font-family: Roboto, sans-serif;
  color: #BAF402;
  outline: 0;
}

a:focus, a:hover, a:active {
  outline: 0;
  color: #BAF402;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  font-family: Roboto, sans-serif;
  color: #34343c;
  line-height: 1.4;
  margin: 0 0 15px;
}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  color: #34343c;
}

h1 > a:hover, h2 > a:hover, h3 > a:hover, h4 > a:hover, h5 > a:hover, h6 > a:hover {
  color: #BAF402;
  text-decoration: none;
}

h1 > a:focus, h2 > a:focus, h3 > a:focus, h4 > a:focus, h5 > a:focus, h6 > a:focus {
  text-decoration: none;
}

selection {
  color: #fff;
  background: #BAF402;
  text-shadow: none;
}

webkit-selection {
  color: #fff;
  background: #BAF402;
  text-shadow: none;
}

moz-selection {
  color: #fff;
  background: #BAF402;
  text-shadow: none;
}

active,
focus {
  outline: none;
}

/*------------------------------------------------------------------
    [Blog Classic]
------------------------------------------------------------------*/
.blog-classic {
  position: relative;
  overflow: hidden;
}

.blog-classic .blog-classic-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 3;
  text-decoration: none;
}

.blog-classic .blog-classic-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.blog-classic .blog-classic-hover:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.3);
  content: " ";
}

.blog-classic .blog-classic-hover .blog-classic-hover-img {
  width: auto;
  height: 100%;
  opacity: 0;
  visibility: hidden;
}

.blog-classic .blog-classic-body {
  position: relative;
  background: #fff;
  padding: 40px 30px 60px;
}

.blog-classic .blog-classic-label {
  position: absolute;
  top: 0;
  right: 30px;
  width: 60px;
  height: 60px;
  display: block;
  font-size: 16px;
  font-family: Droid Serif, serif;
  color: #fff;
  line-height: 3.3;
  text-align: center;
  background: #34343c;
  border-radius: 3px;
  text-transform: uppercase;
  padding: 5px;
  margin-top: -30px;
}

.blog-classic .blog-classic-title {
  font-size: 28px;
  margin-bottom: 5px;
}

.blog-classic .blog-classic-title.blog-classic-title-sm {
  font-size: 24px;
}

.blog-classic .blog-classic-subtitle {
  display: block;
  font-size: 18px;
  font-style: italic;
  font-family: Droid Serif, serif;
}

.blog-classic .blog-classic-subtitle.blog-classic-subtitle-sm {
  font-size: 16px;
}

.blog-classic .blog-classic-paragraph {
  position: relative;
  display: block;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
  padding-top: 25px;
}

.blog-classic .blog-classic-paragraph:after {
  position: absolute;
  top: 10px;
  left: 0;
  width: 40px;
  height: 1px;
  background: #c0c0c8;
  content: " ";
}

.blog-classic .blog-classic-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.blog-classic .blog-classic-overlay:before, .blog-classic .blog-classic-overlay:after {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  content: " ";
  opacity: 0;
  transition-duration: 600ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.blog-classic .blog-classic-overlay:before {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: scale(0, 1);
  -moz-transform: scale(0, 1);
  transform: scale(0, 1);
}

.blog-classic .blog-classic-overlay:after {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transform: scale(1, 0);
  -moz-transform: scale(1, 0);
  transform: scale(1, 0);
}

.blog-classic .blog-classic-overlay-body {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 40px;
  width: auto;
  height: auto;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translate3d(-20%, 0, 0);
  -moz-transform: translate3d(-20%, 0, 0);
  transform: translate3d(-20%, 0, 0);
  transition-duration: 600ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.blog-classic .blog-classic-overlay-body .blog-classic-title,
.blog-classic .blog-classic-overlay-body .blog-classic-subtitle {
  color: #fff;
}

.blog-classic .blog-classic-overlay-body .blog-classic-paragraph {
  color: #fff;
}

.blog-classic .blog-classic-overlay-body .blog-classic-paragraph:after {
  background: #fff;
}

.blog-classic:hover .blog-classic-hover {
  opacity: 1;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.blog-classic:hover .blog-classic-hover .blog-classic-hover-img {
  opacity: 1;
  visibility: visible;
}

.blog-classic:hover .blog-classic-overlay:before, .blog-classic:hover .blog-classic-overlay:after {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  transition-duration: 600ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.blog-classic:hover .blog-classic-overlay-body {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-duration: 600ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*------------------------------------------------------------------
    [Blog Sidebar]
------------------------------------------------------------------*/
.blog-sidebar .blog-sidebar-heading {
  background: #fff;
  border-bottom: 1px solid #ebeef6;
  padding: 15px;
}

.blog-sidebar .blog-sidebar-heading .blog-sidebar-heading-icon {
  position: relative;
  top: 1px;
  font-size: 13px;
  color: #606060;
  margin-right: 5px;
}

.blog-sidebar .blog-sidebar-heading .blog-sidebar-heading-title {
  display: inline-block;
  font-size: 16px;
  margin: 0;
}

.blog-sidebar .blog-sidebar-content {
  background: #fff;
  padding: 15px;
}

.blog-sidebar .blog-sidebar-content.blog-sidebar-content-height {
  height: 290px;
}

/*------------------------------------------------------------------
    [Twitter Feed]
------------------------------------------------------------------*/
.twitter-feed .twitter-feed-item {
  border-top: 1px solid #ebeef6;
  padding-top: 10px;
  margin-top: 10px;
}

.twitter-feed .twitter-feed-item:before, .twitter-feed .twitter-feed-item:after {
  content: " ";
  display: table;
}

.twitter-feed .twitter-feed-item:after {
  clear: both;
}

.twitter-feed .twitter-feed-item:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}

.twitter-feed .twitter-feed-item .twitter-feed-media {
  float: left;
  margin-right: 10px;
}

.twitter-feed .twitter-feed-item .twitter-feed-media .twitter-feed-media-img {
  width: 45px;
  height: 45px;
}

.twitter-feed .twitter-feed-item .twitter-feed-content {
  overflow: hidden;
}

.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-profile-name,
.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-profile-nickname,
.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-profile-nickname-link,
.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-posted-time,
.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-paragraph,
.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-link {
  font-size: 12px;
}

.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-profile-name {
  font-weight: 500;
  color: #3a3a44;
  padding-right: 10px;
}

.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-profile-nickname {
  color: #606060;
}

.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-posted-time {
  color: #9e9e9e;
  float: right;
}

.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-paragraph {
  margin-bottom: 5px;
}

.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-link {
  color: #BAF402;
}

/*------------------------------------------------------------------
    [Latest Tutorials]
------------------------------------------------------------------*/
.latest-tuts {
  overflow: hidden;
  border-bottom: 1px solid #f7f8fa;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.latest-tuts:last-child {
  border-bottom: none;
}

.latest-tuts .latest-tuts-media {
  float: left;
  margin-right: 10px;
}

.latest-tuts .latest-tuts-media .latest-tuts-media-img {
  width: 45px;
  height: 45px;
}

.latest-tuts .latest-tuts-content {
  overflow: hidden;
}

.latest-tuts .latest-tuts-content .latest-tuts-content-title {
  font-size: 14px;
  text-transform: inherit;
  letter-spacing: 0;
  margin: 0 0 3px;
}

.latest-tuts .latest-tuts-content .latest-tuts-content-time {
  font-size: 11px;
  display: block;
  color: #606060;
}

/*------------------------------------------------------------------
    [Featured Article]
------------------------------------------------------------------*/
.featured-article {
  position: relative;
  display: block;
  overflow: hidden;
}

.featured-article .featured-article-content-wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(52, 52, 60, 0);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(52, 52, 60, 0)), to(rgba(52, 52, 60, 0.9)));
  background-image: -webkit-linear-gradient(top, rgba(52, 52, 60, 0), rgba(52, 52, 60, 0.9));
  background-image: -moz-linear-gradient(top, rgba(52, 52, 60, 0), rgba(52, 52, 60, 0.9));
  background-image: -ms-linear-gradient(top, rgba(52, 52, 60, 0), rgba(52, 52, 60, 0.9));
  background-image: -o-linear-gradient(top, rgba(52, 52, 60, 0), rgba(52, 52, 60, 0.9));
  background-image: linear-gradient(top, rgba(52, 52, 60, 0), rgba(52, 52, 60, 0.9));
}

.featured-article .featured-article-content {
  padding: 20px;
}

.featured-article .featured-article-content .featured-article-content-title {
  font-size: 15px;
  color: #fff;
  text-transform: inherit;
  letter-spacing: 0;
  margin: 0 0 5px;
}

.featured-article .featured-article-content .featured-article-content-title > a {
  color: #fff;
}

.featured-article .featured-article-content .featured-article-content-title > a:hover {
  color: #BAF402;
  text-decoration: none;
}

.featured-article .featured-article-content .featured-article-content-time {
  display: block;
  font-size: 12px;
  color: #fff;
}

.featured-article:hover {
  text-decoration: none;
}

/*------------------------------------------------------------------
    [Footer Tags]
------------------------------------------------------------------*/
.blog-sidebar-tags {
  margin: -5px 0 0;
}

.blog-sidebar-tags > li {
  padding: 5px 2px;
}

.blog-sidebar-tags > li > a {
  display: inline-block;
  font-size: 13px;
  color: #3a3a44;
  border: 1px solid #606060;
  text-decoration: none;
  padding: 3px 8px;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.blog-sidebar-tags > li > a:hover {
  color: #fff;
  background: #BAF402;
  border-color: #BAF402;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*------------------------------------------------------------------
    [Blog Single Post]
------------------------------------------------------------------*/
/* Blog Single Post Source */
.blog-single-post-source {
  display: block;
  margin: 20px 0;
}

/* Blog Single Post Content */
.blog-single-post-content {
  padding: 60px 20px 40px;
}

/* Blog Single Post Comment Forum */
.blog-single-post-comment-form .blog-single-post-form {
  color: #34343c;
  background: #f7f8fa;
  border-color: #f7f8fa;
}

.blog-single-post-comment-form .blog-single-post-form::-moz-placeholder {
  color: #34343c;
}

.blog-single-post-comment-form .blog-single-post-form:-ms-input-placeholder {
  color: #34343c;
}

.blog-single-post-comment-form .blog-single-post-form::-webkit-input-placeholder {
  color: #34343c;
}

.blog-single-post-comment-form .blog-single-post-form:focus {
  color: #34343c;
  background: #f7f8fa;
  border-color: #eff1f8;
}

.blog-single-post-comment-form .blog-single-post-form:focus::-moz-placeholder {
  color: #34343c;
}

.blog-single-post-comment-form .blog-single-post-form:focus:-ms-input-placeholder {
  color: #34343c;
}

.blog-single-post-comment-form .blog-single-post-form:focus::-webkit-input-placeholder {
  color: #34343c;
}

.blog-single-post-comment-form .blog-single-post-comment {
  overflow: hidden;
  border-top: 1px solid #f1f1f1;
  padding-top: 20px;
  margin-top: 10px;
}

.blog-single-post-comment-form .blog-single-post-comment.blog-single-post-comment-first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}

.blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment {
  margin-left: 30px;
}

.blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment-media {
  float: left;
  margin-right: 15px;
}

.blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment-media .blog-single-post-comment-media-img {
  width: 50px;
  height: 50px;
}

.blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment-content {
  overflow: hidden;
}

.blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment-content .blog-single-post-comment-username {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  text-transform: inherit;
  margin: 0 0 5px;
}

.blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment-content .blog-single-post-comment-time {
  float: right;
  color: #606060;
  font-size: 11px;
}

.blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment-content .blog-single-post-comment-text {
  color: #34343c;
}

.blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment-share {
  overflow: hidden;
}

.blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment-share .blog-single-post-comment-share-item {
  font-size: 12px;
  padding: 0 5px;
}

.blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment-share .blog-single-post-comment-share-link {
  font-size: 12px;
  text-decoration: none;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment-media {
    float: none;
    margin-right: 0;
  }
  .blog-single-post-comment-form .blog-single-post-comment .blog-single-post-comment-media .blog-single-post-comment-media-img {
    margin-bottom: 15px;
  }
}

/*------------------------------------------------------------------
    [News v1]
------------------------------------------------------------------*/
/* Heading */
.news-v1 {
  box-shadow: 2px 4px 7px 2px #eff1f8;
}

.news-v1 .news-v1-heading {
  background: #fff;
  text-align: center;
  padding: 20px;
}

.news-v1 .news-v1-heading .news-v1-heading-tag {
  display: block;
  font-size: 14px;
  font-style: italic;
  color: #34343c;
  text-decoration: none;
  margin-bottom: 5px;
}

.news-v1 .news-v1-heading .news-v1-heading-tag:hover {
  color: #BAF402;
}

.news-v1 .news-v1-heading .news-v1-element {
  position: relative;
  display: inline-block;
  font-size: 13px;
  color: #34343c;
}

.news-v1 .news-v1-heading .news-v1-element:before, .news-v1 .news-v1-heading .news-v1-element:after {
  position: absolute;
  top: 8px;
  display: inline-block;
  width: 30px;
  height: 1px;
  background: #34343c;
  content: " ";
}

.news-v1 .news-v1-heading .news-v1-element:before {
  right: 100%;
  margin-right: 10px;
}

.news-v1 .news-v1-heading .news-v1-element:after {
  left: 100%;
  margin-left: 10px;
}

.news-v1 .news-v1-heading .news-v1-element .news-v1-element-icon {
  position: relative;
  top: -3px;
  display: inline-block;
  font-size: 6px;
  color: #606060;
}

.news-v1 .news-v1-heading .news-v1-heading-title {
  font-size: 20px;
  font-style: italic;
  margin-bottom: 0;
}

.news-v1 .news-v1-heading .news-v1-heading-title > a {
  font-family: Droid Serif, serif;
  text-decoration: none;
}

/* Image Gradient */
.news-v1 .news-v1-img-gradient {
  position: relative;
  overflow: hidden;
}

.news-v1 .news-v1-img-gradient .news-v1-img-content {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(52, 52, 60, 0);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(52, 52, 60, 0)), to(rgba(52, 52, 60, 0.9)));
  background-image: -webkit-linear-gradient(top, rgba(52, 52, 60, 0), rgba(52, 52, 60, 0.9));
  background-image: -moz-linear-gradient(top, rgba(52, 52, 60, 0), rgba(52, 52, 60, 0.9));
  background-image: -ms-linear-gradient(top, rgba(52, 52, 60, 0), rgba(52, 52, 60, 0.9));
  background-image: -o-linear-gradient(top, rgba(52, 52, 60, 0), rgba(52, 52, 60, 0.9));
  background-image: linear-gradient(top, rgba(52, 52, 60, 0), rgba(52, 52, 60, 0.9));
}

.news-v1 .news-v1-img-gradient .news-v1-img-title {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  color: #fff;
  text-transform: inherit;
  letter-spacing: 0;
  padding: 30px;
  margin-bottom: 0;
}

/* Content */
.news-v1 .news-v1-content {
  background: #fff;
  padding: 20px;
}

.news-v1 .news-v1-lists {
  text-align: center;
  margin-bottom: 0;
}

.news-v1 .news-v1-lists .news-v1-lists-item {
  position: relative;
  font-size: 13px;
  font-style: italic;
  color: #34343c;
  padding: 0 7px;
}

.news-v1 .news-v1-lists .news-v1-lists-item:before {
  position: absolute;
  top: 0;
  left: -45px;
  width: 100%;
  height: 100%;
  color: #34343c;
  content: "/";
}

.news-v1 .news-v1-lists .news-v1-lists-item:first-child:before {
  display: none;
  content: " ";
}

.news-v1 .news-v1-lists .news-v1-lists-item .news-v1-lists-link {
  position: relative;
  z-index: 1;
  font-size: 13px;
  font-style: italic;
  color: #34343c;
  text-decoration: none;
}

.news-v1 .news-v1-lists .news-v1-lists-item .news-v1-lists-link:hover {
  color: #BAF402;
}

.news-v1 .news-v1-quote {
  position: relative;
  display: block;
  font-size: 15px;
  color: #3a3a44;
  line-height: 1.6;
  padding-left: 45px;
  margin-bottom: 0;
}

.news-v1 .news-v1-quote:before {
  position: absolute;
  top: -20px;
  left: 0;
  display: inline-block;
  font-size: 70px;
  font-family: Droid Serif, serif;
  color: #34343c;
  content: "“";
}

/*------------------------------------------------------------------
    [News v2]
------------------------------------------------------------------*/
.news-v2 {
  background: #fff;
  padding: 50px;
}

.news-v2.news-v2-base, .news-v2.news-v2-red, .news-v2.news-v2-teal {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.news-v2.news-v2-base {
  border-top: 2px solid #BAF402;
}

.news-v2.news-v2-red {
  border-top: 2px solid #e55973;
}

.news-v2.news-v2-teal {
  border-top: 2px solid #009688;
}

.news-v2 .news-v2-title,
.news-v2 .news-v2-subtitle,
.news-v2 .news-v2-link {
  transition-duration: 100ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.news-v2 .news-v2-title {
  font-size: 26px;
}

.news-v2 .news-v2-subtitle {
  display: block;
  font-size: 14px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #606060;
  margin-bottom: 10px;
}

.news-v2 .news-v2-link {
  color: #606060;
}

.news-v2:hover.news-v2-base, .news-v2:hover.news-v2-red, .news-v2:hover.news-v2-teal {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.news-v2:hover.news-v2-base {
  background: #BAF402;
}

.news-v2:hover.news-v2-red {
  background: #e55973;
}

.news-v2:hover.news-v2-teal {
  background: #009688;
}

.news-v2:hover .news-v2-title,
.news-v2:hover .news-v2-subtitle,
.news-v2:hover .news-v2-link {
  color: #fff;
  transition-duration: 100ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*------------------------------------------------------------------
    [News v3]
------------------------------------------------------------------*/
.news-v3 .news-v3-content {
  text-align: center;
  background: #fff;
  padding: 30px;
}

.news-v3 .news-v3-content .news-v3-subtitle {
  display: block;
  font-size: 16px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #BAF402;
  margin-bottom: 5px;
}

.news-v3 .news-v3-content .news-v3-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.news-v3 .news-v3-content .news-v3-element {
  position: relative;
  display: inline-block;
  font-size: 13px;
  color: #606060;
}

.news-v3 .news-v3-content .news-v3-element:before, .news-v3 .news-v3-content .news-v3-element:after {
  position: absolute;
  top: 8px;
  width: 30px;
  height: 1px;
  border-bottom: 1px solid #BAF402;
  content: " ";
}

.news-v3 .news-v3-content .news-v3-element:before {
  right: 100%;
  margin-right: 10px;
}

.news-v3 .news-v3-content .news-v3-element:after {
  left: 100%;
  margin-left: 10px;
}

.news-v3 .news-v3-content .news-v3-element .news-v3-element-icon {
  position: relative;
  top: -3px;
  font-size: 5px;
  color: #BAF402;
}

/*------------------------------------------------------------------
    [News v4]
------------------------------------------------------------------*/
.news-v4 {
  padding: 20px 0;
}

.news-v4 .news-v4-title {
  font-size: 24px;
}

.news-v4 .news-v4-title-element-left {
  position: relative;
  margin-top: 20px;
}

.news-v4 .news-v4-title-element-left:after {
  position: absolute;
  top: -15px;
  left: 0;
  width: 30px;
  height: 1px;
  border-bottom: 1px solid #BAF402;
  content: " ";
}

.news-v4 .news-v4-title-element-right {
  position: relative;
  margin-top: 20px;
}

.news-v4 .news-v4-title-element-right:after {
  position: absolute;
  top: -15px;
  right: 0;
  width: 30px;
  height: 1px;
  border-bottom: 1px solid #BAF402;
  content: " ";
}

/*------------------------------------------------------------------
    [News v5]
------------------------------------------------------------------*/
.news-v5 {
  width: 100%;
  height: 300px;
  min-height: 1px;
}

.news-v5:before, .news-v5:after {
  content: " ";
  display: table;
}

.news-v5:after {
  clear: both;
}

.news-v5 .news-v5-col {
  position: relative;
  width: 43%;
  height: 100%;
  float: left;
}

.news-v5 .news-v5-col-p-left {
  padding: 15px 0 15px 40px;
}

.news-v5 .news-v5-col-p-right {
  padding: 15px 40px 15px 0;
}

.news-v5 .news-v5-content {
  position: relative;
  display: table;
  float: left;
  width: 57%;
  height: 100%;
  min-height: 1px;
  padding: 0;
  text-align: center;
}

.news-v5 .news-v5-border-right,
.news-v5 .news-v5-border-left {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #c0c0c8;
}

.news-v5 .news-v5-border-right::before,
.news-v5 .news-v5-border-left::before {
  width: 70%;
  height: 102%;
  background: #fff;
  background-color: transparent;
  content: " ";
}

.news-v5 .news-v5-border-right::before {
  position: absolute;
  top: -2px;
  left: -1px;
  right: 0;
  bottom: 0;
  background-image: -webkit-linear-gradient(left, #fff 50%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, 0) 100%);
}

.news-v5 .news-v5-border-left::before {
  position: absolute;
  top: -2px;
  left: auto;
  right: -1px;
  bottom: 0;
  background-image: -webkit-linear-gradient(right, #fff 50%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to left, #fff 50%, rgba(255, 255, 255, 0) 100%);
}

.news-v5 .news-v5-inner {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.news-v5 .news-v5-inner-body {
  padding: 0 50px;
}

.news-v5 .news-v5-icon {
  display: block;
  font-size: 36px;
  color: #BAF402;
  margin-bottom: 20px;
}

.news-v5 .news-v5-order-name {
  font-size: 32px;
  color: #34343c;
  margin-bottom: 5px;
}

/* Media Queries below 769px */
@media (min-width: 769px) {
  .news-v5 .news-v5-col-sm-push {
    left: 57%;
  }
  .news-v5 .news-v5-content-sm-pull {
    right: 43%;
  }
}

/* Media Queries below 768 */
@media (max-width: 768px) {
  .news-v5 {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0 auto;
    display: block;
  }
  .news-v5 .news-v5-col-p-left,
  .news-v5 .news-v5-col-p-right {
    padding: 15px;
  }
  .news-v5 .news-v5-border-right,
  .news-v5 .news-v5-border-left {
    border: none;
  }
  .news-v5 .news-v5-col,
  .news-v5 .news-v5-content {
    width: 100%;
    height: 100%;
    float: none;
  }
  .news-v5 .news-v5-border-right::before,
  .news-v5 .news-v5-border-left::before {
    position: static;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: none;
    background-image: none;
  }
}

/*------------------------------------------------------------------
    [News v6]
------------------------------------------------------------------*/
.news-v6 {
  background: #fff;
  padding: 40px;
}

.news-v6 .news-v6-title {
  font-size: 24px;
}

.news-v6 .news-v6-subtitle {
  max-width: 350px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #BAF402;
}

/*--------------------------------------------------
    [News v7]
----------------------------------------------------*/
.news-v7.news-v7-lg {
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 5px 3px #eff1f8;
  margin-top: -20px;
}

.news-v7.news-v7-lg .news-v7-content {
  padding: 30px 20px;
}

.news-v7 .news-v7-content {
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 3px -1px rgba(52, 52, 60, 0.03);
}

.news-v7 .news-v7-title {
  font-size: 22px;
  margin-bottom: 5px;
}

.news-v7 .news-v7-subtitle {
  font-size: 14px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #606060;
  margin-bottom: 0;
}

/*------------------------------------------------------------------
    [News v8]
------------------------------------------------------------------*/
/* News v8 Image Effect */
.news-v8 {
  text-align: center;
  box-shadow: 0 2px 5px 3px #eff1f8;
}

.news-v8 .news-v8-img-effect {
  position: relative;
}

.news-v8 .news-v8-img-effect:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0);
  content: " ";
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.news-v8 .news-v8-img-effect-center-align {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.news-v8 .theme-icons-wrap {
  opacity: 0;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.news-v8:hover .news-v8-img-effect:before {
  background: rgba(186, 244, 2, 0.5);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.news-v8:hover .theme-icons-wrap {
  opacity: 1;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* News v8 Wrap */
.news-v8 .news-v8-wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.news-v8 .news-v8-content {
  position: relative;
  background: #fff;
  padding: 30px;
}

.news-v8 .news-v8-category {
  display: block;
  font-size: 16px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #7a7979;
  line-height: 1.3;
  margin-bottom: 10px;
}

.news-v8 .news-v8-title {
  font-size: 20px;
  margin-bottom: 0;
}

.news-v8 .news-v8-footer {
  border-top: 1px solid #ebeef6;
  background: #fff;
  padding: 15px 20px;
}

.news-v8 .news-v8-footer-list {
  margin-bottom: 0;
}

.news-v8 .news-v8-footer-list .news-v8-footer-list-item {
  font-size: 13px;
  color: #606060;
  padding: 0 15px 0 0;
}

.news-v8 .news-v8-footer-list .news-v8-footer-list-icon {
  position: relative;
  top: 1px;
  font-size: 14px;
  color: #606060;
  margin-right: 5px;
}

.news-v8 .news-v8-footer-list .news-v8-footer-list-link {
  font-size: 13px;
  color: #606060;
  text-decoration: none;
}

.news-v8 .news-v8-footer-list .news-v8-footer-list-link:hover {
  color: #BAF402;
}

/* News v8 More */
.news-v8 .news-v8-more .news-v8-more-link {
  position: absolute;
  left: 50%;
  bottom: 1px;
  font-size: 14px;
  font-weight: 300;
  background: #BAF402;
  color: #fff;
  cursor: pointer;
  padding: 5px 20px;
  margin-bottom: 0;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.news-v8 .news-v8-more .news-v8-more-info {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0;
  z-index: 2;
  width: auto;
  height: 0;
  text-align: left;
  background: #34343c;
  overflow: hidden;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.news-v8 .news-v8-more .news-v8-more-info-body {
  padding: 30px;
}

.news-v8 .news-v8-more .news-v8-more-info-title {
  font-size: 18px;
  color: #fff;
  margin-bottom: 5px;
}

.news-v8 .news-v8-more .news-v8-more-info-subtitle {
  display: block;
  font-size: 14px;
  font-weight: 300;
  color: #c4c4c4;
}

.news-v8 .news-v8-more .news-v8-more-info-text {
  font-size: 14px;
  color: #c4c4c4;
  margin-bottom: 0;
}

.news-v8 .news-v8-more:hover .news-v8-more-info {
  width: auto;
  height: 100%;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.news-v8:hover .news-v8-more .news-v8-more-link {
  bottom: -28px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*------------------------------------------------------------------
    [News v9]
------------------------------------------------------------------*/
.news-v9 {
  position: relative;
}

.news-v9:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.5);
  content: " ";
}

.news-v9.news-v9-box-shadow {
  box-shadow: 0 3px 7px 3px rgba(52, 52, 60, 0.3);
}

.news-v9 .news-v9-img {
  width: 100%;
  height: auto;
}

.news-v9 .news-v9-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.news-v9 .news-v9-title {
  font-size: 30px;
  color: #fff;
  margin-bottom: 40px;
}

.news-v9 .news-v9-text {
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  opacity: .8;
  padding-top: 20px;
  margin-top: 20px;
}

.news-v9 .news-v9-link {
  position: absolute;
  left: 30px;
  bottom: 30px;
  display: block;
  font-weight: 300;
  color: #fff;
  opacity: .8;
}

/*------------------------------------------------------------------
    [Latest Posts v1]
------------------------------------------------------------------*/
.news-v10 {
  border-bottom: 1px solid #ebeef6;
  overflow: hidden;
  padding: 20px 0;
}

.news-v10:first-child {
  padding-top: 0;
}

.news-v10 .news-v10-media {
  float: left;
  margin-right: 20px;
}

.news-v10 .news-v10-media .news-v10-media-img {
  width: 100px;
  height: auto;
}

.news-v10 .news-v10-content {
  overflow: hidden;
}

.news-v10 .news-v10-title {
  font-size: 18px;
  margin: 0 0 5px;
}

.news-v10 .news-v10-lists {
  margin-bottom: 0;
}

.news-v10 .news-v10-lists .news-v10-lists-item {
  position: relative;
  font-size: 13px;
  color: #34343c;
  padding: 0 10px;
}

.news-v10 .news-v10-lists .news-v10-lists-item:before {
  position: absolute;
  top: 5px;
  left: -3px;
  width: 1px;
  height: 10px;
  background: #9e9e9e;
  content: " ";
}

.news-v10 .news-v10-lists .news-v10-lists-item:first-child {
  padding-left: 1px;
}

.news-v10 .news-v10-lists .news-v10-lists-item:first-child:before {
  display: none;
}

.news-v10 .news-v10-lists .news-v10-lists-item .news-v10-lists-icon {
  position: relative;
  top: 1px;
  font-size: 13px;
  margin-right: 3px;
}

.news-v10 .news-v10-lists .news-v10-lists-item .news-v10-lists-link {
  font-size: 13px;
}

.news-v10 .news-v10-lists .news-v10-lists-item .news-v10-lists-link:hover {
  color: #34343c;
  text-decoration: none;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .news-v10 .news-v10-media {
    float: none;
    margin: 0 0 20px;
  }
  .news-v10 .news-v10-media .news-v10-media-img {
    width: 200px;
    height: auto;
  }
}

/*------------------------------------------------------------------
    [News v11]
------------------------------------------------------------------*/
.news-v11 {
  text-align: center;
  background: #fff;
}

.news-v11 .news-v11-content {
  position: relative;
}

.news-v11 .news-v11-content.news-v11-content-arrow-bottom:after {
  position: absolute;
  top: 93%;
  left: 50%;
  width: 0;
  height: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 20px 25px 20px;
  border-color: transparent transparent #fff transparent;
  content: " ";
  margin-left: -22.5px;
}

.news-v11 .news-v11-content.news-v11-content-arrow-left:after {
  position: absolute;
  top: 50%;
  right: 99%;
  width: 0;
  height: 0;
  z-index: 1;
  border-style: solid;
  border-width: 20px 25px 20px 0;
  border-color: transparent #fff transparent transparent;
  content: " ";
  margin-top: -20px;
}

.news-v11 .news-v11-content.news-v11-content-arrow-right:after {
  position: absolute;
  top: 50%;
  left: 99%;
  width: 0;
  height: 0;
  z-index: 1;
  border-style: solid;
  border-width: 20px 0 20px 25px;
  border-color: transparent transparent transparent #fff;
  content: " ";
  margin-top: -20px;
}

.news-v11 .news-v11-title {
  position: relative;
  font-size: 20px;
  margin: 0 0 20px;
}

.news-v11 .news-v11-title:after {
  position: absolute;
  left: 50%;
  bottom: -12px;
  width: 20px;
  height: 1px;
  background: #34343c;
  margin-left: -10px;
  content: " ";
}

.news-v11 .news-v11-subtitle {
  display: block;
  font-size: 12px;
  color: #BAF402;
  margin-bottom: 5px;
}

.news-v11 .news-v11-paragraph {
  margin-bottom: 0;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .news-v11 .news-v11-content.news-v11-content-arrow-bottom:after {
    position: absolute;
    top: 97%;
  }
  .news-v11 .news-v11-content.news-v11-content-arrow-left:after, .news-v11 .news-v11-content.news-v11-content-arrow-right:after {
    position: absolute;
    top: -15%;
    right: 50%;
    border-width: 0 20px 25px 20px;
    border-color: transparent transparent #fff transparent;
    margin: 0 -20px 0 0;
  }
}

/*------------------------------------------------------------------
    [News v12]
------------------------------------------------------------------*/
.news-v12 .news-v12-img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.news-v12 .news-v12-content {
  position: relative;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 30px;
}

.news-v12 .news-v12-title {
  font-size: 24px;
  color: #34343c;
}

.news-v12 .news-v12-text {
  line-height: 1.6;
}

/* Video */
.news-v12 .news-v12-video {
  position: relative;
  top: -20px;
}

.news-v12 .news-v12-video .news-v12-video-link {
  position: absolute;
  top: -30px;
  right: 0;
  width: 40px;
  height: 40px;
  display: block;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #f7f8fa;
  text-decoration: none;
  padding: 5px;
}

.news-v12 .news-v12-video .news-v12-video-tooltip {
  position: absolute;
  top: -80px;
  right: 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  padding: 10px 20px;
  transform: translateY(-20px);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.news-v12 .news-v12-video .news-v12-video-tooltip:after {
  position: absolute;
  right: 10px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 10px 0 10px;
  border-color: #45bbff transparent transparent transparent;
  content: " ";
}

.news-v12 .news-v12-video:hover .news-v12-video-tooltip {
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transform: translateY(-5px);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.news-v12 .news-v12-color-vm .news-v12-video-link,
.news-v12 .news-v12-color-vm .news-v12-video-tooltip {
  background: #45bbff;
}

.news-v12 .news-v12-color-vm .news-v12-video-tooltip:after {
  border-color: #45bbff transparent transparent transparent;
}

.news-v12 .news-v12-color-yt .news-v12-video-link,
.news-v12 .news-v12-color-yt .news-v12-video-tooltip {
  background: #cc181e;
}

.news-v12 .news-v12-color-yt .news-v12-video-tooltip:after {
  border-color: #cc181e transparent transparent transparent;
}

.news-v12 .news-v12-color-pp .news-v12-video-link,
.news-v12 .news-v12-color-pp .news-v12-video-tooltip {
  background: #34343c;
}

.news-v12 .news-v12-color-pp .news-v12-video-tooltip:after {
  border-color: #34343c transparent transparent transparent;
}

/*--------------------------------------------------
    [Accordion v1]
----------------------------------------------------*/
.accordion-v1 .panel-group {
  margin-bottom: 0;
}

.accordion-v1 .panel {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.accordion-v1 .panel-default .panel-heading {
  border-radius: 0;
  padding: 0;
}

.accordion-v1 .panel-default .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.accordion-v1 .panel-title > a {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  color: #34343c;
  background: #f7f8fa;
  padding: 15px 50px 15px 15px;
}

.accordion-v1 .panel-title > a:after {
  position: absolute;
  top: 8px;
  right: 20px;
  font-size: 24px;
  font-weight: 200;
  color: #34343c;
  content: "\2B";
}

.accordion-v1 .panel-title > a[aria-expanded="true"] {
  color: #fff;
  background: #BAF402;
}

.accordion-v1 .panel-title > a[aria-expanded="true"]:after {
  color: #fff;
  position: absolute;
  top: 5px;
  right: 20px;
  content: "\2013";
}

.accordion-v1 .panel-title > a:focus, .accordion-v1 .panel-title > a:hover {
  color: #fff;
  background: #BAF402;
  text-decoration: none;
}

.accordion-v1 .panel-title > a:focus:after, .accordion-v1 .panel-title > a:hover:after {
  color: #fff;
}

.accordion-v1 .panel-body {
  font-size: 15px;
  font-weight: 300;
  color: #606060;
}

/* Accordion v1 Active aligned to the left (the default is right) */
.accordion-v1.accordion-v1-active-left .panel-title > a {
  padding: 15px 15px 15px 50px;
}

.accordion-v1.accordion-v1-active-left .panel-title > a:after {
  position: absolute;
  top: 7.5px;
  left: 20px;
  right: auto;
}

.accordion-v1.accordion-v1-active-left .panel-title > a[aria-expanded="true"]:after {
  position: absolute;
  top: 6px;
  left: 20px;
  right: auto;
}

/*--------------------------------------------------
    [Accordion v2]
----------------------------------------------------*/
.accordion-v2 .panel-group {
  margin-bottom: 0;
}

.accordion-v2 .panel {
  background: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.accordion-v2 .panel + .panel {
  margin: 0;
}

.accordion-v2 .panel-default .panel-heading {
  background: #fff;
  border: 1px solid #f7f8fa;
  border-radius: 0;
  box-shadow: 1px 2px 5px 0 #eff1f8;
  padding: 0;
  margin-bottom: 15px;
}

.accordion-v2 .panel-default .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.accordion-v2 .panel-title > a {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  color: #34343c;
  padding: 15px 50px 15px 15px;
}

.accordion-v2 .panel-title > a:after {
  position: absolute;
  top: 10px;
  right: 15px;
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 18px;
  font-weight: 200;
  color: #34343c;
  text-align: center;
  vertical-align: middle;
  content: "\2B";
  padding: 4px;
}

.accordion-v2 .panel-title > a[aria-expanded="true"]:after {
  content: "\2013";
  padding: 5px;
}

.accordion-v2 .panel-title > a:focus, .accordion-v2 .panel-title > a:hover {
  color: #BAF402;
  text-decoration: none;
}

.accordion-v2 .panel-title > a:focus:after, .accordion-v2 .panel-title > a:hover:after {
  color: #BAF402;
}

.accordion-v2 .panel-title > a .panel-title-icon {
  position: relative;
  top: 3px;
  min-width: 30px;
  height: auto;
  text-align: center;
  font-size: 18px;
  margin-right: 8px;
}

.accordion-v2 .panel-body {
  font-size: 15px;
  font-weight: 300;
  color: #606060;
  border: none;
}

/* Accordion v2 Active aligned to the left (the default is right) */
.accordion-v2.accordion-v2-active-left .panel-title > a {
  padding: 15px 15px 15px 70px;
}

.accordion-v2.accordion-v2-active-left .panel-title > a:after {
  position: absolute;
  top: 10px;
  left: 15px;
}

/*--------------------------------------------------
    [Accordion v3]
----------------------------------------------------*/
.accordion-v3 .panel-group {
  margin-bottom: 0;
}

.accordion-v3 .panel {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.accordion-v3 .panel-default {
  background: transparent;
}

.accordion-v3 .panel-default .panel-heading {
  border-radius: 0;
  border: 1px solid #fff;
  background: transparent;
  padding: 0;
}

.accordion-v3 .panel-default .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.accordion-v3 .panel-title > a {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 15px;
  color: #fff;
  padding: 15px 15px 15px 45px;
}

.accordion-v3 .panel-title > a:before {
  position: absolute;
  top: 8px;
  left: 15px;
  font-size: 24px;
  font-weight: 200;
  color: #fff;
  content: "\2B";
}

.accordion-v3 .panel-title > a[aria-expanded="true"]:before {
  position: absolute;
  top: 10px;
  left: 15px;
  content: "\2013";
}

.accordion-v3 .panel-title > a:focus, .accordion-v3 .panel-title > a:hover {
  color: #BAF402;
  text-decoration: none;
}

.accordion-v3 .panel-title > a:focus:before, .accordion-v3 .panel-title > a:hover:before {
  color: #BAF402;
}

.accordion-v3 .panel-body {
  font-size: 15px;
  font-weight: 300;
  color: #fff;
}

/* Accordion v3 Active aligned to the right (the default is left) */
.accordion-v3.accordion-v3-active-right .panel-title > a {
  padding: 15px 50px 15px 15px;
}

.accordion-v3.accordion-v3-active-right .panel-title > a:before {
  position: absolute;
  top: 8px;
  left: auto;
  right: 15px;
}

/*--------------------------------------------------
    [Accordion v4]
----------------------------------------------------*/
.accordion-v4 {
  padding-left: 50px;
}

.accordion-v4 .panel-group {
  margin-bottom: 0;
}

.accordion-v4 .panel {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.accordion-v4 .panel-default {
  background: transparent;
}

.accordion-v4 .panel-default .panel-heading {
  border-radius: 0;
  border: 1px solid #fff;
  background: transparent;
  padding: 0;
}

.accordion-v4 .panel-default .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.accordion-v4 .panel-title > a {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 15px;
  color: #fff;
  padding: 10px;
}

.accordion-v4 .panel-title > a:before {
  position: absolute;
  top: 0;
  left: -50px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  font-weight: 200;
  color: #BAF402;
  line-height: .5;
  border: 1px solid #fff;
  content: "\2B";
  padding: 13px;
}

.accordion-v4 .panel-title > a[aria-expanded="true"]:before {
  line-height: .6;
  content: "\2013";
}

.accordion-v4 .panel-title > a:focus, .accordion-v4 .panel-title > a:hover {
  color: #BAF402;
  text-decoration: none;
}

.accordion-v4 .panel-title > a:focus:before, .accordion-v4 .panel-title > a:hover:before {
  color: #BAF402;
}

.accordion-v4 .panel-body {
  font-size: 15px;
  font-weight: 300;
  color: #fff;
}

/* Accordion v4 Active aligned to the left (the default is right) */
.accordion-v4.accordion-v4-active-right {
  padding: 0 50px 0 0;
}

.accordion-v4.accordion-v4-active-right .panel-title > a:before {
  position: absolute;
  top: 0;
  left: auto;
  right: -50px;
}

/*--------------------------------------------------
    [Accordion v5]
----------------------------------------------------*/
.accordion-v5 .panel-group {
  margin-bottom: 0;
}

.accordion-v5 .panel {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.accordion-v5 .panel + .panel {
  margin-top: 0;
}

.accordion-v5 .panel-default .panel-heading {
  background: 0;
  border-radius: 0;
  padding: 0;
}

.accordion-v5 .panel-default .panel-heading + .panel-collapse > .panel-body {
  border-top: none;
}

.accordion-v5 .panel-title > a {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  color: #34343c;
  padding: 15px 35px;
}

.accordion-v5 .panel-title > a:after {
  position: absolute;
  top: 8px;
  right: 20px;
  display: inline-block;
  font-size: 24px;
  font-weight: 200;
  color: #34343c;
  vertical-align: middle;
  content: "\2B";
}

.accordion-v5 .panel-title > a[aria-expanded="true"]:after {
  position: absolute;
  top: 10px;
  right: 20px;
  content: "\2013";
}

.accordion-v5 .panel-title > a:focus, .accordion-v5 .panel-title > a:hover {
  color: #BAF402;
  text-decoration: none;
}

.accordion-v5 .panel-title > a:focus:after, .accordion-v5 .panel-title > a:hover:after {
  color: #BAF402;
}

.accordion-v5 .panel-body {
  font-size: 15px;
  font-weight: 300;
  color: #5d5d5d;
  background: #f7f8fa;
  border-top: none;
  padding: 50px;
}

/* Accordion v5 Active aligned to the left (the default is right) */
.accordion-v5.accordion-v5-active-left .panel-title > a {
  padding: 15px 15px 15px 50px;
}

.accordion-v5.accordion-v5-active-left .panel-title > a:after {
  position: absolute;
  top: 8px;
  left: 20px;
  right: auto;
}

.accordion-v5.accordion-v5-active-left .panel-title > a[aria-expanded="true"]:after {
  position: absolute;
  top: 5px;
  left: 20px;
  right: auto;
}

/*--------------------------------------------------
    [Alerts]
----------------------------------------------------*/
/* Alert Box Error */
.alert-box-error {
  font-size: 14px;
  color: #e57287;
  background: rgba(229, 114, 135, 0.2);
  border: 1px solid #f5bec9;
}

.alert-box-error .close {
  color: #e57287;
}

.alert-box-error .alert-box-element {
  color: #eb90a1;
  margin: 0 10px;
}

/* Alert Box Info */
.alert-box-info {
  font-size: 14px;
  color: #687ffa;
  background: rgba(104, 127, 250, 0.2);
  border: 1px solid #aebaff;
}

.alert-box-info .close {
  color: #687ffa;
}

.alert-box-info .alert-box-element {
  color: #8b9cfb;
  margin: 0 10px;
}

/* Alert Box Warning */
.alert-box-warning {
  font-size: 14px;
  color: #c69f73;
  background: rgba(211, 175, 135, 0.2);
  border: 1px solid #e4d1bb;
}

.alert-box-warning .close {
  color: #c69f73;
}

.alert-box-warning .alert-box-element {
  color: #ddc0a1;
  margin: 0 10px;
}

/* Alert Box Success */
.alert-box-success {
  font-size: 14px;
  color: #009688;
  background: rgba(0, 150, 136, 0.2);
  border: 1px solid #00e3cd;
}

.alert-box-success .close {
  color: #009688;
}

.alert-box-success .alert-box-element {
  color: #5cc1b7;
  margin: 0 10px;
}

/* Alert Box General */
.alert-box-general {
  background: #fff;
  border: 1px solid #ebeef6;
  overflow: hidden;
}

.alert-box-general .alert-box-element {
  float: left;
  margin-right: 20px;
}

.alert-box-general .alert-box-body {
  overflow: hidden;
}

.alert-box-general .alert-box-body .alert-box-title {
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}

.alert-box-general .alert-box-body .alert-box-paragraph {
  font-size: 14px;
  color: #606060;
  margin-bottom: 0;
}

/* Alert Box Vertical */
.alert-box-vertical {
  position: relative;
  color: #fff;
  text-align: center;
  border: none;
  overflow: hidden;
  padding: 30px 10px;
}

.alert-box-vertical .close {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 1;
  float: none;
  color: #fff;
  opacity: .6;
}

.alert-box-vertical .close:hover {
  opacity: .9;
}

.alert-box-vertical .close-overlay {
  position: absolute;
  top: 0;
  right: -40px;
  width: 100px;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 20px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.alert-box-vertical .alert-box-element {
  display: block;
  font-size: 24px;
  margin-bottom: 10px;
}

.alert-box-vertical .alert-box-title {
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

/*--------------------------------------------------
    [Blockquotes v1]
----------------------------------------------------*/
.blockquotes-v1 {
  position: relative;
  max-width: 400px;
  float: left;
  font-size: 20px;
  font-style: italic;
  color: #BAF402;
  border-left: none;
  clear: left;
  padding: 10px 0;
  margin-right: 60px;
}

.blockquotes-v1:before {
  display: inline-block;
  font-size: 26px;
  content: open-quote;
  padding-right: 5px;
}

.blockquotes-v1:after {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 120px;
  height: 2px;
  background: #BAF402;
  content: " ";
}

/*--------------------------------------------------
    [Blockquotes v2]
----------------------------------------------------*/
.blockquotes-v2 {
  float: right;
  max-width: 350px;
  font-size: 20px;
  font-style: italic;
  color: #e55973;
  text-align: right;
  border-left: none;
  border-right: 2px solid #e55973;
  text-transform: uppercase;
  clear: right;
  padding: 10px 20px 10px 10px;
}

/*--------------------------------------------------
    [Blockquotes v3]
----------------------------------------------------*/
.blockquotes-v3 {
  position: relative;
  max-width: 400px;
  z-index: 1;
  float: left;
  font-size: 24px;
  font-weight: 300;
  color: #606060;
  text-transform: uppercase;
  border-left: none;
  clear: left;
  padding: 15px 0 15px 20px;
  margin: 0 60px 0 0;
}

.blockquotes-v3:before {
  position: absolute;
  top: -5px;
  left: 10px;
  z-index: -1;
  display: inline-block;
  font-size: 50px;
  font-family: FontAwesome;
  color: #f7f7f7;
  content: "\f10d";
}

/*--------------------------------------------------
    [Blockquotes v4]
----------------------------------------------------*/
.blockquotes-v4 {
  border-left: none;
  text-align: center;
  margin: 0 0 15px;
}

.blockquotes-v4 .blockquotes-v4-text {
  position: relative;
  font-size: 15px;
  font-style: italic;
  font-weight: 300;
  color: #606060;
  margin: 30px 0;
}

.blockquotes-v4 .blockquotes-v4-text:before, .blockquotes-v4 .blockquotes-v4-text:after {
  position: absolute;
  left: 50%;
  width: 20px;
  height: 1px;
  background: #cbcbcb;
  content: " ";
  margin-left: -10px;
}

.blockquotes-v4 .blockquotes-v4-text:before {
  top: -15px;
}

.blockquotes-v4 .blockquotes-v4-text:after {
  bottom: -15px;
}

.blockquotes-v4 .blockquotes-v4-by {
  display: block;
  color: #34343c;
}

/*--------------------------------------------------
    [Breadcrumbs v1]
----------------------------------------------------*/
.breadcrumbs-v1 {
  background: #eef0f4;
  border-top: 1px solid #ebeef6;
  border-bottom: 1px solid #ebeef6;
  padding: 50px 0;
}

.breadcrumbs-v1 .breadcrumbs-v1-title {
  display: inline-block;
  font-size: 20px;
  margin: 0;
}

.breadcrumbs-v1 .breadcrumbs-v1-links {
  list-style: none;
  float: right;
  margin-top: 3px;
}

.breadcrumbs-v1 .breadcrumbs-v1-links > li {
  display: inline-block;
}

.breadcrumbs-v1 .breadcrumbs-v1-links > li > a {
  color: #757589;
}

.breadcrumbs-v1 .breadcrumbs-v1-links > li > a:hover {
  color: #BAF402;
  text-decoration: none;
}

.breadcrumbs-v1 .breadcrumbs-v1-links > li.active {
  color: #BAF402;
}

.breadcrumbs-v1 .breadcrumbs-v1-links > li + li:before {
  color: #757589;
  content: "/\00a0";
  padding: 0 5px;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .breadcrumbs-v1 .breadcrumbs-v1-title {
    float: none;
    margin-bottom: 10px;
  }
  .breadcrumbs-v1 .breadcrumbs-v1-links {
    text-align: left;
    padding-left: 0;
  }
}

/*--------------------------------------------------
    [Breadcrumbs v2]
----------------------------------------------------*/
.breadcrumbs-v2 {
  background: #eef0f4;
  border-top: 1px solid #ebeef6;
  border-bottom: 1px solid #ebeef6;
  overflow: hidden;
  padding: 50px 0;
}

.breadcrumbs-v2 .breadcrumbs-v2-title {
  font-size: 20px;
  margin: 0;
}

.breadcrumbs-v2 .breadcrumbs-v2-links {
  list-style: none;
  padding-left: 0;
}

.breadcrumbs-v2 .breadcrumbs-v2-links > li {
  display: inline-block;
}

.breadcrumbs-v2 .breadcrumbs-v2-links > li > a {
  color: #757589;
}

.breadcrumbs-v2 .breadcrumbs-v2-links > li > a:hover {
  color: #BAF402;
  text-decoration: none;
}

.breadcrumbs-v2 .breadcrumbs-v2-links > li.active {
  color: #BAF402;
}

.breadcrumbs-v2 .breadcrumbs-v2-links > li + li:before {
  color: #757589;
  content: "/\00a0";
  padding: 0 5px;
}

/*--------------------------------------------------
    [Breadcrumbs v3]
----------------------------------------------------*/
.breadcrumbs-v3 {
  position: relative;
  z-index: 1;
  background: url(../../assets/img/1920x1080/10.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  padding: 80px 0;
}

.breadcrumbs-v3:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.6);
  content: " ";
}

.breadcrumbs-v3 .breadcrumbs-v3-left-wing {
  float: left;
  margin-right: 15px;
}

.breadcrumbs-v3 .breadcrumbs-v3-left-wing .breadcrumbs-v3-title {
  font-size: 26px;
  color: #fff;
  margin: 0;
}

.breadcrumbs-v3 .breadcrumbs-v3-links {
  list-style: none;
  text-align: right;
  overflow: hidden;
  margin-top: 6px;
}

.breadcrumbs-v3 .breadcrumbs-v3-links > li {
  display: inline-block;
  font-size: 15px;
}

.breadcrumbs-v3 .breadcrumbs-v3-links > li > a {
  color: #fff;
}

.breadcrumbs-v3 .breadcrumbs-v3-links > li > a:hover {
  color: #BAF402;
  text-decoration: none;
}

.breadcrumbs-v3 .breadcrumbs-v3-links > li.active {
  color: #BAF402;
}

.breadcrumbs-v3 .breadcrumbs-v3-links > li + li:before {
  color: #fff;
  content: "/\00a0";
  padding: 0 5px;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .breadcrumbs-v3 .breadcrumbs-v3-title {
    float: none;
    margin-bottom: 10px;
  }
  .breadcrumbs-v3 .breadcrumbs-v3-links {
    text-align: left;
    padding-left: 0;
  }
}

/*--------------------------------------------------
    [Breadcrumbs v4]
----------------------------------------------------*/
.breadcrumbs-v4 {
  position: relative;
  z-index: 1;
  text-align: center;
  background: url(../../assets/img/1920x1080/07.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 170px 0 100px;
}

.breadcrumbs-v4:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.7);
  content: " ";
}

.breadcrumbs-v4 .breadcrumbs-v4-title {
  font-size: 50px;
  color: #fff;
  margin: 0 0 10px;
}

.breadcrumbs-v4 .breadcrumbs-v4-subtitle {
  display: block;
  font-size: 22px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #fff;
}

.breadcrumbs-v4 .breadcrumbs-v4-arrows-left,
.breadcrumbs-v4 .breadcrumbs-v4-arrows-right {
  position: absolute;
  top: 50%;
  margin-top: -15px;
}

.breadcrumbs-v4 .breadcrumbs-v4-arrows-left .disabled,
.breadcrumbs-v4 .breadcrumbs-v4-arrows-right .disabled {
  pointer-events: none;
}

.breadcrumbs-v4 .breadcrumbs-v4-arrows-left {
  left: 20%;
}

.breadcrumbs-v4 .breadcrumbs-v4-arrows-right {
  right: 20%;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .breadcrumbs-v4 .breadcrumbs-v4-arrows-left {
    left: 5%;
  }
  .breadcrumbs-v4 .breadcrumbs-v4-arrows-right {
    right: 5%;
  }
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .breadcrumbs-v4 .breadcrumbs-v4-title {
    font-size: 22px;
  }
}

/*--------------------------------------------------
    [Breadcrumbs v5]
----------------------------------------------------*/
.breadcrumbs-v5 {
  position: relative;
  z-index: 1;
  background: url(../../assets/img/1920x1080/48.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
  padding: 170px 0 100px;
}

.breadcrumbs-v5:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.5);
  content: " ";
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-v2 {
  background: url(../../assets/img/1920x1080/31.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-v3 {
  background: url(../../assets/img/1920x1080/17.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 100%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-v4 {
  background: url(../../assets/img/1920x1080/22.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-v5 {
  background: url(../../assets/img/1920x1080/29.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-v5:before {
  background: rgba(52, 52, 60, 0.6);
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-v6 {
  background: url(../../assets/img/1920x1080/52.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-v7 {
  background: url(../../assets/img/1920x1080/34.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5 .breadcrumbs-v5-title {
  display: block;
  font-size: 70px;
  font-weight: 300;
  color: #fff;
  line-height: 1.1;
  margin-bottom: 10px;
}

.breadcrumbs-v5 .breadcrumbs-v5-subtitle {
  display: block;
  font-size: 18px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #fff;
  opacity: .8;
}

.breadcrumbs-v5 .breadcrumbs-v5-divider {
  font-size: 18px;
  color: #fff;
  padding: 0 10px;
}

.breadcrumbs-v5 .breadcrumbs-v5-video {
  display: inline-block;
  font-size: 22px;
  font-weight: 300;
  color: #fff;
  border-bottom: 2px solid #fff;
  padding-bottom: 3px;
  text-decoration: none;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .breadcrumbs-v5 .breadcrumbs-v5-title {
    font-size: 50px;
  }
}

/*--------------------------------------------------
    [Breadcrumbs v6]
----------------------------------------------------*/
.breadcrumbs-v6 {
  padding: 150px 0;
}

.breadcrumbs-v6 .breadcrumbs-v6-title {
  font-size: 70px;
  font-weight: 700;
  margin: 0;
  line-height: 1.1;
}

.breadcrumbs-v6 .breadcrumbs-v6-title-child {
  font-size: 100px;
  font-style: italic;
  font-family: Lora, serif;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .breadcrumbs-v6 .breadcrumbs-v6-title {
    font-size: 50px;
  }
  .breadcrumbs-v6 .breadcrumbs-v6-title-child {
    font-size: 70px;
  }
}

/*--------------------------------------------------
    [Buttons On Hover]
----------------------------------------------------*/
/* Button Base Color */
.btn-base {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base:focus, .btn-base:active:focus, .btn-base.active:focus, .btn-base.focus, .btn-base:active.focus, .btn-base.active.focus {
  outline: none;
}

.btn-base:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base:hover, .btn-base:focus, .btn-base.focus {
  color: #fff;
  background: #BAF402;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-base:after, .btn-base:before {
  background: transparent;
}

.btn-base:active, .btn-base.active {
  background-image: none;
  outline: 0;
}

.btn-base.disabled, .btn-base[disabled],
fieldset[disabled] .btn-base {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 6px 9px;
}

.btn-base .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 9px 11px;
}

.btn-base .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 12px 13px;
}

.btn-base .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 15px 15px;
}

/* Button Base White Color */
.btn-white {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .btn-white.focus, .btn-white:active.focus, .btn-white.active.focus {
  outline: none;
}

.btn-white:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white:hover, .btn-white:focus, .btn-white.focus {
  color: #34343c;
  background: #fff;
  border-color: #fff;
  text-decoration: none;
}

.btn-white:after, .btn-white:before {
  background: transparent;
}

.btn-white:active, .btn-white.active {
  background-image: none;
  outline: 0;
}

.btn-white.disabled, .btn-white[disabled],
fieldset[disabled] .btn-white {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Dark Color */
.btn-dark {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .btn-dark.focus, .btn-dark:active.focus, .btn-dark.active.focus {
  outline: none;
}

.btn-dark:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dark:hover, .btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background: #34343c;
  border-color: #34343c;
  text-decoration: none;
}

.btn-dark:after, .btn-dark:before {
  background: transparent;
}

.btn-dark:active, .btn-dark.active {
  background-image: none;
  outline: 0;
}

.btn-dark.disabled, .btn-dark[disabled],
fieldset[disabled] .btn-dark {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dark .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 6px 9px;
}

.btn-dark .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 9px 11px;
}

.btn-dark .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 12px 13px;
}

.btn-dark .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 15px 15px;
}

/* Button Grey Color */
.btn-grey {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #9e9e9e;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-grey:focus, .btn-grey:active:focus, .btn-grey.active:focus, .btn-grey.focus, .btn-grey:active.focus, .btn-grey.active.focus {
  outline: none;
}

.btn-grey:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-grey:hover, .btn-grey:focus, .btn-grey.focus {
  color: #fff;
  background: #9e9e9e;
  border-color: #9e9e9e;
  text-decoration: none;
}

.btn-grey:after, .btn-grey:before {
  background: transparent;
}

.btn-grey:active, .btn-grey.active {
  background-image: none;
  outline: 0;
}

.btn-grey.disabled, .btn-grey[disabled],
fieldset[disabled] .btn-grey {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-grey .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 6px 9px;
}

.btn-grey .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 9px 11px;
}

.btn-grey .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 12px 13px;
}

.btn-grey .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 15px 15px;
}

/* Button Blue Grey Color */
.btn-blue-grey {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #546e7a;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-grey:focus, .btn-blue-grey:active:focus, .btn-blue-grey.active:focus, .btn-blue-grey.focus, .btn-blue-grey:active.focus, .btn-blue-grey.active.focus {
  outline: none;
}

.btn-blue-grey:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-grey:hover, .btn-blue-grey:focus, .btn-blue-grey.focus {
  color: #fff;
  background: #546e7a;
  border-color: #546e7a;
  text-decoration: none;
}

.btn-blue-grey:after, .btn-blue-grey:before {
  background: transparent;
}

.btn-blue-grey:active, .btn-blue-grey.active {
  background-image: none;
  outline: 0;
}

.btn-blue-grey.disabled, .btn-blue-grey[disabled],
fieldset[disabled] .btn-blue-grey {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-grey .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 6px 9px;
}

.btn-blue-grey .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 9px 11px;
}

.btn-blue-grey .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 12px 13px;
}

.btn-blue-grey .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 15px 15px;
}

/* Button Blue Color */
.btn-blue {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #536dfe;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue:focus, .btn-blue:active:focus, .btn-blue.active:focus, .btn-blue.focus, .btn-blue:active.focus, .btn-blue.active.focus {
  outline: none;
}

.btn-blue:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue:hover, .btn-blue:focus, .btn-blue.focus {
  color: #fff;
  background: #536dfe;
  border-color: #536dfe;
  text-decoration: none;
}

.btn-blue:after, .btn-blue:before {
  background: transparent;
}

.btn-blue:active, .btn-blue.active {
  background-image: none;
  outline: 0;
}

.btn-blue.disabled, .btn-blue[disabled],
fieldset[disabled] .btn-blue {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 6px 9px;
}

.btn-blue .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 9px 11px;
}

.btn-blue .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 12px 13px;
}

.btn-blue .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 15px 15px;
}

/* Button Teal Color */
.btn-teal {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #009688;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-teal:focus, .btn-teal:active:focus, .btn-teal.active:focus, .btn-teal.focus, .btn-teal:active.focus, .btn-teal.active.focus {
  outline: none;
}

.btn-teal:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-teal:hover, .btn-teal:focus, .btn-teal.focus {
  color: #fff;
  background: #009688;
  border-color: #009688;
  text-decoration: none;
}

.btn-teal:after, .btn-teal:before {
  background: transparent;
}

.btn-teal:active, .btn-teal.active {
  background-image: none;
  outline: 0;
}

.btn-teal.disabled, .btn-teal[disabled],
fieldset[disabled] .btn-teal {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-teal .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 6px 9px;
}

.btn-teal .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 9px 11px;
}

.btn-teal .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 12px 13px;
}

.btn-teal .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 15px 15px;
}

/* Button Green Color */
.btn-green {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-green:focus, .btn-green:active:focus, .btn-green.active:focus, .btn-green.focus, .btn-green:active.focus, .btn-green.active.focus {
  outline: none;
}

.btn-green:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-green:hover, .btn-green:focus, .btn-green.focus {
  color: #fff;
  background: #BAF402;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-green:after, .btn-green:before {
  background: transparent;
}

.btn-green:active, .btn-green.active {
  background-image: none;
  outline: 0;
}

.btn-green.disabled, .btn-green[disabled],
fieldset[disabled] .btn-green {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-green .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 6px 9px;
}

.btn-green .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 9px 11px;
}

.btn-green .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 12px 13px;
}

.btn-green .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 15px 15px;
}

/* Button Red Color */
.btn-red {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #e55973;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-red:focus, .btn-red:active:focus, .btn-red.active:focus, .btn-red.focus, .btn-red:active.focus, .btn-red.active.focus {
  outline: none;
}

.btn-red:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-red:hover, .btn-red:focus, .btn-red.focus {
  color: #fff;
  background: #e55973;
  border-color: #e55973;
  text-decoration: none;
}

.btn-red:after, .btn-red:before {
  background: transparent;
}

.btn-red:active, .btn-red.active {
  background-image: none;
  outline: 0;
}

.btn-red.disabled, .btn-red[disabled],
fieldset[disabled] .btn-red {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-red .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 6px 9px;
}

.btn-red .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 9px 11px;
}

.btn-red .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 12px 13px;
}

.btn-red .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 15px 15px;
}

/* Button Pink Color */
.btn-pink {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #e91e63;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-pink:focus, .btn-pink:active:focus, .btn-pink.active:focus, .btn-pink.focus, .btn-pink:active.focus, .btn-pink.active.focus {
  outline: none;
}

.btn-pink:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-pink:hover, .btn-pink:focus, .btn-pink.focus {
  color: #fff;
  background: #e91e63;
  border-color: #e91e63;
  text-decoration: none;
}

.btn-pink:after, .btn-pink:before {
  background: transparent;
}

.btn-pink:active, .btn-pink.active {
  background-image: none;
  outline: 0;
}

.btn-pink.disabled, .btn-pink[disabled],
fieldset[disabled] .btn-pink {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-pink .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 6px 9px;
}

.btn-pink .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 9px 11px;
}

.btn-pink .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 12px 13px;
}

.btn-pink .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 15px 15px;
}

/* Button Purple Color */
.btn-purple {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #b260ce;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-purple:focus, .btn-purple:active:focus, .btn-purple.active:focus, .btn-purple.focus, .btn-purple:active.focus, .btn-purple.active.focus {
  outline: none;
}

.btn-purple:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-purple:hover, .btn-purple:focus, .btn-purple.focus {
  color: #fff;
  background: #b260ce;
  border-color: #b260ce;
  text-decoration: none;
}

.btn-purple:after, .btn-purple:before {
  background: transparent;
}

.btn-purple:active, .btn-purple.active {
  background-image: none;
  outline: 0;
}

.btn-purple.disabled, .btn-purple[disabled],
fieldset[disabled] .btn-purple {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-purple .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 6px 9px;
}

.btn-purple .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 9px 11px;
}

.btn-purple .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 12px 13px;
}

.btn-purple .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 15px 15px;
}

/* Button Gold Color */
.btn-gold {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #c69f73;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-gold:focus, .btn-gold:active:focus, .btn-gold.active:focus, .btn-gold.focus, .btn-gold:active.focus, .btn-gold.active.focus {
  outline: none;
}

.btn-gold:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-gold:hover, .btn-gold:focus, .btn-gold.focus {
  color: #fff;
  background: #c69f73;
  border-color: #c69f73;
  text-decoration: none;
}

.btn-gold:after, .btn-gold:before {
  background: transparent;
}

.btn-gold:active, .btn-gold.active {
  background-image: none;
  outline: 0;
}

.btn-gold.disabled, .btn-gold[disabled],
fieldset[disabled] .btn-gold {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-gold .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 6px 9px;
}

.btn-gold .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 9px 11px;
}

.btn-gold .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 12px 13px;
}

.btn-gold .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 15px 15px;
}

/*--------------------------------------------------
    [Buttons Border]
----------------------------------------------------*/
/* Button Base Color */
.btn-base-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #BAF402;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-brd:focus, .btn-base-brd:active:focus, .btn-base-brd.active:focus, .btn-base-brd.focus, .btn-base-brd:active.focus, .btn-base-brd.active.focus {
  outline: none;
}

.btn-base-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-brd:hover, .btn-base-brd:focus, .btn-base-brd.focus {
  color: #fff;
  background: #BAF402;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-base-brd:after, .btn-base-brd:before {
  background: transparent;
}

.btn-base-brd:active, .btn-base-brd.active {
  background-image: none;
  outline: 0;
}

.btn-base-brd.disabled, .btn-base-brd[disabled],
fieldset[disabled] .btn-base-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 6px 9px;
}

.btn-base-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 9px 11px;
}

.btn-base-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 12px 13px;
}

.btn-base-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 15px 15px;
}

/* Button Base White Color */
.btn-white-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-brd:focus, .btn-white-brd:active:focus, .btn-white-brd.active:focus, .btn-white-brd.focus, .btn-white-brd:active.focus, .btn-white-brd.active.focus {
  outline: none;
}

.btn-white-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-brd:hover, .btn-white-brd:focus, .btn-white-brd.focus {
  color: #34343c;
  background: #fff;
  border-color: #fff;
  text-decoration: none;
}

.btn-white-brd:after, .btn-white-brd:before {
  background: transparent;
}

.btn-white-brd:active, .btn-white-brd.active {
  background-image: none;
  outline: 0;
}

.btn-white-brd.disabled, .btn-white-brd[disabled],
fieldset[disabled] .btn-white-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Dark Color */
.btn-dark-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #34343c;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dark-brd:focus, .btn-dark-brd:active:focus, .btn-dark-brd.active:focus, .btn-dark-brd.focus, .btn-dark-brd:active.focus, .btn-dark-brd.active.focus {
  outline: none;
}

.btn-dark-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dark-brd:hover, .btn-dark-brd:focus, .btn-dark-brd.focus {
  color: #fff;
  background: #34343c;
  border-color: #34343c;
  text-decoration: none;
}

.btn-dark-brd:after, .btn-dark-brd:before {
  background: transparent;
}

.btn-dark-brd:active, .btn-dark-brd.active {
  background-image: none;
  outline: 0;
}

.btn-dark-brd.disabled, .btn-dark-brd[disabled],
fieldset[disabled] .btn-dark-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dark-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 6px 9px;
}

.btn-dark-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 9px 11px;
}

.btn-dark-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 12px 13px;
}

.btn-dark-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 15px 15px;
}

/* Button Grey Color */
.btn-grey-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #9e9e9e;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #9e9e9e;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-grey-brd:focus, .btn-grey-brd:active:focus, .btn-grey-brd.active:focus, .btn-grey-brd.focus, .btn-grey-brd:active.focus, .btn-grey-brd.active.focus {
  outline: none;
}

.btn-grey-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-grey-brd:hover, .btn-grey-brd:focus, .btn-grey-brd.focus {
  color: #fff;
  background: #9e9e9e;
  border-color: #9e9e9e;
  text-decoration: none;
}

.btn-grey-brd:after, .btn-grey-brd:before {
  background: transparent;
}

.btn-grey-brd:active, .btn-grey-brd.active {
  background-image: none;
  outline: 0;
}

.btn-grey-brd.disabled, .btn-grey-brd[disabled],
fieldset[disabled] .btn-grey-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-grey-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 6px 9px;
}

.btn-grey-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 9px 11px;
}

.btn-grey-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 12px 13px;
}

.btn-grey-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 15px 15px;
}

/* Button Blue Grey Color */
.btn-blue-grey-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #546e7a;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #546e7a;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-grey-brd:focus, .btn-blue-grey-brd:active:focus, .btn-blue-grey-brd.active:focus, .btn-blue-grey-brd.focus, .btn-blue-grey-brd:active.focus, .btn-blue-grey-brd.active.focus {
  outline: none;
}

.btn-blue-grey-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-grey-brd:hover, .btn-blue-grey-brd:focus, .btn-blue-grey-brd.focus {
  color: #fff;
  background: #546e7a;
  border-color: #546e7a;
  text-decoration: none;
}

.btn-blue-grey-brd:after, .btn-blue-grey-brd:before {
  background: transparent;
}

.btn-blue-grey-brd:active, .btn-blue-grey-brd.active {
  background-image: none;
  outline: 0;
}

.btn-blue-grey-brd.disabled, .btn-blue-grey-brd[disabled],
fieldset[disabled] .btn-blue-grey-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-grey-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 6px 9px;
}

.btn-blue-grey-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 9px 11px;
}

.btn-blue-grey-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 12px 13px;
}

.btn-blue-grey-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 15px 15px;
}

/* Button Blue Color */
.btn-blue-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #536dfe;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #536dfe;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-brd:focus, .btn-blue-brd:active:focus, .btn-blue-brd.active:focus, .btn-blue-brd.focus, .btn-blue-brd:active.focus, .btn-blue-brd.active.focus {
  outline: none;
}

.btn-blue-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-brd:hover, .btn-blue-brd:focus, .btn-blue-brd.focus {
  color: #fff;
  background: #536dfe;
  border-color: #536dfe;
  text-decoration: none;
}

.btn-blue-brd:after, .btn-blue-brd:before {
  background: transparent;
}

.btn-blue-brd:active, .btn-blue-brd.active {
  background-image: none;
  outline: 0;
}

.btn-blue-brd.disabled, .btn-blue-brd[disabled],
fieldset[disabled] .btn-blue-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 6px 9px;
}

.btn-blue-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 9px 11px;
}

.btn-blue-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 12px 13px;
}

.btn-blue-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 15px 15px;
}

/* Button Teal Color */
.btn-teal-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #009688;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #009688;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-teal-brd:focus, .btn-teal-brd:active:focus, .btn-teal-brd.active:focus, .btn-teal-brd.focus, .btn-teal-brd:active.focus, .btn-teal-brd.active.focus {
  outline: none;
}

.btn-teal-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-teal-brd:hover, .btn-teal-brd:focus, .btn-teal-brd.focus {
  color: #fff;
  background: #009688;
  border-color: #009688;
  text-decoration: none;
}

.btn-teal-brd:after, .btn-teal-brd:before {
  background: transparent;
}

.btn-teal-brd:active, .btn-teal-brd.active {
  background-image: none;
  outline: 0;
}

.btn-teal-brd.disabled, .btn-teal-brd[disabled],
fieldset[disabled] .btn-teal-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-teal-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 6px 9px;
}

.btn-teal-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 9px 11px;
}

.btn-teal-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 12px 13px;
}

.btn-teal-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 15px 15px;
}

/* Button Green Color */
.btn-green-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #BAF402;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-green-brd:focus, .btn-green-brd:active:focus, .btn-green-brd.active:focus, .btn-green-brd.focus, .btn-green-brd:active.focus, .btn-green-brd.active.focus {
  outline: none;
}

.btn-green-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-green-brd:hover, .btn-green-brd:focus, .btn-green-brd.focus {
  color: #fff;
  background: #BAF402;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-green-brd:after, .btn-green-brd:before {
  background: transparent;
}

.btn-green-brd:active, .btn-green-brd.active {
  background-image: none;
  outline: 0;
}

.btn-green-brd.disabled, .btn-green-brd[disabled],
fieldset[disabled] .btn-green-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-green-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 6px 9px;
}

.btn-green-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 9px 11px;
}

.btn-green-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 12px 13px;
}

.btn-green-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 15px 15px;
}

/* Button Red Color */
.btn-red-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #e55973;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #e55973;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-red-brd:focus, .btn-red-brd:active:focus, .btn-red-brd.active:focus, .btn-red-brd.focus, .btn-red-brd:active.focus, .btn-red-brd.active.focus {
  outline: none;
}

.btn-red-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-red-brd:hover, .btn-red-brd:focus, .btn-red-brd.focus {
  color: #fff;
  background: #e55973;
  border-color: #e55973;
  text-decoration: none;
}

.btn-red-brd:after, .btn-red-brd:before {
  background: transparent;
}

.btn-red-brd:active, .btn-red-brd.active {
  background-image: none;
  outline: 0;
}

.btn-red-brd.disabled, .btn-red-brd[disabled],
fieldset[disabled] .btn-red-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-red-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 6px 9px;
}

.btn-red-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 9px 11px;
}

.btn-red-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 12px 13px;
}

.btn-red-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 15px 15px;
}

/* Button Pink Color */
.btn-pink-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #e91e63;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #e91e63;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-pink-brd:focus, .btn-pink-brd:active:focus, .btn-pink-brd.active:focus, .btn-pink-brd.focus, .btn-pink-brd:active.focus, .btn-pink-brd.active.focus {
  outline: none;
}

.btn-pink-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-pink-brd:hover, .btn-pink-brd:focus, .btn-pink-brd.focus {
  color: #fff;
  background: #e91e63;
  border-color: #e91e63;
  text-decoration: none;
}

.btn-pink-brd:after, .btn-pink-brd:before {
  background: transparent;
}

.btn-pink-brd:active, .btn-pink-brd.active {
  background-image: none;
  outline: 0;
}

.btn-pink-brd.disabled, .btn-pink-brd[disabled],
fieldset[disabled] .btn-pink-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-pink-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 6px 9px;
}

.btn-pink-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 9px 11px;
}

.btn-pink-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 12px 13px;
}

.btn-pink-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 15px 15px;
}

/* Button Purple Color */
.btn-purple-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #b260ce;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #b260ce;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-purple-brd:focus, .btn-purple-brd:active:focus, .btn-purple-brd.active:focus, .btn-purple-brd.focus, .btn-purple-brd:active.focus, .btn-purple-brd.active.focus {
  outline: none;
}

.btn-purple-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-purple-brd:hover, .btn-purple-brd:focus, .btn-purple-brd.focus {
  color: #fff;
  background: #b260ce;
  border-color: #b260ce;
  text-decoration: none;
}

.btn-purple-brd:after, .btn-purple-brd:before {
  background: transparent;
}

.btn-purple-brd:active, .btn-purple-brd.active {
  background-image: none;
  outline: 0;
}

.btn-purple-brd.disabled, .btn-purple-brd[disabled],
fieldset[disabled] .btn-purple-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-purple-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 6px 9px;
}

.btn-purple-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 9px 11px;
}

.btn-purple-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 12px 13px;
}

.btn-purple-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 15px 15px;
}

/* Button Gold Color */
.btn-gold-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #c69f73;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #c69f73;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-gold-brd:focus, .btn-gold-brd:active:focus, .btn-gold-brd.active:focus, .btn-gold-brd.focus, .btn-gold-brd:active.focus, .btn-gold-brd.active.focus {
  outline: none;
}

.btn-gold-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-gold-brd:hover, .btn-gold-brd:focus, .btn-gold-brd.focus {
  color: #fff;
  background: #c69f73;
  border-color: #c69f73;
  text-decoration: none;
}

.btn-gold-brd:after, .btn-gold-brd:before {
  background: transparent;
}

.btn-gold-brd:active, .btn-gold-brd.active {
  background-image: none;
  outline: 0;
}

.btn-gold-brd.disabled, .btn-gold-brd[disabled],
fieldset[disabled] .btn-gold-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-gold-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 6px 9px;
}

.btn-gold-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 9px 11px;
}

.btn-gold-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 12px 13px;
}

.btn-gold-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 15px 15px;
}

/* Facebook */
.btn-fb-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #44619d;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #44619d;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-fb-brd:focus, .btn-fb-brd:active:focus, .btn-fb-brd.active:focus, .btn-fb-brd.focus, .btn-fb-brd:active.focus, .btn-fb-brd.active.focus {
  outline: none;
}

.btn-fb-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-fb-brd:hover, .btn-fb-brd:focus, .btn-fb-brd.focus {
  color: #fff;
  background: #44619d;
  border-color: #44619d;
  text-decoration: none;
}

.btn-fb-brd:after, .btn-fb-brd:before {
  background: transparent;
}

.btn-fb-brd:active, .btn-fb-brd.active {
  background-image: none;
  outline: 0;
}

.btn-fb-brd.disabled, .btn-fb-brd[disabled],
fieldset[disabled] .btn-fb-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-fb-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 6px 9px;
}

.btn-fb-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 9px 11px;
}

.btn-fb-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 12px 13px;
}

.btn-fb-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 15px 15px;
}

/* Twitter */
.btn-tw-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #55acee;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #55acee;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-tw-brd:focus, .btn-tw-brd:active:focus, .btn-tw-brd.active:focus, .btn-tw-brd.focus, .btn-tw-brd:active.focus, .btn-tw-brd.active.focus {
  outline: none;
}

.btn-tw-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-tw-brd:hover, .btn-tw-brd:focus, .btn-tw-brd.focus {
  color: #fff;
  background: #55acee;
  border-color: #55acee;
  text-decoration: none;
}

.btn-tw-brd:after, .btn-tw-brd:before {
  background: transparent;
}

.btn-tw-brd:active, .btn-tw-brd.active {
  background-image: none;
  outline: 0;
}

.btn-tw-brd.disabled, .btn-tw-brd[disabled],
fieldset[disabled] .btn-tw-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-tw-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 6px 9px;
}

.btn-tw-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 9px 11px;
}

.btn-tw-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 12px 13px;
}

.btn-tw-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 15px 15px;
}

/* Dribbble */
.btn-dr-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #ea4c89;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #ea4c89;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dr-brd:focus, .btn-dr-brd:active:focus, .btn-dr-brd.active:focus, .btn-dr-brd.focus, .btn-dr-brd:active.focus, .btn-dr-brd.active.focus {
  outline: none;
}

.btn-dr-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dr-brd:hover, .btn-dr-brd:focus, .btn-dr-brd.focus {
  color: #fff;
  background: #ea4c89;
  border-color: #ea4c89;
  text-decoration: none;
}

.btn-dr-brd:after, .btn-dr-brd:before {
  background: transparent;
}

.btn-dr-brd:active, .btn-dr-brd.active {
  background-image: none;
  outline: 0;
}

.btn-dr-brd.disabled, .btn-dr-brd[disabled],
fieldset[disabled] .btn-dr-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dr-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 6px 9px;
}

.btn-dr-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 9px 11px;
}

.btn-dr-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 12px 13px;
}

.btn-dr-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 15px 15px;
}

/* Instagram */
.btn-ig-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #125688;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #125688;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-ig-brd:focus, .btn-ig-brd:active:focus, .btn-ig-brd.active:focus, .btn-ig-brd.focus, .btn-ig-brd:active.focus, .btn-ig-brd.active.focus {
  outline: none;
}

.btn-ig-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-ig-brd:hover, .btn-ig-brd:focus, .btn-ig-brd.focus {
  color: #fff;
  background: #125688;
  border-color: #125688;
  text-decoration: none;
}

.btn-ig-brd:after, .btn-ig-brd:before {
  background: transparent;
}

.btn-ig-brd:active, .btn-ig-brd.active {
  background-image: none;
  outline: 0;
}

.btn-ig-brd.disabled, .btn-ig-brd[disabled],
fieldset[disabled] .btn-ig-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-ig-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 6px 9px;
}

.btn-ig-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 9px 11px;
}

.btn-ig-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 12px 13px;
}

.btn-ig-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 15px 15px;
}

/*--------------------------------------------------
    [Buttons Slide]
----------------------------------------------------*/
/* Button Slide */
.btn-slide {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.btn-slide:after {
  z-index: -1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  content: '';
}

/* Button Slide Top */
.btn-slide-top:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
}

.btn-slide-top:hover:after, .btn-slide-top:focus:after, .btn-slide-top:active:after {
  height: 100%;
}

/* Button Slide Right */
.btn-slide-right:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
}

.btn-slide-right:hover:after, .btn-slide-right:focus:after, .btn-slide-right:active:after {
  width: 100%;
}

/* Button Slide Bottom */
.btn-slide-bottom:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0%;
}

.btn-slide-bottom:hover:after, .btn-slide-bottom:focus:after, .btn-slide-bottom:active:after {
  height: 100%;
}

/* Button Slide Left */
.btn-slide-left:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
}

.btn-slide-left:hover:after, .btn-slide-left:focus:after, .btn-slide-left:active:after {
  width: 100%;
}

/* Button Slide Corner */
.btn-slide-corner {
  overflow: hidden;
}

.btn-slide-corner:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 0;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.btn-slide-corner:hover:after, .btn-slide-corner:focus:after, .btn-slide-corner:active:after {
  height: 160%;
  opacity: 1;
}

.btn-slide-corner:active:after {
  height: 200%;
}

/* Button Slide Center v1 */
.btn-slide-center-v1 {
  overflow: hidden;
}

.btn-slide-center-v1:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 103%;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.btn-slide-center-v1:hover:after, .btn-slide-center-v1:focus:after, .btn-slide-center-v1:active:after {
  opacity: 1;
  width: 101%;
}

.btn-slide-center-v1:active:after {
  width: 101%;
}

/* Button Slide Center v2 */
.btn-slide-center-v2 {
  overflow: hidden;
}

.btn-slide-center-v2:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 103%;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.btn-slide-center-v2:hover:after, .btn-slide-center-v2:focus:after, .btn-slide-center-v2:active:after {
  opacity: 1;
  width: 90%;
}

.btn-slide-center-v2:active:after {
  width: 101%;
}

/* Button Slide Center v3 */
.btn-slide-center-v3 {
  overflow: hidden;
}

.btn-slide-center-v3:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 101%;
  height: 0;
  opacity: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.btn-slide-center-v3:hover:after, .btn-slide-center-v3:focus:after, .btn-slide-center-v3:active:after {
  height: 75%;
  opacity: 1;
}

.btn-slide-center-v3:active:after {
  height: 130%;
}

/*------------------------------------------------------------------
    [Animate Buttons Wrap]
------------------------------------------------------------------*/
/* Animate Button Wrap */
.animate-btn-wrap {
  overflow: hidden;
}

.animate-btn-wrap .btn-icon {
  color: #fff;
}

.animate-btn-wrap .btn-text {
  top: 0;
}

.animate-btn-wrap .btn-icon,
.animate-btn-wrap .btn-text {
  display: block;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}

/*------------------------------------------------------------------
    [Animate Buttons Top]
------------------------------------------------------------------*/
/* Animate Mini Button Top */
.animate-btn-t-xs:hover .btn-text {
  top: -45px;
}

.animate-btn-t-xs .btn-icon {
  bottom: -45px;
}

.animate-btn-t-xs:hover .btn-icon {
  bottom: 18px;
}

/* Animate Small Button Top */
.animate-btn-t-sm:hover .btn-text {
  top: -45px;
}

.animate-btn-t-sm .btn-icon {
  bottom: -45px;
}

.animate-btn-t-sm:hover .btn-icon {
  bottom: 18px;
}

/* Animate Medium Button Top */
.animate-btn-t-md:hover .btn-text {
  top: -55px;
}

.animate-btn-t-md .btn-icon {
  bottom: -55px;
}

.animate-btn-t-md:hover .btn-icon {
  bottom: 20px;
}

/* Animate Large Button Top */
.animate-btn-t-lg:hover .btn-text {
  top: -70px;
}

.animate-btn-t-lg .btn-icon {
  bottom: -70px;
}

.animate-btn-t-lg:hover .btn-icon {
  bottom: 28px;
}

/*------------------------------------------------------------------
    [Animate Buttons Bottom]
------------------------------------------------------------------*/
/* Animate Mini Button Bottom */
.animate-btn-b-xs:hover .btn-text {
  top: 45px;
}

.animate-btn-b-xs .btn-icon {
  top: -45px;
}

.animate-btn-b-xs:hover .btn-icon {
  top: -20px;
}

/* Animate Small Button Bottom */
.animate-btn-b-sm:hover .btn-text {
  top: 45px;
}

.animate-btn-b-sm .btn-icon {
  top: -45px;
}

.animate-btn-b-sm:hover .btn-icon {
  top: -18px;
}

/* Animate Medium Button Bottom */
.animate-btn-b-md:hover .btn-text {
  top: 55px;
}

.animate-btn-b-md .btn-icon {
  top: -55px;
}

.animate-btn-b-md:hover .btn-icon {
  top: -20px;
}

/* Animate Large Button Bottom */
.animate-btn-b-lg:hover .btn-text {
  top: 70px;
}

.animate-btn-b-lg .btn-icon {
  top: -70px;
}

.animate-btn-b-lg:hover .btn-icon {
  top: -28px;
}

/*------------------------------------------------------------------
    [Animate Buttons Right]
------------------------------------------------------------------*/
/* Animate Button Right */
.animate-btn-r-xs .btn-text,
.animate-btn-r-sm .btn-text,
.animate-btn-r-md .btn-text,
.animate-btn-r-lg .btn-text {
  right: 0;
}

.animate-btn-r-xs:hover .btn-text,
.animate-btn-r-sm:hover .btn-text,
.animate-btn-r-md:hover .btn-text,
.animate-btn-r-lg:hover .btn-text {
  right: -90px;
  opacity: 0;
}

.animate-btn-r-xs .btn-icon,
.animate-btn-r-sm .btn-icon,
.animate-btn-r-md .btn-icon,
.animate-btn-r-lg .btn-icon {
  top: -20px;
  left: -90px;
  opacity: 0;
}

.animate-btn-r-xs:hover .btn-icon,
.animate-btn-r-sm:hover .btn-icon,
.animate-btn-r-md:hover .btn-icon,
.animate-btn-r-lg:hover .btn-icon {
  left: 0;
  opacity: 1;
}

/* Animate Large Button Right */
.animate-btn-r-lg .btn-icon {
  top: -28px;
}

/*------------------------------------------------------------------
    [Animate Buttons Left]
------------------------------------------------------------------*/
/* Animate Button Left */
.animate-btn-l-xs .btn-text,
.animate-btn-l-sm .btn-text,
.animate-btn-l-md .btn-text,
.animate-btn-l-lg .btn-text {
  left: 0;
}

.animate-btn-l-xs:hover .btn-text,
.animate-btn-l-sm:hover .btn-text,
.animate-btn-l-md:hover .btn-text,
.animate-btn-l-lg:hover .btn-text {
  left: -90px;
  opacity: 0;
}

.animate-btn-l-xs .btn-icon,
.animate-btn-l-sm .btn-icon,
.animate-btn-l-md .btn-icon,
.animate-btn-l-lg .btn-icon {
  top: -20px;
  right: -90px;
  opacity: 0;
}

.animate-btn-l-xs:hover .btn-icon,
.animate-btn-l-sm:hover .btn-icon,
.animate-btn-l-md:hover .btn-icon,
.animate-btn-l-lg:hover .btn-icon {
  right: 0;
  opacity: 1;
}

/* Animate Large Button Left */
.animate-btn-l-lg .btn-icon {
  top: -28px;
}

/* Animate Button Backgound Hover Effect */
.animate-btn-bg-hover:hover:after, .animate-btn-bg-hover:hover:before {
  width: 100%;
  height: 50%;
}

.animate-btn-bg-hover:before, .animate-btn-bg-hover:after {
  position: absolute;
  width: 0;
  height: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  content: "";
}

.animate-btn-bg-hover:before {
  left: 0;
  bottom: 0;
}

.animate-btn-bg-hover:after {
  right: 0;
  top: 0;
}

/*------------------------------------------------------------------
    [Animate Buttons Sizes]
------------------------------------------------------------------*/
/* Mini Size */
.animate-btn-base-xs {
  height: 35px;
  font-size: 14px;
  font-style: 300;
  padding: 8px 15px;
}

/* Small Size */
.animate-btn-base-sm {
  height: 40px;
  font-size: 14px;
  font-style: 300;
  padding: 9px 22px;
}

/* Medium Size */
.animate-btn-base-md {
  height: 50px;
  font-size: 16px;
  font-style: 300;
  padding: 12px 25px;
}

/* Large Size */
.animate-btn-base-lg {
  height: 60px;
  font-size: 20px;
  font-style: 300;
  padding: 15px 30px;
}

/*--------------------------------------------------
    [Buttons Border Slide]
----------------------------------------------------*/
/* Button Base Color Slide */
.btn-base-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #BAF402;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-brd-slide:focus, .btn-base-brd-slide:active:focus, .btn-base-brd-slide.active:focus, .btn-base-brd-slide.focus, .btn-base-brd-slide:active.focus, .btn-base-brd-slide.active.focus {
  outline: none;
}

.btn-base-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-brd-slide:hover, .btn-base-brd-slide:focus, .btn-base-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-base-brd-slide:after, .btn-base-brd-slide:before {
  background: #BAF402;
}

.btn-base-brd-slide:active, .btn-base-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-base-brd-slide.disabled, .btn-base-brd-slide[disabled],
fieldset[disabled] .btn-base-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 6px 9px;
}

.btn-base-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 9px 11px;
}

.btn-base-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 12px 13px;
}

.btn-base-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 15px 15px;
}

/* Button White Color Slide */
.btn-white-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-brd-slide:focus, .btn-white-brd-slide:active:focus, .btn-white-brd-slide.active:focus, .btn-white-brd-slide.focus, .btn-white-brd-slide:active.focus, .btn-white-brd-slide.active.focus {
  outline: none;
}

.btn-white-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-brd-slide:hover, .btn-white-brd-slide:focus, .btn-white-brd-slide.focus {
  color: #34343c;
  background: transparent;
  border-color: #fff;
  text-decoration: none;
}

.btn-white-brd-slide:after, .btn-white-brd-slide:before {
  background: #fff;
}

.btn-white-brd-slide:active, .btn-white-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-white-brd-slide.disabled, .btn-white-brd-slide[disabled],
fieldset[disabled] .btn-white-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Dark Color Slide */
.btn-dark-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #34343c;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dark-brd-slide:focus, .btn-dark-brd-slide:active:focus, .btn-dark-brd-slide.active:focus, .btn-dark-brd-slide.focus, .btn-dark-brd-slide:active.focus, .btn-dark-brd-slide.active.focus {
  outline: none;
}

.btn-dark-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dark-brd-slide:hover, .btn-dark-brd-slide:focus, .btn-dark-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #34343c;
  text-decoration: none;
}

.btn-dark-brd-slide:after, .btn-dark-brd-slide:before {
  background: #34343c;
}

.btn-dark-brd-slide:active, .btn-dark-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-dark-brd-slide.disabled, .btn-dark-brd-slide[disabled],
fieldset[disabled] .btn-dark-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dark-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 6px 9px;
}

.btn-dark-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 9px 11px;
}

.btn-dark-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 12px 13px;
}

.btn-dark-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 15px 15px;
}

/* Button Grey Color Slide */
.btn-grey-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #9e9e9e;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #9e9e9e;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-grey-brd-slide:focus, .btn-grey-brd-slide:active:focus, .btn-grey-brd-slide.active:focus, .btn-grey-brd-slide.focus, .btn-grey-brd-slide:active.focus, .btn-grey-brd-slide.active.focus {
  outline: none;
}

.btn-grey-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-grey-brd-slide:hover, .btn-grey-brd-slide:focus, .btn-grey-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #9e9e9e;
  text-decoration: none;
}

.btn-grey-brd-slide:after, .btn-grey-brd-slide:before {
  background: #9e9e9e;
}

.btn-grey-brd-slide:active, .btn-grey-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-grey-brd-slide.disabled, .btn-grey-brd-slide[disabled],
fieldset[disabled] .btn-grey-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-grey-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 6px 9px;
}

.btn-grey-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 9px 11px;
}

.btn-grey-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 12px 13px;
}

.btn-grey-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 15px 15px;
}

/* Button Blue Grey Color Slide */
.btn-blue-grey-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #546e7a;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #546e7a;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-grey-brd-slide:focus, .btn-blue-grey-brd-slide:active:focus, .btn-blue-grey-brd-slide.active:focus, .btn-blue-grey-brd-slide.focus, .btn-blue-grey-brd-slide:active.focus, .btn-blue-grey-brd-slide.active.focus {
  outline: none;
}

.btn-blue-grey-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-grey-brd-slide:hover, .btn-blue-grey-brd-slide:focus, .btn-blue-grey-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #546e7a;
  text-decoration: none;
}

.btn-blue-grey-brd-slide:after, .btn-blue-grey-brd-slide:before {
  background: #546e7a;
}

.btn-blue-grey-brd-slide:active, .btn-blue-grey-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-blue-grey-brd-slide.disabled, .btn-blue-grey-brd-slide[disabled],
fieldset[disabled] .btn-blue-grey-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-grey-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 6px 9px;
}

.btn-blue-grey-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 9px 11px;
}

.btn-blue-grey-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 12px 13px;
}

.btn-blue-grey-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 15px 15px;
}

/* Button Blue Color Slide */
.btn-blue-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #536dfe;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #536dfe;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-brd-slide:focus, .btn-blue-brd-slide:active:focus, .btn-blue-brd-slide.active:focus, .btn-blue-brd-slide.focus, .btn-blue-brd-slide:active.focus, .btn-blue-brd-slide.active.focus {
  outline: none;
}

.btn-blue-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-brd-slide:hover, .btn-blue-brd-slide:focus, .btn-blue-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #536dfe;
  text-decoration: none;
}

.btn-blue-brd-slide:after, .btn-blue-brd-slide:before {
  background: #536dfe;
}

.btn-blue-brd-slide:active, .btn-blue-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-blue-brd-slide.disabled, .btn-blue-brd-slide[disabled],
fieldset[disabled] .btn-blue-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 6px 9px;
}

.btn-blue-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 9px 11px;
}

.btn-blue-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 12px 13px;
}

.btn-blue-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 15px 15px;
}

/* Button Teal Color Slide */
.btn-teal-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #009688;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #009688;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-teal-brd-slide:focus, .btn-teal-brd-slide:active:focus, .btn-teal-brd-slide.active:focus, .btn-teal-brd-slide.focus, .btn-teal-brd-slide:active.focus, .btn-teal-brd-slide.active.focus {
  outline: none;
}

.btn-teal-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-teal-brd-slide:hover, .btn-teal-brd-slide:focus, .btn-teal-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #009688;
  text-decoration: none;
}

.btn-teal-brd-slide:after, .btn-teal-brd-slide:before {
  background: #009688;
}

.btn-teal-brd-slide:active, .btn-teal-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-teal-brd-slide.disabled, .btn-teal-brd-slide[disabled],
fieldset[disabled] .btn-teal-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-teal-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 6px 9px;
}

.btn-teal-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 9px 11px;
}

.btn-teal-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 12px 13px;
}

.btn-teal-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 15px 15px;
}

/* Button Green Color Slide */
.btn-green-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #BAF402;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-green-brd-slide:focus, .btn-green-brd-slide:active:focus, .btn-green-brd-slide.active:focus, .btn-green-brd-slide.focus, .btn-green-brd-slide:active.focus, .btn-green-brd-slide.active.focus {
  outline: none;
}

.btn-green-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-green-brd-slide:hover, .btn-green-brd-slide:focus, .btn-green-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-green-brd-slide:after, .btn-green-brd-slide:before {
  background: #BAF402;
}

.btn-green-brd-slide:active, .btn-green-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-green-brd-slide.disabled, .btn-green-brd-slide[disabled],
fieldset[disabled] .btn-green-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-green-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 6px 9px;
}

.btn-green-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 9px 11px;
}

.btn-green-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 12px 13px;
}

.btn-green-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 15px 15px;
}

/* Button Red Color Slide */
.btn-red-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #e55973;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #e55973;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-red-brd-slide:focus, .btn-red-brd-slide:active:focus, .btn-red-brd-slide.active:focus, .btn-red-brd-slide.focus, .btn-red-brd-slide:active.focus, .btn-red-brd-slide.active.focus {
  outline: none;
}

.btn-red-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-red-brd-slide:hover, .btn-red-brd-slide:focus, .btn-red-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #e55973;
  text-decoration: none;
}

.btn-red-brd-slide:after, .btn-red-brd-slide:before {
  background: #e55973;
}

.btn-red-brd-slide:active, .btn-red-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-red-brd-slide.disabled, .btn-red-brd-slide[disabled],
fieldset[disabled] .btn-red-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-red-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 6px 9px;
}

.btn-red-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 9px 11px;
}

.btn-red-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 12px 13px;
}

.btn-red-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 15px 15px;
}

/* Button Pink Color Slide */
.btn-pink-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #e91e63;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #e91e63;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-pink-brd-slide:focus, .btn-pink-brd-slide:active:focus, .btn-pink-brd-slide.active:focus, .btn-pink-brd-slide.focus, .btn-pink-brd-slide:active.focus, .btn-pink-brd-slide.active.focus {
  outline: none;
}

.btn-pink-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-pink-brd-slide:hover, .btn-pink-brd-slide:focus, .btn-pink-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #e91e63;
  text-decoration: none;
}

.btn-pink-brd-slide:after, .btn-pink-brd-slide:before {
  background: #e91e63;
}

.btn-pink-brd-slide:active, .btn-pink-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-pink-brd-slide.disabled, .btn-pink-brd-slide[disabled],
fieldset[disabled] .btn-pink-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-pink-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 6px 9px;
}

.btn-pink-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 9px 11px;
}

.btn-pink-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 12px 13px;
}

.btn-pink-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 15px 15px;
}

/* Button Purple Color Slide */
.btn-purple-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #b260ce;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #b260ce;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-purple-brd-slide:focus, .btn-purple-brd-slide:active:focus, .btn-purple-brd-slide.active:focus, .btn-purple-brd-slide.focus, .btn-purple-brd-slide:active.focus, .btn-purple-brd-slide.active.focus {
  outline: none;
}

.btn-purple-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-purple-brd-slide:hover, .btn-purple-brd-slide:focus, .btn-purple-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #b260ce;
  text-decoration: none;
}

.btn-purple-brd-slide:after, .btn-purple-brd-slide:before {
  background: #b260ce;
}

.btn-purple-brd-slide:active, .btn-purple-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-purple-brd-slide.disabled, .btn-purple-brd-slide[disabled],
fieldset[disabled] .btn-purple-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-purple-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 6px 9px;
}

.btn-purple-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 9px 11px;
}

.btn-purple-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 12px 13px;
}

.btn-purple-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 15px 15px;
}

/* Button Gold Color Slide */
.btn-gold-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #c69f73;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #c69f73;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-gold-brd-slide:focus, .btn-gold-brd-slide:active:focus, .btn-gold-brd-slide.active:focus, .btn-gold-brd-slide.focus, .btn-gold-brd-slide:active.focus, .btn-gold-brd-slide.active.focus {
  outline: none;
}

.btn-gold-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-gold-brd-slide:hover, .btn-gold-brd-slide:focus, .btn-gold-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #c69f73;
  text-decoration: none;
}

.btn-gold-brd-slide:after, .btn-gold-brd-slide:before {
  background: #c69f73;
}

.btn-gold-brd-slide:active, .btn-gold-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-gold-brd-slide.disabled, .btn-gold-brd-slide[disabled],
fieldset[disabled] .btn-gold-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-gold-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 6px 9px;
}

.btn-gold-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 9px 11px;
}

.btn-gold-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 12px 13px;
}

.btn-gold-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 15px 15px;
}

/* Button Facebook Color Slide */
.btn-fb-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #44619d;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #44619d;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-fb-brd-slide:focus, .btn-fb-brd-slide:active:focus, .btn-fb-brd-slide.active:focus, .btn-fb-brd-slide.focus, .btn-fb-brd-slide:active.focus, .btn-fb-brd-slide.active.focus {
  outline: none;
}

.btn-fb-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-fb-brd-slide:hover, .btn-fb-brd-slide:focus, .btn-fb-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #44619d;
  text-decoration: none;
}

.btn-fb-brd-slide:after, .btn-fb-brd-slide:before {
  background: #44619d;
}

.btn-fb-brd-slide:active, .btn-fb-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-fb-brd-slide.disabled, .btn-fb-brd-slide[disabled],
fieldset[disabled] .btn-fb-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-fb-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 6px 9px;
}

.btn-fb-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 9px 11px;
}

.btn-fb-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 12px 13px;
}

.btn-fb-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 15px 15px;
}

/* Button Twitter Color Slide */
.btn-tw-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #55acee;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #55acee;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-tw-brd-slide:focus, .btn-tw-brd-slide:active:focus, .btn-tw-brd-slide.active:focus, .btn-tw-brd-slide.focus, .btn-tw-brd-slide:active.focus, .btn-tw-brd-slide.active.focus {
  outline: none;
}

.btn-tw-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-tw-brd-slide:hover, .btn-tw-brd-slide:focus, .btn-tw-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #55acee;
  text-decoration: none;
}

.btn-tw-brd-slide:after, .btn-tw-brd-slide:before {
  background: #55acee;
}

.btn-tw-brd-slide:active, .btn-tw-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-tw-brd-slide.disabled, .btn-tw-brd-slide[disabled],
fieldset[disabled] .btn-tw-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-tw-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 6px 9px;
}

.btn-tw-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 9px 11px;
}

.btn-tw-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 12px 13px;
}

.btn-tw-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 15px 15px;
}

/* Button Dribbble Color Slide */
.btn-dr-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #ea4c89;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #ea4c89;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dr-brd-slide:focus, .btn-dr-brd-slide:active:focus, .btn-dr-brd-slide.active:focus, .btn-dr-brd-slide.focus, .btn-dr-brd-slide:active.focus, .btn-dr-brd-slide.active.focus {
  outline: none;
}

.btn-dr-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dr-brd-slide:hover, .btn-dr-brd-slide:focus, .btn-dr-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #ea4c89;
  text-decoration: none;
}

.btn-dr-brd-slide:after, .btn-dr-brd-slide:before {
  background: #ea4c89;
}

.btn-dr-brd-slide:active, .btn-dr-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-dr-brd-slide.disabled, .btn-dr-brd-slide[disabled],
fieldset[disabled] .btn-dr-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dr-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 6px 9px;
}

.btn-dr-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 9px 11px;
}

.btn-dr-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 12px 13px;
}

.btn-dr-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 15px 15px;
}

/* Button Instagram Color Slide */
.btn-ig-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #125688;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #125688;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-ig-brd-slide:focus, .btn-ig-brd-slide:active:focus, .btn-ig-brd-slide.active:focus, .btn-ig-brd-slide.focus, .btn-ig-brd-slide:active.focus, .btn-ig-brd-slide.active.focus {
  outline: none;
}

.btn-ig-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-ig-brd-slide:hover, .btn-ig-brd-slide:focus, .btn-ig-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #125688;
  text-decoration: none;
}

.btn-ig-brd-slide:after, .btn-ig-brd-slide:before {
  background: #125688;
}

.btn-ig-brd-slide:active, .btn-ig-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-ig-brd-slide.disabled, .btn-ig-brd-slide[disabled],
fieldset[disabled] .btn-ig-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-ig-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 6px 9px;
}

.btn-ig-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 9px 11px;
}

.btn-ig-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 12px 13px;
}

.btn-ig-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 15px 15px;
}

/*--------------------------------------------------
    [Buttons Background Slide]
----------------------------------------------------*/
/* Button Base Color Slide */
.btn-base-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #BAF402;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: tranparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-bg-slide:focus, .btn-base-bg-slide:active:focus, .btn-base-bg-slide.active:focus, .btn-base-bg-slide.focus, .btn-base-bg-slide:active.focus, .btn-base-bg-slide.active.focus {
  outline: none;
}

.btn-base-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-bg-slide:hover, .btn-base-bg-slide:focus, .btn-base-bg-slide.focus {
  color: #fff;
  background: #BAF402;
  border-color: tranparent;
  text-decoration: none;
}

.btn-base-bg-slide:after, .btn-base-bg-slide:before {
  background: #4ed7e8;
}

.btn-base-bg-slide:active, .btn-base-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-base-bg-slide.disabled, .btn-base-bg-slide[disabled],
fieldset[disabled] .btn-base-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 6px 9px;
}

.btn-base-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 9px 11px;
}

.btn-base-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 12px 13px;
}

.btn-base-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 15px 15px;
}

/* Button White Color Slide */
.btn-white-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: #fff;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-bg-slide:focus, .btn-white-bg-slide:active:focus, .btn-white-bg-slide.active:focus, .btn-white-bg-slide.focus, .btn-white-bg-slide:active.focus, .btn-white-bg-slide.active.focus {
  outline: none;
}

.btn-white-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-bg-slide:hover, .btn-white-bg-slide:focus, .btn-white-bg-slide.focus {
  color: #fff;
  background: #fff;
  border-color: transparent;
  text-decoration: none;
}

.btn-white-bg-slide:after, .btn-white-bg-slide:before {
  background: #BAF402;
}

.btn-white-bg-slide:active, .btn-white-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-white-bg-slide.disabled, .btn-white-bg-slide[disabled],
fieldset[disabled] .btn-white-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Dark Color Slide */
.btn-dark-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #34343c;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dark-bg-slide:focus, .btn-dark-bg-slide:active:focus, .btn-dark-bg-slide.active:focus, .btn-dark-bg-slide.focus, .btn-dark-bg-slide:active.focus, .btn-dark-bg-slide.active.focus {
  outline: none;
}

.btn-dark-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dark-bg-slide:hover, .btn-dark-bg-slide:focus, .btn-dark-bg-slide.focus {
  color: #fff;
  background: #34343c;
  border-color: transparent;
  text-decoration: none;
}

.btn-dark-bg-slide:after, .btn-dark-bg-slide:before {
  background: #3a3a44;
}

.btn-dark-bg-slide:active, .btn-dark-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-dark-bg-slide.disabled, .btn-dark-bg-slide[disabled],
fieldset[disabled] .btn-dark-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dark-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 6px 9px;
}

.btn-dark-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 9px 11px;
}

.btn-dark-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 12px 13px;
}

.btn-dark-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #2b2b31;
  padding: 15px 15px;
}

/* Button Grey Color Slide */
.btn-grey-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #9e9e9e;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-grey-bg-slide:focus, .btn-grey-bg-slide:active:focus, .btn-grey-bg-slide.active:focus, .btn-grey-bg-slide.focus, .btn-grey-bg-slide:active.focus, .btn-grey-bg-slide.active.focus {
  outline: none;
}

.btn-grey-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-grey-bg-slide:hover, .btn-grey-bg-slide:focus, .btn-grey-bg-slide.focus {
  color: #fff;
  background: #9e9e9e;
  border-color: transparent;
  text-decoration: none;
}

.btn-grey-bg-slide:after, .btn-grey-bg-slide:before {
  background: #f1f1f1;
}

.btn-grey-bg-slide:active, .btn-grey-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-grey-bg-slide.disabled, .btn-grey-bg-slide[disabled],
fieldset[disabled] .btn-grey-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-grey-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 6px 9px;
}

.btn-grey-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 9px 11px;
}

.btn-grey-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 12px 13px;
}

.btn-grey-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #949494;
  padding: 15px 15px;
}

/* Button Blue Grey Color Slide */
.btn-blue-grey-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #546e7a;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-grey-bg-slide:focus, .btn-blue-grey-bg-slide:active:focus, .btn-blue-grey-bg-slide.active:focus, .btn-blue-grey-bg-slide.focus, .btn-blue-grey-bg-slide:active.focus, .btn-blue-grey-bg-slide.active.focus {
  outline: none;
}

.btn-blue-grey-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-grey-bg-slide:hover, .btn-blue-grey-bg-slide:focus, .btn-blue-grey-bg-slide.focus {
  color: #fff;
  background: #546e7a;
  border-color: transparent;
  text-decoration: none;
}

.btn-blue-grey-bg-slide:after, .btn-blue-grey-bg-slide:before {
  background: #6b8997;
}

.btn-blue-grey-bg-slide:active, .btn-blue-grey-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-blue-grey-bg-slide.disabled, .btn-blue-grey-bg-slide[disabled],
fieldset[disabled] .btn-blue-grey-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-grey-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 6px 9px;
}

.btn-blue-grey-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 9px 11px;
}

.btn-blue-grey-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 12px 13px;
}

.btn-blue-grey-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #4c636e;
  padding: 15px 15px;
}

/* Button Blue Color Slide */
.btn-blue-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #536dfe;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-bg-slide:focus, .btn-blue-bg-slide:active:focus, .btn-blue-bg-slide.active:focus, .btn-blue-bg-slide.focus, .btn-blue-bg-slide:active.focus, .btn-blue-bg-slide.active.focus {
  outline: none;
}

.btn-blue-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-bg-slide:hover, .btn-blue-bg-slide:focus, .btn-blue-bg-slide.focus {
  color: #fff;
  background: #536dfe;
  border-color: transparent;
  text-decoration: none;
}

.btn-blue-bg-slide:after, .btn-blue-bg-slide:before {
  background: #687ffa;
}

.btn-blue-bg-slide:active, .btn-blue-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-blue-bg-slide.disabled, .btn-blue-bg-slide[disabled],
fieldset[disabled] .btn-blue-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 6px 9px;
}

.btn-blue-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 9px 11px;
}

.btn-blue-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 12px 13px;
}

.btn-blue-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3f5cfe;
  padding: 15px 15px;
}

/* Button Teal Color Slide */
.btn-teal-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #009688;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-teal-bg-slide:focus, .btn-teal-bg-slide:active:focus, .btn-teal-bg-slide.active:focus, .btn-teal-bg-slide.focus, .btn-teal-bg-slide:active.focus, .btn-teal-bg-slide.active.focus {
  outline: none;
}

.btn-teal-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-teal-bg-slide:hover, .btn-teal-bg-slide:focus, .btn-teal-bg-slide.focus {
  color: #fff;
  background: #009688;
  border-color: transparent;
  text-decoration: none;
}

.btn-teal-bg-slide:after, .btn-teal-bg-slide:before {
  background: #45b4a9;
}

.btn-teal-bg-slide:active, .btn-teal-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-teal-bg-slide.disabled, .btn-teal-bg-slide[disabled],
fieldset[disabled] .btn-teal-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-teal-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 6px 9px;
}

.btn-teal-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 9px 11px;
}

.btn-teal-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 12px 13px;
}

.btn-teal-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #008276;
  padding: 15px 15px;
}

/* Button Green Color Slide */
.btn-green-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #BAF402;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-green-bg-slide:focus, .btn-green-bg-slide:active:focus, .btn-green-bg-slide.active:focus, .btn-green-bg-slide.focus, .btn-green-bg-slide:active.focus, .btn-green-bg-slide.active.focus {
  outline: none;
}

.btn-green-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-green-bg-slide:hover, .btn-green-bg-slide:focus, .btn-green-bg-slide.focus {
  color: #fff;
  background: #BAF402;
  border-color: transparent;
  text-decoration: none;
}

.btn-green-bg-slide:after, .btn-green-bg-slide:before {
  background: #baf451;
}

.btn-green-bg-slide:active, .btn-green-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-green-bg-slide.disabled, .btn-green-bg-slide[disabled],
fieldset[disabled] .btn-green-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-green-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 6px 9px;
}

.btn-green-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 9px 11px;
}

.btn-green-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 12px 13px;
}

.btn-green-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 15px 15px;
}

/* Button Red Color Slide */
.btn-red-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #e55973;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-red-bg-slide:focus, .btn-red-bg-slide:active:focus, .btn-red-bg-slide.active:focus, .btn-red-bg-slide.focus, .btn-red-bg-slide:active.focus, .btn-red-bg-slide.active.focus {
  outline: none;
}

.btn-red-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-red-bg-slide:hover, .btn-red-bg-slide:focus, .btn-red-bg-slide.focus {
  color: #fff;
  background: #e55973;
  border-color: transparent;
  text-decoration: none;
}

.btn-red-bg-slide:after, .btn-red-bg-slide:before {
  background: #e57287;
}

.btn-red-bg-slide:active, .btn-red-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-red-bg-slide.disabled, .btn-red-bg-slide[disabled],
fieldset[disabled] .btn-red-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-red-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 6px 9px;
}

.btn-red-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 9px 11px;
}

.btn-red-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 12px 13px;
}

.btn-red-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e24764;
  padding: 15px 15px;
}

/* Button Pink Color Slide */
.btn-pink-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #e91e63;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-pink-bg-slide:focus, .btn-pink-bg-slide:active:focus, .btn-pink-bg-slide.active:focus, .btn-pink-bg-slide.focus, .btn-pink-bg-slide:active.focus, .btn-pink-bg-slide.active.focus {
  outline: none;
}

.btn-pink-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-pink-bg-slide:hover, .btn-pink-bg-slide:focus, .btn-pink-bg-slide.focus {
  color: #fff;
  background: #e91e63;
  border-color: transparent;
  text-decoration: none;
}

.btn-pink-bg-slide:after, .btn-pink-bg-slide:before {
  background: #ec407a;
}

.btn-pink-bg-slide:active, .btn-pink-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-pink-bg-slide.disabled, .btn-pink-bg-slide[disabled],
fieldset[disabled] .btn-pink-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-pink-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 6px 9px;
}

.btn-pink-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 9px 11px;
}

.btn-pink-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 12px 13px;
}

.btn-pink-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #dd1659;
  padding: 15px 15px;
}

/* Button Purple Color Slide */
.btn-purple-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #b260ce;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-purple-bg-slide:focus, .btn-purple-bg-slide:active:focus, .btn-purple-bg-slide.active:focus, .btn-purple-bg-slide.focus, .btn-purple-bg-slide:active.focus, .btn-purple-bg-slide.active.focus {
  outline: none;
}

.btn-purple-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-purple-bg-slide:hover, .btn-purple-bg-slide:focus, .btn-purple-bg-slide.focus {
  color: #fff;
  background: #b260ce;
  border-color: transparent;
  text-decoration: none;
}

.btn-purple-bg-slide:after, .btn-purple-bg-slide:before {
  background: #ba75d1;
}

.btn-purple-bg-slide:active, .btn-purple-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-purple-bg-slide.disabled, .btn-purple-bg-slide[disabled],
fieldset[disabled] .btn-purple-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-purple-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 6px 9px;
}

.btn-purple-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 9px 11px;
}

.btn-purple-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 12px 13px;
}

.btn-purple-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #aa50c9;
  padding: 15px 15px;
}

/* Button Gold Color Slide */
.btn-gold-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #c69f73;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-gold-bg-slide:focus, .btn-gold-bg-slide:active:focus, .btn-gold-bg-slide.active:focus, .btn-gold-bg-slide.focus, .btn-gold-bg-slide:active.focus, .btn-gold-bg-slide.active.focus {
  outline: none;
}

.btn-gold-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-gold-bg-slide:hover, .btn-gold-bg-slide:focus, .btn-gold-bg-slide.focus {
  color: #fff;
  background: #c69f73;
  border-color: transparent;
  text-decoration: none;
}

.btn-gold-bg-slide:after, .btn-gold-bg-slide:before {
  background: #d3af87;
}

.btn-gold-bg-slide:active, .btn-gold-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-gold-bg-slide.disabled, .btn-gold-bg-slide[disabled],
fieldset[disabled] .btn-gold-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-gold-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 6px 9px;
}

.btn-gold-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 9px 11px;
}

.btn-gold-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 12px 13px;
}

.btn-gold-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #c09565;
  padding: 15px 15px;
}

/* Button Facebook Color Slide */
.btn-fb-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #44619d;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-fb-bg-slide:focus, .btn-fb-bg-slide:active:focus, .btn-fb-bg-slide.active:focus, .btn-fb-bg-slide.focus, .btn-fb-bg-slide:active.focus, .btn-fb-bg-slide.active.focus {
  outline: none;
}

.btn-fb-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-fb-bg-slide:hover, .btn-fb-bg-slide:focus, .btn-fb-bg-slide.focus {
  color: #fff;
  background: #44619d;
  border-color: transparent;
  text-decoration: none;
}

.btn-fb-bg-slide:after, .btn-fb-bg-slide:before {
  background: #44619d;
}

.btn-fb-bg-slide:active, .btn-fb-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-fb-bg-slide.disabled, .btn-fb-bg-slide[disabled],
fieldset[disabled] .btn-fb-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-fb-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 6px 9px;
}

.btn-fb-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 9px 11px;
}

.btn-fb-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 12px 13px;
}

.btn-fb-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #3e588f;
  padding: 15px 15px;
}

/* Button Twitter Color Slide */
.btn-tw-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #55acee;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-tw-bg-slide:focus, .btn-tw-bg-slide:active:focus, .btn-tw-bg-slide.active:focus, .btn-tw-bg-slide.focus, .btn-tw-bg-slide:active.focus, .btn-tw-bg-slide.active.focus {
  outline: none;
}

.btn-tw-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-tw-bg-slide:hover, .btn-tw-bg-slide:focus, .btn-tw-bg-slide.focus {
  color: #fff;
  background: #55acee;
  border-color: transparent;
  text-decoration: none;
}

.btn-tw-bg-slide:after, .btn-tw-bg-slide:before {
  background: #55acee;
}

.btn-tw-bg-slide:active, .btn-tw-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-tw-bg-slide.disabled, .btn-tw-bg-slide[disabled],
fieldset[disabled] .btn-tw-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-tw-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 6px 9px;
}

.btn-tw-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 9px 11px;
}

.btn-tw-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 12px 13px;
}

.btn-tw-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #42a3ec;
  padding: 15px 15px;
}

/* Button Dribbble Color Slide */
.btn-dr-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #ea4c89;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dr-bg-slide:focus, .btn-dr-bg-slide:active:focus, .btn-dr-bg-slide.active:focus, .btn-dr-bg-slide.focus, .btn-dr-bg-slide:active.focus, .btn-dr-bg-slide.active.focus {
  outline: none;
}

.btn-dr-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dr-bg-slide:hover, .btn-dr-bg-slide:focus, .btn-dr-bg-slide.focus {
  color: #fff;
  background: #ea4c89;
  border-color: transparent;
  text-decoration: none;
}

.btn-dr-bg-slide:after, .btn-dr-bg-slide:before {
  background: #ea4c89;
}

.btn-dr-bg-slide:active, .btn-dr-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-dr-bg-slide.disabled, .btn-dr-bg-slide[disabled],
fieldset[disabled] .btn-dr-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dr-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 6px 9px;
}

.btn-dr-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 9px 11px;
}

.btn-dr-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 12px 13px;
}

.btn-dr-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #e83a7d;
  padding: 15px 15px;
}

/* Button Instagram Color Slide */
.btn-ig-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #125688;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-ig-bg-slide:focus, .btn-ig-bg-slide:active:focus, .btn-ig-bg-slide.active:focus, .btn-ig-bg-slide.focus, .btn-ig-bg-slide:active.focus, .btn-ig-bg-slide.active.focus {
  outline: none;
}

.btn-ig-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-ig-bg-slide:hover, .btn-ig-bg-slide:focus, .btn-ig-bg-slide.focus {
  color: #fff;
  background: #125688;
  border-color: transparent;
  text-decoration: none;
}

.btn-ig-bg-slide:after, .btn-ig-bg-slide:before {
  background: #125688;
}

.btn-ig-bg-slide:active, .btn-ig-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-ig-bg-slide.disabled, .btn-ig-bg-slide[disabled],
fieldset[disabled] .btn-ig-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-ig-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 6px 9px;
}

.btn-ig-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 9px 11px;
}

.btn-ig-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 12px 13px;
}

.btn-ig-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #104b76;
  padding: 15px 15px;
}

/*--------------------------------------------------
    [Buttons Background Color Hover Animate]
----------------------------------------------------*/
/* Button Background Base Color */
.btn-base-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #BAF402;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-bg-anim:focus, .btn-base-bg-anim:active:focus, .btn-base-bg-anim.active:focus, .btn-base-bg-anim.focus, .btn-base-bg-anim:active.focus, .btn-base-bg-anim.active.focus {
  outline: none;
}

.btn-base-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-bg-anim:hover, .btn-base-bg-anim:focus, .btn-base-bg-anim.focus {
  color: #fff;
  background: #BAF402;
  border-color: transparent;
  text-decoration: none;
}

.btn-base-bg-anim:after, .btn-base-bg-anim:before {
  background: #a7db02;
}

.btn-base-bg-anim:active, .btn-base-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-base-bg-anim.disabled, .btn-base-bg-anim[disabled],
fieldset[disabled] .btn-base-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-base-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-base-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-base-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background White Color */
.btn-white-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: #fff;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-bg-anim:focus, .btn-white-bg-anim:active:focus, .btn-white-bg-anim.active:focus, .btn-white-bg-anim.focus, .btn-white-bg-anim:active.focus, .btn-white-bg-anim.active.focus {
  outline: none;
}

.btn-white-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-bg-anim:hover, .btn-white-bg-anim:focus, .btn-white-bg-anim.focus {
  color: #fff;
  background: #fff;
  border-color: transparent;
  text-decoration: none;
}

.btn-white-bg-anim:after, .btn-white-bg-anim:before {
  background: #BAF402;
}

.btn-white-bg-anim:active, .btn-white-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-white-bg-anim.disabled, .btn-white-bg-anim[disabled],
fieldset[disabled] .btn-white-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Background Dark Color */
.btn-dark-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #34343c;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dark-bg-anim:focus, .btn-dark-bg-anim:active:focus, .btn-dark-bg-anim.active:focus, .btn-dark-bg-anim.focus, .btn-dark-bg-anim:active.focus, .btn-dark-bg-anim.active.focus {
  outline: none;
}

.btn-dark-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dark-bg-anim:hover, .btn-dark-bg-anim:focus, .btn-dark-bg-anim.focus {
  color: #fff;
  background: #34343c;
  border-color: transparent;
  text-decoration: none;
}

.btn-dark-bg-anim:after, .btn-dark-bg-anim:before {
  background: #28282e;
}

.btn-dark-bg-anim:active, .btn-dark-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-dark-bg-anim.disabled, .btn-dark-bg-anim[disabled],
fieldset[disabled] .btn-dark-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dark-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-dark-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-dark-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-dark-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Blue Grey Color */
.btn-blue-grey-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #546e7a;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-grey-bg-anim:focus, .btn-blue-grey-bg-anim:active:focus, .btn-blue-grey-bg-anim.active:focus, .btn-blue-grey-bg-anim.focus, .btn-blue-grey-bg-anim:active.focus, .btn-blue-grey-bg-anim.active.focus {
  outline: none;
}

.btn-blue-grey-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-grey-bg-anim:hover, .btn-blue-grey-bg-anim:focus, .btn-blue-grey-bg-anim.focus {
  color: #fff;
  background: #6b8997;
  border-color: transparent;
  text-decoration: none;
}

.btn-blue-grey-bg-anim:after, .btn-blue-grey-bg-anim:before {
  background: #4a606b;
}

.btn-blue-grey-bg-anim:active, .btn-blue-grey-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-blue-grey-bg-anim.disabled, .btn-blue-grey-bg-anim[disabled],
fieldset[disabled] .btn-blue-grey-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-grey-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-blue-grey-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-blue-grey-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-blue-grey-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Grey Color */
.btn-grey-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #9e9e9e;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-grey-bg-anim:focus, .btn-grey-bg-anim:active:focus, .btn-grey-bg-anim.active:focus, .btn-grey-bg-anim.focus, .btn-grey-bg-anim:active.focus, .btn-grey-bg-anim.active.focus {
  outline: none;
}

.btn-grey-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-grey-bg-anim:hover, .btn-grey-bg-anim:focus, .btn-grey-bg-anim.focus {
  color: #fff;
  background: #b0b0b0;
  border-color: transparent;
  text-decoration: none;
}

.btn-grey-bg-anim:after, .btn-grey-bg-anim:before {
  background: #919191;
}

.btn-grey-bg-anim:active, .btn-grey-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-grey-bg-anim.disabled, .btn-grey-bg-anim[disabled],
fieldset[disabled] .btn-grey-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-grey-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-grey-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-grey-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-grey-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Blue Color */
.btn-blue-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #536dfe;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-bg-anim:focus, .btn-blue-bg-anim:active:focus, .btn-blue-bg-anim.active:focus, .btn-blue-bg-anim.focus, .btn-blue-bg-anim:active.focus, .btn-blue-bg-anim.active.focus {
  outline: none;
}

.btn-blue-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-bg-anim:hover, .btn-blue-bg-anim:focus, .btn-blue-bg-anim.focus {
  color: #fff;
  background: #687ffa;
  border-color: transparent;
  text-decoration: none;
}

.btn-blue-bg-anim:after, .btn-blue-bg-anim:before {
  background: #3a57fe;
}

.btn-blue-bg-anim:active, .btn-blue-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-blue-bg-anim.disabled, .btn-blue-bg-anim[disabled],
fieldset[disabled] .btn-blue-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-blue-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-blue-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-blue-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Teal Color */
.btn-teal-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #009688;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-teal-bg-anim:focus, .btn-teal-bg-anim:active:focus, .btn-teal-bg-anim.active:focus, .btn-teal-bg-anim.focus, .btn-teal-bg-anim:active.focus, .btn-teal-bg-anim.active.focus {
  outline: none;
}

.btn-teal-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-teal-bg-anim:hover, .btn-teal-bg-anim:focus, .btn-teal-bg-anim.focus {
  color: #fff;
  background: #45b4a9;
  border-color: transparent;
  text-decoration: none;
}

.btn-teal-bg-anim:after, .btn-teal-bg-anim:before {
  background: #007d71;
}

.btn-teal-bg-anim:active, .btn-teal-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-teal-bg-anim.disabled, .btn-teal-bg-anim[disabled],
fieldset[disabled] .btn-teal-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-teal-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-teal-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-teal-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-teal-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Green Color */
.btn-green-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #BAF402;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-green-bg-anim:focus, .btn-green-bg-anim:active:focus, .btn-green-bg-anim.active:focus, .btn-green-bg-anim.focus, .btn-green-bg-anim:active.focus, .btn-green-bg-anim.active.focus {
  outline: none;
}

.btn-green-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-green-bg-anim:hover, .btn-green-bg-anim:focus, .btn-green-bg-anim.focus {
  color: #fff;
  background: #baf451;
  border-color: transparent;
  text-decoration: none;
}

.btn-green-bg-anim:after, .btn-green-bg-anim:before {
  background: #a7db02;
}

.btn-green-bg-anim:active, .btn-green-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-green-bg-anim.disabled, .btn-green-bg-anim[disabled],
fieldset[disabled] .btn-green-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-green-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-green-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-green-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-green-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Red Color */
.btn-red-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #e55973;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-red-bg-anim:focus, .btn-red-bg-anim:active:focus, .btn-red-bg-anim.active:focus, .btn-red-bg-anim.focus, .btn-red-bg-anim:active.focus, .btn-red-bg-anim.active.focus {
  outline: none;
}

.btn-red-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-red-bg-anim:hover, .btn-red-bg-anim:focus, .btn-red-bg-anim.focus {
  color: #fff;
  background: #e57287;
  border-color: transparent;
  text-decoration: none;
}

.btn-red-bg-anim:after, .btn-red-bg-anim:before {
  background: #e24360;
}

.btn-red-bg-anim:active, .btn-red-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-red-bg-anim.disabled, .btn-red-bg-anim[disabled],
fieldset[disabled] .btn-red-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-red-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-red-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-red-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-red-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Pink Color */
.btn-pink-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #e91e63;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-pink-bg-anim:focus, .btn-pink-bg-anim:active:focus, .btn-pink-bg-anim.active:focus, .btn-pink-bg-anim.focus, .btn-pink-bg-anim:active.focus, .btn-pink-bg-anim.active.focus {
  outline: none;
}

.btn-pink-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-pink-bg-anim:hover, .btn-pink-bg-anim:focus, .btn-pink-bg-anim.focus {
  color: #fff;
  background: #ec407a;
  border-color: transparent;
  text-decoration: none;
}

.btn-pink-bg-anim:after, .btn-pink-bg-anim:before {
  background: #d81558;
}

.btn-pink-bg-anim:active, .btn-pink-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-pink-bg-anim.disabled, .btn-pink-bg-anim[disabled],
fieldset[disabled] .btn-pink-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-pink-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-pink-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-pink-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-pink-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Purple Color */
.btn-purple-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #b260ce;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-purple-bg-anim:focus, .btn-purple-bg-anim:active:focus, .btn-purple-bg-anim.active:focus, .btn-purple-bg-anim.focus, .btn-purple-bg-anim:active.focus, .btn-purple-bg-anim.active.focus {
  outline: none;
}

.btn-purple-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-purple-bg-anim:hover, .btn-purple-bg-anim:focus, .btn-purple-bg-anim.focus {
  color: #fff;
  background: #ba75d1;
  border-color: transparent;
  text-decoration: none;
}

.btn-purple-bg-anim:after, .btn-purple-bg-anim:before {
  background: #a94dc8;
}

.btn-purple-bg-anim:active, .btn-purple-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-purple-bg-anim.disabled, .btn-purple-bg-anim[disabled],
fieldset[disabled] .btn-purple-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-purple-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-purple-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-purple-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-purple-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Gold Color */
.btn-gold-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #c69f73;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-gold-bg-anim:focus, .btn-gold-bg-anim:active:focus, .btn-gold-bg-anim.active:focus, .btn-gold-bg-anim.focus, .btn-gold-bg-anim:active.focus, .btn-gold-bg-anim.active.focus {
  outline: none;
}

.btn-gold-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-gold-bg-anim:hover, .btn-gold-bg-anim:focus, .btn-gold-bg-anim.focus {
  color: #fff;
  background: #d3af87;
  border-color: transparent;
  text-decoration: none;
}

.btn-gold-bg-anim:after, .btn-gold-bg-anim:before {
  background: #bf9361;
}

.btn-gold-bg-anim:active, .btn-gold-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-gold-bg-anim.disabled, .btn-gold-bg-anim[disabled],
fieldset[disabled] .btn-gold-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-gold-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-gold-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-gold-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-gold-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Facebook */
.btn-fb-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #44619d;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-fb-bg-anim:focus, .btn-fb-bg-anim:active:focus, .btn-fb-bg-anim.active:focus, .btn-fb-bg-anim.focus, .btn-fb-bg-anim:active.focus, .btn-fb-bg-anim.active.focus {
  outline: none;
}

.btn-fb-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-fb-bg-anim:hover, .btn-fb-bg-anim:focus, .btn-fb-bg-anim.focus {
  color: #fff;
  background: #44619d;
  border-color: transparent;
  text-decoration: none;
}

.btn-fb-bg-anim:after, .btn-fb-bg-anim:before {
  background: #3c568b;
}

.btn-fb-bg-anim:active, .btn-fb-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-fb-bg-anim.disabled, .btn-fb-bg-anim[disabled],
fieldset[disabled] .btn-fb-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-fb-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-fb-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-fb-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-fb-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Twitter */
.btn-tw-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #55acee;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-tw-bg-anim:focus, .btn-tw-bg-anim:active:focus, .btn-tw-bg-anim.active:focus, .btn-tw-bg-anim.focus, .btn-tw-bg-anim:active.focus, .btn-tw-bg-anim.active.focus {
  outline: none;
}

.btn-tw-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-tw-bg-anim:hover, .btn-tw-bg-anim:focus, .btn-tw-bg-anim.focus {
  color: #fff;
  background: #55acee;
  border-color: transparent;
  text-decoration: none;
}

.btn-tw-bg-anim:after, .btn-tw-bg-anim:before {
  background: #3ea1ec;
}

.btn-tw-bg-anim:active, .btn-tw-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-tw-bg-anim.disabled, .btn-tw-bg-anim[disabled],
fieldset[disabled] .btn-tw-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-tw-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-tw-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-tw-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-tw-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Dribbble */
.btn-dr-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #ea4c89;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dr-bg-anim:focus, .btn-dr-bg-anim:active:focus, .btn-dr-bg-anim.active:focus, .btn-dr-bg-anim.focus, .btn-dr-bg-anim:active.focus, .btn-dr-bg-anim.active.focus {
  outline: none;
}

.btn-dr-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dr-bg-anim:hover, .btn-dr-bg-anim:focus, .btn-dr-bg-anim.focus {
  color: #fff;
  background: #ea4c89;
  border-color: transparent;
  text-decoration: none;
}

.btn-dr-bg-anim:after, .btn-dr-bg-anim:before {
  background: #e7357a;
}

.btn-dr-bg-anim:active, .btn-dr-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-dr-bg-anim.disabled, .btn-dr-bg-anim[disabled],
fieldset[disabled] .btn-dr-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dr-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-dr-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-dr-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-dr-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Instagram */
.btn-ig-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #125688;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-ig-bg-anim:focus, .btn-ig-bg-anim:active:focus, .btn-ig-bg-anim.active:focus, .btn-ig-bg-anim.focus, .btn-ig-bg-anim:active.focus, .btn-ig-bg-anim.active.focus {
  outline: none;
}

.btn-ig-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-ig-bg-anim:hover, .btn-ig-bg-anim:focus, .btn-ig-bg-anim.focus {
  color: #fff;
  background: #125688;
  border-color: transparent;
  text-decoration: none;
}

.btn-ig-bg-anim:after, .btn-ig-bg-anim:before {
  background: #0f4871;
}

.btn-ig-bg-anim:active, .btn-ig-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-ig-bg-anim.disabled, .btn-ig-bg-anim[disabled],
fieldset[disabled] .btn-ig-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-ig-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-ig-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-ig-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-ig-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/*--------------------------------------------------
    [Buttons Border Color Hover Animate]
----------------------------------------------------*/
/* Button Border Base Color */
.btn-base-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #BAF402;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-brd-anim:focus, .btn-base-brd-anim:active:focus, .btn-base-brd-anim.active:focus, .btn-base-brd-anim.focus, .btn-base-brd-anim:active.focus, .btn-base-brd-anim.active.focus {
  outline: none;
}

.btn-base-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-brd-anim:hover, .btn-base-brd-anim:focus, .btn-base-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-base-brd-anim:after, .btn-base-brd-anim:before {
  background: #BAF402;
}

.btn-base-brd-anim:active, .btn-base-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-base-brd-anim.disabled, .btn-base-brd-anim[disabled],
fieldset[disabled] .btn-base-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-base-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-base-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-base-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border White Color */
.btn-white-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-brd-anim:focus, .btn-white-brd-anim:active:focus, .btn-white-brd-anim.active:focus, .btn-white-brd-anim.focus, .btn-white-brd-anim:active.focus, .btn-white-brd-anim.active.focus {
  outline: none;
}

.btn-white-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-brd-anim:hover, .btn-white-brd-anim:focus, .btn-white-brd-anim.focus {
  color: #34343c;
  background: transparent;
  border-color: #fff;
  text-decoration: none;
}

.btn-white-brd-anim:after, .btn-white-brd-anim:before {
  background: #fff;
}

.btn-white-brd-anim:active, .btn-white-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-white-brd-anim.disabled, .btn-white-brd-anim[disabled],
fieldset[disabled] .btn-white-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Border Dark Color */
.btn-dark-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #34343c;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dark-brd-anim:focus, .btn-dark-brd-anim:active:focus, .btn-dark-brd-anim.active:focus, .btn-dark-brd-anim.focus, .btn-dark-brd-anim:active.focus, .btn-dark-brd-anim.active.focus {
  outline: none;
}

.btn-dark-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dark-brd-anim:hover, .btn-dark-brd-anim:focus, .btn-dark-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #34343c;
  text-decoration: none;
}

.btn-dark-brd-anim:after, .btn-dark-brd-anim:before {
  background: #34343c;
}

.btn-dark-brd-anim:active, .btn-dark-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-dark-brd-anim.disabled, .btn-dark-brd-anim[disabled],
fieldset[disabled] .btn-dark-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dark-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-dark-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-dark-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-dark-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Blue Grey Color */
.btn-blue-grey-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #546e7a;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #546e7a;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-grey-brd-anim:focus, .btn-blue-grey-brd-anim:active:focus, .btn-blue-grey-brd-anim.active:focus, .btn-blue-grey-brd-anim.focus, .btn-blue-grey-brd-anim:active.focus, .btn-blue-grey-brd-anim.active.focus {
  outline: none;
}

.btn-blue-grey-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-grey-brd-anim:hover, .btn-blue-grey-brd-anim:focus, .btn-blue-grey-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #546e7a;
  text-decoration: none;
}

.btn-blue-grey-brd-anim:after, .btn-blue-grey-brd-anim:before {
  background: #546e7a;
}

.btn-blue-grey-brd-anim:active, .btn-blue-grey-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-blue-grey-brd-anim.disabled, .btn-blue-grey-brd-anim[disabled],
fieldset[disabled] .btn-blue-grey-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-grey-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-blue-grey-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-blue-grey-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-blue-grey-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Grey Color */
.btn-grey-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #9e9e9e;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #9e9e9e;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-grey-brd-anim:focus, .btn-grey-brd-anim:active:focus, .btn-grey-brd-anim.active:focus, .btn-grey-brd-anim.focus, .btn-grey-brd-anim:active.focus, .btn-grey-brd-anim.active.focus {
  outline: none;
}

.btn-grey-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-grey-brd-anim:hover, .btn-grey-brd-anim:focus, .btn-grey-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #9e9e9e;
  text-decoration: none;
}

.btn-grey-brd-anim:after, .btn-grey-brd-anim:before {
  background: #9e9e9e;
}

.btn-grey-brd-anim:active, .btn-grey-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-grey-brd-anim.disabled, .btn-grey-brd-anim[disabled],
fieldset[disabled] .btn-grey-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-grey-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-grey-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-grey-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-grey-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Blue Color */
.btn-blue-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #536dfe;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #536dfe;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-brd-anim:focus, .btn-blue-brd-anim:active:focus, .btn-blue-brd-anim.active:focus, .btn-blue-brd-anim.focus, .btn-blue-brd-anim:active.focus, .btn-blue-brd-anim.active.focus {
  outline: none;
}

.btn-blue-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-brd-anim:hover, .btn-blue-brd-anim:focus, .btn-blue-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #536dfe;
  text-decoration: none;
}

.btn-blue-brd-anim:after, .btn-blue-brd-anim:before {
  background: #536dfe;
}

.btn-blue-brd-anim:active, .btn-blue-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-blue-brd-anim.disabled, .btn-blue-brd-anim[disabled],
fieldset[disabled] .btn-blue-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-blue-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-blue-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-blue-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Teal Color */
.btn-teal-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #009688;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #009688;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-teal-brd-anim:focus, .btn-teal-brd-anim:active:focus, .btn-teal-brd-anim.active:focus, .btn-teal-brd-anim.focus, .btn-teal-brd-anim:active.focus, .btn-teal-brd-anim.active.focus {
  outline: none;
}

.btn-teal-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-teal-brd-anim:hover, .btn-teal-brd-anim:focus, .btn-teal-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #009688;
  text-decoration: none;
}

.btn-teal-brd-anim:after, .btn-teal-brd-anim:before {
  background: #009688;
}

.btn-teal-brd-anim:active, .btn-teal-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-teal-brd-anim.disabled, .btn-teal-brd-anim[disabled],
fieldset[disabled] .btn-teal-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-teal-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-teal-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-teal-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-teal-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Green Color */
.btn-green-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #BAF402;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-green-brd-anim:focus, .btn-green-brd-anim:active:focus, .btn-green-brd-anim.active:focus, .btn-green-brd-anim.focus, .btn-green-brd-anim:active.focus, .btn-green-brd-anim.active.focus {
  outline: none;
}

.btn-green-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-green-brd-anim:hover, .btn-green-brd-anim:focus, .btn-green-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-green-brd-anim:after, .btn-green-brd-anim:before {
  background: #BAF402;
}

.btn-green-brd-anim:active, .btn-green-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-green-brd-anim.disabled, .btn-green-brd-anim[disabled],
fieldset[disabled] .btn-green-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-green-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-green-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-green-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-green-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Red Color */
.btn-red-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #e55973;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #e55973;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-red-brd-anim:focus, .btn-red-brd-anim:active:focus, .btn-red-brd-anim.active:focus, .btn-red-brd-anim.focus, .btn-red-brd-anim:active.focus, .btn-red-brd-anim.active.focus {
  outline: none;
}

.btn-red-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-red-brd-anim:hover, .btn-red-brd-anim:focus, .btn-red-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #e55973;
  text-decoration: none;
}

.btn-red-brd-anim:after, .btn-red-brd-anim:before {
  background: #e55973;
}

.btn-red-brd-anim:active, .btn-red-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-red-brd-anim.disabled, .btn-red-brd-anim[disabled],
fieldset[disabled] .btn-red-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-red-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-red-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-red-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-red-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Pink Color */
.btn-pink-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #e91e63;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #e91e63;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-pink-brd-anim:focus, .btn-pink-brd-anim:active:focus, .btn-pink-brd-anim.active:focus, .btn-pink-brd-anim.focus, .btn-pink-brd-anim:active.focus, .btn-pink-brd-anim.active.focus {
  outline: none;
}

.btn-pink-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-pink-brd-anim:hover, .btn-pink-brd-anim:focus, .btn-pink-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #e91e63;
  text-decoration: none;
}

.btn-pink-brd-anim:after, .btn-pink-brd-anim:before {
  background: #e91e63;
}

.btn-pink-brd-anim:active, .btn-pink-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-pink-brd-anim.disabled, .btn-pink-brd-anim[disabled],
fieldset[disabled] .btn-pink-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-pink-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-pink-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-pink-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-pink-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Purple Color */
.btn-purple-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #b260ce;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #b260ce;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-purple-brd-anim:focus, .btn-purple-brd-anim:active:focus, .btn-purple-brd-anim.active:focus, .btn-purple-brd-anim.focus, .btn-purple-brd-anim:active.focus, .btn-purple-brd-anim.active.focus {
  outline: none;
}

.btn-purple-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-purple-brd-anim:hover, .btn-purple-brd-anim:focus, .btn-purple-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #b260ce;
  text-decoration: none;
}

.btn-purple-brd-anim:after, .btn-purple-brd-anim:before {
  background: #b260ce;
}

.btn-purple-brd-anim:active, .btn-purple-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-purple-brd-anim.disabled, .btn-purple-brd-anim[disabled],
fieldset[disabled] .btn-purple-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-purple-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-purple-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-purple-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-purple-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Gold Color */
.btn-gold-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #c69f73;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #c69f73;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-gold-brd-anim:focus, .btn-gold-brd-anim:active:focus, .btn-gold-brd-anim.active:focus, .btn-gold-brd-anim.focus, .btn-gold-brd-anim:active.focus, .btn-gold-brd-anim.active.focus {
  outline: none;
}

.btn-gold-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-gold-brd-anim:hover, .btn-gold-brd-anim:focus, .btn-gold-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #c69f73;
  text-decoration: none;
}

.btn-gold-brd-anim:after, .btn-gold-brd-anim:before {
  background: #c69f73;
}

.btn-gold-brd-anim:active, .btn-gold-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-gold-brd-anim.disabled, .btn-gold-brd-anim[disabled],
fieldset[disabled] .btn-gold-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-gold-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-gold-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-gold-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-gold-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Facebook */
.btn-fb-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #44619d;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #44619d;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-fb-brd-anim:focus, .btn-fb-brd-anim:active:focus, .btn-fb-brd-anim.active:focus, .btn-fb-brd-anim.focus, .btn-fb-brd-anim:active.focus, .btn-fb-brd-anim.active.focus {
  outline: none;
}

.btn-fb-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-fb-brd-anim:hover, .btn-fb-brd-anim:focus, .btn-fb-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #44619d;
  text-decoration: none;
}

.btn-fb-brd-anim:after, .btn-fb-brd-anim:before {
  background: #44619d;
}

.btn-fb-brd-anim:active, .btn-fb-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-fb-brd-anim.disabled, .btn-fb-brd-anim[disabled],
fieldset[disabled] .btn-fb-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-fb-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-fb-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-fb-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-fb-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Twitter */
.btn-tw-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #55acee;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #55acee;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-tw-brd-anim:focus, .btn-tw-brd-anim:active:focus, .btn-tw-brd-anim.active:focus, .btn-tw-brd-anim.focus, .btn-tw-brd-anim:active.focus, .btn-tw-brd-anim.active.focus {
  outline: none;
}

.btn-tw-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-tw-brd-anim:hover, .btn-tw-brd-anim:focus, .btn-tw-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #55acee;
  text-decoration: none;
}

.btn-tw-brd-anim:after, .btn-tw-brd-anim:before {
  background: #55acee;
}

.btn-tw-brd-anim:active, .btn-tw-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-tw-brd-anim.disabled, .btn-tw-brd-anim[disabled],
fieldset[disabled] .btn-tw-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-tw-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-tw-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-tw-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-tw-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Dribbble */
.btn-dr-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #ea4c89;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #ea4c89;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dr-brd-anim:focus, .btn-dr-brd-anim:active:focus, .btn-dr-brd-anim.active:focus, .btn-dr-brd-anim.focus, .btn-dr-brd-anim:active.focus, .btn-dr-brd-anim.active.focus {
  outline: none;
}

.btn-dr-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dr-brd-anim:hover, .btn-dr-brd-anim:focus, .btn-dr-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #ea4c89;
  text-decoration: none;
}

.btn-dr-brd-anim:after, .btn-dr-brd-anim:before {
  background: #ea4c89;
}

.btn-dr-brd-anim:active, .btn-dr-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-dr-brd-anim.disabled, .btn-dr-brd-anim[disabled],
fieldset[disabled] .btn-dr-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dr-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-dr-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-dr-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-dr-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Border Instagram */
.btn-ig-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #125688;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #125688;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-ig-brd-anim:focus, .btn-ig-brd-anim:active:focus, .btn-ig-brd-anim.active:focus, .btn-ig-brd-anim.focus, .btn-ig-brd-anim:active.focus, .btn-ig-brd-anim.active.focus {
  outline: none;
}

.btn-ig-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-ig-brd-anim:hover, .btn-ig-brd-anim:focus, .btn-ig-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #125688;
  text-decoration: none;
}

.btn-ig-brd-anim:after, .btn-ig-brd-anim:before {
  background: #125688;
}

.btn-ig-brd-anim:active, .btn-ig-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-ig-brd-anim.disabled, .btn-ig-brd-anim[disabled],
fieldset[disabled] .btn-ig-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-ig-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-ig-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-ig-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-ig-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/*--------------------------------------------------
    [Buttons Background Color]
----------------------------------------------------*/
/* Button Background Base Color */
.btn-base-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #BAF402;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-bg:focus, .btn-base-bg:active:focus, .btn-base-bg.active:focus, .btn-base-bg.focus, .btn-base-bg:active.focus, .btn-base-bg.active.focus {
  outline: none;
}

.btn-base-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-bg:hover, .btn-base-bg:focus, .btn-base-bg.focus {
  color: #fff;
  background: #4ed7e8;
  border-color: transparent;
  text-decoration: none;
}

.btn-base-bg:after, .btn-base-bg:before {
  background: transparent;
}

.btn-base-bg:active, .btn-base-bg.active {
  background-image: none;
  outline: 0;
}

.btn-base-bg.disabled, .btn-base-bg[disabled],
fieldset[disabled] .btn-base-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-base-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-base-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-base-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background White Color */
.btn-white-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: #fff;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: #fff;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-bg:focus, .btn-white-bg:active:focus, .btn-white-bg.active:focus, .btn-white-bg.focus, .btn-white-bg:active.focus, .btn-white-bg.active.focus {
  outline: none;
}

.btn-white-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-bg:hover, .btn-white-bg:focus, .btn-white-bg.focus {
  color: #fff;
  background: #BAF402;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-white-bg:after, .btn-white-bg:before {
  background: transparent;
}

.btn-white-bg:active, .btn-white-bg.active {
  background-image: none;
  outline: 0;
}

.btn-white-bg.disabled, .btn-white-bg[disabled],
fieldset[disabled] .btn-white-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Background Dark Color */
.btn-dark-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #34343c;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dark-bg:focus, .btn-dark-bg:active:focus, .btn-dark-bg.active:focus, .btn-dark-bg.focus, .btn-dark-bg:active.focus, .btn-dark-bg.active.focus {
  outline: none;
}

.btn-dark-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dark-bg:hover, .btn-dark-bg:focus, .btn-dark-bg.focus {
  color: #fff;
  background: #3a3a44;
  border-color: transparent;
  text-decoration: none;
}

.btn-dark-bg:after, .btn-dark-bg:before {
  background: transparent;
}

.btn-dark-bg:active, .btn-dark-bg.active {
  background-image: none;
  outline: 0;
}

.btn-dark-bg.disabled, .btn-dark-bg[disabled],
fieldset[disabled] .btn-dark-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dark-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-dark-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-dark-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-dark-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #2b2b31;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Blue Grey Color */
.btn-blue-grey-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #546e7a;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-grey-bg:focus, .btn-blue-grey-bg:active:focus, .btn-blue-grey-bg.active:focus, .btn-blue-grey-bg.focus, .btn-blue-grey-bg:active.focus, .btn-blue-grey-bg.active.focus {
  outline: none;
}

.btn-blue-grey-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-grey-bg:hover, .btn-blue-grey-bg:focus, .btn-blue-grey-bg.focus {
  color: #fff;
  background: #6b8997;
  border-color: transparent;
  text-decoration: none;
}

.btn-blue-grey-bg:after, .btn-blue-grey-bg:before {
  background: transparent;
}

.btn-blue-grey-bg:active, .btn-blue-grey-bg.active {
  background-image: none;
  outline: 0;
}

.btn-blue-grey-bg.disabled, .btn-blue-grey-bg[disabled],
fieldset[disabled] .btn-blue-grey-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-grey-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-blue-grey-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-blue-grey-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-blue-grey-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #4c636e;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Grey Color */
.btn-grey-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #9e9e9e;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-grey-bg:focus, .btn-grey-bg:active:focus, .btn-grey-bg.active:focus, .btn-grey-bg.focus, .btn-grey-bg:active.focus, .btn-grey-bg.active.focus {
  outline: none;
}

.btn-grey-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-grey-bg:hover, .btn-grey-bg:focus, .btn-grey-bg.focus {
  color: #fff;
  background: #b0b0b0;
  border-color: transparent;
  text-decoration: none;
}

.btn-grey-bg:after, .btn-grey-bg:before {
  background: transparent;
}

.btn-grey-bg:active, .btn-grey-bg.active {
  background-image: none;
  outline: 0;
}

.btn-grey-bg.disabled, .btn-grey-bg[disabled],
fieldset[disabled] .btn-grey-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-grey-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-grey-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-grey-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-grey-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #949494;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Blue Color */
.btn-blue-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #536dfe;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-blue-bg:focus, .btn-blue-bg:active:focus, .btn-blue-bg.active:focus, .btn-blue-bg.focus, .btn-blue-bg:active.focus, .btn-blue-bg.active.focus {
  outline: none;
}

.btn-blue-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-blue-bg:hover, .btn-blue-bg:focus, .btn-blue-bg.focus {
  color: #fff;
  background: #687ffa;
  border-color: transparent;
  text-decoration: none;
}

.btn-blue-bg:after, .btn-blue-bg:before {
  background: transparent;
}

.btn-blue-bg:active, .btn-blue-bg.active {
  background-image: none;
  outline: 0;
}

.btn-blue-bg.disabled, .btn-blue-bg[disabled],
fieldset[disabled] .btn-blue-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-blue-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-blue-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-blue-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-blue-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #3f5cfe;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Teal Color */
.btn-teal-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #009688;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-teal-bg:focus, .btn-teal-bg:active:focus, .btn-teal-bg.active:focus, .btn-teal-bg.focus, .btn-teal-bg:active.focus, .btn-teal-bg.active.focus {
  outline: none;
}

.btn-teal-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-teal-bg:hover, .btn-teal-bg:focus, .btn-teal-bg.focus {
  color: #fff;
  background: #45b4a9;
  border-color: transparent;
  text-decoration: none;
}

.btn-teal-bg:after, .btn-teal-bg:before {
  background: transparent;
}

.btn-teal-bg:active, .btn-teal-bg.active {
  background-image: none;
  outline: 0;
}

.btn-teal-bg.disabled, .btn-teal-bg[disabled],
fieldset[disabled] .btn-teal-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-teal-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-teal-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-teal-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-teal-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #008276;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Green Color */
.btn-green-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #BAF402;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-green-bg:focus, .btn-green-bg:active:focus, .btn-green-bg.active:focus, .btn-green-bg.focus, .btn-green-bg:active.focus, .btn-green-bg.active.focus {
  outline: none;
}

.btn-green-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-green-bg:hover, .btn-green-bg:focus, .btn-green-bg.focus {
  color: #fff;
  background: #baf451;
  border-color: transparent;
  text-decoration: none;
}

.btn-green-bg:after, .btn-green-bg:before {
  background: transparent;
}

.btn-green-bg:active, .btn-green-bg.active {
  background-image: none;
  outline: 0;
}

.btn-green-bg.disabled, .btn-green-bg[disabled],
fieldset[disabled] .btn-green-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-green-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-green-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-green-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-green-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Red Color */
.btn-red-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #e55973;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-red-bg:focus, .btn-red-bg:active:focus, .btn-red-bg.active:focus, .btn-red-bg.focus, .btn-red-bg:active.focus, .btn-red-bg.active.focus {
  outline: none;
}

.btn-red-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-red-bg:hover, .btn-red-bg:focus, .btn-red-bg.focus {
  color: #fff;
  background: #e57287;
  border-color: transparent;
  text-decoration: none;
}

.btn-red-bg:after, .btn-red-bg:before {
  background: transparent;
}

.btn-red-bg:active, .btn-red-bg.active {
  background-image: none;
  outline: 0;
}

.btn-red-bg.disabled, .btn-red-bg[disabled],
fieldset[disabled] .btn-red-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-red-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-red-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-red-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-red-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #e24764;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Pink Color */
.btn-pink-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #e91e63;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-pink-bg:focus, .btn-pink-bg:active:focus, .btn-pink-bg.active:focus, .btn-pink-bg.focus, .btn-pink-bg:active.focus, .btn-pink-bg.active.focus {
  outline: none;
}

.btn-pink-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-pink-bg:hover, .btn-pink-bg:focus, .btn-pink-bg.focus {
  color: #fff;
  background: #ec407a;
  border-color: transparent;
  text-decoration: none;
}

.btn-pink-bg:after, .btn-pink-bg:before {
  background: transparent;
}

.btn-pink-bg:active, .btn-pink-bg.active {
  background-image: none;
  outline: 0;
}

.btn-pink-bg.disabled, .btn-pink-bg[disabled],
fieldset[disabled] .btn-pink-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-pink-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-pink-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-pink-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-pink-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #dd1659;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Purple Color */
.btn-purple-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #b260ce;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-purple-bg:focus, .btn-purple-bg:active:focus, .btn-purple-bg.active:focus, .btn-purple-bg.focus, .btn-purple-bg:active.focus, .btn-purple-bg.active.focus {
  outline: none;
}

.btn-purple-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-purple-bg:hover, .btn-purple-bg:focus, .btn-purple-bg.focus {
  color: #fff;
  background: #ba75d1;
  border-color: transparent;
  text-decoration: none;
}

.btn-purple-bg:after, .btn-purple-bg:before {
  background: transparent;
}

.btn-purple-bg:active, .btn-purple-bg.active {
  background-image: none;
  outline: 0;
}

.btn-purple-bg.disabled, .btn-purple-bg[disabled],
fieldset[disabled] .btn-purple-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-purple-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-purple-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-purple-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-purple-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #aa50c9;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Gold Color */
.btn-gold-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #c69f73;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-gold-bg:focus, .btn-gold-bg:active:focus, .btn-gold-bg.active:focus, .btn-gold-bg.focus, .btn-gold-bg:active.focus, .btn-gold-bg.active.focus {
  outline: none;
}

.btn-gold-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-gold-bg:hover, .btn-gold-bg:focus, .btn-gold-bg.focus {
  color: #fff;
  background: #d3af87;
  border-color: transparent;
  text-decoration: none;
}

.btn-gold-bg:after, .btn-gold-bg:before {
  background: transparent;
}

.btn-gold-bg:active, .btn-gold-bg.active {
  background-image: none;
  outline: 0;
}

.btn-gold-bg.disabled, .btn-gold-bg[disabled],
fieldset[disabled] .btn-gold-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-gold-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-gold-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-gold-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-gold-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #c09565;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Facebook */
.btn-fb-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #44619d;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-fb-bg:focus, .btn-fb-bg:active:focus, .btn-fb-bg.active:focus, .btn-fb-bg.focus, .btn-fb-bg:active.focus, .btn-fb-bg.active.focus {
  outline: none;
}

.btn-fb-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-fb-bg:hover, .btn-fb-bg:focus, .btn-fb-bg.focus {
  color: #fff;
  background: #44619d;
  border-color: transparent;
  text-decoration: none;
}

.btn-fb-bg:after, .btn-fb-bg:before {
  background: transparent;
}

.btn-fb-bg:active, .btn-fb-bg.active {
  background-image: none;
  outline: 0;
}

.btn-fb-bg.disabled, .btn-fb-bg[disabled],
fieldset[disabled] .btn-fb-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-fb-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-fb-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-fb-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-fb-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #3e588f;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Twitter */
.btn-tw-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #55acee;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-tw-bg:focus, .btn-tw-bg:active:focus, .btn-tw-bg.active:focus, .btn-tw-bg.focus, .btn-tw-bg:active.focus, .btn-tw-bg.active.focus {
  outline: none;
}

.btn-tw-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-tw-bg:hover, .btn-tw-bg:focus, .btn-tw-bg.focus {
  color: #fff;
  background: #55acee;
  border-color: transparent;
  text-decoration: none;
}

.btn-tw-bg:after, .btn-tw-bg:before {
  background: transparent;
}

.btn-tw-bg:active, .btn-tw-bg.active {
  background-image: none;
  outline: 0;
}

.btn-tw-bg.disabled, .btn-tw-bg[disabled],
fieldset[disabled] .btn-tw-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-tw-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-tw-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-tw-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-tw-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #42a3ec;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Dribbble */
.btn-dr-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #ea4c89;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-dr-bg:focus, .btn-dr-bg:active:focus, .btn-dr-bg.active:focus, .btn-dr-bg.focus, .btn-dr-bg:active.focus, .btn-dr-bg.active.focus {
  outline: none;
}

.btn-dr-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-dr-bg:hover, .btn-dr-bg:focus, .btn-dr-bg.focus {
  color: #fff;
  background: #ea4c89;
  border-color: transparent;
  text-decoration: none;
}

.btn-dr-bg:after, .btn-dr-bg:before {
  background: transparent;
}

.btn-dr-bg:active, .btn-dr-bg.active {
  background-image: none;
  outline: 0;
}

.btn-dr-bg.disabled, .btn-dr-bg[disabled],
fieldset[disabled] .btn-dr-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-dr-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-dr-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-dr-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-dr-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #e83a7d;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background Instagram */
.btn-ig-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #125688;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-ig-bg:focus, .btn-ig-bg:active:focus, .btn-ig-bg.active:focus, .btn-ig-bg.focus, .btn-ig-bg:active.focus, .btn-ig-bg.active.focus {
  outline: none;
}

.btn-ig-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-ig-bg:hover, .btn-ig-bg:focus, .btn-ig-bg.focus {
  color: #fff;
  background: #125688;
  border-color: transparent;
  text-decoration: none;
}

.btn-ig-bg:after, .btn-ig-bg:before {
  background: transparent;
}

.btn-ig-bg:active, .btn-ig-bg.active {
  background-image: none;
  outline: 0;
}

.btn-ig-bg.disabled, .btn-ig-bg[disabled],
fieldset[disabled] .btn-ig-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-ig-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-ig-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-ig-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-ig-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #104b76;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/*------------------------------------------------------------------
    [Buttons Sizes]
------------------------------------------------------------------*/
/* Mini Size */
.btn-base-xs {
  font-size: 12px;
  font-weight: 300;
  padding: 8px 15px;
}

.btn-base-xs.btn-base-animate-to-top, .btn-base-xs.btn-base-animate-to-right {
  padding-right: 40px;
}

/* Small Size */
.btn-base-sm {
  font-size: 14px;
  font-weight: 300;
  padding: 9px 22px;
}

.btn-base-sm.btn-base-animate-to-top, .btn-base-sm.btn-base-animate-to-right {
  padding-right: 50px;
}

/* Medium Size */
.btn-base-md {
  font-size: 16px;
  font-weight: 300;
  padding: 12px 25px;
}

.btn-base-md.btn-base-animate-to-top, .btn-base-md.btn-base-animate-to-right {
  padding-right: 65px;
}

/* Large Size */
.btn-base-lg {
  font-size: 20px;
  font-weight: 300;
  padding: 15px 30px;
}

.btn-base-lg.btn-base-animate-to-top, .btn-base-lg.btn-base-animate-to-right {
  padding-right: 75px;
}

/*--------------------------------------------------
    [Buttons Toggle]
----------------------------------------------------*/
/* Button White Toggle */
.btn-group.btn-white-toggle.open .btn-white-bg {
  box-shadow: none;
}

.btn-group.btn-white-toggle.open .btn-white-bg:after {
  color: #BAF402;
  content: "\f0d8";
}

.btn-group.btn-white-toggle .btn-white-bg {
  width: 300px;
  font-size: 15px;
  font-weight: 300;
  color: #34343c;
  background: #fff;
  padding: 12px 100px 12px 15px;
}

.btn-group.btn-white-toggle .btn-white-bg:after {
  position: absolute;
  top: 12px;
  right: 15px;
  font-size: 15px;
  font-family: FontAwesome;
  color: #34343c;
  content: "\f0d7";
}

.btn-group.btn-white-toggle .btn-white-bg:hover, .btn-group.btn-white-toggle .btn-white-bg:focus, .btn-group.btn-white-toggle .btn-white-bg.focus {
  color: #34343c;
  background: #fff;
  border-color: #fff;
  box-shadow: inset none;
}

.btn-group.btn-white-toggle .dropdown-menu {
  width: 300px;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  padding: 0;
  margin: 1px 0 0;
}

.btn-group.btn-white-toggle .dropdown-menu > li > a {
  font-size: 15px;
  font-weight: 300;
  color: #34343c;
  border-bottom: 1px solid #f7f8fa;
  padding: 10px 15px;
}

.btn-group.btn-white-toggle .dropdown-menu > li > a .dropdown-menu-icon {
  min-width: 25px;
  text-align: center;
  vertical-align: middle;
  margin-right: 5px;
}

.btn-group.btn-white-toggle .dropdown-menu > li > a:hover, .btn-group.btn-white-toggle .dropdown-menu > li > a:focus {
  background: #BAF402;
  color: #fff;
}

.btn-group.btn-white-toggle .dropdown-menu > li:first-child > a {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.btn-group.btn-white-toggle .dropdown-menu > li:last-child > a {
  border-bottom: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

/*------------------------------------------------------------------
    [Buttons Icon Animate To Top]
------------------------------------------------------------------*/
.btn-base-animate-to-top {
  z-index: 1;
  display: inline-block;
  text-align: center;
  overflow: hidden;
}

.btn-base-animate-to-top:hover .btn-base-element-icon {
  -webkit-animation: toTopFromBottom 0.3s forwards;
  -moz-animation: toTopFromBottom 0.3s forwards;
  animation: toTopFromBottom 0.3s forwards;
}

@-webkit-keyframes toTopFromBottom {
  49% {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toTopFromBottom {
  49% {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toTopFromBottom {
  49% {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  51% {
    opacity: 1;
  }
}

/*------------------------------------------------------------------
    [Buttons Icon Animate To Right]
------------------------------------------------------------------*/
.btn-base-animate-to-right {
  z-index: 1;
  display: inline-block;
  text-align: center;
  overflow: hidden;
}

.btn-base-animate-to-right:hover .btn-base-element-icon {
  -webkit-animation: toRightFromLeft 0.3s forwards;
  -moz-animation: toRightFromLeft 0.3s forwards;
  animation: toRightFromLeft 0.3s forwards;
}

@-webkit-keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  51% {
    opacity: 1;
  }
}

/*------------------------------------------------------------------
    [Buttons Dropdown Default]
------------------------------------------------------------------*/
.btn-dropdown-group.open .btn-dropdown-default,
.btn-dropdown-group.open .btn-dropdown-default.focus,
.btn-dropdown-group.open .btn-dropdown-default:focus,
.btn-dropdown-group.open .btn-dropdown-default:hover {
  box-shadow: none;
  background: transparent;
}

.btn-dropdown-group.open .btn-dropdown-toggle {
  background: #BAF402;
}

.btn-dropdown-group:hover .btn-dropdown-toggle {
  background: #BAF402;
}

.btn-dropdown-group .btn-dropdown-default {
  background: transparent;
  border: none;
  color: #34343c;
}

.btn-dropdown-group .btn-dropdown-default:hover {
  background: transparent;
  box-shadow: none;
}

.btn-dropdown-group .btn-dropdown-default.active.focus, .btn-dropdown-group .btn-dropdown-default.active:focus, .btn-dropdown-group .btn-dropdown-default.active:hover, .btn-dropdown-group .btn-dropdown-default:active.focus, .btn-dropdown-group .btn-dropdown-default:active:focus, .btn-dropdown-group .btn-dropdown-default:active:hover {
  background: transparent;
  box-shadow: none;
  border: none;
}

.btn-dropdown-group .btn-dropdown-toggle {
  position: relative;
  top: -5px;
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #898999;
  border-radius: 50%;
}

.btn-dropdown-group .btn-dropdown-toggle + .btn-dropdown-toggle {
  margin-left: -1px;
}

.btn-dropdown-group .dropdown-menu {
  box-shadow: 0 2px 5px 3px #eff1f8;
  border: none;
}

.btn-dropdown-group .dropdown-menu > li > a {
  color: #34343c;
  font-size: 14px;
  font-weight: 300;
}

.btn-dropdown-group .dropdown-menu > li:hover > a {
  background: #f7f8fa;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .btn-dropdown-group .dropdown-menu.xs-dropdown-menu-right {
    left: auto;
    right: 0;
  }
}

/*--------------------------------------------------
    [Divider]
----------------------------------------------------*/
.divider-v1, .divider-v2, .divider-v3 {
  position: relative;
  width: 100%;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  margin: 30px auto;
}

.divider-v1 .divider-v1-element, .divider-v2 .divider-v2-element, .divider-v3 .divider-v3-element {
  position: relative;
  display: inline-block;
}

.divider-v1 .divider-v1-element:before, .divider-v2 .divider-v2-element:before, .divider-v3 .divider-v3-element:before, .divider-v1 .divider-v1-element:after, .divider-v2 .divider-v2-element:after, .divider-v3 .divider-v3-element:after {
  position: absolute;
  top: 50%;
  display: block;
  width: 2000px;
  height: 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-color: #c4c4c4;
  content: " ";
}

.divider-v1 .divider-v1-element:before, .divider-v2 .divider-v2-element:before, .divider-v3 .divider-v3-element:before {
  right: 100%;
  margin-right: 15px;
}

.divider-v1 .divider-v1-element:after, .divider-v2 .divider-v2-element:after, .divider-v3 .divider-v3-element:after {
  left: 100%;
  margin-left: 15px;
}

.divider-v1 .divider-v1-element-bg, .divider-v2 .divider-v2-element-bg, .divider-v3 .divider-v3-element-bg {
  font-size: 14px;
  color: #fff;
  background: #BAF402;
  padding: 3px 10px;
}

.divider-v1 .divider-v1-icon, .divider-v2 .divider-v2-icon, .divider-v3 .divider-v3-icon {
  font-size: 30px;
  color: #BAF402;
}

/*--------------------------------------------------
    [Divider v1]
----------------------------------------------------*/
/*--------------------------------------------------
    [Divider v2]
----------------------------------------------------*/
.divider-v2 .divider-v2-element:before, .divider-v2 .divider-v2-element:after {
  border-top-style: dashed;
  border-top-width: 1px;
}

/*--------------------------------------------------
    [Divider v3]
----------------------------------------------------*/
.divider-v3 .divider-v3-element:before, .divider-v3 .divider-v3-element:after {
  border-top-style: double;
  border-top-width: 3px;
}

/*------------------------------------------------------------------
    [Divider v4]
------------------------------------------------------------------*/
.divider-v4 .divider-v4-title {
  position: relative;
  font-size: 22px;
  margin: 0 0 30px;
}

/* Divider Center V4 */
.divider-v4.divider-v4-center-single .divider-v4-title:after, .divider-v4.divider-v4-center-double .divider-v4-title:after {
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 15%;
  height: 1px;
  margin-left: -9%;
}

.divider-v4.divider-v4-center-single .divider-v4-title:after, .divider-v4.divider-v4-center-single .divider-v4-title:before, .divider-v4.divider-v4-center-double .divider-v4-title:after, .divider-v4.divider-v4-center-double .divider-v4-title:before {
  background: #34343c;
  content: " ";
}

/* White Divider Center V4 */
.divider-v4-white.divider-v4-center-single .divider-v4-title:before, .divider-v4-white.divider-v4-center-single .divider-v4-title:after, .divider-v4-white.divider-v4-center-double .divider-v4-title:before, .divider-v4-white.divider-v4-center-double .divider-v4-title:after {
  background: #fff;
}

/* Divider Center V4 :before; */
.divider-v4.divider-v4-center-double .divider-v4-title:before {
  position: absolute;
  left: 50%;
  bottom: -15px;
  width: 11%;
  height: 1px;
  margin-left: -7%;
}

/* Divider Left V4 */
.divider-v4.divider-v4-left-single .divider-v4-title:after, .divider-v4.divider-v4-left-double .divider-v4-title:after {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 15%;
  height: 1px;
}

.divider-v4.divider-v4-left-single .divider-v4-title:after, .divider-v4.divider-v4-left-single .divider-v4-title:before, .divider-v4.divider-v4-left-double .divider-v4-title:after, .divider-v4.divider-v4-left-double .divider-v4-title:before {
  background: #34343c;
  content: " ";
}

/* White Divider Left V4 */
.divider-v4-white.divider-v4-left-single .divider-v4-title:before, .divider-v4-white.divider-v4-left-single .divider-v4-title:after, .divider-v4-white.divider-v4-left-double .divider-v4-title:before, .divider-v4-white.divider-v4-left-double .divider-v4-title:after {
  background: #fff;
}

/* Divider V4 Left Double :before */
.divider-v4.divider-v4-left-double .divider-v4-title:before {
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 10%;
  height: 1px;
}

/* Divider Right V4 */
.divider-v4.divider-v4-right-single .divider-v4-title:after, .divider-v4.divider-v4-right-double .divider-v4-title:after {
  position: absolute;
  right: 0;
  bottom: -10px;
  width: 15%;
  height: 1px;
}

.divider-v4.divider-v4-right-single .divider-v4-title:after, .divider-v4.divider-v4-right-single .divider-v4-title:before, .divider-v4.divider-v4-right-double .divider-v4-title:after, .divider-v4.divider-v4-right-double .divider-v4-title:before {
  background: #34343c;
  content: " ";
}

/* White Divider Right V4 */
.divider-v4-white.divider-v4-right-single .divider-v4-title:before, .divider-v4-white.divider-v4-right-single .divider-v4-title:after, .divider-v4-white.divider-v4-right-double .divider-v4-title:before, .divider-v4-white.divider-v4-right-double .divider-v4-title:after {
  background: #fff;
}

/* Tittle Divider V4 Right Double Before */
.divider-v4.divider-v4-right-double .divider-v4-title:before {
  position: absolute;
  right: 0;
  bottom: -15px;
  width: 10%;
  height: 1px;
}

/* White Version */
.divider-v4-white .divider-v4-title,
.divider-v4-white .divider-v4-text {
  color: #fff;
}

/*------------------------------------------------------------------
    [Divider v5]
------------------------------------------------------------------*/
.divider-v5 {
  overflow: hidden;
}

.divider-v5 .divider-v5-title {
  font-size: 22px;
  margin: 0 0 10px;
}

.divider-v5 .divider-v5-element {
  position: relative;
  display: inline-block;
}

.divider-v5 .divider-v5-element .divider-v5-element-icon {
  position: relative;
  top: -1px;
  font-size: 18px;
}

/* Divider Center Element V5 */
.divider-v5 .divider-v5-element-center:before, .divider-v5 .divider-v5-element-center:after {
  position: absolute;
  top: 8px;
  width: 50px;
  height: 1px;
  background: #9e9e9e;
  content: " ";
}

.divider-v5 .divider-v5-element-center:before {
  right: 100%;
  margin-right: 10px;
}

.divider-v5 .divider-v5-element-center:after {
  left: 100%;
  margin-left: 10px;
}

/* Divider Center Element Wrap V5 */
.divider-v5 .center-wrap:before, .divider-v5 .center-wrap:after {
  width: 2000px;
  height: 1px;
}

/* Divider Center Element Dashed V5 */
.divider-v5 .divider-v5-element-center-dashed:before, .divider-v5 .divider-v5-element-center-dashed:after {
  position: absolute;
  top: 8px;
  width: 50px;
  height: 1px;
  border-top-style: dashed;
  border-top-width: 2px;
  border-color: #c4c4c4;
  content: " ";
}

.divider-v5 .divider-v5-element-center-dashed:before {
  right: 100%;
  margin-right: 10px;
}

.divider-v5 .divider-v5-element-center-dashed:after {
  left: 100%;
  margin-left: 10px;
}

/* Divider Center Element Dotted V5 */
.divider-v5 .divider-v5-element-center-dotted:before, .divider-v5 .divider-v5-element-center-dotted:after {
  position: absolute;
  top: 8px;
  width: 50px;
  height: 1px;
  border-top-style: dotted;
  border-top-width: 2px;
  border-color: #c4c4c4;
  content: " ";
}

.divider-v5 .divider-v5-element-center-dotted:before {
  right: 100%;
  margin-right: 10px;
}

.divider-v5 .divider-v5-element-center-dotted:after {
  left: 100%;
  margin-left: 10px;
}

/* Divider Left Element V5 */
.divider-v5 .divider-v5-element-left:after {
  position: absolute;
  top: 8px;
  width: 50px;
  height: 1px;
  background: #9e9e9e;
  content: " ";
}

.divider-v5 .divider-v5-element-left:after {
  left: 100%;
  margin-left: 10px;
}

/* Divider Left Element Wrap V5 */
.divider-v5 .left-wrap:after {
  width: 2000px;
  height: 1px;
}

/* Divider Left Element Dashed V5 */
.divider-v5 .divider-v5-element-left-dashed:after {
  position: absolute;
  top: 8px;
  width: 50px;
  height: 1px;
  border-top-style: dashed;
  border-top-width: 2px;
  border-color: #c4c4c4;
  content: " ";
}

.divider-v5 .divider-v5-element-left-dashed:after {
  left: 100%;
  margin-left: 10px;
}

/* Divider Left Element Dotted V5 */
.divider-v5 .divider-v5-element-left-dotted:after {
  position: absolute;
  top: 8px;
  width: 50px;
  height: 1px;
  border-top-style: dotted;
  border-top-width: 2px;
  border-color: #c4c4c4;
  content: " ";
}

.divider-v5 .divider-v5-element-left-dotted:after {
  left: 100%;
  margin-left: 10px;
}

/* Divider Right Element V5*/
.divider-v5 .divider-v5-element-right:before {
  position: absolute;
  top: 8px;
  width: 50px;
  height: 1px;
  background: #9e9e9e;
  content: " ";
}

.divider-v5 .divider-v5-element-right:before {
  right: 100%;
  margin-right: 10px;
}

/* Divider Right Element Wrap V5*/
.divider-v5 .right-wrap:before {
  width: 2000px;
  height: 1px;
}

/* Divider Right Element Dashed V5*/
.divider-v5 .divider-v5-element-right-dashed:before {
  position: absolute;
  top: 8px;
  width: 50px;
  height: 1px;
  border-top-style: dashed;
  border-top-width: 2px;
  border-color: #c4c4c4;
  content: " ";
}

.divider-v5 .divider-v5-element-right-dashed:before {
  right: 100%;
  margin-right: 10px;
}

/* Divider Right Element Dotted V5*/
.divider-v5 .divider-v5-element-right-dotted:before {
  position: absolute;
  top: 8px;
  width: 50px;
  height: 1px;
  border-top-style: dotted;
  border-top-width: 2px;
  border-color: #c4c4c4;
  content: " ";
}

.divider-v5 .divider-v5-element-right-dotted:before {
  right: 100%;
  margin-right: 10px;
}

/* White Version */
.divider-v5-white .divider-v5-title,
.divider-v5-white .divider-v5-element,
.divider-v5-white .divider-v5-text {
  color: #fff;
}

.divider-v5-white .divider-v5-element:before, .divider-v5-white .divider-v5-element:after {
  border-bottom-color: #fff;
}

/*------------------------------------------------------------------
    [Divider v6]
------------------------------------------------------------------*/
.divider-v6 {
  overflow: hidden;
}

.divider-v6 .divider-v6-element {
  position: relative;
  display: inline-block;
}

.divider-v6 .divider-v6-element-title {
  font-size: 22px;
  padding: 5px 15px;
  margin-bottom: 20px;
}

/* Divider Center Element V6 */
.divider-v6 .divider-v6-element-center:before, .divider-v6 .divider-v6-element-center:after {
  position: absolute;
  top: 20px;
  width: 2000px;
  height: 1px;
  background: #d1d1d1;
  content: " ";
}

.divider-v6 .divider-v6-element-center:before {
  right: 100%;
}

.divider-v6 .divider-v6-element-center:after {
  left: 100%;
}

/* Divider Left Element V6 */
.divider-v6 .divider-v6-element-left .divider-v6-element-title {
  padding-left: 0;
}

.divider-v6 .divider-v6-element-left:after {
  position: absolute;
  top: 20px;
  width: 2000px;
  height: 1px;
  background: #d1d1d1;
  content: " ";
}

.divider-v6 .divider-v6-element-left:after {
  left: 100%;
}

/* Divider Right Element V6 */
.divider-v6 .divider-v6-element-right .divider-v6-element-title {
  padding-right: 0;
}

.divider-v6 .divider-v6-element-right:before {
  position: absolute;
  top: 20px;
  width: 2000px;
  height: 1px;
  background: #d1d1d1;
  content: " ";
}

.divider-v6 .divider-v6-element-right:before {
  right: 100%;
}

/* Divider Element Title Border V6 */
.divider-v6 .divider-v6-element-title.divider-v6-element-title-brd {
  border: 1px solid #d1d1d1;
  padding: 5px 15px;
  margin-bottom: 30px;
}

/* White Version */
.divider-v6-white .divider-v6-element-title,
.divider-v6-white .divider-v6-text {
  color: #fff;
}

.divider-v6-white .divider-v6-element-title-brd {
  border-color: #f7f8fa;
}

.divider-v6-white .divider-v6-element:before, .divider-v6-white .divider-v6-element:after {
  border-bottom-color: #fff;
}

/*------------------------------------------------------------------
    [Divider v7]
------------------------------------------------------------------*/
.divider-v7 {
  text-align: center;
}

.divider-v7 .divider-v7-title {
  position: relative;
  font-size: 22px;
  margin-bottom: 30px;
}

.divider-v7 .divider-v7-title:after {
  position: absolute;
  left: 35%;
  bottom: -14px;
  width: 30%;
  border-top-width: 2px;
  border-color: #c4c4c4;
  content: " ";
}

/* Divider Title Dashed V7 */
.divider-v7 .divider-v7-title-dashed:after {
  border-top-style: dashed;
}

/* Divider Title Dotted V7 */
.divider-v7 .divider-v7-title-dotted:after {
  border-top-style: dotted;
  border-color: #c69f73;
}

/*------------------------------------------------------------------
    [:before, :after Divider Bold]
—----------------------------------------------------------------*/
/* :before, :after Divider Bold */
.before-after-brd-bold:before, .before-after-brd-bold:after {
  height: 2px !important;
}

/*--------------------------------------------------
    [Dropcap]
----------------------------------------------------*/
.dropcap-base, .dropcap-base-bg, .dropcap-base-bordered, .dropcap-dark, .dropcap-dark-bg, .dropcap-dark-bordered {
  width: 40px;
  height: 40px;
  float: left;
  clear: left;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.1;
  text-align: center;
  text-transform: uppercase;
  padding: 1px;
  margin: 5px 10px 0 0;
}

/* Dropcap Base Color */
.dropcap-base {
  color: #BAF402;
}

/* Dropcap Base Background Color */
.dropcap-base-bg {
  color: #fff;
  background: #BAF402;
}

/* Dropcap Base Color Bordered */
.dropcap-base-bordered {
  color: #BAF402;
  border: 1px solid #BAF402;
}

/* Dropcap Base Color */
.dropcap-dark {
  color: #34343c;
}

/* Dropcap Dark Background Color */
.dropcap-dark-bg {
  color: #fff;
  background: #34343c;
}

/* Dropcap Dark Color Bordered */
.dropcap-dark-bordered {
  color: #34343c;
  border: 1px solid #34343c;
}

/*------------------------------------------------------------------
    [Heading v1]
------------------------------------------------------------------*/
.heading-v1 .heading-v1-title {
  font-size: 28px;
  font-style: italic;
  font-family: Droid Serif, serif;
}

.heading-v1 .heading-v1-subtitle {
  max-width: 350px;
  font-size: 16px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #BAF402;
  margin-bottom: 20px;
}

.heading-v1 .heading-v1-text {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 0;
}

/* White Version */
.heading-v1.heading-v1-white .heading-v1-title {
  color: #fff;
}

.heading-v1.heading-v1-white .heading-v1-text {
  color: #fff;
  opacity: .8;
}

/*------------------------------------------------------------------
    [Heading v2]
------------------------------------------------------------------*/
.heading-v2 {
  text-align: center;
  overflow: hidden;
}

.heading-v2 .heading-v2-title {
  font-size: 36px;
  line-height: 1.4;
  margin: 0 0 10px;
}

.heading-v2 .heading-v2-text {
  position: relative;
  display: inline-block;
  font-style: italic;
  font-family: Droid Serif, serif;
  margin-bottom: 0;
}

.heading-v2 .heading-v2-text:before, .heading-v2 .heading-v2-text:after {
  position: absolute;
  top: 10px;
  width: 30px;
  height: 1px;
  background: #d1d1d1;
  content: " ";
}

.heading-v2 .heading-v2-text:before {
  right: 100%;
  margin-right: 15px;
}

.heading-v2 .heading-v2-text:after {
  left: 100%;
  margin-left: 15px;
}

/* White Version */
.heading-v2.heading-v2-white .heading-v2-title,
.heading-v2.heading-v2-white .heading-v2-text {
  color: #fff;
}

.heading-v2.heading-v2-white .heading-v2-paragraph:before, .heading-v2.heading-v2-white .heading-v2-paragraph:after {
  background: #fff;
}

/*------------------------------------------------------------------
    [Heading v3]
------------------------------------------------------------------*/
.heading-v3 {
  text-align: center;
}

.heading-v3 .heading-v3-title {
  font-size: 34px;
  margin: 0 0 10px;
}

.heading-v3 .heading-v3-element {
  position: relative;
  display: inline-block;
  font-size: 12px;
  color: #606060;
}

.heading-v3 .heading-v3-element:before, .heading-v3 .heading-v3-element:after {
  position: absolute;
  top: 8px;
  width: 30px;
  height: 1px;
  border-bottom: 1px solid #d1d1d1;
  content: " ";
}

.heading-v3 .heading-v3-element:before {
  right: 100%;
  margin-right: 10px;
}

.heading-v3 .heading-v3-element:after {
  left: 100%;
  margin-left: 10px;
}

.heading-v3 .heading-v3-element .heading-v3-element-icon {
  position: relative;
  top: -1px;
  font-size: 8px;
  color: #606060;
}

.heading-v3 .heading-v3-text {
  margin-bottom: 0;
  font-style: italic;
  font-family: Droid Serif, serif;
}

/* White Version */
.heading-v3.heading-v3-white .heading-v3-subtitle,
.heading-v3.heading-v3-white .heading-v3-title,
.heading-v3.heading-v3-white .heading-v3-element,
.heading-v3.heading-v3-white .heading-v3-text {
  color: #fff;
}

.heading-v3.heading-v3-white .heading-v3-element:before, .heading-v3.heading-v3-white .heading-v3-element:after {
  border-bottom-color: #fff;
}

.heading-v3.heading-v3-white .heading-v3-element .heading-v3-element-icon {
  color: #fff;
}

/*--------------------------------------------------
    [Heading v4]
----------------------------------------------------*/
.heading-v4 .heading-v4-subtitle {
  display: block;
  font-size: 16px;
  font-style: italic;
  font-family: Droid Serif, serif;
  margin-bottom: 0;
}

.heading-v4 .heading-v4-title {
  font-size: 28px;
}

.heading-v4 .heading-v4-text {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 0;
}

/*------------------------------------------------------------------
    [Font Awesome Icons]
------------------------------------------------------------------*/
.bordered-icon .bordered-icon-box {
  width: 16.7%;
  height: 100px;
  float: left;
  text-align: center;
  padding: 20px 10px;
  margin: 0 -1px -1px 0;
  word-wrap: break-word;
  border: 1px solid #f7f8fa;
}

.bordered-icon .bordered-icon-box .bordered-icon-box-item {
  display: block;
  font-size: 24px;
  color: #34343c;
  padding-bottom: 10px;
}

.bordered-icon .bordered-icon-box .bordered-icon-box-item-title {
  color: #34343c;
}

.bordered-icon .bordered-icon-box:hover {
  cursor: pointer;
  background: #BAF402;
}

.bordered-icon .bordered-icon-box:hover .bordered-icon-box-item,
.bordered-icon .bordered-icon-box:hover .bordered-icon-box-item-title {
  color: #fff;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  .bordered-icon .bordered-icon-box {
    width: 25%;
    height: 100px;
  }
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .bordered-icon .bordered-icon-box {
    width: 33%;
    height: 115px;
  }
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .bordered-icon .bordered-icon-box {
    width: 50%;
    height: 130px;
  }
}

/*--------------------------------------------------
    [Theme Image v1]
----------------------------------------------------*/
.theme-image-popup-v1 {
  position: relative;
}

.theme-image-popup-v1 .theme-image-popup-v1-child {
  position: absolute;
  right: 20px;
  bottom: 20px;
  text-decoration: none;
}

.theme-image-popup-v1 .theme-image-popup-v1-toggle {
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.theme-image-popup-v1 .theme-image-popup-v1-toggle:before, .theme-image-popup-v1 .theme-image-popup-v1-toggle:after {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  content: " ";
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.theme-image-popup-v1 .theme-image-popup-v1-toggle:before {
  width: 16px;
  height: 2px;
  margin: -1px 0 0 -8px;
  -webkit-transform: scale(0.5, 1);
  -moz-transform: scale(0.5, 1);
  transform: scale(0.5, 1);
}

.theme-image-popup-v1 .theme-image-popup-v1-toggle:after {
  width: 2px;
  height: 16px;
  margin: -8px 0 0 -1px;
  -webkit-transform: scale(1, 0.5);
  -moz-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
}

.theme-image-popup-v1:hover .theme-image-popup-v1-toggle:before, .theme-image-popup-v1:hover .theme-image-popup-v1-toggle:after {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Theme Image v2]
----------------------------------------------------*/
.theme-image-popup-v2 {
  position: relative;
}

.theme-image-popup-v2 .theme-image-popup-v2-child {
  position: absolute;
  top: 20px;
  left: 20px;
  text-decoration: none;
}

.theme-image-popup-v2 .theme-image-popup-v2-toggle {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  font-size: 20px;
  text-align: center;
  background: #fff;
  padding: 15px;
  box-shadow: 0 2px 3px 1px #ededed;
}

/*--------------------------------------------------
    [Lists]
----------------------------------------------------*/
.lists-base > li, .lists-dark > li {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
  color: #606060;
}

.lists-base > li > a, .lists-dark > li > a {
  font-size: 14px;
  font-weight: 300;
  color: #606060;
}

.lists-base > li > a:hover, .lists-dark > li > a:hover {
  color: #BAF402;
  text-decoration: none;
}

.lists-base > li .lists-item-element, .lists-dark > li .lists-base .lists-item-element, .lists-base .lists-dark > li .lists-item-element {
  min-width: 15px;
  color: #BAF402;
  text-align: center;
  margin-right: 10px;
}

.lists-base > li .lists-item-element-brd, .lists-base .lists-dark > li .lists-item-element-brd, .lists-dark .lists-base > li .lists-item-element-brd, .lists-dark > li .lists-item-element-brd {
  width: 15px;
  height: 15px;
  font-size: 11px;
  line-height: 3px;
  padding: 5px;
  margin-right: 10px;
}

.lists-base > li .lists-item-element-bg, .lists-base .lists-dark > li .lists-item-element-bg, .lists-dark .lists-base > li .lists-item-element-bg, .lists-dark > li .lists-item-element-bg {
  width: 15px;
  height: 15px;
  font-size: 11px;
  color: #fff;
  line-height: 3px;
  padding: 6px;
  margin-right: 10px;
}

/* Lists Base */
.lists-base .lists-item-element-brd {
  color: #BAF402;
  border: 1px solid #BAF402;
}

.lists-base .lists-item-element-bg {
  background: #BAF402;
}

/* Lists Dark */
.lists-dark .lists-item-element {
  margin-right: 10px;
}

.lists-dark .lists-item-element-brd {
  color: #34343c;
  border: 1px solid #34343c;
}

.lists-dark .lists-item-element-bg {
  background: #34343c;
}

/*--------------------------------------------------
    [Lists Base White Color]
----------------------------------------------------*/
.lists-base-white > li {
  color: #fff;
}

.lists-base-white > li > a {
  color: #fff;
}

.lists-base-white > li > a:hover {
  color: #fff;
  text-decoration: underline;
}

.lists-base-white > li .lists-item-element,
.lists-base-white > li .lists-item-element-brd,
.lists-base-white > li .lists-item-element-bg {
  color: #fff;
}

/*--------------------------------------------------
    [Numeric Lists]
----------------------------------------------------*/
.numeric-base-lists, .numeric-dark-lists {
  counter-reset: li;
  list-style: decimal;
  padding-left: 14px;
}

.numeric-base-lists > li, .numeric-dark-lists > li {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
  color: #606060;
}

.numeric-base-lists > li > a, .numeric-dark-lists > li > a {
  font-size: 14px;
  font-weight: 300;
  color: #606060;
}

.numeric-base-lists > li > a:hover, .numeric-dark-lists > li > a:hover {
  color: #BAF402;
  text-decoration: none;
}

/* Numeric Base Lists */
.numeric-base-lists > li {
  color: #BAF402;
}

/* Numeric Dark Lists */
.numeric-dark-lists > li {
  color: #34343c;
}

/*--------------------------------------------------
    [Login]
----------------------------------------------------*/
.login {
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
}

.login .login-content {
  width: 450px;
  background: #fff;
  padding: 40px;
}

.login .login-form-logo {
  width: 100px;
  height: auto;
}

.login .login-form-title {
  font-size: 30px;
  margin-bottom: 0;
}

.login .login-form-actions {
  overflow: hidden;
  margin-bottom: 30px;
}

.login .login-form-forgot {
  float: right;
  font-size: 13px;
  font-weight: 300;
}

.login .login-form-forgot:hover {
  color: #BAF402;
}

.login .forget-form,
.login .register-form {
  display: none;
}

/* Error */
.login .form-control.error {
  border-color: #e55973;
  margin-bottom: 5px;
}

.login label.error {
  display: block;
  font-size: 13px;
  font-style: italic;
  color: #e55973;
  text-align: left;
}

/* Login Error */
.login-error .form-control.error {
  border-color: #e55973;
  margin-bottom: 5px;
}

.login-error label.error {
  display: block;
  font-size: 13px;
  font-style: italic;
  color: #e55973;
  text-align: left;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .login .login-content {
    width: 100%;
  }
}

/*--------------------------------------------------
    [Form Conrol White Semi Transparent]
----------------------------------------------------*/
.form-control-white-semi-transparent {
  background: rgba(255, 255, 255, 0.3);
  color: #fff !important;
  border: none;
}

.form-control-white-semi-transparent::-moz-placeholder {
  color: #fff !important;
}

.form-control-white-semi-transparent:-ms-input-placeholder {
  color: #fff !important;
}

.form-control-white-semi-transparent::-webkit-input-placeholder {
  color: #fff !important;
}

.form-control-white-semi-transparent:focus {
  background: rgba(255, 255, 255, 0.4);
}

/*--------------------------------------------------
    [Form Conrol White Border Bottom]
----------------------------------------------------*/
.form-control-white-border-bottom {
  background: transparent;
  color: #fff !important;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-left: 1px;
}

.form-control-white-border-bottom::-moz-placeholder {
  color: #fff !important;
}

.form-control-white-border-bottom:-ms-input-placeholder {
  color: #fff !important;
}

.form-control-white-border-bottom::-webkit-input-placeholder {
  color: #fff !important;
}

.form-control-white-border-bottom:focus {
  background: transparent;
  border-bottom-color: white;
}

/*--------------------------------------------------
    [Form Conrol No Border]
----------------------------------------------------*/
.form-control-no-border {
  border: none;
}

/*--------------------------------------------------
    [Paginations v1]
----------------------------------------------------*/
.paginations-v1 {
  position: relative;
  margin: 20px 0;
}

.paginations-v1-list {
  display: inline-block;
  padding-left: 0;
}

.paginations-v1-list > li {
  display: inline-block;
}

.paginations-v1-list > li > a,
.paginations-v1-list > li > span {
  position: relative;
  min-width: 35px;
  min-height: 35px;
  float: left;
  font-size: 14px;
  color: #34343c;
  background: transparent;
  border-radius: 50%;
  text-decoration: none;
  padding: 7px 12.5px;
  margin-left: -1px;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.paginations-v1-list > li > a:hover,
.paginations-v1-list > li > span:hover {
  color: #fff;
  background: rgba(186, 244, 2, 0.5);
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.paginations-v1-list > li.next, .paginations-v1-list > li.previous {
  position: absolute;
}

.paginations-v1-list > li.next > a,
.paginations-v1-list > li.next > span, .paginations-v1-list > li.previous > a,
.paginations-v1-list > li.previous > span {
  font-size: 20px;
  color: #7a7979;
  background: 0;
  padding: 1px 5px;
}

.paginations-v1-list > li.next > a:hover,
.paginations-v1-list > li.next > span:hover, .paginations-v1-list > li.previous > a:hover,
.paginations-v1-list > li.previous > span:hover {
  color: #BAF402;
  background: 0;
}

.paginations-v1-list > li.previous {
  top: 0;
  left: 0;
}

.paginations-v1-list > li.next {
  top: 0;
  right: 0;
}

.paginations-v1-list > li.active > a,
.paginations-v1-list > li.active > span {
  z-index: 2;
  color: #fff;
  background: #BAF402;
  cursor: default;
}

.paginations-v1-list > li.active > a:hover,
.paginations-v1-list > li.active > span:hover {
  color: #fff;
}

.paginations-v1-list > li.disabled > a,
.paginations-v1-list > li.disabled > a:hover,
.paginations-v1-list > li.disabled > a:focus,
.paginations-v1-list > li.disabled > span,
.paginations-v1-list > li.disabled > span:hover,
.paginations-v1-list > li.disabled > span:focus {
  color: #e9e9e9;
  cursor: not-allowed;
  background: transparent;
}

/*--------------------------------------------------
    [Paginations v2]
----------------------------------------------------*/
.paginations-v2 {
  position: relative;
  margin: 20px 0;
}

.paginations-v2-list {
  display: inline-block;
  padding-left: 0;
}

.paginations-v2-list > li {
  display: inline-block;
}

.paginations-v2-list > li > a,
.paginations-v2-list > li > span {
  position: relative;
  min-width: 30px;
  min-height: 30px;
  float: left;
  font-size: 14px;
  color: #34343c;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  padding: 7px 9px;
  margin-left: -1px;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.paginations-v2-list > li > a:hover,
.paginations-v2-list > li > span:hover {
  color: #BAF402;
  border-bottom-color: #BAF402;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.paginations-v2-list > li.next, .paginations-v2-list > li.previous {
  position: absolute;
}

.paginations-v2-list > li.previous {
  top: 0;
  left: 0;
}

.paginations-v2-list > li.next {
  top: 0;
  right: 0;
}

.paginations-v2-list > li.active > a,
.paginations-v2-list > li.active > span {
  z-index: 2;
  color: #BAF402;
  border-bottom-color: #BAF402;
  cursor: default;
}

.paginations-v2-list > li.active > a:hover,
.paginations-v2-list > li.active > span:hover {
  color: #BAF402;
}

.paginations-v2-list > li.disabled > a,
.paginations-v2-list > li.disabled > a:hover,
.paginations-v2-list > li.disabled > a:focus,
.paginations-v2-list > li.disabled > span,
.paginations-v2-list > li.disabled > span:hover,
.paginations-v2-list > li.disabled > span:focus {
  color: #e9e9e9;
  cursor: not-allowed;
  border-bottom: none;
}

/*--------------------------------------------------
    [Paginations v3]
----------------------------------------------------*/
.paginations-v3 {
  position: relative;
  margin: 20px 0;
}

.paginations-v3-list {
  display: inline-block;
  padding-left: 0;
}

.paginations-v3-list > li {
  display: inline-block;
}

.paginations-v3-list > li > a,
.paginations-v3-list > li > span {
  position: relative;
  float: left;
  font-size: 16px;
  color: #34343c;
  background: #fff;
  text-decoration: none;
  padding: 10px 16px;
  margin-left: 2px;
}

.paginations-v3-list > li > a:hover,
.paginations-v3-list > li > span:hover {
  color: #BAF402;
  background: #fff;
}

.paginations-v3-list > li.previous > a:hover i {
  -webkit-animation: toLeftFromRight 0.3s forwards;
  -moz-animation: toLeftFromRight 0.3s forwards;
  animation: toLeftFromRight 0.3s forwards;
}

.paginations-v3-list > li.next > a:hover i {
  -webkit-animation: toRightFromLeft 0.3s forwards;
  -moz-animation: toRightFromLeft 0.3s forwards;
  animation: toRightFromLeft 0.3s forwards;
}

.paginations-v3-list > li.active > a,
.paginations-v3-list > li.active > span {
  z-index: 2;
  color: #fff;
  background: #BAF402;
  cursor: default;
}

.paginations-v3-list > li.active > a:hover,
.paginations-v3-list > li.active > span:hover {
  color: #fff;
  background: #BAF402;
}

.paginations-v3-list > li.disabled > a,
.paginations-v3-list > li.disabled > a:hover,
.paginations-v3-list > li.disabled > a:focus,
.paginations-v3-list > li.disabled > span,
.paginations-v3-list > li.disabled > span:hover,
.paginations-v3-list > li.disabled > span:focus {
  color: #e9e9e9;
  cursor: not-allowed;
  background: transparent;
}

@-webkit-keyframes toLeftFromRight {
  49% {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toLeftFromRight {
  49% {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toLeftFromRight {
  49% {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  51% {
    opacity: 1;
  }
}

@-webkit-keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  51% {
    opacity: 1;
  }
}

/*--------------------------------------------------
    [Pagers v1]
----------------------------------------------------*/
.pagers-v1 {
  display: inline-block;
  float: left;
  width: 50%;
  background: #f7f8fa;
  border: 1px solid #ebeef6;
  text-decoration: none;
  padding: 60px;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.pagers-v1 .previous {
  float: left;
}

.pagers-v1 .next {
  float: right;
}

.pagers-v1 .pagers-v1-title {
  display: block;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.pagers-v1 .pagers-v1-subtitle {
  display: block;
  font-size: 15px;
  font-weight: 300;
  color: #606060;
}

.pagers-v1.disabled {
  color: #e9e9e9;
  cursor: not-allowed;
  border-color: #e9e9e9;
}

.pagers-v1:hover {
  background: #BAF402;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.pagers-v1:hover .pagers-v1-title,
.pagers-v1:hover .pagers-v1-subtitle {
  color: #fff;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .pagers-v1 {
    width: 100%;
  }
  .pagers-v1 .next {
    float: left;
  }
}

/*--------------------------------------------------
    [Pagers v2]
----------------------------------------------------*/
.pagers-v2 {
  text-align: center;
  list-style: none;
  padding-left: 0;
  margin: 20px 0;
}

.pagers-v2 li {
  display: inline;
  padding: 0 2px;
}

.pagers-v2 li > a,
.pagers-v2 li > span {
  display: inline-block;
  min-width: 130px;
  font-size: 15px;
  color: #34343c;
  background: #fff;
  text-decoration: none;
  padding: 12px 23px;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.pagers-v2 li > a:hover,
.pagers-v2 li > span:hover {
  color: #fff;
  background: #BAF402;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.pagers-v2 li.previous > a,
.pagers-v2 li.previous > span {
  float: left;
}

.pagers-v2 li.next > a,
.pagers-v2 li.next > span {
  float: right;
}

.pagers-v2 li.disabled > a,
.pagers-v2 li.disabled > a:hover,
.pagers-v2 li.disabled > a:focus,
.pagers-v2 li.disabled > span,
.pagers-v2 li.disabled > span:hover,
.pagers-v2 li.disabled > span:focus {
  color: #939393;
  cursor: not-allowed;
  background: #f1f3f6;
}

/*--------------------------------------------------
    [Radio Checkbox]
----------------------------------------------------*/
.fuelux .radio-checkbox label,
.fuelux .radio-checkbox-inline label {
  color: #606060;
}

.fuelux .radio-checkbox input[type="checkbox"]:not(.sr-only) ~ label:before,
.fuelux .radio-checkbox input[type="radio"]:not(.sr-only) ~ label:before,
.fuelux .radio-checkbox-inline input[type="checkbox"]:not(.sr-only) ~ label:before,
.fuelux .radio-checkbox-inline input[type="radio"]:not(.sr-only) ~ label:before {
  z-index: 1;
}

.fuelux .radio-checkbox input[type="checkbox"]:not(.sr-only):checked ~ label:before,
.fuelux .radio-checkbox input[type="radio"]:not(.sr-only):checked ~ label:before,
.fuelux .radio-checkbox-inline input[type="checkbox"]:not(.sr-only):checked ~ label:before,
.fuelux .radio-checkbox-inline input[type="radio"]:not(.sr-only):checked ~ label:before {
  font-size: 8px;
  color: #fff;
  line-height: 1em;
  padding-top: 2px;
  padding-left: 1px;
  content: "\f00c";
}

.fuelux .radio-checkbox .radio-checkbox-label,
.fuelux .radio-checkbox-inline .radio-checkbox-label {
  font-size: 14px;
  font-weight: 300;
  font-family: Roboto, sans-serif;
}

.fuelux .radio-checkbox-inline + .radio-checkbox-inline {
  margin-left: 15px;
}

/*--------------------------------------------------
    [Radio Checkbox Button Styles]
----------------------------------------------------*/
/* General */
.fuelux .radio-checkbox-btn {
  position: relative;
  padding-left: 22px;
}

.fuelux .radio-checkbox-btn:before {
  position: absolute;
  top: 3px;
  left: 0;
  width: 15px;
  height: 15px;
  z-index: 2;
  display: inline-block;
  line-height: 1;
  text-align: left;
  speak: none;
  cursor: pointer;
  content: " ";
  padding: 2px;
}

.fuelux .radio-checkbox-btn:active:before, .fuelux .radio-checkbox-btn:focus:before {
  cursor: pointer;
}

.fuelux .radio-checkbox-btn.disabled, .fuelux .radio-checkbox-btn.disabled:before {
  cursor: not-allowed;
  opacity: .65;
}

/* Background Icon */
.fuelux .radio-checkbox-bg-icon:before {
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  font-variant: normal;
  font-family: FontAwesome;
  color: #fff;
  border: 1px solid #e4e8f3;
  padding: 2px;
}

.fuelux .radio-checkbox-bg-icon:active:before, .fuelux .radio-checkbox-bg-icon:focus:before {
  color: #fff;
}

.fuelux .radio-checkbox-bg-icon.checked:before {
  background: #BAF402;
  border-color: #BAF402;
  content: "\f00c";
}

/* Background Color */
.fuelux .radio-checkbox-bg-color:before {
  color: #fff;
  background: #e4e8f3;
}

.fuelux .radio-checkbox-bg-color.checked:before {
  background: #BAF402;
  border-color: #BAF402;
}

/* Border */
.fuelux .radio-checkbox-brd:before {
  border: 3px solid #e4e8f3;
}

.fuelux .radio-checkbox-brd.checked:before {
  border-color: #BAF402;
}

/* Border with Dot */
.fuelux .radio-checkbox-brd-dot:before {
  border: 2px solid #e4e8f3;
}

.fuelux .radio-checkbox-brd-dot:after {
  position: absolute;
  top: 8px;
  left: 5px;
  width: 5px;
  height: 5px;
  z-index: 2;
  display: inline-block;
  speak: none;
  cursor: pointer;
  background: #e4e8f3;
  content: " ";
}

.fuelux .radio-checkbox-brd-dot.checked:before {
  border-color: #BAF402;
}

.fuelux .radio-checkbox-brd-dot.checked:after {
  background: #BAF402;
}

/*--------------------------------------------------
    [Size]
----------------------------------------------------*/
/* Small */
.fuelux .radio-checkbox-btn.radio-checkbox-sm:before {
  top: 0;
  font-size: 11px;
  width: 20px;
  height: 20px;
  padding: 3.5px;
}

.fuelux .radio-checkbox.radio-checkbox-sm .radio-checkbox-btn:before,
.fuelux .radio-checkbox-inline.radio-checkbox-sm .radio-checkbox-btn:before {
  top: 0;
  font-size: 11px;
  width: 20px;
  height: 20px;
  padding: 3.5px;
}

.fuelux .radio-checkbox.radio-checkbox-sm .radio-checkbox-brd-dot:before,
.fuelux .radio-checkbox-inline.radio-checkbox-sm .radio-checkbox-brd-dot:before {
  border-width: 3px;
}

.fuelux .radio-checkbox.radio-checkbox-sm .radio-checkbox-brd-dot:after,
.fuelux .radio-checkbox-inline.radio-checkbox-sm .radio-checkbox-brd-dot:after {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
}

.fuelux .radio-checkbox.radio-checkbox-sm label,
.fuelux .radio-checkbox-inline.radio-checkbox-sm label {
  height: 20px;
}

.fuelux .radio-checkbox.radio-checkbox-sm .radio-checkbox-label,
.fuelux .radio-checkbox-inline.radio-checkbox-sm .radio-checkbox-label {
  font-size: 15px;
}

.fuelux .radio-checkbox.radio-checkbox-sm label {
  padding-left: 29px;
}

.fuelux .radio-checkbox-inline.radio-checkbox-sm {
  padding-left: 29px;
}

/* Medium */
.fuelux .radio-checkbox-btn.radio-checkbox-md:before {
  top: 0;
  font-size: 14px;
  width: 25px;
  height: 25px;
  padding: 4.5px;
}

.fuelux .radio-checkbox.radio-checkbox-md .radio-checkbox-btn:before,
.fuelux .radio-checkbox-inline.radio-checkbox-md .radio-checkbox-btn:before {
  top: 0;
  font-size: 14px;
  width: 25px;
  height: 25px;
  padding: 4.5px;
}

.fuelux .radio-checkbox.radio-checkbox-md .radio-checkbox-brd-dot:before,
.fuelux .radio-checkbox-inline.radio-checkbox-md .radio-checkbox-brd-dot:before {
  border-width: 3px;
}

.fuelux .radio-checkbox.radio-checkbox-md .radio-checkbox-brd-dot:after,
.fuelux .radio-checkbox-inline.radio-checkbox-md .radio-checkbox-brd-dot:after {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 9px;
  height: 9px;
}

.fuelux .radio-checkbox.radio-checkbox-md label,
.fuelux .radio-checkbox-inline.radio-checkbox-md label {
  height: 25px;
}

.fuelux .radio-checkbox.radio-checkbox-md .radio-checkbox-label,
.fuelux .radio-checkbox-inline.radio-checkbox-md .radio-checkbox-label {
  font-size: 16px;
}

.fuelux .radio-checkbox.radio-checkbox-md label {
  padding-left: 33px;
}

.fuelux .radio-checkbox-inline.radio-checkbox-md {
  padding-left: 33px;
}

/*--------------------------------------------------
    [Bootstrap Colors]
----------------------------------------------------*/
/* Primary */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-primary.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-primary.checked:before {
  background: #337ab7;
  border-color: #337ab7;
}

.fuelux .radio-checkbox-brd.radio-checkbox-primary.checked:before {
  border-color: #337ab7;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-primary.checked:before {
  border-color: #337ab7;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-primary.checked:after {
  background: #337ab7;
}

/* Info */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-info.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-info.checked:before {
  background: #5bc0de;
  border-color: #5bc0de;
}

.fuelux .radio-checkbox-brd.radio-checkbox-info.checked:before {
  border-color: #5bc0de;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-info.checked:before {
  border-color: #5bc0de;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-info.checked:after {
  background: #5bc0de;
}

/* Warning */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-warning.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-warning.checked:before {
  background: #5cb85c;
  border-color: #5cb85c;
}

.fuelux .radio-checkbox-brd.radio-checkbox-warning.checked:before {
  border-color: #5cb85c;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-warning.checked:before {
  border-color: #5cb85c;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-warning.checked:after {
  background: #5cb85c;
}

/* Danger */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-danger.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-danger.checked:before {
  background: #f0ad4e;
  border-color: #f0ad4e;
}

.fuelux .radio-checkbox-brd.radio-checkbox-danger.checked:before {
  border-color: #f0ad4e;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-danger.checked:before {
  border-color: #f0ad4e;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-danger.checked:after {
  background: #f0ad4e;
}

/* Success */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-success.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-success.checked:before {
  background: #d9534f;
  border-color: #d9534f;
}

.fuelux .radio-checkbox-brd.radio-checkbox-success.checked:before {
  border-color: #d9534f;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-success.checked:before {
  border-color: #d9534f;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-success.checked:after {
  background: #d9534f;
}

/*--------------------------------------------------
    [Theme Colors]
----------------------------------------------------*/
/* Dark */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-dark.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-dark.checked:before {
  background: #34343c;
  border-color: #34343c;
}

.fuelux .radio-checkbox-brd.radio-checkbox-dark.checked:before {
  border-color: #34343c;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-dark.checked:before {
  border-color: #34343c;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-dark.checked:after {
  background: #34343c;
}

/* Red */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-red.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-red.checked:before {
  background: #e55973;
  border-color: #e55973;
}

.fuelux .radio-checkbox-brd.radio-checkbox-red.checked:before {
  border-color: #e55973;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-red.checked:before {
  border-color: #e55973;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-red.checked:after {
  background: #e55973;
}

/* Pink */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-pink.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-pink.checked:before {
  background: #e91e63;
  border-color: #e91e63;
}

.fuelux .radio-checkbox-brd.radio-checkbox-pink.checked:before {
  border-color: #e91e63;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-pink.checked:before {
  border-color: #e91e63;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-pink.checked:after {
  background: #e91e63;
}

/* Green */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-green.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-green.checked:before {
  background: #BAF402;
  border-color: #BAF402;
}

.fuelux .radio-checkbox-brd.radio-checkbox-green.checked:before {
  border-color: #BAF402;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-green.checked:before {
  border-color: #BAF402;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-green.checked:after {
  background: #BAF402;
}

/* Blue */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-blue.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-blue.checked:before {
  background: #536dfe;
  border-color: #536dfe;
}

.fuelux .radio-checkbox-brd.radio-checkbox-blue.checked:before {
  border-color: #536dfe;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-blue.checked:before {
  border-color: #536dfe;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-blue.checked:after {
  background: #536dfe;
}

/* Teal */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-teal.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-teal.checked:before {
  background: #009688;
  border-color: #009688;
}

.fuelux .radio-checkbox-brd.radio-checkbox-teal.checked:before {
  border-color: #009688;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-teal.checked:before {
  border-color: #009688;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-teal.checked:after {
  background: #009688;
}

/* Blue-Grey */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-blue-grey.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-blue-grey.checked:before {
  background: #546e7a;
  border-color: #546e7a;
}

.fuelux .radio-checkbox-brd.radio-checkbox-blue-grey.checked:before {
  border-color: #546e7a;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-blue-grey.checked:before {
  border-color: #546e7a;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-blue-grey.checked:after {
  background: #546e7a;
}

/* Purple */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-purple.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-purple.checked:before {
  background: #b260ce;
  border-color: #b260ce;
}

.fuelux .radio-checkbox-brd.radio-checkbox-purple.checked:before {
  border-color: #b260ce;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-purple.checked:before {
  border-color: #b260ce;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-purple.checked:after {
  background: #b260ce;
}

/* Gold */
.fuelux .radio-checkbox-bg-icon.radio-checkbox-gold.checked:before,
.fuelux .radio-checkbox-bg-color.radio-checkbox-gold.checked:before {
  background: #c69f73;
  border-color: #c69f73;
}

.fuelux .radio-checkbox-brd.radio-checkbox-gold.checked:before {
  border-color: #c69f73;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-gold.checked:before {
  border-color: #c69f73;
}

.fuelux .radio-checkbox-brd-dot.radio-checkbox-gold.checked:after {
  background: #c69f73;
}

/*------------------------------------------------------------------
    [Scroll To Section Click Button v1]
------------------------------------------------------------------*/
.scroll-to-section-v1 {
  position: absolute;
  left: 50%;
  bottom: 40px;
}

.scroll-to-section-v1 .scroll-to-section-click-icon {
  position: absolute;
  left: 0;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 30px;
  color: #fff;
  margin-left: -10px;
  -webkit-animation-name: scroll-to-section-v1;
  -moz-animation-name: scroll-to-section-v1;
  animation-name: scroll-to-section-v1;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  animation-direction: alternate;
}

@-webkit-keyframes scroll-to-section-v1 {
  0% {
    top: 0;
    bottom: 0;
  }
  25% {
    top: -20px;
    bottom: 25px;
  }
  50% {
    top: -20px;
    bottom: 50px;
  }
  75% {
    top: -20px;
    bottom: 75px;
  }
  100% {
    top: 0;
    bottom: 100px;
  }
}

@-moz-keyframes scroll-to-section-v1 {
  0% {
    top: 0;
    bottom: 0;
  }
  25% {
    top: -20px;
    bottom: 25px;
  }
  50% {
    top: -20px;
    bottom: 50px;
  }
  75% {
    top: -20px;
    bottom: 75px;
  }
  100% {
    top: 0;
    bottom: 100px;
  }
}

@keyframes scroll-to-section-v1 {
  0% {
    top: 0;
    bottom: 0;
  }
  25% {
    top: -20px;
    bottom: 25px;
  }
  50% {
    top: -20px;
    bottom: 50px;
  }
  75% {
    top: -20px;
    bottom: 75px;
  }
  100% {
    top: 0;
    bottom: 100px;
  }
}

/*--------------------------------------------------
    [Segments]
----------------------------------------------------*/
.segment-controls {
  position: relative;
  display: block;
  border: 1px solid #BAF402;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  text-decoration: none;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition-duration: 1000ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Input */
.segment-controls .segment-controls-input {
  position: absolute;
  left: -10000px;
  opacity: 0;
}

.segment-controls .segment-controls-input[type='checkbox'] ~ label {
  transition-duration: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.segment-controls .segment-controls-input[type='checkbox'] ~ label:before {
  opacity: 0;
}

.segment-controls .segment-controls-input:disabled:nth-child(1) ~ label:nth-of-type(1) {
  opacity: 0.3;
  cursor: not-allowed;
}

.segment-controls .segment-controls-input:nth-child(1):checked ~ label:nth-of-type(1):after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:nth-of-type(1):before {
  opacity: 1;
}

.segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1):after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1):before, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1) ~ label:after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(1) ~ label:before {
  left: 0%;
}

.segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2):after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2):before, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:before {
  left: 0%;
}

.segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3):after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3):before, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:before {
  left: 0%;
}

.segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4):after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4):before, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  left: 0%;
}

.segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5):after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5):before, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  left: 0%;
}

.segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6):after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6):before, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segment-controls .segment-controls-input:nth-child(1):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 0%;
}

.segment-controls .segment-controls-input:disabled:nth-child(2) ~ label:nth-of-type(2) {
  opacity: 0.3;
  cursor: not-allowed;
}

.segment-controls .segment-controls-input:nth-child(2):checked ~ label:nth-of-type(2):after, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:nth-of-type(2):before {
  opacity: 1;
}

.segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2):after, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2):before, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:after, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(2) ~ label:before {
  left: 50%;
}

.segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3):after, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3):before, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:after, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:before {
  left: 33.3333333333%;
}

.segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4):after, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4):before, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  left: 25%;
}

.segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5):after, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5):before, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  left: 20%;
}

.segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6):after, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6):before, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segment-controls .segment-controls-input:nth-child(2):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 16.6666666667%;
}

.segment-controls .segment-controls-input:disabled:nth-child(3) ~ label:nth-of-type(3) {
  opacity: 0.3;
  cursor: not-allowed;
}

.segment-controls .segment-controls-input:nth-child(3):checked ~ label:nth-of-type(3):after, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:nth-of-type(3):before {
  opacity: 1;
}

.segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3):after, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3):before, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:after, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(3) ~ label:before {
  left: 66.6666666667%;
}

.segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4):after, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4):before, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  left: 50%;
}

.segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5):after, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5):before, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  left: 40%;
}

.segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6):after, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6):before, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segment-controls .segment-controls-input:nth-child(3):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 33.3333333333%;
}

.segment-controls .segment-controls-input:disabled:nth-child(4) ~ label:nth-of-type(4) {
  opacity: 0.3;
  cursor: not-allowed;
}

.segment-controls .segment-controls-input:nth-child(4):checked ~ label:nth-of-type(4):after, .segment-controls .segment-controls-input:nth-child(4):checked ~ label:nth-of-type(4):before {
  opacity: 1;
}

.segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4):after, .segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4):before, .segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:after, .segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(4) ~ label:before {
  left: 75%;
}

.segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5):after, .segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5):before, .segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after, .segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  left: 60%;
}

.segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6):after, .segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6):before, .segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segment-controls .segment-controls-input:nth-child(4):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 50%;
}

.segment-controls .segment-controls-input:disabled:nth-child(5) ~ label:nth-of-type(5) {
  opacity: 0.3;
  cursor: not-allowed;
}

.segment-controls .segment-controls-input:nth-child(5):checked ~ label:nth-of-type(5):after, .segment-controls .segment-controls-input:nth-child(5):checked ~ label:nth-of-type(5):before {
  opacity: 1;
}

.segment-controls .segment-controls-input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5):after, .segment-controls .segment-controls-input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5):before, .segment-controls .segment-controls-input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:after, .segment-controls .segment-controls-input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(5) ~ label:before {
  left: 80%;
}

.segment-controls .segment-controls-input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6):after, .segment-controls .segment-controls-input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6):before, .segment-controls .segment-controls-input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segment-controls .segment-controls-input:nth-child(5):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 66.6666666667%;
}

.segment-controls .segment-controls-input:disabled:nth-child(6) ~ label:nth-of-type(6) {
  opacity: 0.3;
  cursor: not-allowed;
}

.segment-controls .segment-controls-input:nth-child(6):checked ~ label:nth-of-type(6):after, .segment-controls .segment-controls-input:nth-child(6):checked ~ label:nth-of-type(6):before {
  opacity: 1;
}

.segment-controls .segment-controls-input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6):after, .segment-controls .segment-controls-input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6):before, .segment-controls .segment-controls-input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:after, .segment-controls .segment-controls-input:nth-child(6):checked ~ label:first-of-type:nth-last-of-type(6) ~ label:before {
  left: 83.3333333333%;
}

/* Label */
.segment-controls .segment-controls-label {
  display: inline-block;
  float: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  line-height: 3;
  text-decoration: inherit;
  cursor: pointer;
  padding: 0 25px;
  margin-bottom: 0;
  transition-duration: inherit;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  -webkit-transition: none inherit;
  -moz-transition: none inherit;
  transition: none inherit;
}

.segment-controls .segment-controls-label:before, .segment-controls .segment-controls-label:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.segment-controls .segment-controls-label:before {
  color: inherit;
  background: #BAF402;
  content: " ";
  -webkit-transition: left inherit;
  -moz-transition: left inherit;
  transition: left inherit;
  transition-duration: inherit;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.segment-controls .segment-controls-label:after {
  z-index: 10;
  font-style: inherit;
  font-weight: inherit;
  color: #fff;
  text-align: center;
  content: attr(data-value);
  padding: inherit;
  opacity: 0;
  text-decoration: inherit;
  transition-duration: inherit;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  -webkit-transition: opacity left;
  -moz-transition: opacity left;
  transition: opacity left;
}

.segment-controls .segment-controls-label:after, .segment-controls .segment-controls-label {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.segment-controls .segment-controls-label:first-of-type:nth-last-of-type(1), .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(1):after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(1):before, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(1) ~ label, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(1) ~ label:after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(1) ~ label:before {
  width: 100%;
}

.segment-controls .segment-controls-label:first-of-type:nth-last-of-type(2), .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(2):after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(2):before, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(2) ~ label, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(2) ~ label:after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(2) ~ label:before {
  width: 50%;
}

.segment-controls .segment-controls-label:first-of-type:nth-last-of-type(3), .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(3):after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(3):before, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(3) ~ label, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(3) ~ label:after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(3) ~ label:before {
  width: 33.3333333333%;
}

.segment-controls .segment-controls-label:first-of-type:nth-last-of-type(4), .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(4):after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(4):before, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(4) ~ label, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(4) ~ label:after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(4) ~ label:before {
  width: 25%;
}

.segment-controls .segment-controls-label:first-of-type:nth-last-of-type(5), .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(5):after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(5):before, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(5) ~ label, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(5) ~ label:after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(5) ~ label:before {
  width: 20%;
}

.segment-controls .segment-controls-label:first-of-type:nth-last-of-type(6), .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(6):after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(6):before, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(6) ~ label, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(6) ~ label:after, .segment-controls .segment-controls-label:first-of-type:nth-last-of-type(6) ~ label:before {
  width: 16.6666666667%;
}

/*--------------------------------------------------
    [Size]
----------------------------------------------------*/
/* Extra Small */
.segment-controls.segment-controls-xs .segment-controls-label {
  font-size: 13px;
  line-height: 2.5;
  padding: 0 15px;
}

/* Small */
.segment-controls.segment-controls-sm .segment-controls-label {
  font-size: 14px;
  line-height: 3;
  padding: 0 20px;
}

/*--------------------------------------------------
    [Color Options]
----------------------------------------------------*/
/* Color Dark */
.segment-controls.segment-controls-dark {
  border-color: #34343c;
}

.segment-controls.segment-controls-dark .segment-controls-label:before {
  background: #34343c;
}

/* Color Pink */
.segment-controls.segment-controls-pink {
  border-color: #e91e63;
}

.segment-controls.segment-controls-pink .segment-controls-label:before {
  background: #e91e63;
}

/* Color Purple */
.segment-controls.segment-controls-purple {
  border-color: #b260ce;
}

.segment-controls.segment-controls-purple .segment-controls-label:before {
  background: #b260ce;
}

/* Color Green */
.segment-controls.segment-controls-green {
  border-color: #BAF402;
}

.segment-controls.segment-controls-green .segment-controls-label:before {
  background: #BAF402;
}

/* Color Red */
.segment-controls.segment-controls-red {
  border-color: #e55973;
}

.segment-controls.segment-controls-red .segment-controls-label:before {
  background: #e55973;
}

/* Color Teal */
.segment-controls.segment-controls-teal {
  border-color: #009688;
}

.segment-controls.segment-controls-teal .segment-controls-label:before {
  background: #009688;
}

/* Color Blue */
.segment-controls.segment-controls-blue {
  border-color: #536dfe;
}

.segment-controls.segment-controls-blue .segment-controls-label:before {
  background: #536dfe;
}

/* Color Blue Grey */
.segment-controls.segment-controls-blue-grey {
  border-color: #546e7a;
}

.segment-controls.segment-controls-blue-grey .segment-controls-label:before {
  background: #546e7a;
}

/* Color Grey */
.segment-controls.segment-controls-grey {
  border-color: #9e9e9e;
}

.segment-controls.segment-controls-grey .segment-controls-label:before {
  background: #9e9e9e;
}

/* Color Gold */
.segment-controls.segment-controls-gold {
  border-color: #c69f73;
}

.segment-controls.segment-controls-gold .segment-controls-label:before {
  background: #c69f73;
}

/*--------------------------------------------------
    [Sliding Background]
----------------------------------------------------*/
.sliding-bg {
  position: relative;
  overflow: hidden;
}

.sliding-bg.sliding-bg-semi-dark:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.9);
  content: " ";
}

.sliding-bg.sliding-bg-semi-white:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  content: " ";
}

.sliding-bg .sliding-bg-img {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center center;
}

.sliding-bg .sliding-bg-title {
  font-size: 100px;
  font-style: italic;
  font-weight: 400;
  font-family: Droid Serif, serif;
  letter-spacing: 10px;
  line-height: 1;
  margin-bottom: 10px;
}

.sliding-bg .sliding-bg-subtitle {
  display: block;
  font-size: 36px;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 10px;
}

.sliding-bg .sliding-bg-text {
  font-size: 20px;
}

/* Sliding Background Left */
.sliding-bg .sliding-bg-left {
  -webkit-animation-name: sliding-bg-left;
  -moz-animation-name: sliding-bg-left;
  animation-name: sliding-bg-left;
  -webkit-animation-duration: 200s;
  -moz-animation-duration: 200s;
  animation-duration: 200s;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Keyframes - Sliding Left */
@-webkit-keyframes sliding-bg-left {
  100% {
    background-position: -3840px 0;
  }
}

@-moz-keyframes sliding-bg-left {
  100% {
    background-position: -3840px 0;
  }
}

@keyframes sliding-bg-left {
  100% {
    background-position: -3840px 0;
  }
}

/* Sliding Background Right */
.sliding-bg .sliding-bg-right {
  -webkit-animation-name: sliding-bg-right;
  -moz-animation-name: sliding-bg-right;
  animation-name: sliding-bg-right;
  -webkit-animation-duration: 200s;
  -moz-animation-duration: 200s;
  animation-duration: 200s;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Keyframes - Sliding Right */
@-webkit-keyframes sliding-bg-right {
  100% {
    background-position: 3840px 0;
  }
}

@-moz-keyframes sliding-bg-right {
  100% {
    background-position: 3840px 0;
  }
}

@keyframes sliding-bg-right {
  100% {
    background-position: 3840px 0;
  }
}

/* Sliding Background Top */
.sliding-bg .sliding-bg-top {
  -webkit-animation-name: sliding-bg-top;
  -moz-animation-name: sliding-bg-top;
  animation-name: sliding-bg-top;
  -webkit-animation-duration: 200s;
  -moz-animation-duration: 200s;
  animation-duration: 200s;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Keyframes - Sliding Top */
@-webkit-keyframes sliding-bg-top {
  100% {
    background-position: 0 -1000px;
  }
}

@-moz-keyframes sliding-bg-top {
  100% {
    background-position: 0 -1000px;
  }
}

@keyframes sliding-bg-top {
  100% {
    background-position: 0 -1000px;
  }
}

/* Sliding Background Bottom */
.sliding-bg .sliding-bg-bottom {
  -webkit-animation-name: sliding-bg-bottom;
  -moz-animation-name: sliding-bg-bottom;
  animation-name: sliding-bg-bottom;
  -webkit-animation-duration: 200s;
  -moz-animation-duration: 200s;
  animation-duration: 200s;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Keyframes - Sliding Bottom */
@-webkit-keyframes sliding-bg-bottom {
  100% {
    background-position: 0 1000px;
  }
}

@-moz-keyframes sliding-bg-bottom {
  100% {
    background-position: 0 1000px;
  }
}

@keyframes sliding-bg-bottom {
  100% {
    background-position: 0 1000px;
  }
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .sliding-bg .sliding-bg-title {
    font-size: 70px;
  }
  .sliding-bg .sliding-bg-subtitle {
    font-size: 30px;
  }
  .sliding-bg .sliding-bg-text {
    font-size: 18px;
  }
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .sliding-bg .sliding-bg-title {
    font-size: 50px;
  }
  .sliding-bg .sliding-bg-subtitle {
    font-size: 26px;
  }
  .sliding-bg .sliding-bg-text {
    font-size: 16px;
  }
}

/*--------------------------------------------------
    [Tab v1]
----------------------------------------------------*/
.tab-v1 .nav-tabs {
  width: 100%;
  display: inline-block;
  background: #fff;
  border-bottom: none;
  box-shadow: 1px 2px 7px 0 #eff1f8;
  margin-bottom: 15px;
}

.tab-v1 .nav-tabs > li {
  margin: 0;
}

.tab-v1 .nav-tabs > li:first-child {
  margin-left: 0;
}

.tab-v1 .nav-tabs > li > a {
  font-size: 15px;
  color: #34343c;
  border-radius: 0;
  border: none;
  padding: 15px 20px;
  margin-right: 0;
}

.tab-v1 .nav-tabs > li:active > a, .tab-v1 .nav-tabs > li:focus > a, .tab-v1 .nav-tabs > li:hover > a {
  background: inherit;
}

.tab-v1 .nav-tabs > li:hover > a {
  color: #BAF402;
  border: none;
}

.tab-v1 .nav-tabs > li.active > a {
  color: #BAF402;
  border: none;
}

.tab-v1 .tab-pane {
  background: #fff;
  font-size: 15px;
  color: #606060;
  box-shadow: 1px 2px 7px 0 #eff1f8;
  padding: 20px;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .tab-v1 .nav-tabs {
    display: block;
    width: 100%;
  }
  .tab-v1 .nav-tabs > li {
    width: 100%;
    float: none;
    margin: 5px 0 0;
  }
  .tab-v1 .nav-tabs > li:first-child {
    margin-top: 0;
  }
}

/*--------------------------------------------------
    [Tab v2]
----------------------------------------------------*/
.tab-v2 .nav-tabs {
  display: inline-block;
  border-bottom: none;
}

.tab-v2 .nav-tabs > li {
  margin-left: 20px;
}

.tab-v2 .nav-tabs > li:first-child {
  margin-left: 0;
}

.tab-v2 .nav-tabs > li > a {
  font-size: 15px;
  color: #9e9e9e;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  padding: 8px 20px;
}

.tab-v2 .nav-tabs > li:active > a, .tab-v2 .nav-tabs > li:focus > a, .tab-v2 .nav-tabs > li:hover > a {
  background: inherit;
}

.tab-v2 .nav-tabs > li:hover > a {
  color: #34343c;
  border-bottom-color: #c9c9c9;
}

.tab-v2 .nav-tabs > li.active > a {
  color: #34343c;
  background: transparent;
  border: none;
  border-bottom: 1px solid #34343c;
}

.tab-v2 .tab-pane {
  font-size: 15px;
  color: #606060;
  padding: 20px 0;
}

.tab-v2 .tab-pane.tab-pane-space-around {
  padding: 20px;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .tab-v2 .nav-tabs {
    display: block;
    width: 100%;
  }
  .tab-v2 .nav-tabs > li {
    width: 100%;
    float: none;
    margin: 5px 0 0;
  }
  .tab-v2 .nav-tabs > li:first-child {
    margin-top: 0;
  }
}

/*--------------------------------------------------
    [Tab v3]
----------------------------------------------------*/
.tab-v3 {
  position: relative;
}

.tab-v3 .nav-tabs-wrap {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  text-align: center;
  padding: 10px 0;
}

.tab-v3 .nav-tabs {
  display: inline-block;
  border-bottom: none;
  margin-bottom: -5px;
}

.tab-v3 .nav-tabs > li {
  margin: 0 1px 0;
}

.tab-v3 .nav-tabs > li > a {
  font-size: 15px;
  color: #34343c;
  background: #fff;
  border: none;
  border-radius: 3px;
  padding: 8px 20px;
}

.tab-v3 .nav-tabs > li:active > a, .tab-v3 .nav-tabs > li:focus > a, .tab-v3 .nav-tabs > li:hover > a {
  background: inherit;
}

.tab-v3 .nav-tabs > li:hover > a {
  color: #BAF402;
  background: #fff;
}

.tab-v3 .nav-tabs > li.active > a {
  color: #fff;
  background: #BAF402;
  border: none;
}

.tab-v3 .tab-pane {
  text-align: left;
  border: 1px solid #ebeef6;
}

.tab-v3 .tab-pane .tab-pane-content {
  font-size: 15px;
  color: #606060;
  background: #fff;
  padding: 20px;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .tab-v3 .nav-tabs-wrap {
    position: static;
  }
  .tab-v3 .nav-tabs {
    display: block;
    width: 100%;
  }
  .tab-v3 .nav-tabs > li {
    width: 100%;
    float: none;
    margin: 5px 0 0;
  }
  .tab-v3 .nav-tabs > li:first-child {
    margin-top: 0;
  }
  .tab-v3 .nav-tabs > li > a {
    margin-right: 0;
  }
}

/*--------------------------------------------------
    [Tab v4]
----------------------------------------------------*/
.tab-v4 {
  overflow: hidden;
}

.tab-v4 .nav-tabs {
  border-bottom: none;
  background: #BAF402;
  padding: 7px 0;
}

.tab-v4 .nav-tabs.nav-tabs-left {
  float: left;
}

.tab-v4 .nav-tabs.nav-tabs-left > li {
  margin: 0;
  float: none;
}

.tab-v4 .nav-tabs.nav-tabs-left > li > a {
  min-width: 170px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 0;
  padding: 12px 20px;
  margin-right: 0;
}

.tab-v4 .nav-tabs.nav-tabs-left > li:active > a, .tab-v4 .nav-tabs.nav-tabs-left > li:focus > a, .tab-v4 .nav-tabs.nav-tabs-left > li:hover > a {
  background: inherit;
}

.tab-v4 .nav-tabs.nav-tabs-left > li:hover > a {
  color: #fff;
  background: #9bcc02;
}

.tab-v4 .nav-tabs.nav-tabs-left > li.active {
  border: none;
}

.tab-v4 .nav-tabs.nav-tabs-left > li.active > a {
  color: #fff;
  background: #9bcc02;
}

.tab-v4 .tab-content {
  overflow: hidden;
}

.tab-v4 .tab-content .tab-pane {
  font-size: 15px;
  color: #606060;
  background: #fff;
  padding: 0;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .tab-v4 .nav-tabs.nav-tabs-left {
    float: none;
  }
}

/*--------------------------------------------------
    [Tab v5 (Left)]
----------------------------------------------------*/
.tab-v5 {
  overflow: hidden;
  border: 1px solid #f7f8fa;
}

.tab-v5 .nav-tabs {
  border-bottom: none;
}

.tab-v5 .nav-tabs.nav-tabs-left {
  float: left;
}

.tab-v5 .nav-tabs.nav-tabs-left > li {
  margin: 0;
  float: none;
}

.tab-v5 .nav-tabs.nav-tabs-left > li > a {
  font-size: 15px;
  color: #34343c;
  background: #f7f8fa;
  border-color: transparent;
  border-radius: 0;
  padding: 12px 20px;
}

.tab-v5 .nav-tabs.nav-tabs-left > li:active > a, .tab-v5 .nav-tabs.nav-tabs-left > li:focus > a, .tab-v5 .nav-tabs.nav-tabs-left > li:hover > a {
  color: #3E5101 !important;
  background: inherit;
}

.tab-v5 .nav-tabs.nav-tabs-left > li:hover > a {
  color: #3E5101 !important;
}

.tab-v5 .nav-tabs.nav-tabs-left > li.active:first-child > a {
  border-top-color: transparent;
}

.tab-v5 .nav-tabs.nav-tabs-left > li.active:last-child > a {
  border-bottom-color: transparent;
}

.tab-v5 .nav-tabs.nav-tabs-left > li.active > a {
  color: #3E5101 !important;
  background: #fff;
  border-top: 1px solid #f7f8fa;
  border-bottom: 1px solid #f7f8fa;
}

.tab-v5 .tab-content {
  overflow: hidden;
}

.tab-v5 .tab-content .tab-pane {
  font-size: 15px;
  color: #606060;
  background: #fff;
  padding: 20px 25px;
}

/*--------------------------------------------------
    [Tab v6 (Right)]
----------------------------------------------------*/
.tab-v6 {
  overflow: hidden;
  border: 1px solid #f7f8fa;
}

.tab-v6 .nav-tabs {
  border-bottom: none;
}

.tab-v6 .nav-tabs.nav-tabs-right {
  float: right;
}

.tab-v6 .nav-tabs.nav-tabs-right > li {
  margin: 0;
  float: none;
}

.tab-v6 .nav-tabs.nav-tabs-right > li > a {
  font-size: 15px;
  color: #34343c;
  background: #f7f8fa;
  border-color: transparent;
  border-radius: 0;
  padding: 12px 20px;
  margin-right: 0;
}

.tab-v6 .nav-tabs.nav-tabs-right > li:active > a, .tab-v6 .nav-tabs.nav-tabs-right > li:focus > a, .tab-v6 .nav-tabs.nav-tabs-right > li:hover > a {
  background: inherit;
}

.tab-v6 .nav-tabs.nav-tabs-right > li:hover > a {
  color: #BAF402;
}

.tab-v6 .nav-tabs.nav-tabs-right > li.active:first-child > a {
  border-top-color: transparent;
}

.tab-v6 .nav-tabs.nav-tabs-right > li.active:last-child > a {
  border-bottom-color: transparent;
}

.tab-v6 .nav-tabs.nav-tabs-right > li.active > a {
  color: #BAF402;
  background: #fff;
  border-top: 1px solid #f7f8fa;
  border-bottom: 1px solid #f7f8fa;
}

.tab-v6 .tab-content {
  overflow: hidden;
}

.tab-v6 .tab-content .tab-pane {
  font-size: 15px;
  color: #606060;
  background: #fff;
  padding: 20px 25px;
}

/*--------------------------------------------------
    [Tab v7]
----------------------------------------------------*/
.tab-v7 .nav-tabs {
  border-bottom: none;
}

.tab-v7 .nav-tabs > li {
  margin: 0 -1px -2px;
}

.tab-v7 .nav-tabs > li:first-child {
  margin-left: 0;
}

.tab-v7 .nav-tabs > li > a {
  font-size: 15px;
  color: #fff;
  background: #34343c;
  border-radius: 0;
  border-color: #f7f8fa;
  padding: 12px 22px;
}

.tab-v7 .nav-tabs > li:active > a, .tab-v7 .nav-tabs > li:focus > a, .tab-v7 .nav-tabs > li:hover > a {
  background: inherit;
}

.tab-v7 .nav-tabs > li:hover > a {
  color: #fff;
  background: #34343c;
}

.tab-v7 .nav-tabs > li.active > a {
  color: #34343c;
  background: #fff;
  border-top: 2px solid #34343c;
  border-bottom: none;
}

.tab-v7 .tab-pane {
  font-size: 15px;
  color: #606060;
  border: 1px solid #f7f8fa;
  padding: 20px;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .tab-v7 .nav-tabs {
    display: block;
    width: 100%;
  }
  .tab-v7 .nav-tabs > li {
    width: 100%;
    float: none;
    margin: 5px 0 0;
  }
  .tab-v7 .nav-tabs > li:first-child {
    margin-top: 0;
  }
  .tab-v7 .nav-tabs > li > a {
    margin-right: 0;
  }
}

/*--------------------------------------------------
    [Tab v8]
----------------------------------------------------*/
.tab-v8 .nav-tabs {
  border-bottom: none;
}

.tab-v8 .nav-tabs > li {
  width: 50%;
}

.tab-v8 .nav-tabs > li > a {
  font-size: 15px;
  color: #34343c;
  background: #f7f8fa;
  border-radius: 0;
  border: none;
  padding: 12px 22px;
}

.tab-v8 .nav-tabs > li:active > a, .tab-v8 .nav-tabs > li:focus > a, .tab-v8 .nav-tabs > li:hover > a {
  background: inherit;
}

.tab-v8 .nav-tabs > li:hover > a {
  color: #fff;
  background: #34343c;
}

.tab-v8 .nav-tabs > li.active > a {
  color: #fff;
  background: #34343c;
  border: none;
}

.tab-v8 .tab-pane {
  font-size: 15px;
  color: #606060;
  padding: 20px 0;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .tab-v8 .nav-tabs {
    display: block;
    width: 100%;
  }
  .tab-v8 .nav-tabs > li {
    width: 100%;
    float: none;
    margin-top: 5px;
  }
  .tab-v8 .nav-tabs > li:first-child {
    margin-top: 0;
  }
}

/*--------------------------------------------------
    [Tables]
----------------------------------------------------*/
/* General Stylesheet Design */
.table {
  margin-bottom: 0;
}

.table thead tr th {
  font-weight: 300;
  color: #3a3a44;
  border-top: none;
  border-width: 1px;
  padding: 10px;
}

.table thead tr > td.active,
.table thead tr > th.active {
  background: #f7f8fa;
}

.table thead tr.active > td,
.table thead tr.active > th {
  background: #f7f8fa;
}

.table tbody tr td {
  font-weight: 300;
  color: #3a3a44;
  border-top: none;
  vertical-align: middle;
  padding: 10px;
}

.table tbody tr > td.active,
.table tbody tr > th.active {
  background: #f7f8fa;
}

.table tbody tr.active > td,
.table tbody tr.active > th {
  background: #f7f8fa;
}

.table tbody tr:nth-of-type(odd) {
  background: #f7f8fa;
}

.table tfoot tr > td.active,
.table tfoot tr > th.active {
  background: #f7f8fa;
}

.table tfoot tr.active > td,
.table tfoot tr.active > th {
  background: #f7f8fa;
}

/* Table Advance */
.table-advance {
  overflow: hidden;
}

.table-advance .table-advance-body .table-advance-body-user-img {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.table-advance .table-advance-body .table-advance-body-user-name {
  position: relative;
  top: 2px;
  color: #34343c;
}

.table-advance .table-advance-element .progress {
  position: relative;
  top: 11px;
}

/* Table Bordered */
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-color: #ebeef6;
}

/* Table Responsive */
.table-responsive {
  border: none;
}

/*--------------------------------------------------
    [Table Wrap]
----------------------------------------------------*/
.table-wrap {
  background: #fff;
  border: 1px solid #f2f4f9;
}

.table-wrap .table-wrap-header {
  padding: 15px 20px;
  border-bottom: 1px solid #ebeef6;
}

.table-wrap .table-wrap-header .table-wrap-header-title {
  float: left;
  font-size: 18px;
  margin: 8px 0 0;
}

.table-wrap .table-wrap-header-tools {
  position: relative;
  top: 2px;
  text-align: right;
  margin-bottom: 0;
}

.table-wrap .table-wrap-header-tools .table-wrap-header-tools-item {
  padding: 0;
}

.table-wrap .table-wrap-body {
  padding: 20px;
}

/*------------------------------------------------------------------
    [Theme Icons]
------------------------------------------------------------------*/
.theme-icons-wrap {
  z-index: 1;
}

.theme-icons-wrap .theme-icons {
  text-align: center;
  margin-bottom: 5px;
}

.theme-icons-wrap a[href]:hover .theme-icons {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* Theme Icons Base */
.theme-icons-base {
  display: inline-block;
  color: #BAF402;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-base-hover:hover {
  color: #fff;
  background: #BAF402;
  border-color: transparent;
}

/* Theme Icons White */
.theme-icons-white {
  display: inline-block;
  color: #fff;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-white-hover {
  color: #fff;
}

.theme-icons-wrap > a[href] > .theme-icons-white-hover:hover {
  color: #34343c;
  background: #fff;
  border-color: transparent;
}

/* Theme Icons Dark */
.theme-icons-dark {
  display: inline-block;
  color: #3a3a44;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-dark-hover:hover {
  color: #fff;
  background: #34343c;
  border-color: #34343c;
}

/* Theme Icons Dark Light */
.theme-icons-dark-light {
  display: inline-block;
  color: #3a3a44;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-dark-light-hover:hover {
  color: #fff;
  background: #3a3a44;
  border-color: #3a3a44;
}

/* Theme Icons Blue */
.theme-icons-blue {
  display: inline-block;
  color: #3a3a44;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-blue-hover:hover {
  color: #fff;
  background: #536dfe;
  border-color: transparent;
}

/* Theme Icons Blue Grey */
.theme-icons-blue-grey {
  display: inline-block;
  color: #3a3a44;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-blue-grey-hover:hover {
  color: #fff;
  background: #546e7a;
  border-color: #546e7a;
}

/* Theme Icons Grey */
.theme-icons-grey {
  display: inline-block;
  color: #3a3a44;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-grey-hover:hover {
  color: #fff;
  background: #9e9e9e;
  border-color: transparent;
}

/* Theme Icons Teal */
.theme-icons-teal {
  display: inline-block;
  color: #3a3a44;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-teal-hover:hover {
  color: #fff;
  background: #009688;
  border-color: transparent;
}

/* Theme Icons Red */
.theme-icons-red {
  display: inline-block;
  color: #3a3a44;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-red-hover:hover {
  color: #fff;
  background: #e55973;
  border-color: transparent;
}

/* Theme Icons Red */
.theme-icons-pink {
  display: inline-block;
  color: #3a3a44;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-pink-hover:hover {
  color: #fff;
  background: #e91e63;
  border-color: transparent;
}

/* Theme Icons Purple */
.theme-icons-purple {
  display: inline-block;
  color: #3a3a44;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-purple-hover:hover {
  color: #fff;
  background: #b260ce;
  border-color: transparent;
}

/* Theme Icons Gold */
.theme-icons-gold {
  display: inline-block;
  color: #3a3a44;
  background: transparent;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-gold-hover:hover {
  color: #fff;
  background: #c69f73;
  border-color: transparent;
}

/*------------------------------------------------------------------
    [Theme Icons Background Color]
------------------------------------------------------------------*/
/* Theme Icons Base Background */
.theme-icons-base-bg {
  display: inline-block;
  color: #fff;
  background: #BAF402;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-base-bg:hover {
  color: #fff;
  background: #4ed7e8;
  border-color: transparent;
}

/* Theme Icons White Background */
.theme-icons-white-bg {
  display: inline-block;
  color: #3a3a44;
  background: #fff;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-white-bg:hover {
  color: #fff;
  background: #BAF402;
  border-color: transparent;
}

/* Theme Icons Dark Background */
.theme-icons-dark-bg {
  display: inline-block;
  color: #fff;
  background: #34343c;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-dark-bg:hover {
  color: #fff;
  background: #3a3a44;
  border-color: transparent;
}

/* Theme Icons Dark Light Background */
.theme-icons-dark-light-bg {
  display: inline-block;
  color: #fff;
  background: #3a3a44;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-dark-light-bg:hover {
  color: #fff;
  background: #34343c;
  border-color: transparent;
}

/* Theme Icons Blue Background */
.theme-icons-blue-bg {
  display: inline-block;
  color: #fff;
  background: #536dfe;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-blue-bg:hover {
  color: #fff;
  background: #687ffa;
  border-color: transparent;
}

/* Theme Icons Blue Grey Background */
.theme-icons-blue-grey-bg {
  display: inline-block;
  color: #fff;
  background: #546e7a;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-blue-grey-bg:hover {
  color: #fff;
  background: #6b8997;
  border-color: transparent;
}

/* Theme Icons Grey Background */
.theme-icons-grey-bg {
  display: inline-block;
  color: #fff;
  background: #6a6969;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-grey-bg:hover {
  color: #fff;
  background: #f1f1f1;
  border-color: transparent;
}

/* Theme Icons Teal Background */
.theme-icons-teal-bg {
  display: inline-block;
  color: #fff;
  background: #009688;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-teal-bg:hover {
  color: #fff;
  background: #45b4a9;
  border-color: transparent;
}

/* Theme Icons Red Background */
.theme-icons-red-bg {
  display: inline-block;
  color: #fff;
  background: #e55973;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-red-bg:hover {
  color: #fff;
  background: #e57287;
  border-color: transparent;
}

/* Theme Icons Pink Background */
.theme-icons-pink-bg {
  display: inline-block;
  color: #fff;
  background: #e91e63;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-pink-bg:hover {
  color: #fff;
  background: #ec407a;
  border-color: transparent;
}

/* Theme Icons Purple Background */
.theme-icons-purple-bg {
  display: inline-block;
  color: #fff;
  background: #b260ce;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-purple-bg:hover {
  color: #fff;
  background: #ba75d1;
  border-color: transparent;
}

/* Theme Icons Gold Background */
.theme-icons-gold-bg {
  display: inline-block;
  color: #fff;
  background: #c69f73;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-gold-bg:hover {
  color: #fff;
  background: #d3af87;
  border-color: transparent;
}

/* Theme Icons Grey Light Background */
.theme-icons-grey-light-bg {
  display: inline-block;
  color: #606060;
  background: #f1f1f1;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-grey-light-bg:hover {
  color: #fff;
  background: #BAF402;
  border-color: transparent;
}

/*------------------------------------------------------------------
    [Theme Icons Bordered Color]
------------------------------------------------------------------*/
/* Theme Icons Base Bordered */
.theme-icons-base-brd {
  display: inline-block;
  color: #BAF402;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #BAF402;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-base-brd:hover {
  color: #fff;
  background: #BAF402;
  border-color: #BAF402;
}

/* Theme Icons White Bordered */
.theme-icons-white-brd {
  display: inline-block;
  color: white;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-white-brd:hover {
  color: #3a3a44;
  background: #fff;
  border-color: #fff;
}

.theme-icons-wrap .theme-icons-white-brd-hover-base:hover {
  color: #3a3a44;
  background: #fff;
}

/* Theme Icons Dark Bordered */
.theme-icons-dark-brd {
  display: inline-block;
  color: #34343c;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #34343c;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-dark-brd:hover {
  color: #fff;
  background: #34343c;
  border-color: #34343c;
}

/* Theme Icons Dark Light Bordered */
.theme-icons-dark-light-brd {
  display: inline-block;
  color: #3a3a44;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #3a3a44;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-dark-light-brd:hover {
  color: #fff;
  background: #3a3a44;
  border-color: #3a3a44;
}

/* Theme Icons Blue Bordered */
.theme-icons-blue-brd {
  display: inline-block;
  color: #536dfe;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #536dfe;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-blue-brd:hover {
  color: #fff;
  background: #536dfe;
  border-color: #536dfe;
}

/* Theme Icons Blue Grey Bordered */
.theme-icons-blue-grey-brd {
  display: inline-block;
  color: #546e7a;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #546e7a;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-blue-grey-brd:hover {
  color: #fff;
  background: #546e7a;
  border-color: #546e7a;
}

/* Theme Icons Grey Bordered */
.theme-icons-grey-brd {
  display: inline-block;
  color: #9e9e9e;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #9e9e9e;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-grey-brd:hover {
  color: #fff;
  background: #9e9e9e;
  border-color: #9e9e9e;
}

/* Theme Icons Teal Bordered */
.theme-icons-teal-brd {
  display: inline-block;
  color: #009688;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #009688;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-teal-brd:hover {
  color: #fff;
  background: #009688;
  border-color: #009688;
}

/* Theme Icons Red Bordered */
.theme-icons-red-brd {
  display: inline-block;
  color: #e55973;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #e55973;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-red-brd:hover {
  color: #fff;
  background: #e55973;
  border-color: #e55973;
}

/* Theme Icons Pink Bordered */
.theme-icons-pink-brd {
  display: inline-block;
  color: #e91e63;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #e91e63;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-pink-brd:hover {
  color: #fff;
  background: #e91e63;
  border-color: #e91e63;
}

/* Theme Icons Purple Bordered */
.theme-icons-purple-brd {
  display: inline-block;
  color: #b260ce;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #b260ce;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-purple-brd:hover {
  color: #fff;
  background: #b260ce;
  border-color: #b260ce;
}

/* Theme Icons Gold Bordered */
.theme-icons-gold-brd {
  display: inline-block;
  color: #c69f73;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #c69f73;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-gold-brd:hover {
  color: #fff;
  background: #c69f73;
  border-color: #c69f73;
}

/*------------------------------------------------------------------
    [Theme Icons Sizes]
------------------------------------------------------------------*/
/* Extra Small Size */
.theme-icons-xs {
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 1.4;
  padding: 5px;
}

/* Small Size */
.theme-icons-sm {
  width: 35px;
  height: 35px;
  font-size: 16px;
  line-height: 1.4;
  padding: 6px;
}

/* Medium Size */
.theme-icons-md {
  width: 45px;
  height: 45px;
  font-size: 20px;
  line-height: 1.5;
  padding: 8px;
}

/* Large Size */
.theme-icons-lg {
  width: 55px;
  height: 55px;
  font-size: 24px;
  line-height: 1.15;
  padding: 14px;
}

/*------------------------------------------------------------------
    [Social Icons Theme]
------------------------------------------------------------------*/
/* Theme Icons Facebook */
.theme-icons-fb {
  display: inline-block;
  color: #fff;
  background: #44619d;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

/* Theme Icons Twitter */
.theme-icons-tw {
  display: inline-block;
  color: #fff;
  background: #55acee;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

/* Theme Icons Dribbble */
.theme-icons-dr {
  display: inline-block;
  color: #fff;
  background: #ea4c89;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

/* Theme Icons Youtube */
.theme-icons-yt {
  display: inline-block;
  color: #fff;
  background: #cc181e;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

/*------------------------------------------------------------------
    [Animate Theme Icons]
------------------------------------------------------------------*/
.animate-theme-icons .animate-theme-icons-body {
  z-index: 1;
  display: inline-block;
  text-align: center;
  overflow: hidden;
}

.animate-theme-icons:hover .animate-theme-icons-body .animate-theme-icons-element {
  -webkit-animation: toTopFromBottom 0.3s forwards;
  -moz-animation: toTopFromBottom 0.3s forwards;
  animation: toTopFromBottom 0.3s forwards;
}

@-webkit-keyframes toTopFromBottom {
  49% {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toTopFromBottom {
  49% {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toTopFromBottom {
  49% {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  51% {
    opacity: 1;
  }
}

/*------------------------------------------------------------------
    [Animate Theme Icons Colors]
------------------------------------------------------------------*/
/* Animate Theme Icons Base */
.animate-theme-icons .animate-theme-icons-base {
  color: #BAF402;
}

/* Animate Theme Icons White */
.animate-theme-icons .animate-theme-icons-white {
  color: #fff;
}

/* Animate Theme Icons Dark */
.animate-theme-icons .animate-theme-icons-dark {
  color: #34343c;
}

/* Animate Theme Icons Dark Light */
.animate-theme-icons .animate-theme-icons-dark-light {
  color: #3a3a44;
}

/* Animate Theme Icons Blue */
.animate-theme-icons .animate-theme-icons-blue {
  color: #536dfe;
}

/* Animate Theme Icons Blue Grey */
.animate-theme-icons .animate-theme-icons-blue-grey {
  color: #546e7a;
}

/* Animate Theme Icons Grey */
.animate-theme-icons .animate-theme-icons-grey {
  color: #9e9e9e;
}

/* Animate Theme Icons Teal */
.animate-theme-icons .animate-theme-icons-teal {
  color: #009688;
}

/* Animate Theme Icons Red */
.animate-theme-icons .animate-theme-icons-red {
  color: #e55973;
}

/* Animate Theme Icons Pink */
.animate-theme-icons .animate-theme-icons-pink {
  color: #e91e63;
}

/* Animate Theme Icons Purple */
.animate-theme-icons .animate-theme-icons-purple {
  color: #b260ce;
}

/* Animate Theme Icons Gold */
.animate-theme-icons .animate-theme-icons-gold {
  color: #c69f73;
}

/*------------------------------------------------------------------
    [Animate Theme Icons Background Colors]
------------------------------------------------------------------*/
/* Animate Theme Icons Base Background */
.animate-theme-icons .animate-theme-icons-base-bg {
  color: #fff;
  background: #BAF402;
}

/* Animate Theme Icons White Background */
.animate-theme-icons .animate-theme-icons-white-bg {
  color: #3a3a44;
  background: #fff;
}

/* Animate Theme Icons Dark Background */
.animate-theme-icons .animate-theme-icons-dark-bg {
  color: #fff;
  background: #34343c;
}

/* Animate Theme Icons Dark Light Background */
.animate-theme-icons .animate-theme-icons-dark-light-bg {
  color: #fff;
  background: #3a3a44;
}

/* Animate Theme Icons Blue Background */
.animate-theme-icons .animate-theme-icons-blue-bg {
  color: #fff;
  background: #536dfe;
}

/* Animate Theme Icons Blue Grey Background */
.animate-theme-icons .animate-theme-icons-blue-grey-bg {
  color: #fff;
  background: #546e7a;
}

/* Animate Theme Icons Grey Background */
.animate-theme-icons .animate-theme-icons-grey-bg {
  color: #fff;
  background: #9e9e9e;
}

/* Animate Theme Icons Teal Background */
.animate-theme-icons .animate-theme-icons-teal-bg {
  color: #fff;
  background: #009688;
}

/* Animate Theme Icons Red Background */
.animate-theme-icons .animate-theme-icons-red-bg {
  color: #fff;
  background: #e55973;
}

/* Animate Theme Icons Pink Background */
.animate-theme-icons .animate-theme-icons-pink-bg {
  color: #fff;
  background: #e91e63;
}

/* Animate Theme Icons Purple Background */
.animate-theme-icons .animate-theme-icons-purple-bg {
  color: #fff;
  background: #b260ce;
}

/* Animate Theme Icons Gold Background */
.animate-theme-icons .animate-theme-icons-gold-bg {
  color: #fff;
  background: #c69f73;
}

/*------------------------------------------------------------------
    [Animate Theme Icons Bordered]
------------------------------------------------------------------*/
/* Animate Theme Icons Base Bordered */
.animate-theme-icons .animate-theme-icons-base-brd {
  color: #BAF402;
  border: 1px solid #BAF402;
}

/* Animate Theme Icons White Bordered */
.animate-theme-icons .animate-theme-icons-white-brd {
  color: #fff;
  border: 1px solid #fff;
}

/* Animate Theme Icons Dark Bordered */
.animate-theme-icons .animate-theme-icons-dark-brd {
  color: #34343c;
  border: 1px solid #34343c;
}

/* Animate Theme Icons Dark Light Bordered */
.animate-theme-icons .animate-theme-icons-dark-light-brd {
  color: #3a3a44;
  border: 1px solid #3a3a44;
}

/* Animate Theme Icons Blue Bordered */
.animate-theme-icons .animate-theme-icons-blue-brd {
  color: #536dfe;
  border: 1px solid #536dfe;
}

/* Animate Theme Icons Blue Grey Bordered */
.animate-theme-icons .animate-theme-icons-blue-grey-brd {
  color: #546e7a;
  border: 1px solid #546e7a;
}

/* Animate Theme Icons Grey Bordered */
.animate-theme-icons .animate-theme-icons-grey-brd {
  color: #9e9e9e;
  border: 1px solid #9e9e9e;
}

/* Animate Theme Icons Teal Bordered */
.animate-theme-icons .animate-theme-icons-teal-brd {
  color: #009688;
  border: 1px solid #009688;
}

/* Animate Theme Icons Red Bordered */
.animate-theme-icons .animate-theme-icons-red-brd {
  color: #e55973;
  border: 1px solid #e55973;
}

/* Animate Theme Icons Pink Bordered */
.animate-theme-icons .animate-theme-icons-pink-brd {
  color: #e91e63;
  border: 1px solid #e91e63;
}

/* Animate Theme Icons Purple Bordered */
.animate-theme-icons .animate-theme-icons-purple-brd {
  color: #b260ce;
  border: 1px solid #b260ce;
}

/* Animate Theme Icons Gold Bordered */
.animate-theme-icons .animate-theme-icons-gold-brd {
  color: #c69f73;
  border: 1px solid #c69f73;
}

/*------------------------------------------------------------------
    [Theme Icons Elegant]
------------------------------------------------------------------*/
.theme-icons-elegant:before, .theme-icons-elegant:after {
  content: " ";
  display: table;
}

.theme-icons-elegant:after {
  clear: both;
}

.theme-icons-elegant .theme-icons-element {
  float: left;
  margin-right: 15px;
}

.theme-icons-elegant .theme-icons-element .theme-icons-wrap > a[href] > .theme-icons:hover {
  color: #fff;
  background: #BAF402;
  border-color: transparent;
}

.theme-icons-elegant .theme-icons-body {
  overflow: hidden;
}

.theme-icons-elegant .theme-icons-body .theme-icons-body-title {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.theme-icons-elegant .theme-icons-body .them-icon-body-paragraph {
  font-size: 12px;
  margin-bottom: 0;
}

/* Theme Icons Elegant Right */
.theme-icons-elegant-right {
  text-align: right;
}

.theme-icons-elegant-right .theme-icons-element {
  float: right;
  margin: 0 0 0 15px;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .theme-icons-elegant .theme-icons-element {
    float: none;
    text-align: center;
    margin: 0 0 5px;
  }
  .theme-icons-elegant .theme-icons-body {
    text-align: center;
  }
}

/*------------------------------------------------------------------
    [Animate Icons]
------------------------------------------------------------------*/
.animate-icon {
  margin: 5px 0;
}

.animate-icon .animate-icon-wrap {
  display: block;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  color: #3a3a44;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 0;
}

.animate-icon .animate-icon-wrap:hover .animate-icon-item:last-child {
  color: #fff;
}

.animate-icon .animate-icon-wrap:hover, .animate-icon .animate-icon-wrap:focus {
  text-decoration: none;
}

.animate-icon .animate-icon-item {
  position: relative;
  display: block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.animate-icon .animate-icon-item-shadow {
  text-shadow: 1px 1px 1px #7c7c8d;
}

/*------------------------------------------------------------------
    [Animate Icons Sizes]
------------------------------------------------------------------*/
/* Extra Small Size */
.animate-icon .animate-icon-xs {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.animate-icon .animate-icon-xs .animate-icon-item {
  padding: 8px 0;
}

.animate-icon .animate-icon-xs:hover .animate-icon-item:first-child {
  margin-top: -30px;
}

/* Small Size */
.animate-icon .animate-icon-sm {
  width: 35px;
  height: 35px;
  font-size: 16px;
}

.animate-icon .animate-icon-sm .animate-icon-item {
  padding: 10px 0;
}

.animate-icon .animate-icon-sm:hover .animate-icon-item:first-child {
  margin-top: -35px;
}

/* Medium Size */
.animate-icon .animate-icon-md {
  width: 45px;
  height: 45px;
  font-size: 22px;
}

.animate-icon .animate-icon-md .animate-icon-item {
  padding: 12px 0;
}

.animate-icon .animate-icon-md:hover .animate-icon-item:first-child {
  margin-top: -45px;
}

/* Large Size */
.animate-icon .animate-icon-lg {
  width: 55px;
  height: 55px;
  font-size: 26px;
}

.animate-icon .animate-icon-lg .animate-icon-item {
  padding: 15px 0;
}

.animate-icon .animate-icon-lg:hover .animate-icon-item:first-child {
  margin-top: -55px;
}

/*------------------------------------------------------------------
    [Animate Transition Icons Bordered Color]
------------------------------------------------------------------*/
/* Animate Transition Icons Border Grey */
.animate-icon .animate-icon-grey-brd {
  border: 1px solid #9e9e9e;
}

.animate-icon .animate-icon-grey-brd:hover {
  border-color: transparent;
}

/*------------------------------------------------------------------
    [Animate Transition Icons Tooltips]
------------------------------------------------------------------*/
/* Animate Icons Tooltip */
.animate-icon {
  position: relative;
}

.animate-icon .icon-tooltip {
  display: block;
  font-size: 14px;
  color: #fff;
  background: #3a3a44;
  visibility: hidden;
  opacity: 0;
  white-space: nowrap;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  padding: 15px 25px;
}

.animate-icon:hover .icon-tooltip-top,
.animate-icon:hover .icon-tooltip-bottom {
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.animate-icon:hover .icon-tooltip-top {
  transform: translateX(-50%) translateY(-80%);
}

.animate-icon:hover .icon-tooltip-bottom {
  transform: translateX(-50%) translateY(80%);
}

/* Animate Icons Tooltip Top */
.animate-icon .icon-tooltip-top {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  margin-top: -30px;
}

.animate-icon .icon-tooltip-arrow-top {
  bottom: -14px;
  border-bottom-color: transparent !important;
}

/* Animate Icons Tooltip Bottom */
.animate-icon .icon-tooltip-bottom {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) translateY(100%);
  margin-bottom: -30px;
}

.animate-icon .icon-tooltip-arrow-bottom {
  top: -14px;
  border-top-color: transparent !important;
}

/* Animate Icons Tooltip Arrow */
.animate-icon .icon-tooltip-arrow {
  position: absolute;
  left: 50%;
  border-color: #3a3a44;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
  border-style: solid;
  border-width: 7px;
  margin-left: -7px;
}

/*------------------------------------------------------------------
    [Animate Icons Horizontal]
------------------------------------------------------------------*/
.animate-icon .animate-icon-horizontal {
  position: relative;
  display: inline-block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
  vertical-align: middle;
}

.animate-icon .animate-icon-horizontal:hover, .animate-icon .animate-icon-horizontal:focus {
  text-decoration: none;
}

/* Animated Icon Horizontal Wrap */
.animate-icon .animate-icon-horizontal-wrap {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 80px;
  height: 80px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 20px;
}

/* Animated Icon Left To Right Hover */
.animate-icon .animate-icon-horizontal:hover .animate-icon-left-to-right:first-child {
  transform: translate(100%, 0);
}

.animate-icon .animate-icon-horizontal:hover .animate-icon-left-to-right:last-child {
  transform: translate(0, 0);
}

/* Animated Icon Left To Right */
.animate-icon .animate-icon-left-to-right:first-child {
  transform: translate(0, 0);
}

.animate-icon .animate-icon-left-to-right:last-child {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  transform: translate(-100%, 0);
}

/* Animated Icon Right To Left Hover */
.animate-icon .animate-icon-horizontal:hover .animate-icon-right-to-left:first-child {
  transform: translate(-100%, 0);
}

.animate-icon .animate-icon-horizontal:hover .animate-icon-right-to-left:last-child {
  transform: translate(0, 0);
}

/* Animated Icon Right To Left */
.animate-icon .animate-icon-right-to-left:first-child {
  transform: translate(0, 0);
}

.animate-icon .animate-icon-right-to-left:last-child {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  transform: translate(100%, 0);
}

/*------------------------------------------------------------------
    [Animate Icons Border Style]
------------------------------------------------------------------*/
/* Animated Icons With Solid Border */
.animate-icon .brd-solid {
  border: 1px solid #858484;
}

/* Animated Icons With Dashed Border */
.animate-icon .brd-dashed {
  border: 1px dashed #858484;
}

/* Animated Icons With Dotted Border */
.animate-icon .brd-dotted {
  border: 1px dotted #858484;
}

/* Animated Icons With Double Line Border */
.animate-icon .brd-double-square,
.animate-icon .brd-double-circle {
  position: relative;
  border: 1px double #858484;
}

.animate-icon .brd-double-square:after,
.animate-icon .brd-double-circle:after {
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  z-index: -1;
  width: auto;
  height: auto;
  border: 1px solid #858484;
  content: " ";
}

.animate-icon .brd-double-circle:after {
  border-radius: 50%;
}

/*--------------------------------------------------
    [Timeline Current Date]
----------------------------------------------------*/
.timeline-v1-current-date {
  padding-top: 100px;
}

.timeline-v1-current-date .timeline-v1-current-day {
  font-size: 30px;
  font-weight: 400;
  color: #BAF402;
  margin: 0;
}

.timeline-v1-current-date .timeline-v1-current-time {
  display: block;
  font-size: 11px;
  font-weight: 400;
  color: #BAF402;
}

/*--------------------------------------------------
    [Timeline v1]
----------------------------------------------------*/
.timeline-v1 {
  position: relative;
  list-style: none;
  padding: 10px 0 0;
}

.timeline-v1:before {
  position: absolute;
  top: 0;
  left: 20px;
  bottom: 0;
  width: 1px;
  background: #e7eaf0;
  content: " ";
}

.timeline-v1 .timeline-v1-list-item {
  position: relative;
  padding-left: 40px;
  margin-bottom: 15px;
}

.timeline-v1 .timeline-v1-list-item:before, .timeline-v1 .timeline-v1-list-item:after {
  content: " ";
  display: table;
}

.timeline-v1 .timeline-v1-list-item:after {
  clear: both;
}

.timeline-v1 .timeline-v1-list-item .color-one {
  border-color: #BAF402;
}

.timeline-v1 .timeline-v1-list-item .color-two {
  border-color: #b260ce;
}

.timeline-v1 .timeline-v1-list-item .color-three {
  border-color: #536dfe;
}

.timeline-v1 .timeline-v1-list-item .color-four {
  border-color: #BAF402;
}

.timeline-v1 .timeline-v1-list-item .color-five {
  border-color: #546e7a;
}

.timeline-v1 .timeline-v1-list-item .color-six {
  border-color: #c69f73;
}

.timeline-v1 .timeline-v1-list-item .color-seven {
  border-color: #e55973;
}

.timeline-v1 .timeline-v1-list-item .timeline-v1-badge-icon {
  position: absolute;
  top: 5px;
  left: 15.5px;
  display: block;
  width: 10px;
  height: 10px;
  background: #fff;
  border-style: solid;
  border-width: 2px;
}

.timeline-v1 .timeline-v1-list-item .timeline-v1-news-label {
  font-size: 13px;
  font-weight: 400;
  color: #e57287;
  padding-right: 15px;
}

.timeline-v1 .timeline-v1-list-item .timeline-v1-news-time {
  font-size: 12px;
  color: #565656;
}

.timeline-v1 .timeline-v1-list-item .timeline-v1-news-title {
  font-size: 13px;
  font-weight: 400;
  color: #3a3a3a;
  letter-spacing: 0;
  text-transform: inherit;
  margin: 0;
}

.timeline-v1 .timeline-v1-list-item .timeline-v1-news-title > a {
  font-size: 13px;
  color: #3a3a3a;
}

.timeline-v1 .timeline-v1-list-item .timeline-v1-news-title > a:hover {
  color: #BAF402;
  text-decoration: none;
}

/*--------------------------------------------------
    [Timeline v2]
----------------------------------------------------*/
.timeline-v2 {
  position: relative;
  list-style: none;
  overflow: hidden;
  padding: 10px 0 0;
}

.timeline-v2:before {
  position: absolute;
  top: 0;
  left: 20px;
  bottom: 0;
  width: 1px;
  background: #e7eaf0;
  content: " ";
}

.timeline-v2 .timeline-v2-list-item {
  position: relative;
  padding-left: 45px;
  margin-bottom: 25px;
}

.timeline-v2 .timeline-v2-list-item:before, .timeline-v2 .timeline-v2-list-item:after {
  content: " ";
  display: table;
}

.timeline-v2 .timeline-v2-list-item:after {
  clear: both;
}

.timeline-v2 .timeline-v2-current-date {
  position: relative;
  border-top: 1px solid #e7eaf0;
  padding: 15px 0 0 40px;
  margin: 20px 0 25px;
}

.timeline-v2 .timeline-v2-current-date:before, .timeline-v2 .timeline-v2-current-date:after {
  content: " ";
  display: table;
}

.timeline-v2 .timeline-v2-current-date:after {
  clear: both;
}

.timeline-v2 .timeline-v2-badge-icon {
  position: absolute;
  top: 0;
  left: 5px;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 13px;
  color: #a3a3a3;
  background: #fff;
  text-align: center;
  padding: 8px;
}

.timeline-v2 .timeline-v2-news-date {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #e57287;
}

.timeline-v2 .timeline-v2-news-title {
  font-size: 12px;
  font-weight: 400;
  color: #3a3a3a;
  letter-spacing: 0;
  text-transform: inherit;
  margin: 0;
}

.timeline-v2 .timeline-v2-news-title > a {
  font-size: 13px;
  color: #3a3a3a;
}

.timeline-v2 .timeline-v2-news-title > a:hover {
  color: #BAF402;
  text-decoration: none;
}

.timeline-v2 .timeline-v2-current-day {
  font-size: 26px;
  font-weight: 400;
  color: #BAF402;
  margin: 0;
}

.timeline-v2 .timeline-v2-current-time {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: #BAF402;
}

/*--------------------------------------------------
    [Timeline v3]
----------------------------------------------------*/
.timeline-v3 {
  position: relative;
  list-style: none;
  padding: 20px 0 20px;
}

.timeline-v3:before {
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 2px;
  background: #69697b;
  margin-left: -1px;
  content: " ";
}

.timeline-v3 .timeline-v3-list-item {
  position: relative;
  width: 50%;
  float: left;
  clear: left;
  padding-right: 30px;
  margin-top: 50px;
}

.timeline-v3 .timeline-v3-list-item:before, .timeline-v3 .timeline-v3-list-item:after {
  content: " ";
  display: table;
}

.timeline-v3 .timeline-v3-list-item:after {
  clear: both;
}

.timeline-v3 .timeline-v3-list-item:first-child {
  margin-top: 0;
}

.timeline-v3 .timeline-v3-list-item:nth-child(2) {
  margin-top: 50px;
}

.timeline-v3 .timeline-v3-list-item .timeline-v3-badge-icon {
  position: absolute;
  top: 25px;
  right: 0;
  display: block;
  width: 12px;
  height: 12px;
  background: #BAF402;
  border: 2px solid #fff;
  margin-right: -6px;
}

.timeline-v3 .timeline-v3-list-item .timeline-v3-badge {
  position: relative;
}

.timeline-v3 .timeline-v3-list-item .timeline-v3-badge:before {
  position: absolute;
  top: 30px;
  right: -23px;
  width: 19px;
  border-top: 2px dotted #757589;
  content: " ";
}

.timeline-v3 .timeline-v3-right-wing {
  float: right;
  clear: right;
  padding: 0 0 0 30px;
}

.timeline-v3 .timeline-v3-right-wing .timeline-v3-badge-icon {
  left: 0;
  right: auto;
  margin-left: -6px;
}

.timeline-v3 .timeline-v3-right-wing .timeline-v3-badge:before {
  position: absolute;
  top: 31px;
  left: auto;
  right: 100.4%;
}

.timeline-v3 .timeline-v3-right-wing .timeline-v3-panel {
  float: right;
}

.timeline-v3 .timeline-v3-panel {
  float: left;
  width: 100%;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .timeline-v3:before {
    left: 15px;
  }
  .timeline-v3 .timeline-v3-list-item {
    position: relative;
    width: 100%;
    float: left;
    clear: left;
    padding: 0 0 0 50px;
  }
  .timeline-v3 .timeline-v3-list-item .timeline-v3-badge-icon {
    position: absolute;
    top: 25px;
    left: 9px;
    right: auto;
  }
  .timeline-v3 .timeline-v3-list-item .timeline-v3-badge:before {
    position: absolute;
    top: 30px;
    left: -24px;
    right: auto;
  }
  .timeline-v3 .timeline-v3-right-wing {
    padding: 0 0 0 50px;
  }
  .timeline-v3 .timeline-v3-right-wing .timeline-v3-badge-icon {
    margin-left: 0;
  }
}

/*--------------------------------------------------
    [Timeline v4]
----------------------------------------------------*/
.timeline-v4 {
  position: relative;
  list-style: none;
  padding: 20px 0 20px;
}

.timeline-v4:before {
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 2px;
  background: #BAF402;
  margin-left: -1px;
  content: " ";
}

.timeline-v4:after {
  position: absolute;
  top: 0;
  left: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  font-size: 18px;
  font-family: FontAwesome;
  background: #BAF402;
  border-radius: 50%;
  content: "\f107";
  color: #fff;
  line-height: .8;
  padding: 9px;
  margin-left: -15px;
}

.timeline-v4 .timeline-v4-list-item {
  position: relative;
  width: 50%;
  float: left;
  clear: left;
  padding-right: 50px;
  margin-top: 50px;
}

.timeline-v4 .timeline-v4-list-item:before, .timeline-v4 .timeline-v4-list-item:after {
  content: " ";
  display: table;
}

.timeline-v4 .timeline-v4-list-item:after {
  clear: both;
}

.timeline-v4 .timeline-v4-list-item:first-child {
  margin-top: 0;
}

.timeline-v4 .timeline-v4-list-item:nth-child(2) {
  margin-top: 50px;
}

.timeline-v4 .timeline-v4-list-item .timeline-v4-badge-icon {
  position: absolute;
  top: 59px;
  right: 0;
  display: block;
  width: 15px;
  height: 15px;
  background: #BAF402;
  border: 2px solid #fff;
  margin-right: -7.5px;
}

.timeline-v4 .timeline-v4-right-wing {
  float: right;
  clear: right;
  padding: 0 0 0 50px;
}

.timeline-v4 .timeline-v4-right-wing .timeline-v4-badge-icon {
  left: 0;
  right: auto;
  margin-left: -7.5px;
}

.timeline-v4 .timeline-v4-right-wing .timeline-v4-badge:before {
  position: absolute;
  top: 31px;
  left: auto;
  right: 100.4%;
}

.timeline-v4 .timeline-v4-right-wing .timeline-v4-panel {
  float: right;
}

.timeline-v4 .timeline-v4-right-wing .timeline-v4-panel.timeline-v4-panel-right {
  position: relative;
}

.timeline-v4 .timeline-v4-right-wing .timeline-v4-panel.timeline-v4-panel-right:before {
  position: absolute;
  top: 50px;
  right: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 20px 15px 0;
  border-color: transparent #fff transparent transparent;
  content: " ";
  margin-right: -3px;
}

.timeline-v4 .timeline-v4-panel {
  float: left;
  width: 100%;
  background: #fff;
  padding: 20px;
}

.timeline-v4 .timeline-v4-panel.timeline-v4-panel-left {
  position: relative;
}

.timeline-v4 .timeline-v4-panel.timeline-v4-panel-left:after {
  position: absolute;
  top: 50px;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 20px;
  border-color: transparent transparent transparent #fff;
  content: " ";
  margin-left: -3px;
}

.timeline-v4 .timeline-v4-subtitle {
  display: block;
  font-size: 16px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #BAF402;
  margin-bottom: 5px;
}

.timeline-v4 .timeline-v4-title {
  font-size: 24px;
}

.timeline-v4 .timeline-v4-title .timeline-v4-title-link {
  font-size: 24px;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .timeline-v4:before {
    left: 15px;
  }
  .timeline-v4:after {
    left: 15px;
  }
  .timeline-v4 .timeline-v4-list-item {
    position: relative;
    width: 100%;
    float: left;
    clear: left;
    padding: 0 0 0 50px;
  }
  .timeline-v4 .timeline-v4-list-item .timeline-v4-badge-icon {
    position: absolute;
    top: 59px;
    left: 8px;
    right: auto;
  }
  .timeline-v4 .timeline-v4-list-item .timeline-v4-badge:before {
    position: absolute;
    top: 30px;
    left: -24px;
    right: auto;
  }
  .timeline-v4 .timeline-v4-right-wing .timeline-v4-badge-icon {
    margin-left: 0;
  }
  .timeline-v4 .timeline-v4-panel.timeline-v4-panel-left:after {
    position: absolute;
    top: 50px;
    left: auto;
    right: 100%;
    border-width: 15px 20px 15px 0;
    border-color: transparent #fff transparent transparent;
    margin-left: -3px;
  }
}

/*--------------------------------------------------
    [Call To Action v1]
----------------------------------------------------*/
.call-to-action-v1 {
  position: relative;
  z-index: 1;
  background: url(../../assets/img/1920x1080/10.jpg) no-repeat;
  background-size: cover;
  background-position: center top;
}

.call-to-action-v1:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.7);
  content: " ";
}

.call-to-action-v1 .call-to-action-v1-title {
  font-size: 36px;
  color: #fff;
}

.call-to-action-v1 .call-to-action-v1-text {
  font-size: 22px;
  color: #fff;
  opacity: .8;
  margin-bottom: 0;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .call-to-action-v1 .call-to-action-v1-title {
    font-size: 28px;
  }
}

/*--------------------------------------------------
    [Call To Action v2]
----------------------------------------------------*/
.call-to-action-v2 .call-to-action-v2-title {
  font-size: 30px;
  margin-bottom: 10px;
}

.call-to-action-v2 .call-to-action-v2-text {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 0;
}

/*--------------------------------------------------
    [Call To Action v3]
----------------------------------------------------*/
.call-to-action-v3 {
  text-align: center;
}

.call-to-action-v3 .call-to-action-badge {
  font-size: 18px;
  font-weight: 400;
  padding: 10px;
  margin-right: 10px;
}

.call-to-action-v3 .call-to-action-v3-text {
  font-size: 18px;
  font-weight: 300;
}

.call-to-action-v3 .call-to-action-v3-text .call-to-action-text-bold {
  font-size: 20px;
  font-weight: 500;
  color: #34343c;
  margin-right: 10px;
}

.call-to-action-v3 .call-to-action-v3-link {
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .call-to-action-v3 .call-to-action-v3-text {
    display: block;
    margin: 5px 0;
  }
}

/*--------------------------------------------------
    [Clients v1]
----------------------------------------------------*/
.clients-v1 {
  margin-bottom: 0;
}

.clients-v1 .clients-v1-item {
  position: relative;
  width: 135px;
  height: 135px;
  overflow: hidden;
}

.clients-v1 .clients-v1-item .clients-v1-img {
  display: block;
  width: 135px;
  height: 135px;
  text-align: center;
}

.clients-v1 .clients-v1-item .clients-v1-img-default {
  opacity: 1;
  background: #34343c;
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.clients-v1 .clients-v1-item .clients-v1-img-active {
  opacity: 0;
  position: absolute;
  top: -100%;
  left: 0;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.clients-v1 .clients-v1-item:hover .clients-v1-img-default {
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.clients-v1 .clients-v1-item:hover .clients-v1-img-active {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Clients v2]
----------------------------------------------------*/
.clients-v2 {
  position: relative;
  z-index: 1;
  text-align: center;
  background: url(../../assets/img/1920x1080/22.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.clients-v2:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.5);
  content: " ";
}

.clients-v2 .clients-v2-content {
  padding: 15px;
}

.clients-v2 .clients-v2-content .clients-v2-img {
  width: 150px;
  height: 150px;
}

.clients-v2 .clients-v2-border-left,
.clients-v2 .clients-v2-border-right,
.clients-v2 .clients-v2-border-bottom {
  position: relative;
}

.clients-v2 .clients-v2-border-left:after,
.clients-v2 .clients-v2-border-right:after,
.clients-v2 .clients-v2-border-bottom:after {
  background: rgba(255, 255, 255, 0.4);
  content: " ";
}

.clients-v2 .clients-v2-border-left:after {
  position: absolute;
  top: 11px;
  right: 100%;
  width: 1px;
  height: 91%;
}

.clients-v2 .clients-v2-border-right:after {
  position: absolute;
  top: 0;
  right: 100%;
  width: 1px;
  height: 91%;
}

.clients-v2 .clients-v2-border-bottom:after {
  position: absolute;
  right: -3px;
  bottom: 0;
  width: 103.5%;
  height: 1px;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .clients-v2 .clients-v2-border-left:after,
  .clients-v2 .clients-v2-border-right:after,
  .clients-v2 .clients-v2-border-bottom:after {
    background: 0;
  }
}

/*--------------------------------------------------
    [Clients v3]
----------------------------------------------------*/
.clients-v3-container {
  text-align: center;
  margin: 0;
  padding: 0;
}

.clients-v3-container .clients-v3 {
  display: inline-block;
  width: 20%;
  text-align: center;
  margin-right: -5px;
}

.clients-v3-container .clients-v3-content {
  position: relative;
  display: block;
  width: 100%;
  height: 160px;
}

.clients-v3-container .clients-v3-content:hover .clients-v3-wrap {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  -moz-transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.clients-v3-container .clients-v3-content:hover .clients-v3-logo .clients-v3-img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transform: scale(0.75, 0.75);
  -moz-transform: scale(0.75, 0.75);
  transform: scale(0.75, 0.75);
}

.clients-v3-container .clients-v3-wrap {
  position: absolute;
  display: table;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(186, 244, 2, 0.95);
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #fff;
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  transform: scale(0, 0);
  opacity: 0;
  padding: 0 12px;
}

.clients-v3-container .clients-v3-wrap-info {
  display: table-cell;
  vertical-align: middle;
  list-style: none;
  padding: 0;
}

.clients-v3-container .clients-v3-wrap-info > li {
  color: #fff;
}

.clients-v3-container .clients-v3-logo {
  width: 100%;
  height: 100%;
}

.clients-v3-container .clients-v3-logo span {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.clients-v3-container .clients-v3-img {
  vertical-align: middle;
  max-height: 100%;
  max-width: 100%;
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  .clients-v3-container .clients-v3 {
    width: 33.3333%;
  }
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .clients-v3-container .clients-v3 {
    width: 50%;
  }
}

/* Media Queries below 448px */
@media (max-width: 480px) {
  .clients-v3-container .clients-v3 {
    width: 100%;
  }
}

/*--------------------------------------------------
    [DZSParallaxer]
----------------------------------------------------*/
/* Settings */
.divimage {
  background-size: cover !important;
}

/*--------------------------------------------------
    [Parallax Content v1]
----------------------------------------------------*/
.parallax-content-v1 .parallax-content-v1-title {
  font-size: 50px;
  color: #fff;
  line-height: 1;
}

.parallax-content-v1 .parallax-content-v1-subtitle {
  font-size: 22px;
  font-style: italic;
  color: #fff;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .parallax-content-v1 .parallax-content-v1-title {
    font-size: 36px;
  }
  .parallax-content-v1 .parallax-content-v1-subtitle {
    font-size: 15px;
  }
}

/*--------------------------------------------------
    [Pricing List v1]
----------------------------------------------------*/
.pricing-list-v1 {
  border: 2px solid #f7f8fa;
  padding: 10px;
}

.pricing-list-v1 .pricing-list-v1-body {
  background: #f7f8fa;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-header {
  border-bottom: 2px solid #fff;
  padding: 30px 0;
  margin: 0 30px;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-header-title {
  font-size: 24px;
  font-weight: 400;
  margin: 0 0 5px;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-header-subtitle {
  font-size: 14px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #606060;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-header-price-sign {
  position: relative;
  top: -20px;
  font-size: 24px;
  font-weight: 400;
  color: #34343c;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-header-price {
  font-size: 45px;
  font-weight: 500;
  color: #34343c;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-header-price:after {
  font-size: 36px;
  font-weight: 400;
  content: "/";
  margin-right: -5px;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-header-price-info {
  font-size: 22px;
  font-weight: 400;
  color: #34343c;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-content {
  padding: 30px;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-content-list {
  margin-bottom: 20px;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-content-list > li {
  padding: 5px;
}

/* Pricing List v1 Active */
.pricing-list-v1-active {
  z-index: 1;
  border-color: #f7f8fa;
  border-width: 2px;
  background: #fff;
}

.pricing-list-v1-active .pricing-list-v1-body {
  background: #fff;
}

.pricing-list-v1-active .pricing-list-v1-body .pricing-list-v1-header {
  border-color: #f7f8fa;
}

.pricing-list-v1-active .pricing-list-v1-body .pricing-list-v1-header-price,
.pricing-list-v1-active .pricing-list-v1-body .pricing-list-v1-header-price-sign,
.pricing-list-v1-active .pricing-list-v1-body .pricing-list-v1-header-price-info {
  color: #BAF402;
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  .pricing-list-v1-active {
    -webkit-transform: scale(1.01, 1.07);
    -moz-transform: scale(1.01, 1.07);
    transform: scale(1.01, 1.07);
  }
}

/*--------------------------------------------------
    [Pricing List v2]
----------------------------------------------------*/
.pricing-list-v2 {
  box-shadow: 0 2px 5px 3px #eff1f8;
}

.pricing-list-v2 .pricing-list-v2-header {
  padding: 20px;
}

.pricing-list-v2 .pricing-list-v2-header-title {
  font-size: 20px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #fff;
  text-transform: inherit;
  text-align: right;
  margin: 0;
}

.pricing-list-v2 .pricing-list-v2-header-price-sign {
  position: relative;
  top: -22px;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}

.pricing-list-v2 .pricing-list-v2-header-price {
  font-size: 50px;
  font-weight: 500;
  color: #fff;
}

.pricing-list-v2 .pricing-list-v2-header-price:after {
  font-size: 26px;
  font-weight: 400;
  content: "/";
  margin-right: -5px;
}

.pricing-list-v2 .pricing-list-v2-header-price-info {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.pricing-list-v2 .pricing-list-v2-content {
  background: #fff;
  border: 1px solid #f7f8fa;
  padding: 30px;
}

.pricing-list-v2 .pricing-list-v2-content-list {
  margin-bottom: 20px;
}

.pricing-list-v2 .pricing-list-v2-content-list > li {
  padding: 5px;
}

/*--------------------------------------------------
    [Pricing List v3]
----------------------------------------------------*/
.pricing-list-v3 {
  text-align: center;
  padding: 80px 30px;
}

.pricing-list-v3 .pricing-list-v3-border {
  border-right: 1px solid rgba(255, 255, 255, 0.7);
}

.pricing-list-v3 .pricing-list-v3-title {
  font-size: 40px;
  color: #fff;
}

.pricing-list-v3 .pricing-list-v3-subtitle {
  display: block;
  font-size: 22px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 10px;
}

.pricing-list-v3 .pricing-list-v3-text {
  display: block;
  font-size: 16px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #fff;
  letter-spacing: 1px;
}

.pricing-list-v3 .pricing-list-v3-price-sign {
  position: relative;
  top: -20px;
  font-size: 18px;
  color: #fff;
}

.pricing-list-v3 .pricing-list-v3-price {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
}

.pricing-list-v3 .pricing-list-v3-paragraph {
  color: #fff;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .pricing-list-v3 .pricing-list-v3-border {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    padding-bottom: 30px;
  }
}

/*--------------------------------------------------
    [Pricing List v4]
----------------------------------------------------*/
.pricing-list-v4 {
  text-align: center;
  background: #fff;
  box-shadow: 15px 15px 15px 0 #eff1f8;
}

.pricing-list-v4 .pricing-list-v4-header {
  border-bottom: 1px solid #e4e8f3;
  padding: 40px 20px 40px;
  margin: 10px 30px;
}

.pricing-list-v4 .pricing-list-v4-header .pricing-list-v4-title {
  font-size: 36px;
  margin-bottom: 0;
}

.pricing-list-v4 .pricing-list-v4-header .pricing-list-v4-subtitle {
  display: block;
  font-size: 16px;
  font-weight: 300;
}

.pricing-list-v4 .pricing-list-v4-content {
  padding: 40px 20px;
}

.pricing-list-v4 .pricing-list-v4-content .pricing-list-v4-price-sign {
  position: relative;
  top: -30px;
  font-size: 20px;
}

.pricing-list-v4 .pricing-list-v4-content .pricing-list-v4-price {
  font-size: 70px;
  font-weight: 400;
  line-height: .5;
  letter-spacing: -3px;
}

.pricing-list-v4 .pricing-list-v4-content .pricing-list-v4-subprice {
  font-size: 30px;
  font-weight: 400;
}

.pricing-list-v4 .pricing-list-v4-content .pricing-list-v4-price-info {
  position: relative;
  display: block;
  letter-spacing: 1px;
}

.pricing-list-v4 .pricing-list-v4-content .pricing-list-v4-price-info:before {
  font-size: 22px;
  font-weight: 300;
  content: "/";
  margin-right: 3px;
}

/*------------------------------------------------------------------
    [Promo Block v1]
------------------------------------------------------------------*/
.promo-block-v1 {
  position: relative;
  z-index: 1;
  background: url(../img/1920x1080/05.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.promo-block-v1:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.2);
  content: " ";
}

.promo-block-v1.promo-block-v1-bg-img-v2 {
  background: url(../img/1920x1080/45.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.promo-block-v1.promo-block-v1-bg-img-v3 {
  background: url(../img/1920x1080/53.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.promo-block-v1.promo-block-v1-bg-img-v3:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.4);
  content: " ";
}

.promo-block-v1 .promo-block-v1-title {
  font-size: 50px;
  color: #fff;
}

.promo-block-v1 .promo-block-v1-text {
  display: block;
  max-width: 500px;
  font-size: 20px;
  font-style: italic;
  color: #fff;
  text-align: center;
  margin: 0 auto;
}

/* Media Queries below 550px */
@media (max-width: 550px) {
  .promo-block-v1 .promo-block-v1-title {
    font-size: 36px;
  }
  .promo-block-v1 .promo-block-v1-text {
    font-size: 18px;
  }
}

/*------------------------------------------------------------------
    [Promo Block v2]
------------------------------------------------------------------*/
.promo-block-v2 {
  position: relative;
  z-index: 1;
  background: url(../img/1920x1080/48.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.promo-block-v2:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.3);
  content: " ";
}

.promo-block-v2 .promo-block-v2-title {
  font-size: 50px;
  color: #fff;
}

.promo-block-v2 .promo-block-v2-text {
  display: block;
  max-width: 500px;
  font-size: 20px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #fff;
  text-align: center;
  margin: 0 auto;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .promo-block-v2 .promo-block-v2-title {
    font-size: 36px;
  }
  .promo-block-v2 .promo-block-v2-text {
    font-size: 18px;
  }
}

/*------------------------------------------------------------------
    [Promo Block v3]
------------------------------------------------------------------*/
.promo-block-v3 {
  position: relative;
  z-index: 1;
  text-align: center;
  background: url(../img/1920x1080/39.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.promo-block-v3 .promo-block-v3-title {
  font-size: 70px;
  color: #fff;
}

.promo-block-v3 .promo-block-v3-subtitle {
  font-size: 36px;
  font-style: italic;
  font-weight: 300;
  font-family: Droid Serif, serif;
  color: #fff;
}

.promo-block-v3 .promo-block-v3-text {
  display: block;
  max-width: 500px;
  font-size: 26px;
  color: #fff;
  margin: 0 auto;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .promo-block-v3 .promo-block-v3-title {
    font-size: 50px;
  }
  .promo-block-v3 .promo-block-v3-text {
    font-size: 18px;
  }
}

/*------------------------------------------------------------------
    [Promo Block v4]
------------------------------------------------------------------*/
.promo-block-v4 {
  position: relative;
  z-index: 1;
  background: url(../img/1920x1080/47.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.promo-block-v4:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.2);
  content: " ";
}

.promo-block-v4 .promo-block-v4-title {
  display: inline-block;
  font-size: 100px;
  color: #fff;
  letter-spacing: 5px;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .promo-block-v4 .promo-block-v4-title {
    font-size: 70px;
  }
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .promo-block-v4 .promo-block-v4-title {
    font-size: 30px;
    letter-spacing: 0;
  }
}

/*------------------------------------------------------------------
    [Promo Block v5]
------------------------------------------------------------------*/
.promo-block-v5 {
  position: relative;
  z-index: 1;
  text-align: center;
  background: url(../img/1920x1080/51.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.promo-block-v5:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.4);
  content: " ";
}

.promo-block-v5.promo-block-v5-bg-img-v2 {
  background: url(../../assets/img/1920x1080/04.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.promo-block-v5.promo-block-v5-bg-img-v2:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.6);
  content: " ";
}

.promo-block-v5 .promo-block-v5-title {
  font-size: 70px;
  color: #fff;
}

.promo-block-v5 .promo-block-v5-subtitle {
  display: block;
  font-size: 22px;
  font-style: italic;
  color: #fff;
}

.promo-block-v5 .promo-block-v5-form {
  max-width: 800px;
  margin: 0 auto;
}

.promo-block-v5 .promo-block-v5-form-title {
  font-size: 22px;
  color: #fff;
}

.promo-block-v5 .promo-block-v5-form-input {
  width: 100%;
  height: 48px;
  background: rgba(255, 255, 255, 0.8);
  border: none;
}

.promo-block-v5 .promo-block-v5-form-input:focus {
  background: rgba(255, 255, 255, 0.9);
}

.promo-block-v5 .promo-block-v5-text {
  color: #fff;
  opacity: .9;
  margin-bottom: 0;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .promo-block-v5 .promo-block-v5-title {
    font-size: 50px;
  }
  .promo-block-v5 .promo-block-v5-subtitle {
    font-size: 18px;
  }
}

/*------------------------------------------------------------------
    [Promo Block v6]
------------------------------------------------------------------*/
.promo-block-v6 {
  position: relative;
  z-index: 1;
  background: url(../img/1920x1080/22.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.promo-block-v6:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.2);
  content: " ";
}

.promo-block-v6 .promo-block-v6-top-offset {
  padding-top: 170px;
}

.promo-block-v6 .promo-block-v6-title {
  font-size: 70px;
  color: #fff;
  letter-spacing: 1px;
}

.promo-block-v6 .promo-block-v6-text {
  font-size: 24px;
  color: #fff;
  opacity: .9;
  margin-bottom: 0;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .promo-block-v6 .promo-block-v6-title {
    font-size: 50px;
    letter-spacing: 0;
  }
  .promo-block-v6 .promo-block-v6-text {
    font-size: 20px;
  }
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .promo-block-v6 .promo-block-v6-title {
    font-size: 36px;
    letter-spacing: 0;
  }
  .promo-block-v6 .promo-block-v6-text {
    font-size: 18px;
  }
}

/*------------------------------------------------------------------
    [Promo Block v7]
------------------------------------------------------------------*/
.promo-block-v7 {
  position: relative;
  overflow: hidden;
  background: url(/assets/img/1920x1080/41.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 100px;
}

.promo-block-v7.promo-block-v7-bg-img-v2 {
  background: url(../img/1920x1080/59.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.promo-block-v7 .promo-block-v7-title {
  font-size: 50px;
  color: #fff;
  margin-bottom: 0;
}

.promo-block-v7 .promo-block-v7-text {
  color: #fff;
  opacity: .9;
  margin-bottom: 3px;
}

.promo-block-v7 .promo-block-v7-img-content {
  position: relative;
  height: 394px;
}

.promo-block-v7 .promo-block-v7-img-one {
  position: absolute;
  top: 0;
  left: 0;
}

.promo-block-v7 .promo-block-v7-img-two {
  position: absolute;
  top: 0;
  left: 0;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .promo-block-v7 .promo-block-v7-img-content {
    height: 100%;
  }
  .promo-block-v7 .promo-block-v7-img-one {
    position: inherit;
  }
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .promo-block-v7 .promo-block-v7-title {
    font-size: 36px;
  }
}

/*------------------------------------------------------------------
    [Promo Block v8]
------------------------------------------------------------------*/
.promo-block-v8 {
  position: relative;
  z-index: 1;
  background: url(../img/1920x1080/34.jpg) no-repeat;
  background-size: cover;
  background-position: center top;
  padding: 200px 0 70px;
}

.promo-block-v8:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.5);
  content: " ";
}

.promo-block-v8 .promo-block-v8-col {
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}

.promo-block-v8 .promo-block-v8-col.promo-block-v8-col-right {
  float: right;
}

.promo-block-v8 .promo-block-v8-title {
  font-size: 70px;
  color: #fff;
  margin-bottom: 50px;
}

.promo-block-v8 .promo-block-v8-text {
  font-size: 16px;
  color: #fff;
}

.promo-block-v8 .promo-block-v8-pricing {
  overflow: hidden;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding-right: 30px;
}

.promo-block-v8 .promo-block-v8-pricing .promo-block-v8-pricing-col {
  float: left;
  margin-right: 5px;
}

.promo-block-v8 .promo-block-v8-pricing .promo-block-v8-pricing-sign,
.promo-block-v8 .promo-block-v8-pricing .promo-block-v8-pricing-text,
.promo-block-v8 .promo-block-v8-pricing .promo-block-v8-pricing-no,
.promo-block-v8 .promo-block-v8-pricing .promo-block-v8-pricing-mon {
  font-weight: 300;
  color: #fff;
}

.promo-block-v8 .promo-block-v8-pricing .promo-block-v8-pricing-sign {
  display: block;
  font-size: 20px;
  text-align: right;
}

.promo-block-v8 .promo-block-v8-pricing .promo-block-v8-pricing-text {
  font-size: 18px;
}

.promo-block-v8 .promo-block-v8-pricing .promo-block-v8-pricing-no {
  font-size: 100px;
  font-weight: 200;
  line-height: 0.8;
}

.promo-block-v8 .promo-block-v8-pricing .promo-block-v8-pricing-mon {
  display: block;
  font-size: 16px;
  margin-top: 50px;
}

.promo-block-v8 .promo-block-v8-video {
  text-decoration: none;
}

.promo-block-v8 .promo-block-v8-video .promo-block-v8-video-btn {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
}

.promo-block-v8 .promo-block-v8-video .promo-block-v8-video-img {
  width: 40px;
  height: 40px;
  margin-left: 5px;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .promo-block-v8 .promo-block-v8-col {
    float: none;
    margin-right: 0;
  }
  .promo-block-v8 .promo-block-v8-col.promo-block-v8-col-right {
    float: none;
  }
  .promo-block-v8 .promo-block-v8-pricing {
    border-right: none;
  }
  .promo-block-v8 .promo-block-v8-video {
    float: none;
  }
}

/*------------------------------------------------------------------
    [Promo Block v9]
------------------------------------------------------------------*/
.promo-block-v9 {
  position: relative;
  text-align: center;
}

.promo-block-v9 .promo-block-v9-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.4);
  padding: 150px 0 80px;
}

.promo-block-v9 .promo-block-v9-title {
  font-size: 170px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .promo-block-v9 .promo-block-v9-title {
    font-size: 100px;
  }
}

/*------------------------------------------------------------------
    [Promo Block v10]
------------------------------------------------------------------*/
.promo-block-v10 {
  background: url(../../app/assets/img/home/join.jpg) no-repeat;
  background-size: cover;
  background-position: center top;
}

.promo-block-v10 .form-small {
  height: 30px !important;
}

.promo-block-v10 .checkbox label {
  font-size: 14px;
  font-style: italic;
}

.promo-block-v10 .promo-block-v10-title {
  font-size: 40px;
  color: #fff;
  line-height: 1;
  margin: 0 0 20px;
}

.promo-block-v10 .promo-block-v10-text {
  font-size: 18px;
  font-style: italic;
  color: #fff;
  margin-bottom: 0;
}

.promo-block-v10 .promo-block-v10-subtitle {
  font-size: 18px;
  color: #fff;
}

.promo-block-v10 .promo-block-v10-content {
  background: rgba(52, 52, 60, 0.8);
  padding: 120px 40px 40px;
}

.promo-block-v10 .promo-block-v10-form-input {
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0);
}

.promo-block-v10 .promo-block-v10-form-input::-moz-placeholder {
  color: #fff;
}

.promo-block-v10 .promo-block-v10-form-input:-ms-input-placeholder {
  color: #fff;
}

.promo-block-v10 .promo-block-v10-form-input::-webkit-input-placeholder {
  color: #fff;
}

.promo-block-v10 .promo-block-v10-form-input:focus {
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #fff;
  background: rgba(255, 255, 255, 0.4);
}

.promo-block-v10 .promo-block-v10-form-input:focus::-moz-placeholder {
  color: #fff;
}

.promo-block-v10 .promo-block-v10-form-input:focus:-ms-input-placeholder {
  color: #fff;
}

.promo-block-v10 .promo-block-v10-form-input:focus::-webkit-input-placeholder {
  color: #fff;
}

.promo-block-v10 .has-error {
  border: 1px solid #e55973;
  background: rgba(229, 89, 115, 0.3);
}

.promo-block-v10 .help-text {
  color: #fff;
  font-size: 14px;
  font-style: italic;
}

@media (max-width: 992px) {
  .promo-block-v10 .promo-block-v10-content {
    background: rgba(52, 52, 60, 0.85);
    padding: 40px;
  }
}

@media (max-width: 1199px) {
  .promo-block-v10 .promo-block-v10-content {
    padding: 70px 40px 40px;
  }
}

@media (max-width: 991px) {
  .promo-block-v10 .promo-block-v10-content {
    padding: 20px;
  }
  .promo-block-v10 .promo-block-v10-title {
    text-align: center;
    font-size: 30px;
    color: #fff;
    line-height: 1;
    margin: 15px 0 15px;
  }
  .promo-block-v10 .promo-block-v10-subtitle {
    font-size: 16px;
    color: #fff;
  }
}

/*------------------------------------------------------------------
    [Promo Block v11]
------------------------------------------------------------------*/
.promo-block-v11 {
  position: relative;
  z-index: 1;
  text-align: center;
  background: url(../img/1920x1080/33.jpg) no-repeat;
  background-size: cover;
  background-position: center top;
}

.promo-block-v11:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.4);
  content: " ";
}

.promo-block-v11 .promo-block-v11-title {
  font-size: 50px;
  color: #fff;
}

.promo-block-v11 .promo-block-v11-subtitle {
  font-size: 18px;
  color: #fff;
  margin-bottom: 0;
}

.promo-block-v11 .promo-block-v11-category {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: 0;
  padding: 0;
}

.promo-block-v11 .promo-block-v11-category .promo-block-v11-category-item {
  padding: 0 1px;
}

.promo-block-v11 .promo-block-v11-category .promo-block-v11-category-link {
  min-width: 100px;
  display: block;
  font-size: 14px;
  color: #fff;
  text-align: center;
  background: rgba(255, 255, 255, 0.3);
  text-decoration: none;
  padding: 20px 25px;
}

.promo-block-v11 .promo-block-v11-category .promo-block-v11-category-link:hover {
  background: rgba(255, 255, 255, 0.4);
}

.promo-block-v11 .promo-block-v11-category .promo-block-v11-category-icon {
  display: block;
  font-size: 30px;
  color: #fff;
  margin-bottom: 10px;
}

/*------------------------------------------------------------------
    [Promo Block cfo]
------------------------------------------------------------------*/
.promo-block-cfo .team-v10 {
  color: #ffffff;
  margin-top: 20px;
}

.promo-block-cfo .team-v10 h3 {
  color: #ffffff;
}

.promo-block-cfo .team-v10 .team-v10-name {
  color: #ffffff;
  margin: 0 0 10px;
}

.promo-block-cfo .team-v10 p {
  color: #ffffff;
}

.promo-block-cfo {
  background: url(../img/cfo-banner.jpg) no-repeat;
  background-size: cover;
  background-position: center top;
}

.promo-block-cfo .checkbox label {
  font-size: 14px;
  font-style: italic;
}

.promo-block-cfo .promo-block-cfo-title {
  font-size: 20px;
  color: #fff;
  line-height: 1.3;
  margin: 10px 0 20px;
  font-style: italic;
}

.promo-block-cfo .promo-block-cfo-text {
  font-size: 14px;
  font-style: none;
  color: #fff;
  margin-bottom: 0;
}

.promo-block-cfo .promo-block-cfo-subtitle {
  font-size: 18px;
  color: #fff;
}

.promo-block-cfo .promo-block-cfo-content {
  background: rgba(52, 52, 60, 0.8);
  padding: 120px 40px 40px;
}

.promo-block-cfo .promo-block-cfo-form-input {
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0);
}

.promo-block-cfo .promo-block-cfo-form-input::-moz-placeholder {
  color: #fff;
}

.promo-block-cfo .promo-block-cfo-form-input:-ms-input-placeholder {
  color: #fff;
}

.promo-block-cfo .promo-block-cfo-form-input::-webkit-input-placeholder {
  color: #fff;
}

.promo-block-cfo .promo-block-cfo-form-input:focus {
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #fff;
  background: rgba(255, 255, 255, 0.4);
}

.promo-block-cfo .promo-block-cfo-form-input:focus::-moz-placeholder {
  color: #fff;
}

.promo-block-cfo .promo-block-cfo-form-input:focus:-ms-input-placeholder {
  color: #fff;
}

.promo-block-cfo .promo-block-cfo-form-input:focus::-webkit-input-placeholder {
  color: #fff;
}

.promo-block-cfo .has-error {
  border: 1px solid #e55973;
  background: rgba(229, 89, 115, 0.3);
}

.promo-block-cfo .help-text {
  color: #fff;
  font-size: 14px;
  font-style: italic;
}

@media (max-width: 992px) {
  .promo-block-cfo .promo-block-cfo-content {
    background: rgba(52, 52, 60, 0.85);
    padding: 40px;
  }
}

@media (max-width: 1199px) {
  .promo-block-cfo .promo-block-cfo-content {
    padding: 70px 40px 40px;
  }
}

@media (max-width: 991px) {
  .promo-block-cfo .promo-block-cfo-content {
    padding: 20px;
  }
  .promo-block-cfo .promo-block-cfo-text {
    display: none;
  }
  .promo-block-cfo .promo-block-cfo-title {
    text-align: center;
    font-size: 30px;
    color: #fff;
    line-height: 1;
    margin: 15px 0 15px;
  }
  .promo-block-cfo .promo-block-cfo-subtitle {
    font-size: 16px;
    color: #fff;
  }
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .promo-block-v11 .promo-block-v11-title {
    font-size: 30px;
    line-height: 1.2;
  }
  .promo-block-v11 .promo-block-v11-subtitle {
    font-size: 15px;
  }
  .promo-block-v11 .promo-block-v11-category .promo-block-v11-category-link {
    min-width: 1px;
  }
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .promo-block-v11 .promo-block-v11-category .promo-block-v11-category-item {
    padding: 0 10px;
  }
  .promo-block-v11 .promo-block-v11-category .promo-block-v11-category-link {
    padding: 1px;
    background: rgba(255, 255, 255, 0);
  }
  .promo-block-v11 .promo-block-v11-category .promo-block-v11-category-link:hover {
    background: rgba(255, 255, 255, 0);
  }
}

/*------------------------------------------------------------------
    [Promo Block v12]
------------------------------------------------------------------*/
.promo-block-v12 {
  background: url(../img/1920x1080/44.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.promo-block-v12 .promo-block-v12-wrap {
  position: relative;
  z-index: 1;
}

.promo-block-v12 .promo-block-v12-wrap:before {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.9);
  content: " ";
}

.promo-block-v12 .promo-block-v12-title {
  font-size: 36px;
  color: #fff;
  line-height: 1.2;
  margin-bottom: 40px;
}

.promo-block-v12 .promo-block-v12-form {
  width: 100%;
  height: 48px;
  color: #34343c;
  background: #fff;
  border-color: #fff;
}

.promo-block-v12 .promo-block-v12-form::-moz-placeholder {
  color: #34343c;
}

.promo-block-v12 .promo-block-v12-form:-ms-input-placeholder {
  color: #34343c;
}

.promo-block-v12 .promo-block-v12-form::-webkit-input-placeholder {
  color: #34343c;
}

.promo-block-v12 .promo-block-v12-form:focus {
  color: #BAF402;
  background: #fff;
  border-color: #fff;
}

.promo-block-v12 .promo-block-v12-form:focus::-moz-placeholder {
  color: #BAF402;
}

.promo-block-v12 .promo-block-v12-form:focus:-ms-input-placeholder {
  color: #BAF402;
}

.promo-block-v12 .promo-block-v12-form:focus::-webkit-input-placeholder {
  color: #BAF402;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  .promo-block-v12 .promo-block-v12-wrap:before {
    width: 100%;
    height: 100%;
  }
  .promo-block-v12 .promo-block-v12-title {
    font-size: 26px;
  }
}

/*------------------------------------------------------------------
    [Promo Block v13]
------------------------------------------------------------------*/
.promo-block-v13 {
  position: relative;
  z-index: 1;
  background: url(../img/1920x1080/43.jpg) no-repeat;
  background-size: cover;
  background-position: center top;
}

.promo-block-v13:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.7);
  content: " ";
}

.promo-block-v13 .promo-block-v13-title {
  font-size: 50px;
  color: #fff;
  margin-bottom: 0;
}

.promo-block-v13 .promo-block-v13-text {
  color: #fff;
  opacity: .7;
  margin-bottom: 3px;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .promo-block-v13 .promo-block-v13-title {
    font-size: 26px;
  }
}

/*--------------------------------------------------
    [Serviecs v1]
----------------------------------------------------*/
.services-v1 {
  text-align: center;
  background: #fff;
  box-shadow: 0 2px 5px 3px #eff1f8;
  padding: 60px 40px;
}

.services-v1 .services-v1-icon-wrap {
  width: 150px;
  height: 150px;
  padding: 22px;
  margin: 0 auto 50px;
  box-shadow: 0 5px 10px 0 #f6f7fb;
}

.services-v1 .services-v1-icon-wrap .services-v1-icon {
  width: 90px;
  height: auto;
}

.services-v1 .services-v1-subtitle {
  display: block;
  font-size: 16px;
  font-style: italic;
  font-family: Droid Serif, serif;
  margin-bottom: 5px;
}

.services-v1 .services-v1-title {
  font-size: 26px;
}

.services-v1 .services-v1-text {
  margin-bottom: 0;
}

/*--------------------------------------------------
    [Serviecs v2]
----------------------------------------------------*/
.services-v2 {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 30px;
}

.services-v2:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.7);
  content: " ";
}

.services-v2.services-v2-step-one {
  background: url(../../assets/img/970x647/32.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v2.services-v2-step-two {
  background: url(../../assets/img/970x647/33.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v2.services-v2-step-three {
  background: url(../../assets/img/970x647/34.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v2 .services-v2-header {
  margin-bottom: 20px;
}

.services-v2 .services-v2-header .services-v2-header-subtitle {
  display: block;
  font-size: 18px;
  font-style: italic;
  color: #fff;
}

.services-v2 .services-v2-header .services-v2-header-title {
  font-size: 30px;
  font-weight: 400;
  color: #fff;
  text-transform: inherit;
  line-height: 1.1;
  margin: 0;
}

.services-v2 .services-v2-list {
  padding-left: 8px;
  margin-bottom: 0;
}

.services-v2 .services-v2-list .services-v2-list-item {
  position: relative;
  width: 100%;
  color: #fff;
  padding: 7px 0;
}

.services-v2 .services-v2-list .services-v2-list-item:before, .services-v2 .services-v2-list .services-v2-list-item:after {
  content: " ";
  display: table;
}

.services-v2 .services-v2-list .services-v2-list-item:after {
  clear: both;
}

.services-v2 .services-v2-list .services-v2-list-item:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
  content: " ";
}

.services-v2 .services-v2-list .services-v2-list-item:first-child:after {
  display: none;
}

.services-v2 .services-v2-list .services-v2-list-item .services-v2-list-subtitle {
  font-size: 14px;
  float: right;
  padding-left: 10px;
}

.services-v2 .services-v2-testimonials {
  overflow: hidden;
}

.services-v2 .services-v2-testimonials .services-v2-testimonials-quote {
  font-style: italic;
  color: #fff;
  margin-bottom: 20px;
}

.services-v2 .services-v2-testimonials .services-v2-testimonials-body {
  width: 40px;
}

.services-v2 .services-v2-testimonials .services-v2-testimonials-user-picture {
  width: 40px;
  height: 40px;
  border: 3px solid #fff;
  margin-left: 5px;
}

.services-v2 .services-v2-testimonials .services-v2-testimonials-author {
  font-size: 15px;
  color: #fff;
  margin: 0;
}

.services-v2 .services-v2-testimonials .services-v2-testimonials-author-comp {
  display: block;
  font-size: 13px;
  color: #fff;
}

.services-v2 .services-v2-form {
  height: 30px;
  color: #fff;
  background: 0;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0;
}

.services-v2 .services-v2-form::-moz-placeholder {
  color: #fff;
}

.services-v2 .services-v2-form:-ms-input-placeholder {
  color: #fff;
}

.services-v2 .services-v2-form::-webkit-input-placeholder {
  color: #fff;
}

.services-v2 .services-v2-form:focus {
  color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.services-v2 .services-v2-form:focus::-moz-placeholder {
  color: #fff;
}

.services-v2 .services-v2-form:focus:-ms-input-placeholder {
  color: #fff;
}

.services-v2 .services-v2-form:focus::-webkit-input-placeholder {
  color: #fff;
}

/*--------------------------------------------------
    [Serviecs v3]
----------------------------------------------------*/
.services-v3 {
  position: relative;
  padding: 25px;
}

.services-v3.services-v3-color-one {
  position: relative;
  z-index: 1;
  background: url(../../assets/img/970x647/60.jpg) no-repeat;
  background-size: cover;
  background-position: center top;
}

.services-v3.services-v3-color-one:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(0, 150, 136, 0.8);
  content: " ";
}

.services-v3.services-v3-color-two {
  position: relative;
  z-index: 1;
  background: url(../../assets/img/970x647/22.jpg) no-repeat;
  background-size: cover;
  background-position: center top;
}

.services-v3.services-v3-color-two:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(229, 89, 115, 0.8);
  content: " ";
}

.services-v3.services-v3-color-three {
  position: relative;
  z-index: 1;
  background: url(../../assets/img/970x970/27.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v3.services-v3-color-three:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(186, 244, 2, 0.8);
  content: " ";
}

.services-v3.services-v3-color-four {
  position: relative;
  z-index: 1;
  background: url(../../assets/img/970x647/48.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v3.services-v3-color-four:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(178, 96, 206, 0.8);
  content: " ";
}

.services-v3.services-v3-color-five {
  position: relative;
  z-index: 1;
  background: url(../../assets/img/970x647/44.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v3.services-v3-color-five:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.8);
  content: " ";
}

.services-v3 .services-v3-header {
  padding-bottom: 50px;
}

.services-v3 .services-v3-header .services-v3-title {
  font-size: 26px;
  color: #fff;
  margin: 0 0 10px;
}

.services-v3 .services-v3-header .services-v3-text {
  color: #fff;
}

.services-v3 .services-v3-content .services-v3-content-element {
  position: absolute;
  left: 25px;
  bottom: 25px;
  font-size: 36px;
  color: #fff;
  margin-right: 10px;
}

.services-v3 .services-v3-content .services-v3-content-link {
  position: absolute;
  right: 25px;
  bottom: 25px;
  font-size: 13px;
  background: rgba(52, 52, 60, 0.3);
  color: #fff;
  padding: 3px 8px;
  transition-duration: 250ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v3 .services-v3-content .services-v3-content-link:hover {
  color: #34343c;
  background: white;
  text-decoration: none;
  transition-duration: 250ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Serviecs v4]
----------------------------------------------------*/
.services-v4 {
  position: relative;
  padding: 20px;
}

.services-v4:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.15);
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  content: " ";
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v4 .services-v4-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 2;
  text-decoration: none;
}

.services-v4 .services-v4-media {
  max-width: 90px;
  min-width: 90px;
}

.services-v4 .services-v4-media .services-v4-icon {
  width: 70px;
  height: 70px;
}

.services-v4 .services-v4-body {
  position: relative;
  z-index: 1;
}

.services-v4 .services-v4-body .services-v4-content {
  overflow: hidden;
}

.services-v4 .services-v4-body .services-v4-title,
.services-v4 .services-v4-body .services-v4-text,
.services-v4 .services-v4-body .services-v4-learn {
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v4 .services-v4-body .services-v4-title {
  font-size: 24px;
  color: #fff;
  margin: 0 0 10px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.services-v4 .services-v4-body .services-v4-text {
  color: #fff;
  opacity: 1;
  margin-bottom: 5px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.services-v4 .services-v4-body .services-v4-learn {
  font-weight: 500;
  display: block;
  color: #34343c;
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.services-v4:hover:before {
  background: white;
  -webkit-transform: scale(1.075, 1.075);
  -moz-transform: scale(1.075, 1.075);
  transform: scale(1.075, 1.075);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v4:hover .services-v4-title,
.services-v4:hover .services-v4-text,
.services-v4:hover .services-v4-learn {
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v4:hover .services-v4-title {
  color: #34343c;
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.services-v4:hover .services-v4-text {
  color: #606060;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.services-v4:hover .services-v4-learn {
  margin-bottom: 0;
  opacity: 1;
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

/*--------------------------------------------------
    [Service Block v5]
----------------------------------------------------*/
.services-v5 .services-v5-wrap {
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
}

.services-v5 .services-v5-wrap .services-v5-no {
  position: relative;
  display: inline-block;
  font-size: 36px;
  font-style: italic;
  font-weight: 700;
  color: #34343c;
  padding-right: 20px;
}

.services-v5 .services-v5-wrap .services-v5-no:after {
  position: absolute;
  top: 12px;
  right: 8px;
  width: 1px;
  height: 28px;
  display: inline-block;
  background: #34343c;
  content: " ";
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  transform: rotate(30deg);
}

.services-v5 .services-v5-wrap .services-v5-body-title {
  display: inline-block;
  font-size: 16px;
  font-style: italic;
  line-height: 1;
  text-transform: inherit;
  margin: 0;
}

.services-v5 .services-v5-text {
  font-size: 14px;
}

/*--------------------------------------------------
    [Serviecs v6]
----------------------------------------------------*/
.services-v6 {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 80px 40px;
}

.services-v6:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.4);
  content: " ";
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v6.services-v6-bg-img-one {
  background: url(../../assets/img/970x970/10.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v6.services-v6-bg-img-two {
  background: url(../../assets/img/970x970/12.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v6.services-v6-bg-img-three {
  background: url(../../assets/img/970x970/09.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v6.services-v6-bg-img-four {
  background: url(../../assets/img/970x970/13.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v6 .services-v6-icon {
  display: inline-block;
  width: 130px;
  height: 130px;
  font-size: 65px;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  border: 2px solid #fff;
  vertical-align: middle;
  padding: 20px;
  margin-bottom: 30px;
}

.services-v6 .services-v6-title {
  font-size: 24px;
  color: #fff;
}

.services-v6 .services-v6-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 3;
  text-decoration: none;
}

.services-v6:hover:before {
  background: rgba(186, 244, 2, 0.8);
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*------------------------------------------------------------------
    [Service Block v7]
------------------------------------------------------------------*/
.services-v7 {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.services-v7:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.7);
  content: " ";
}

.services-v7.services-v7-img-one, .services-v7.services-v7-img-two, .services-v7.services-v7-img-three {
  width: 100%;
  height: 450px;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v7.services-v7-img-one {
  background: url(../../assets/img/970x970/19.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v7.services-v7-img-two {
  background: url(../../assets/img/970x970/21.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v7.services-v7-img-three {
  background: url(../../assets/img/970x970/23.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v7 .services-v7-body {
  position: relative;
  top: 50%;
  padding: 40px;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.services-v7 .services-v7-title {
  font-size: 28px;
  color: #fff;
}

.services-v7 .services-v7-text {
  color: #fff;
}

.services-v7 .services-v7-more {
  overflow: hidden;
}

.services-v7 .services-v7-link {
  display: block;
  text-decoration: none;
}

.services-v7 .services-v7-link-title {
  opacity: 0;
  color: #fff;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v7 .services-v7-link-icon {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 24px;
  font-style: inherit;
  font-family: FontAwesome;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
  vertical-align: middle;
  padding: 3px;
  margin-right: 10px;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v7 .services-v7-link-icon:before {
  content: "\f107";
}

.services-v7 .services-v7-link-icon:hover {
  background: #BAF402;
  border-color: #BAF402;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v7:hover.services-v7-img-one, .services-v7:hover.services-v7-img-two, .services-v7:hover.services-v7-img-three {
  width: 100%;
  height: 450px;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v7:hover.services-v7-img-one {
  background: url(../../assets/img/970x970/20.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v7:hover.services-v7-img-two {
  background: url(../../assets/img/970x970/22.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v7:hover.services-v7-img-three {
  background: url(../../assets/img/970x970/24.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v7:hover .services-v7-link-title {
  opacity: 1;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v7:hover .services-v7-link-icon:before {
  position: absolute;
  top: 19px;
  left: 12px;
  width: 15px;
  height: 1px;
  background: #fff;
  content: " ";
}

.services-v7:hover .services-v7-link-icon:after {
  position: absolute;
  top: 12px;
  left: 19px;
  width: 1px;
  height: 15px;
  background: #fff;
  content: " ";
}

/*--------------------------------------------------
    [Serviecs v8]
----------------------------------------------------*/
.services-v8 {
  display: block;
  background: #f7f8fa;
  overflow: hidden;
  padding: 20px;
  margin-bottom: 10px;
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v8 .services-v8-no,
.services-v8 .services-v8-text,
.services-v8 .services-v8-like,
.services-v8 .services-v8-like-amount,
.services-v8 .services-v8-like-icon {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v8 .services-v8-no {
  width: 40px;
  font-size: 18px;
  font-weight: 300;
  color: #606060;
  text-align: center;
  margin-right: 10px;
}

.services-v8 .services-v8-text {
  font-size: 18px;
  font-weight: 300;
  color: #34343c;
}

.services-v8 .services-v8-like {
  float: right;
  text-align: center;
  opacity: 0;
  margin-right: -100px;
}

.services-v8 .services-v8-like .services-v8-like-amount {
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  margin-right: 3px;
}

.services-v8 .services-v8-like .services-v8-like-icon {
  font-size: 18px;
}

.services-v8:focus, .services-v8:hover {
  text-decoration: none;
}

.services-v8:hover {
  background: #BAF402;
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v8:hover .services-v8-no,
.services-v8:hover .services-v8-text,
.services-v8:hover .services-v8-like,
.services-v8:hover .services-v8-like-amount,
.services-v8:hover .services-v8-like-icon {
  color: #fff;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v8:hover .services-v8-no {
  opacity: 0;
  margin-left: -40px;
}

.services-v8:hover .services-v8-like {
  opacity: 1;
  margin-right: 0;
}

/*--------------------------------------------------
    [Service Block v9]
----------------------------------------------------*/
.services-v9 {
  text-align: center;
  padding: 80px 40px;
}

.services-v9 .services-v9-icon {
  display: block;
  font-size: 40px;
  color: #fff;
  opacity: .9;
  margin-bottom: 20px;
}

.services-v9 .services-v9-title {
  font-size: 28px;
  color: #fff;
}

.services-v9 .services-v9-text {
  font-size: 18px;
  color: #fff;
  opacity: .85;
  margin-bottom: 20px;
}

/*--------------------------------------------------
    [Services v10]
----------------------------------------------------*/
.services-v10 {
  padding: 60px;
}

.services-v10.services-v10-border-l {
  position: relative;
  border-left: 1px solid #ebeef6;
}

.services-v10.services-v10-border-l:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 20px;
  background: lightgray;
  box-shadow: 0 0 0 4px #fff;
  border: 1px solid #f7f8fa;
  content: " ";
  margin: -10px 0 0 -10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.services-v10.services-v10-border-r {
  position: relative;
  border-right: 1px solid #ebeef6;
}

.services-v10.services-v10-border-r:after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 20px;
  height: 20px;
  background: lightgray;
  box-shadow: 0 0 0 4px #fff;
  border: 1px solid #f7f8fa;
  content: " ";
  margin: -10px -10px 0 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.services-v10 .services-v10-no {
  display: block;
  font-size: 70px;
  font-style: italic;
  font-weight: 700;
  font-family: Droid Serif, serif;
  color: #34343c;
}

.services-v10 .services-v10-title {
  font-size: 24px;
  letter-spacing: 1px;
  text-transform: inherit;
  margin-bottom: 20px;
}

.services-v10 .services-v10-subtitle {
  display: block;
  font-size: 14px;
  color: #606060;
  margin-bottom: 20px;
}

.services-v10 .services-v10-text {
  margin-bottom: 30px;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .services-v10.services-v10-border-l {
    border-top: 1px solid #ebeef6;
    border-left: none;
  }
  .services-v10.services-v10-border-l:before {
    position: absolute;
    top: 0;
    left: 50%;
    margin: -10px 0 0 -10px;
  }
  .services-v10.services-v10-border-r {
    border-bottom: 1px solid #ebeef6;
    border-right: none;
  }
  .services-v10.services-v10-border-r:after {
    position: absolute;
    top: auto;
    left: 50%;
    right: auto;
    bottom: -10px;
    margin: 0 0 0 -10px;
  }
}

/*--------------------------------------------------
    [Servives v11]
----------------------------------------------------*/
.services-v11 {
  padding: 30px;
}

.services-v11 .services-v11-subtitle {
  display: block;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  font-family: Droid Serif, serif;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.services-v11 .services-v11-title {
  position: relative;
  font-size: 22px;
  color: #fff;
  margin: 0 0 30px;
}

.services-v11 .services-v11-title:after {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 30px;
  height: 2px;
  background: #fff;
  content: " ";
}

.services-v11 .services-v11-text {
  color: #fff;
}

/*--------------------------------------------------
    [Services v12]
----------------------------------------------------*/
.services-v12 {
  padding: 60px 30px;
}

.services-v12.services-v12-bg-color {
  background: #34343c;
}

.services-v12.services-v12-bg-img-one {
  background: url(../../assets/img/970x970/01.jpg) no-repeat;
}

.services-v12.services-v12-bg-img-two {
  background: url(../../assets/img/970x970/04.jpg) no-repeat;
}

.services-v12.services-v12-bg-img-one, .services-v12.services-v12-bg-img-two {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center center;
}

.services-v12.services-v12-bg-img-one:before, .services-v12.services-v12-bg-img-two:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(52, 52, 60, 0.92);
  content: " ";
}

.services-v12 .services-v12-icon {
  font-size: 30px;
  color: #fff;
  margin-bottom: 20px;
}

.services-v12 .services-v12-title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 10px;
}

.services-v12 .services-v12-title.services-v12-title-element {
  position: relative;
  margin-bottom: 30px;
}

.services-v12 .services-v12-title.services-v12-title-element:after {
  position: absolute;
  left: 0;
  bottom: -12px;
  width: 30px;
  height: 1px;
}

.services-v12 .services-v12-title.services-v12-title-element:before {
  position: absolute;
  left: 0;
  bottom: -15px;
  width: 20px;
  height: 1px;
}

.services-v12 .services-v12-title.services-v12-title-element:after, .services-v12 .services-v12-title.services-v12-title-element:before {
  background: white;
  content: " ";
}

.services-v12 .services-v12-text {
  font-size: 13px;
  color: #fff;
}

.services-v12 .services-v12-link {
  display: block;
  text-decoration: none;
  opacity: 0;
  -webkit-transform: translate3d(-5%, 0, 0);
  -moz-transform: translate3d(-5%, 0, 0);
  transform: translate3d(-5%, 0, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v12:hover .services-v12-link {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Team v1]
----------------------------------------------------*/
.team-v1 {
  position: relative;
  overflow: hidden;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v1 .team-v1-content {
  z-index: 1;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.team-v1 .team-v1-content .team-v1-member {
  font-size: 18px;
  margin: 0 0 15px;
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v1 .team-v1-content .team-v1-socials {
  opacity: 0;
  margin-bottom: 0;
  -webkit-transform: translate3d(0, 120%, 0);
  -moz-transform: translate3d(0, 120%, 0);
  transform: translate3d(0, 120%, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v1 .team-v1-content .team-v1-socials .team-v1-socials-link {
  font-size: 14px;
  color: #34343c;
}

.team-v1 .team-v1-content .team-v1-socials .team-v1-socials-link:hover {
  color: #BAF402;
  text-decoration: none;
}

.team-v1:hover {
  box-shadow: 0 0 40px -6px #d8dde6;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v1:hover .team-v1-content .team-v1-member {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v1:hover .team-v1-content .team-v1-socials {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Team v2]
----------------------------------------------------*/
.team-v2 {
  position: relative;
}

.team-v2 .team-v2-img-gradient {
  position: relative;
}

.team-v2 .team-v2-img-gradient:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  content: " ";
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v2 .team-v2-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
}

.team-v2 .team-v2-center-align {
  width: 100%;
  height: auto;
  opacity: 0;
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  transform: scale(0, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v2 .team-v2-member {
  font-size: 20px;
  margin: 0 0 5px;
}

.team-v2 .team-v2-member-position {
  display: block;
  font-size: 14px;
  color: #BAF402;
}

.team-v2 .team-v2-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 3;
  text-decoration: none;
}

.team-v2:hover .team-v2-img-gradient:after {
  background: rgba(255, 255, 255, 0.9);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v2:hover .team-v2-center-align {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Team v3]
----------------------------------------------------*/
.team-v3 {
  background: #fff;
  padding: 20px;
}

.team-v3 .team-v3-img-wrap {
  position: relative;
  overflow: hidden;
}

.team-v3 .team-v3-img-wrap .team-v3-overlay-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  opacity: 0;
  margin-bottom: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.team-v3 .team-v3-header {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
}

.team-v3 .team-v3-header .team-v3-member {
  font-size: 20px;
  margin: 0 0 5px;
}

.team-v3 .team-v3-header .team-v3-member-position {
  display: block;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  font-family: Droid Serif, serif;
  color: #BAF402;
}

.team-v3 .team-v3-paragraph a:hover {
  text-decoration: none;
}

.team-v3 .team-v3-member-contact {
  font-size: 13px;
}

.team-v3:hover .team-v3-overlay-content {
  opacity: 1;
  -webkit-transform: translate3d(0, -5px, 0);
  -moz-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
  -webkit-transition: all 0.5 ease;
  -moz-transition: all 0.5 ease;
  transition: all 0.5 ease;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .team-v3 {
    text-align: center;
  }
  .team-v3 .team-v3-img-wrap {
    margin-right: 0;
  }
}

/*--------------------------------------------------
    [Team v4]
----------------------------------------------------*/
.team-v4 {
  text-align: center;
  overflow: hidden;
}

.team-v4 .team-v4-img-wrap {
  position: relative;
  overflow: hidden;
}

.team-v4 .team-v4-overlay-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20%;
  text-align: center;
  margin-bottom: 0;
  opacity: 0;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v4 .team-v4-content {
  padding: 20px;
}

.team-v4 .team-v4-content .team-v4-member {
  font-size: 20px;
  color: #3a3a44;
  margin: 0;
}

.team-v4 .team-v4-content .team-v4-member-position {
  display: block;
  font-size: 14px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #606060;
}

.team-v4:hover .team-v4-overlay-content {
  bottom: 10px;
  opacity: 1;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Team v5]
----------------------------------------------------*/
.team-v5 .team-v5-header {
  position: relative;
}

.team-v5 .team-v5-header:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.3);
  content: " ";
}

.team-v5 .team-v5-gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 10px;
}

.team-v5 .team-v5-gradient .team-v5-member {
  font-size: 20px;
  color: #fff;
  margin: 0;
}

.team-v5 .team-v5-gradient .team-v5-member-position {
  display: block;
  font-size: 14px;
  color: #fff;
}

.team-v5 .team-v5-content {
  background: #fff;
  text-align: center;
  padding: 20px;
}

.team-v5 .team-v5-content .team-v5-author-contact {
  font-style: italic;
  color: #3a3a44;
}

.team-v5 .team-v5-content .team-v5-author-contact:hover {
  color: #BAF402;
}

.team-v5 .team-v5-content .progress-title {
  font-size: 13px;
}

/*--------------------------------------------------
    [Team v6]
----------------------------------------------------*/
.team-v6 {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.team-v6 .team-v6-img-gradient {
  position: relative;
}

.team-v6 .team-v6-img-gradient:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.3);
  content: " ";
}

.team-v6 .team-v6-info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  padding: 20px;
}

.team-v6 .team-v6-member {
  font-size: 24px;
  color: #fff;
  opacity: 1;
  visibility: visible;
  margin-bottom: 0;
  -webkit-filter: blur(0);
  -moz-filter: blur(0);
  filter: blur(0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v6 .team-v6-socials {
  opacity: 0;
  visibility: hidden;
  margin: -40px 0 0;
  -webkit-filter: blur(7px);
  -moz-filter: blur(7px);
  filter: blur(7px);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v6:hover .team-v6-member {
  -webkit-filter: blur(7px);
  -moz-filter: blur(7px);
  filter: blur(7px);
  opacity: 0;
  visibility: hidden;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v6:hover .team-v6-socials {
  opacity: 1;
  visibility: visible;
  -webkit-filter: blur(0);
  -moz-filter: blur(0);
  filter: blur(0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Team v7]
----------------------------------------------------*/
.team-v7 {
  background: #fff;
  box-shadow: 0 2px 5px 2px #f2f4f9;
  padding: 30px 20px;
}

.team-v7 .team-v7-img {
  box-shadow: 0 2px 5px 2px #eff1f8;
}

.team-v7 .team-v7-name {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.team-v7 .team-v7-position {
  font-size: 14px;
  font-weight: 300;
}

.team-v7 .team-v7-trigger {
  position: relative;
  display: inline-block;
  width: 27px;
  height: 27px;
  background: #34343c;
  cursor: pointer;
}

.team-v7 .team-v7-trigger:before {
  position: absolute;
  top: 7px;
  left: 50%;
  width: 1px;
  height: 13px;
  background: #fff;
  content: " ";
  margin-left: -0.5px;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v7 .team-v7-trigger:after {
  position: absolute;
  top: 50%;
  left: 7px;
  width: 13px;
  height: 1px;
  background: #fff;
  content: " ";
  margin-top: -0.5px;
}

.team-v7 .team-v7-trigger.is-clicked:before {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  transform: scale(0, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v7 .team-v7-collapse {
  padding-top: 15px;
}

/*--------------------------------------------------
    [Team v8]
----------------------------------------------------*/
.team-v8 {
  position: relative;
  overflow: hidden;
}

.team-v8 .team-v8-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 3;
  text-decoration: none;
}

.team-v8 .team-v8-img {
  position: relative;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v8 .team-v8-img:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0);
  content: " ";
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v8 .team-v8-info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 40px;
  opacity: 0;
  -webkit-transform: scale(0.9, 0.9);
  -moz-transform: scale(0.9, 0.9);
  transform: scale(0.9, 0.9);
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v8 .team-v8-name {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 2px;
}

.team-v8 .team-v8-position {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}

.team-v8 .team-v8-position:after {
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 100%;
  height: 1px;
  background: #fff;
  content: " ";
}

.team-v8:hover .team-v8-img {
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v8:hover .team-v8-img:before {
  background: rgba(52, 52, 60, 0.7);
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v8:hover .team-v8-info {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Team v9]
----------------------------------------------------*/
.team-v9 {
  text-align: center;
  overflow: hidden;
}

.team-v9 .team-v9-img-wrap {
  position: relative;
  background: #fff;
  box-shadow: 0 2px 5px 2px #f2f4f9;
  width: 210px;
  height: 210px;
  padding: 12px;
  margin: 0 auto;
}

.team-v9 .team-v9-img-wrap:hover .team-v9-img-effect:before {
  background: rgba(52, 52, 60, 0.5);
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v9 .team-v9-img-wrap:hover .team-v9-socials {
  opacity: 1;
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v9 .team-v9-img-effect {
  position: relative;
}

.team-v9 .team-v9-img-effect:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0);
  content: " ";
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v9 .team-v9-img {
  width: 185px;
  height: 185px;
  box-shadow: 0 2px 5px 2px #eff1f8;
}

.team-v9 .team-v9-socials {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v9 .team-v9-name {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.team-v9 .team-v9-position {
  font-size: 14px;
  font-weight: 300;
}

/*--------------------------------------------------
    [Team v10]
----------------------------------------------------*/
.team-v10 .team-v10-img-effect {
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v10 .team-v10-img-effect:hover {
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v10 .team-v10-img-effect-one:hover {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}

.team-v10 .team-v10-img-effect-two {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}

.team-v10 .team-v10-img-effect-two:hover {
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  filter: grayscale(0);
}

.team-v10 .team-v10-img-effect-three {
  -webkit-filter: grayscale(40%);
  -moz-filter: grayscale(40%);
  filter: grayscale(40%);
}

.team-v10 .team-v10-img-effect-three:hover {
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  filter: grayscale(0);
}

.team-v10 .team-v10-name {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 0;
}

.team-v10 .team-v10-position {
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}

/*--------------------------------------------------
    [Team v11]
----------------------------------------------------*/
.team-v11 {
  position: relative;
}

.team-v11 .team-v11-name {
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 0;
}

.team-v11 .team-v11-position {
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}

/* Media Queries below 992px */
@media (min-width: 992px) {
  .team-v11 .team-v11-img-wrap {
    position: relative;
    height: 277px;
  }
  .team-v11 .team-v11-img {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

/*--------------------------------------------------
    [Team v12]
----------------------------------------------------*/
.team-v12 {
  position: relative;
  overflow: hidden;
}

.team-v12 .team-v12-info {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
}

.team-v12 .team-v12-name,
.team-v12 .team-v12-position,
.team-v12 .team-v12-text,
.team-v12 .team-v12-socials {
  color: #fff;
  opacity: 0;
  visibility: hidden;
  margin-bottom: 0;
  -webkit-transform: translate3d(-50px, 0, 0);
  -moz-transform: translate3d(-50px, 0, 0);
  transform: translate3d(-50px, 0, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v12 .team-v12-name {
  font-size: 32px;
}

.team-v12 .team-v12-position {
  font-size: 20px;
  opacity: .9;
}

.team-v12 .team-v12-text {
  opacity: .7;
}

.team-v12:hover .team-v12-name,
.team-v12:hover .team-v12-position,
.team-v12:hover .team-v12-text,
.team-v12:hover .team-v12-socials {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.team-v12:hover .team-v12-name {
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v12:hover .team-v12-position {
  transition-duration: 500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v12:hover .team-v12-text {
  transition-duration: 600ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.team-v12:hover .team-v12-socials {
  transition-duration: 700ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Testimonials v1]
----------------------------------------------------*/
.testimonials-v1 {
  position: relative;
  box-shadow: 0 4px 8px -3px #b6c1de;
  margin: 15px 0 10px;
}

.testimonials-v1:before {
  position: absolute;
  top: -10px;
  left: 9px;
  width: 95%;
  height: 20%;
  z-index: 1;
  background: #fdfdfd;
  box-shadow: inset 0 10px 10px 0 #fdfdfe;
  content: " ";
}

.testimonials-v1 .testimonials-v1-body {
  position: relative;
  z-index: 2;
  text-align: center;
  background: #fff;
  padding: 20px;
}

.testimonials-v1 .testimonials-v1-user-picture {
  width: 60px;
  height: 60px;
  border: 3px solid #ebeef6;
  margin-bottom: 15px;
}

.testimonials-v1 .testimonials-v1-quote {
  font-size: 14px;
}

.testimonials-v1 .testimonials-v1-author {
  font-size: 18px;
  color: #BAF402;
  margin: 0 0 5px;
}

.testimonials-v1 .testimonials-v1-author-position {
  display: block;
  font-size: 15px;
  font-style: normal;
  font-family: "Roboto";
  color: #3a3a44;
}

/*--------------------------------------------------
    [Testimonials v2]
----------------------------------------------------*/
.testimonials-v2 {
  position: relative;
  z-index: 1;
  padding: 0 10px;
}

.testimonials-v2.testimonials-v2-icon-one:before, .testimonials-v2.testimonials-v2-icon-two:before, .testimonials-v2.testimonials-v2-icon-three:before {
  position: absolute;
  top: -35px;
  right: -10px;
  display: inline-block;
  font-size: 100px;
  font-family: et-line;
  color: #fff;
  opacity: .2;
}

.testimonials-v2.testimonials-v2-icon-one:before {
  content: "\e02b";
}

.testimonials-v2.testimonials-v2-icon-two:before {
  content: "\e016";
}

.testimonials-v2.testimonials-v2-icon-three:before {
  content: "\e03c";
}

.testimonials-v2 .testimonials-v2-title,
.testimonials-v2 .testimonials-v2-quote,
.testimonials-v2 .testimonials-v2-author {
  color: #fff;
}

.testimonials-v2 .testimonials-v2-title {
  font-size: 24px;
}

.testimonials-v2 .testimonials-v2-quote {
  opacity: .8;
  margin-bottom: 25px;
}

.testimonials-v2 .testimonials-v2-author {
  display: block;
  font-size: 16px;
}

/*--------------------------------------------------
    [Testimonials v3]
----------------------------------------------------*/
.testimonials-v3 {
  text-align: center;
  background: #fff;
  box-shadow: 0 2px 5px 3px #eff1f8;
  padding: 30px;
  margin: 5px;
}

.testimonials-v3 .testimonials-v3-subtitle {
  position: relative;
  display: block;
  font-size: 16px;
  font-style: italic;
  margin: 50px 0 20px;
}

.testimonials-v3 .testimonials-v3-subtitle:before {
  position: absolute;
  top: -60px;
  left: 50%;
  font-size: 70px;
  font-family: Droid Serif, serif;
  color: #34343c;
  content: "“";
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}

.testimonials-v3 .testimonials-v3-title {
  font-size: 26px;
}

.testimonials-v3 .testimonials-v3-quote {
  margin-bottom: 10px;
}

.testimonials-v3 .testimonials-v3-user-img {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto 10px;
}

.testimonials-v3 .testimonials-v3-author {
  font-size: 16px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #3a3a44;
  margin-bottom: 0;
}

/*--------------------------------------------------
    [Testimonials v4]
----------------------------------------------------*/
.testimonials-v4 {
  text-align: center;
  padding: 20px 0;
}

.testimonials-v4 .testimonials-v4-header {
  padding: 0 50px;
}

.testimonials-v4 .testimonials-v4-header .testimonials-v4-subtitle {
  display: block;
  font-size: 18px;
  font-style: italic;
  color: #3a3a44;
  margin-bottom: 5px;
}

.testimonials-v4 .testimonials-v4-header .testimonials-v4-title {
  font-size: 26px;
}

.testimonials-v4 .testimonials-v4-img {
  width: 100px;
  height: auto;
  display: block;
  text-align: center;
  border: 5px solid rgba(255, 255, 255, 0.3);
  margin: 0 auto 20px;
}

.testimonials-v4 .testimonials-v4-quote {
  font-style: italic;
}

.testimonials-v4 .testimonials-v4-rating-list > li {
  padding: 0;
}

.testimonials-v4 .testimonials-v4-author {
  font-size: 18px;
  color: #BAF402;
  margin-bottom: 10px;
}

.testimonials-v4 .testimonials-v4-author-position {
  font-size: 15px;
  font-style: italic;
  color: #606060;
}

/* Testimonials v4 White Version */
.testimonials-v4-white .testimonials-v4-header .testimonials-v4-subtitle {
  color: #fff;
}

.testimonials-v4-white .testimonials-v4-header .testimonials-v4-title {
  color: #fff;
}

.testimonials-v4-white .testimonials-v4-header .testimonials-v4-title:before, .testimonials-v4-white .testimonials-v4-header .testimonials-v4-title:after {
  background: #fff;
}

.testimonials-v4-white .testimonials-v4-quote {
  color: #fff;
}

.testimonials-v4-white .testimonials-v4-author-position {
  color: #f1f1f1;
}

/*--------------------------------------------------
    [Testimonials v5]
----------------------------------------------------*/
.testimonials-v5 {
  text-align: center;
}

.testimonials-v5 .testimonials-v5-quote {
  position: relative;
  margin-bottom: 30px;
}

.testimonials-v5 .testimonials-v5-quote:before {
  position: absolute;
  top: -25px;
  left: 50%;
  width: 40px;
  height: 1px;
  background: #fff;
  content: " ";
  margin-left: -20px;
}

.testimonials-v5 .testimonials-v5-img {
  width: 100px;
  height: auto;
  display: block;
  text-align: center;
  border: 5px solid #fff;
  margin: 0 auto 20px;
}

.testimonials-v5 .testimonials-v5-author {
  font-size: 26px;
  color: #BAF402;
}

.testimonials-v5 .testimonials-v5-quote-text {
  font-size: 24px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #fff;
}

/*--------------------------------------------------
    [Testimonials v6]
----------------------------------------------------*/
.testimonials-v6 {
  background: #f7f8fa;
  padding: 60px 0;
}

.testimonials-v6 .testimonials-v6-quote {
  position: relative;
  background: #fff;
  box-shadow: 3px 3px 3px 0 rgba(182, 193, 222, 0.3);
  padding: 15px;
  margin-bottom: 25px;
}

.testimonials-v6 .testimonials-v6-quote:after {
  position: absolute;
  left: 40px;
  bottom: -18px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 0 0;
  border-color: #fff transparent transparent;
  filter: drop-shadow(2px 2px 1px rgba(182, 193, 222, 0.3));
  -moz-filter: drop-shadow(2px 2px 1px rgba(182, 193, 222, 0.3));
  -webkit-filter: drop-shadow(2px 2px 1px rgba(182, 193, 222, 0.3));
  content: " ";
}

.testimonials-v6 .testimonials-v6-author {
  overflow: hidden;
}

.testimonials-v6 .testimonials-v6-author .testimonials-v6-user-picture {
  width: 55px;
  height: 55px;
  float: left;
  border: 2px solid #fff;
  margin-right: 15px;
}

.testimonials-v6 .testimonials-v6-element {
  overflow: hidden;
  margin-top: 3px;
}

.testimonials-v6 .testimonials-v6-element .testimonials-v6-author {
  font-size: 18px;
  color: #BAF402;
  margin: 0 0 2px;
}

.testimonials-v6 .testimonials-v6-element .testimonials-v6-position {
  display: block;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #606060;
}

.testimonials-v6 .testimonials-v6-quote-text {
  font-size: 16px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #3a3a44;
}

/* Testimonials v6 White Version */
.testimonials-v6-white .testimonials-v6-quote {
  color: #fff;
  background: #e4e4e4;
}

.testimonials-v6-white .testimonials-v6-quote:after {
  border-color: #e4e4e4 transparent transparent;
}

.testimonials-v6-white .testimonials-v6-element .testimonials-v6-author-position {
  color: #f1f1f1;
}

/*--------------------------------------------------
    [Testimonials v7]
----------------------------------------------------*/
.testimonials-v7 {
  position: relative;
  z-index: 1;
  background: url(../../assets/img/1920x1080/08.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.testimonials-v7:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.8);
  content: " ";
}

.testimonials-v7 .testimonials-v7-title {
  position: relative;
  font-size: 36px;
  font-weight: 300;
  color: #fff;
  line-height: 1.2;
  text-align: right;
  padding-left: 50px;
  margin-bottom: 0;
}

.testimonials-v7 .testimonials-v7-title:before {
  position: absolute;
  top: -25px;
  left: 0;
  display: inline-block;
  font-size: 100px;
  font-family: Droid Serif, serif;
  color: #fff;
  content: "“";
}

.testimonials-v7 .testimonials-v7-author {
  position: relative;
  display: block;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  font-family: Droid Serif, serif;
  color: #BAF402;
  padding-left: 30px;
}

.testimonials-v7 .testimonials-v7-author:before {
  position: absolute;
  top: 13px;
  left: 0;
  width: 20px;
  height: 1px;
  display: inline-block;
  background: #BAF402;
  content: " ";
}

.testimonials-v7 .testimonials-v7-text {
  font-size: 16px;
  font-style: italic;
  color: #fff;
  line-height: 1.6;
  opacity: .95;
}

/*------------------------------------------------------------------
    [Footer v6]
------------------------------------------------------------------*/
.footer-v6 {
  background: #fcfcfd;
}

.footer-v6 .footer-v6-logo-img {
  width: 85px;
  height: auto;
}

.footer-v6 .footer-v6-title {
  font-size: 22px;
}

.footer-v6 .footer-link-v6-list {
  margin-bottom: 0;
}

.footer-v6 .footer-link-v6-list .footer-link-v6-list-item {
  color: #606060;
  padding: 4px 0;
}

.footer-v6 .footer-link-v6-list .footer-link-v6-list-item .footer-link-v6-list-link {
  color: #606060;
}

.footer-v6 .footer-link-v6-list .footer-link-v6-list-item .footer-link-v6-list-link:hover {
  color: #5F7E01 !important;
  text-decoration: none;
}

.footer-v6 .footer-link-v6-list .footer-link-v6-list-item-divider {
  border-bottom: 1px solid #ebeef6;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

/* Footer v6 Copyright */
.footer-v6 .footer-v6-copyright {
  background: #f7f8fa;
  text-align: center;
  padding: 20px 0;
}

.footer-v6 .footer-v6-copyright .footer-v6-copyright-text {
  font-size: 14px;
  margin: 6px 0;
}

/*--------------------------------------------------
    [Header]
----------------------------------------------------*/
.header {
  border: none;
  background: #fff;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* Header Fixed */
.header.header-fixed {
  position: relative;
}

/* Header Box Shadow */
.header.header-box-shadow:before, .header.header-box-shadow:after {
  position: absolute;
  top: 50%;
  left: 10px;
  right: 10px;
  bottom: 0;
  z-index: -1;
  box-shadow: 0 0 15px #e8e8ec;
  border-radius: 1000px / 30px;
  content: " ";
}

.header.header-box-shadow:after {
  left: auto;
  right: 10px;
}

/* Auto Hiding Navbar */
.header.auto-hiding-navbar.nav-up {
  top: -80px;
}

/* Navbar */
.header .navbar {
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}

/* Navbar Toggle */
.header .navbar-toggle {
  width: 25px;
  height: 25px;
  float: left;
  border: none;
  padding: 0;
  margin: 32px 0;
}

.header .navbar-toggle .toggle-icon {
  position: relative;
  width: 15px;
  height: 1px;
  display: inline-block;
  background: #34343c;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header .navbar-toggle .toggle-icon:before, .header .navbar-toggle .toggle-icon:after {
  position: absolute;
  left: 0;
  width: 15px;
  height: 1px;
  background: #34343c;
  content: " ";
}

.header .navbar-toggle .toggle-icon:before {
  bottom: 10px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  transform: rotate(0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header .navbar-toggle .toggle-icon:after {
  top: -5px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  transform: rotate(0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header .navbar-toggle .toggle-icon.is-clicked {
  background: rgba(52, 52, 60, 0);
}

.header .navbar-toggle .toggle-icon.is-clicked:before, .header .navbar-toggle .toggle-icon.is-clicked:after {
  background: #34343c;
}

.header .navbar-toggle .toggle-icon.is-clicked:before {
  bottom: 5px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header .navbar-toggle .toggle-icon.is-clicked:after {
  top: -5px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header .navbar-toggle:hover .toggle-icon {
  background: #BAF402;
}

.header .navbar-toggle:hover .toggle-icon:before, .header .navbar-toggle:hover .toggle-icon:after {
  background: #BAF402;
}

.header .navbar-toggle:hover .toggle-icon.is-clicked {
  background: rgba(52, 52, 60, 0);
}

/* Topbar Toggle Trigger */
.header .topbar-toggle-trigger {
  float: left;
  width: 27px;
  height: 27px;
  text-align: center;
  cursor: pointer;
  padding: 35px 0 55px;
}

.header .topbar-toggle-trigger .topbar-toggle-trigger-style {
  position: relative;
  top: 8px;
  display: block;
  width: 4px;
  height: 4px;
  text-align: center;
  background: #34343c;
  border-radius: 50%;
  content: " ";
  margin: 0 auto;
}

.header .topbar-toggle-trigger .topbar-toggle-trigger-style:before, .header .topbar-toggle-trigger .topbar-toggle-trigger-style:after {
  position: absolute;
  left: 50%;
  width: 4px;
  height: 4px;
  background: #34343c;
  border-radius: 50%;
  content: " ";
  margin-left: -2px;
}

.header .topbar-toggle-trigger .topbar-toggle-trigger-style:before {
  top: -6px;
}

.header .topbar-toggle-trigger .topbar-toggle-trigger-style:after {
  bottom: -6px;
}

.header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style {
  background: #BAF402;
}

.header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before, .header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after {
  background: #BAF402;
}

/* Topbar Toggle Trigger Icon */
.header .topbar-toggle-trigger-icon-style {
  color: #34343c;
  padding: 2px 5px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header .topbar-toggle-trigger-icon-style:hover {
  color: #BAF402;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* Navbar Actions */
.header .navbar-actions {
  position: relative;
  float: right;
}

.header .navbar-actions .navbar-actions-shrink {
  line-height: 90px;
  float: left;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* Shopping Cart */
.header .shopping-cart {
  position: relative;
}

.header .shopping-cart .shopping-cart-icon {
  font-size: 18px;
  color: #34343c;
  text-align: center;
  cursor: pointer;
  padding: 0 9px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header .shopping-cart .shopping-cart-icon:hover {
  color: #BAF402;
}

.header .shopping-cart .shopping-cart-amount {
  position: absolute;
  top: 20px;
  right: -3px;
  width: 20px;
  height: 20px;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 1.4;
  text-align: center;
  background: #BAF402;
  vertical-align: middle;
  padding: 2px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header .shopping-cart .shopping-cart-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 310px;
  display: none;
  line-height: 1.4;
  z-index: 1;
  background: #fff;
  border: 1px solid #ebeef6;
  border-top: 2px solid #BAF402;
}

.header .shopping-cart .shopping-cart-menu-title {
  display: block;
  font-size: 20px;
  font-weight: 400;
  color: #34343c;
  border-bottom: 1px solid #ebeef6;
  padding: 15px;
}

.header .shopping-cart .shopping-cart-menu-content {
  display: block;
  padding: 15px;
  border-bottom: 1px solid #ebeef6;
}

.header .shopping-cart .shopping-cart-menu-content:before, .header .shopping-cart .shopping-cart-menu-content:after {
  content: " ";
  display: table;
}

.header .shopping-cart .shopping-cart-menu-content:after {
  clear: both;
}

.header .shopping-cart .shopping-cart-menu-product-media {
  float: left;
  margin-right: 15px;
}

.header .shopping-cart .shopping-cart-menu-product-media .shopping-cart-menu-product-img {
  width: 60px;
  height: auto;
}

.header .shopping-cart .shopping-cart-menu-product-wrap {
  float: left;
  margin-right: 15px;
}

.header .shopping-cart .shopping-cart-menu-product-wrap:before, .header .shopping-cart .shopping-cart-menu-product-wrap:after {
  content: " ";
  display: table;
}

.header .shopping-cart .shopping-cart-menu-product-wrap:after {
  clear: both;
}

.header .shopping-cart .shopping-cart-menu-product-name {
  display: block;
  font-size: 15px;
  color: #34343c;
}

.header .shopping-cart .shopping-cart-menu-product-name:hover {
  color: #BAF402;
  text-decoration: none;
}

.header .shopping-cart .shopping-cart-menu-product-price {
  display: block;
  font-size: 14px;
  color: #535353;
  margin-top: 5px;
}

.header .shopping-cart .shopping-cart-close {
  float: right;
  font-size: 16px;
  color: #535353;
}

.header .shopping-cart .shopping-cart-close:before, .header .shopping-cart .shopping-cart-close:after {
  content: " ";
  display: table;
}

.header .shopping-cart .shopping-cart-close:after {
  clear: both;
}

.header .shopping-cart .shopping-cart-close:hover {
  color: #BAF402;
  text-decoration: none;
}

.header .shopping-cart .shopping-cart-subtotal {
  padding: 15px;
}

.header .shopping-cart .shopping-cart-subtotal-content {
  display: block;
  margin-bottom: 10px;
}

.header .shopping-cart .shopping-cart-subtotal-content:before, .header .shopping-cart .shopping-cart-subtotal-content:after {
  content: " ";
  display: table;
}

.header .shopping-cart .shopping-cart-subtotal-content:after {
  clear: both;
}

.header .shopping-cart .shopping-cart-subtotal-title {
  font-size: 16px;
  color: #34343c;
}

.header .shopping-cart .shopping-cart-subtotal-price {
  font-size: 16px;
  color: #BAF402;
  float: right;
}

.header .shopping-cart .shopping-cart-subtotal-view {
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;
}

.header .shopping-cart .shopping-cart-subtotal-view-link {
  font-size: 13px;
  color: #34343c;
}

.header .shopping-cart .shopping-cart-subtotal-view-link:hover {
  color: #BAF402;
}

.header .shopping-cart:hover .shopping-cart-menu {
  display: block;
}

/* Navbar Logo */
.header .navbar-logo {
  width: 100px;
  height: 100px;
  float: left;
  max-height: 90px;
  line-height: 60px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header .navbar-logo .navbar-logo-wrap {
  display: inline-block;
  padding: 15px 0;
}

.header .navbar-logo .navbar-logo-wrap:focus, .header .navbar-logo .navbar-logo-wrap:hover {
  text-decoration: none;
}

.header .navbar-logo .navbar-logo-img {
  display: inline-block;
  width: 85px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

/* Navbar Nav */
.header .navbar-nav .nav-item-child {
  font-size: 13px;
  font-weight: 500;
  color: #34343c;
  background: 0;
  line-height: 90px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0 17px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header .navbar-nav .nav-item-child.active {
  color: #5F7E01 !important;
}

.header .navbar-nav .nav-item-child.active:hover {
  color: #5F7E01 !important;
  background: 0;
}

.header .navbar-nav .nav-item-child:hover, .header .navbar-nav .nav-item-child:focus {
  color: #5F7E01 !important;
}

.header .navbar-nav .open .nav-item-child:hover, .header .navbar-nav .open .nav-item-child:focus {
  background: 0;
}

/* Dropdown Menu */
.header .dropdown-menu {
  min-width: 235px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  margin-top: 1px;
}

.header .dropdown-menu .dropdown-menu-item {
  color: #4c4c57;
}

.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child {
  font-size: 13px;
  font-weight: 400;
  color: #4c4c57;
  text-transform: uppercase;
  padding: 8px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active {
  color: #BAF402;
}

.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover {
  color: #BAF402;
  background: #fff;
}

.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:focus {
  background: 0;
}

.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover {
  color: #fff;
  background: #BAF402;
  padding-left: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child {
  color: #BAF402;
}

.header .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child:hover {
  background: 0;
}

/* Dropdown Submenu */
.header .dropdown-submenu {
  position: relative;
}

.header .dropdown-submenu:hover .dropdown-submenu-child {
  color: #BAF402;
}

.header .dropdown-submenu:hover .dropdown-menu {
  display: block;
}

.header .dropdown-submenu .dropdown-submenu-child {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #4c4c57;
  text-transform: uppercase;
  cursor: pointer;
  padding: 8px 20px;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header .dropdown-submenu .dropdown-submenu-child:after {
  position: absolute;
  top: 8px;
  right: 12px;
  display: inline-block;
  font-size: 14px;
  font-family: FontAwesome;
  content: "\f105";
}

.header .dropdown-submenu .dropdown-submenu-child.active {
  color: #BAF402;
}

.header .dropdown-submenu .dropdown-submenu-child.active:hover {
  color: #BAF402;
  background: #fff;
}

.header .dropdown-submenu .dropdown-submenu-child.active:hover:after {
  color: #BAF402;
}

.header .dropdown-submenu .dropdown-submenu-child:hover {
  color: #fff;
  background: #BAF402;
  padding-left: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header .dropdown-submenu .dropdown-submenu-child:hover:after {
  color: #fff;
}

.header .dropdown-submenu .dropdown-menu {
  top: -1px;
  left: 100%;
  margin-left: -0.8px;
}

.header .dropdown-submenu .dropdown-menu.dropdown-menu-left {
  left: -100%;
  margin-left: 0.5px;
}

.header .dropdown-submenu.active .dropdown-submenu-child {
  color: #BAF402;
}

.header .dropdown-submenu.active .dropdown-submenu-child:hover {
  color: #BAF402;
  background: #fff;
}

/* Mega Menu */
.header .mega-menu-list .mega-menu-title {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #34343c;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.header .mega-menu-list .mega-menu-child {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #4c4c57;
  text-decoration: none;
  text-transform: uppercase;
  padding: 8px 15px 8px 0;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header .mega-menu-list .mega-menu-child.active {
  color: #BAF402;
}

.header .mega-menu-list .mega-menu-child.active:hover {
  color: #BAF402;
  background: #fff;
}

.header .mega-menu-list .mega-menu-child:hover {
  color: #fff;
  background: #BAF402;
  text-decoration: none;
  padding-left: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  /* Bootstrap collapse of navigation with a maximum width: 991px
    (Change it to any breakpoint you want to be collapsed) */
  .header .navbar-toggle {
    display: block;
  }
  .header .navbar-collapse.collapse {
    display: none !important;
  }
  .header .navbar-collapse.collapse.in {
    display: block !important;
    overflow-y: auto !important;
  }
  .header .navbar-nav {
    margin: 0;
    float: none;
  }
  .header .navbar-nav .nav-item {
    float: none;
  }
  /* Navbar Fixed Top */
  .header.navbar-fixed-top, .header.navbar-fixed-top-boxed {
    position: relative;
  }
  .header.navbar-fixed-top .nav-collapse, .header.navbar-fixed-top-boxed .nav-collapse {
    max-height: none;
    padding: 0;
    margin: 0;
  }
  /* Menu Container */
  .header .menu-container:before, .header .menu-container:after {
    content: " ";
    display: table;
  }
  .header .menu-container:after {
    clear: both;
  }
  /* Navbar Logo */
  .header .navbar-logo {
    width: auto;
    height: 100%;
    display: block;
    float: none;
    text-align: center;
    margin: 0 70px;
  }
  /* Navbar Nav */
  .header .navbar-nav .nav-item {
    border-top: 1px solid #ebeef6;
  }
  .header .navbar-nav .nav-item.open .nav-item-child:after {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header .navbar-nav .nav-item-child {
    line-height: 1.4;
    padding: 12px 12px 12px 0;
  }
  .header .navbar-nav .dropdown-toggle {
    position: relative;
  }
  .header .navbar-nav .dropdown-toggle:after {
    position: absolute;
    top: 12px;
    right: 0;
    display: inline-block;
    font-size: 12px;
    font-family: FontAwesome;
    content: "\f105";
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header .navbar-nav .nav-item-bg {
    display: inline-block;
    border-top: none;
    margin: 10px 0 20px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header .navbar-nav .nav-item-bg .nav-item-child {
    color: #fff;
    line-height: 20px;
    padding: 7px 15px;
    background: #BAF402;
  }
  .header .navbar-nav .nav-item-bg .nav-item-child:after {
    content: " ";
    display: none;
  }
  .header .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
  }
  /* Dropdown Menu */
  .header .dropdown-menu {
    position: static;
    float: none;
  }
  .header .dropdown-menu .dropdown-menu-item:last-child {
    margin-bottom: 20px;
  }
  /* Dropdown Submenu */
  .header .dropdown-submenu .dropdown-submenu-child {
    font-weight: 500;
  }
  .header .dropdown-submenu .dropdown-submenu-child:after {
    display: none;
  }
  .header .dropdown-submenu .dropdown-submenu-child:hover {
    cursor: inherit;
  }
  .header .dropdown-submenu .dropdown-menu {
    display: block;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .header .dropdown-submenu:hover .dropdown-submenu-child {
    color: #34343c;
    background: 0;
  }
  /* Mega Menu */
  .header .mega-menu-list {
    margin-bottom: 20px;
  }
  .header .mega-menu-list .mega-menu-title {
    font-size: 12px;
    padding: 8px 20px;
    margin-bottom: 0;
  }
  .header .mega-menu-list .mega-menu-item {
    margin-left: 20px;
  }
}

/* Media Queries below 767px */
@media (max-width: 767px) {
  /* Menu Container */
  .header .menu-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0;
    margin-right: 0;
  }
  .header .navbar > .container {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .header .navbar > .container > .nav-collapse {
    margin-left: 0;
    margin-right: 0;
  }
  /* Theme Toggle Trigger */
  .header .theme-toggle-trigger {
    display: block;
  }
  .header .theme-toggle-content {
    display: none;
  }
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .header .shopping-cart .shopping-cart-menu {
    position: absolute;
    top: 100%;
    right: -50px;
  }
}

/* Media Queries above 768px */
@media (min-width: 768px) {
  /* Navbar Fixed Top Boxed */
  .header.navbar-fixed-top.navbar-fixed-top-boxed {
    position: fixed;
    top: 0;
    left: auto;
    right: auto;
  }
  /* Theme Toggle Trigger */
  .header .theme-toggle-trigger {
    display: none !important;
  }
  .header .theme-toggle-content {
    display: block !important;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Header Shrink */
  .header-shrink.header {
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-shrink.header:before, .header-shrink.header:after {
    position: absolute;
    top: 50%;
    left: 10px;
    right: 10px;
    bottom: 0;
    z-index: -1;
    box-shadow: 0 0 15px #e8e8ec;
    border-radius: 1000px / 30px;
    content: " ";
  }
  .header-shrink.header:after {
    left: auto;
    right: 10px;
  }
  .header-shrink.header .navbar {
    background: #fff;
  }
  .header-shrink.header .navbar-logo {
    width: 85px;
    height: 85px;
    max-height: 70px;
    line-height: 35px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-shrink.header .navbar-logo .navbar-logo-wrap {
    padding: 13px 0;
  }
  .header-shrink.header .navbar-actions .navbar-actions-shrink {
    line-height: 70px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-shrink.header .navbar-actions .shopping-cart-amount {
    top: 10px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-shrink.header .navbar-nav .nav-item-child {
    line-height: 70px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-shrink.header .navbar-nav .nav-item-bg {
    margin: 17px 15px 17px 0;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  /* Navbar */
  .header .navbar-nav {
    float: right;
  }
  .header .navbar-nav .nav-item .dropdown-menu {
    z-index: 9999;
    min-width: 235px;
    border: none;
    border-radius: 0;
    border-top: 2px solid #BAF402;
  }
  .header .navbar-nav .nav-item .open > .dropdown-menu {
    display: none;
  }
  .header .navbar-nav .nav-item:hover .nav-item-child {
    color: #5F7E01 !important;
  }
  .header .navbar-nav .nav-item:hover > .dropdown-menu {
    display: block;
  }
  .header .navbar-nav .nav-item-bg {
    margin: 27px 15px 27px 0;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header .navbar-nav .nav-item-bg .nav-item-child {
    color: #fff;
    line-height: 20px;
    padding: 7px 15px;
    background: #BAF402;
  }
  .header .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
  }
  /* Mega Menu */
  .header .mega-menu .nav,
  .header .mega-menu .collapse,
  .header .mega-menu .dropdown,
  .header .mega-menu .dropup {
    position: static;
  }
  .header .mega-menu .container {
    position: relative;
  }
  .header .dropdown-menu {
    left: auto;
  }
  .header .mega-menu-fullwidth .dropdown-menu {
    left: 15px;
    right: 15px;
    padding: 25px 0;
  }
  .header .mega-menu-content {
    padding: 0 25px;
  }
  .header .mega-menu-list {
    margin-bottom: 0;
  }
  .header .mega-menu-list .mega-menu-item {
    padding: 0;
  }
  .header .mega-menu-list .mega-menu-title {
    display: block;
  }
}

/*--------------------------------------------------
    [Header]
----------------------------------------------------*/
.header-transparent {
  border: none;
  background: transparent;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent.header-transparent-bb {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

/* Auto Hiding Navbar */
.header-transparent.auto-hiding-navbar.nav-up {
  top: -80px;
}

/* Navbar */
.header-transparent .navbar {
  background: transparent;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}

/* Navbar Toggle */
.header-transparent .navbar-toggle {
  width: 25px;
  height: 25px;
  float: left;
  border: none;
  padding: 0;
  margin: 32px 0;
}

.header-transparent .navbar-toggle .toggle-icon {
  position: relative;
  width: 15px;
  height: 1px;
  display: inline-block;
  background: #34343c;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .navbar-toggle .toggle-icon:before, .header-transparent .navbar-toggle .toggle-icon:after {
  position: absolute;
  left: 0;
  width: 15px;
  height: 1px;
  background: #34343c;
  content: " ";
}

.header-transparent .navbar-toggle .toggle-icon:before {
  bottom: 10px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  transform: rotate(0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .navbar-toggle .toggle-icon:after {
  top: -5px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  transform: rotate(0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .navbar-toggle .toggle-icon.is-clicked {
  background: rgba(52, 52, 60, 0);
}

.header-transparent .navbar-toggle .toggle-icon.is-clicked:before, .header-transparent .navbar-toggle .toggle-icon.is-clicked:after {
  background: #34343c;
}

.header-transparent .navbar-toggle .toggle-icon.is-clicked:before {
  bottom: 5px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .navbar-toggle .toggle-icon.is-clicked:after {
  top: -5px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .navbar-toggle:hover .toggle-icon {
  background: #BAF402;
}

.header-transparent .navbar-toggle:hover .toggle-icon:before, .header-transparent .navbar-toggle:hover .toggle-icon:after {
  background: #BAF402;
}

.header-transparent .navbar-toggle:hover .toggle-icon.is-clicked {
  background: rgba(52, 52, 60, 0);
}

/* Topbar Toggle Trigger */
.header-transparent .topbar-toggle-trigger {
  float: left;
  width: 27px;
  height: 27px;
  text-align: center;
  cursor: pointer;
  padding: 35px 0 55px;
}

.header-transparent .topbar-toggle-trigger .topbar-toggle-trigger-style {
  position: relative;
  top: 8px;
  display: block;
  width: 4px;
  height: 4px;
  text-align: center;
  background: #34343c;
  border-radius: 50%;
  content: " ";
  margin: 0 auto;
}

.header-transparent .topbar-toggle-trigger .topbar-toggle-trigger-style:before, .header-transparent .topbar-toggle-trigger .topbar-toggle-trigger-style:after {
  position: absolute;
  left: 50%;
  width: 4px;
  height: 4px;
  background: #34343c;
  border-radius: 50%;
  content: " ";
  margin-left: -2px;
}

.header-transparent .topbar-toggle-trigger .topbar-toggle-trigger-style:before {
  top: -6px;
}

.header-transparent .topbar-toggle-trigger .topbar-toggle-trigger-style:after {
  bottom: -6px;
}

.header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style {
  background: #BAF402;
}

.header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before, .header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after {
  background: #BAF402;
}

/* Topbar Toggle Trigger Icon */
.header-transparent .topbar-toggle-trigger-icon-style {
  color: #34343c;
  padding: 2px 5px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .topbar-toggle-trigger-icon-style:hover {
  color: #BAF402;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* Navbar Actions */
.header-transparent .navbar-actions {
  position: relative;
  float: right;
}

.header-transparent .navbar-actions .navbar-actions-shrink {
  line-height: 90px;
  float: left;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* Shopping Cart */
.header-transparent .shopping-cart {
  position: relative;
}

.header-transparent .shopping-cart .shopping-cart-icon {
  font-size: 18px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 0 9px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .shopping-cart .shopping-cart-icon:hover {
  color: #BAF402;
}

.header-transparent .shopping-cart .shopping-cart-amount {
  position: absolute;
  top: 20px;
  right: -3px;
  width: 20px;
  height: 20px;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  line-height: 1.4;
  text-align: center;
  background: #BAF402;
  vertical-align: middle;
  padding: 2px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .shopping-cart .shopping-cart-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 310px;
  display: none;
  line-height: 1.4;
  z-index: 1;
  background: #fff;
  border: 1px solid #ebeef6;
  border-top: 2px solid #BAF402;
}

.header-transparent .shopping-cart .shopping-cart-menu-title {
  display: block;
  font-size: 20px;
  font-weight: 400;
  color: #34343c;
  border-bottom: 1px solid #ebeef6;
  padding: 15px;
}

.header-transparent .shopping-cart .shopping-cart-menu-content {
  display: block;
  padding: 15px;
  border-bottom: 1px solid #ebeef6;
}

.header-transparent .shopping-cart .shopping-cart-menu-content:before, .header-transparent .shopping-cart .shopping-cart-menu-content:after {
  content: " ";
  display: table;
}

.header-transparent .shopping-cart .shopping-cart-menu-content:after {
  clear: both;
}

.header-transparent .shopping-cart .shopping-cart-menu-product-media {
  float: left;
  margin-right: 15px;
}

.header-transparent .shopping-cart .shopping-cart-menu-product-media .shopping-cart-menu-product-img {
  width: 60px;
  height: auto;
}

.header-transparent .shopping-cart .shopping-cart-menu-product-wrap {
  float: left;
  margin-right: 15px;
}

.header-transparent .shopping-cart .shopping-cart-menu-product-wrap:before, .header-transparent .shopping-cart .shopping-cart-menu-product-wrap:after {
  content: " ";
  display: table;
}

.header-transparent .shopping-cart .shopping-cart-menu-product-wrap:after {
  clear: both;
}

.header-transparent .shopping-cart .shopping-cart-menu-product-name {
  display: block;
  font-size: 15px;
  color: #34343c;
}

.header-transparent .shopping-cart .shopping-cart-menu-product-name:hover {
  color: #BAF402;
  text-decoration: none;
}

.header-transparent .shopping-cart .shopping-cart-menu-product-price {
  display: block;
  font-size: 14px;
  color: #535353;
  margin-top: 5px;
}

.header-transparent .shopping-cart .shopping-cart-close {
  float: right;
  font-size: 16px;
  color: #535353;
}

.header-transparent .shopping-cart .shopping-cart-close:before, .header-transparent .shopping-cart .shopping-cart-close:after {
  content: " ";
  display: table;
}

.header-transparent .shopping-cart .shopping-cart-close:after {
  clear: both;
}

.header-transparent .shopping-cart .shopping-cart-close:hover {
  color: #BAF402;
  text-decoration: none;
}

.header-transparent .shopping-cart .shopping-cart-subtotal {
  padding: 15px;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-content {
  display: block;
  margin-bottom: 10px;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-content:before, .header-transparent .shopping-cart .shopping-cart-subtotal-content:after {
  content: " ";
  display: table;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-content:after {
  clear: both;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-title {
  font-size: 16px;
  color: #34343c;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-price {
  font-size: 16px;
  color: #BAF402;
  float: right;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-view {
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-view-link {
  font-size: 13px;
  color: #34343c;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-view-link:hover {
  color: #BAF402;
}

.header-transparent .shopping-cart:hover .shopping-cart-menu {
  display: block;
}

/* Navbar Logo */
.header-transparent .navbar-logo {
  width: 100px;
  height: 100px;
  float: left;
  max-height: 90px;
  line-height: 60px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .navbar-logo .navbar-logo-wrap {
  display: inline-block;
  padding: 15px 0;
}

.header-transparent .navbar-logo .navbar-logo-wrap:focus, .header-transparent .navbar-logo .navbar-logo-wrap:hover {
  text-decoration: none;
}

.header-transparent .navbar-logo .navbar-logo-img {
  display: inline-block;
  width: 85px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .navbar-logo .navbar-logo-img.navbar-logo-img-white {
  display: inline-block;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .navbar-logo .navbar-logo-img.navbar-logo-img-dark {
  display: none;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* Navbar Nav */
.header-transparent .navbar-nav .nav-item-child {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  background: transparent;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 5px 17px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.header-transparent .navbar-nav .nav-item-child.active {
  color: #BAF402;
  background: rgba(255, 255, 255, 0.9);
}

.header-transparent .navbar-nav .nav-item-child.active:hover {
  color: #BAF402;
  background: rgba(255, 255, 255, 0.9);
}

.header-transparent .navbar-nav .nav-item-child:hover, .header-transparent .navbar-nav .nav-item-child:focus {
  color: #BAF402;
  background: rgba(255, 255, 255, 0.9);
}

.header-transparent .navbar-nav .open .nav-item-child:hover, .header-transparent .navbar-nav .open .nav-item-child:focus {
  color: #BAF402;
  background: rgba(255, 255, 255, 0.9);
}

/* Dropdown Menu */
.header-transparent .dropdown-menu {
  min-width: 235px;
  line-height: 1.4;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  margin-top: 1px;
}

.header-transparent .dropdown-menu .dropdown-menu-item {
  color: #4c4c57;
}

.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child {
  font-size: 13px;
  font-weight: 400;
  color: #4c4c57;
  text-transform: uppercase;
  padding: 8px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active {
  color: #BAF402;
}

.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover {
  color: #BAF402;
  background: #fff;
}

.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:focus {
  background: 0;
}

.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover {
  color: #fff;
  background: #BAF402;
  padding-left: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-transparent .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child {
  color: #BAF402;
}

.header-transparent .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child:hover {
  background: 0;
}

/* Dropdown Submenu */
.header-transparent .dropdown-submenu {
  position: relative;
  line-height: 1.4;
}

.header-transparent .dropdown-submenu:hover .dropdown-submenu-child {
  color: #BAF402;
}

.header-transparent .dropdown-submenu:hover .dropdown-menu {
  display: block;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #4c4c57;
  text-transform: uppercase;
  cursor: pointer;
  padding: 8px 20px;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child:after {
  position: absolute;
  top: 8px;
  right: 12px;
  display: inline-block;
  font-size: 12px;
  font-family: FontAwesome;
  content: "\f105";
}

.header-transparent .dropdown-submenu .dropdown-submenu-child.active {
  color: #BAF402;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child.active:hover {
  color: #BAF402;
  background: #fff;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child.active:hover:after {
  color: #BAF402;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child:hover {
  color: #fff;
  background: #BAF402;
  padding-left: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child:hover:after {
  color: #fff;
}

.header-transparent .dropdown-submenu .dropdown-menu {
  top: -1px;
  left: 100%;
  margin-left: -0.8px;
}

.header-transparent .dropdown-submenu .dropdown-menu.dropdown-menu-left {
  left: -100%;
  margin-left: 0.5px;
}

.header-transparent .dropdown-submenu.active .dropdown-submenu-child {
  color: #BAF402;
}

.header-transparent .dropdown-submenu.active .dropdown-submenu-child:hover {
  color: #BAF402;
  background: #fff;
}

/* Mega Menu */
.header-transparent .mega-menu-list .mega-menu-title {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #34343c;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.header-transparent .mega-menu-list .mega-menu-child {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #4c4c57;
  text-transform: uppercase;
  text-decoration: none;
  padding: 8px 15px 8px 0;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-transparent .mega-menu-list .mega-menu-child.active {
  color: #BAF402;
}

.header-transparent .mega-menu-list .mega-menu-child.active:hover {
  color: #BAF402;
  background: #fff;
}

.header-transparent .mega-menu-list .mega-menu-child:hover {
  color: #fff;
  background: #BAF402;
  text-decoration: none;
  padding-left: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  /* Bootstrap collapse of navigation with a maximum width: 991px
    (Change it to any breakpoint you want to be collapsed) */
  .header-transparent .navbar-toggle {
    display: block;
  }
  .header-transparent .navbar-collapse.collapse {
    display: none !important;
  }
  .header-transparent .navbar-collapse.collapse.in {
    display: block !important;
    overflow-y: auto !important;
  }
  .header-transparent .navbar-nav {
    margin: 0;
    float: none;
  }
  .header-transparent .navbar-nav .nav-item {
    float: none;
  }
  /* Navbar Fixed Top */
  .header-transparent {
    background: #fff;
  }
  .header-transparent.navbar-fixed-top, .header-transparent.navbar-fixed-top-boxed {
    position: relative;
  }
  .header-transparent.navbar-fixed-top .nav-collapse, .header-transparent.navbar-fixed-top-boxed .nav-collapse {
    max-height: none;
    padding: 0;
    margin: 0;
  }
  /* Menu Container */
  .header-transparent .menu-container:before, .header-transparent .menu-container:after {
    content: " ";
    display: table;
  }
  .header-transparent .menu-container:after {
    clear: both;
  }
  /* Shopping Cart */
  .header-transparent .shopping-cart .shopping-cart-icon {
    color: #34343c;
  }
  /* Navbar Logo */
  .header-transparent .navbar-logo {
    width: auto;
    height: 100%;
    display: block;
    float: none;
    text-align: center;
    margin: 0 70px;
  }
  .header-transparent .navbar-logo .navbar-logo-img.navbar-logo-img-white {
    display: none;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-transparent .navbar-logo .navbar-logo-img.navbar-logo-img-dark {
    display: inline-block;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  /* Navbar Nav */
  .header-transparent .navbar-nav .nav-item {
    border-top: 1px solid #ebeef6;
  }
  .header-transparent .navbar-nav .nav-item.open .nav-item-child:after {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-transparent .navbar-nav .nav-item-child {
    color: #34343c;
    line-height: 1.4;
    padding: 12px 12px 12px 0;
  }
  .header-transparent .navbar-nav .dropdown-toggle {
    position: relative;
  }
  .header-transparent .navbar-nav .dropdown-toggle:after {
    position: absolute;
    top: 12px;
    right: 0;
    display: inline-block;
    font-size: 12px;
    font-family: FontAwesome;
    content: "\f105";
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-transparent .navbar-nav .nav-item-bg {
    display: inline-block;
    border-top: none;
    border: 1px solid #BAF402;
    margin: 10px 0 20px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-transparent .navbar-nav .nav-item-bg .nav-item-child {
    color: #fff;
    line-height: 20px;
    padding: 7px 15px;
    background: #BAF402;
  }
  .header-transparent .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
  }
  /* Dropdown Menu */
  .header-transparent .dropdown-menu {
    position: static;
    float: none;
  }
  .header-transparent .dropdown-menu .dropdown-menu-item:last-child {
    margin-bottom: 20px;
  }
  /* Dropdown Submenu */
  .header-transparent .dropdown-submenu .dropdown-submenu-child {
    font-weight: 500;
  }
  .header-transparent .dropdown-submenu .dropdown-submenu-child:after {
    display: none;
  }
  .header-transparent .dropdown-submenu .dropdown-submenu-child:hover {
    cursor: inherit;
  }
  .header-transparent .dropdown-submenu .dropdown-menu {
    display: block;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .header-transparent .dropdown-submenu:hover .dropdown-submenu-child {
    color: #34343c;
    background: 0;
  }
  /* Mega Menu */
  .header-transparent .mega-menu-list {
    margin-bottom: 20px;
  }
  .header-transparent .mega-menu-list .mega-menu-title {
    font-size: 12px;
    padding: 8px 20px;
    margin-bottom: 0;
  }
  .header-transparent .mega-menu-list .mega-menu-item {
    margin-left: 20px;
  }
}

/* Media Queries below 767px */
@media (max-width: 767px) {
  /* Menu Container */
  .header-transparent .menu-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 0;
    margin-right: 0;
  }
  .header-transparent .navbar > .container {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .header-transparent .navbar > .container > .nav-collapse {
    margin-left: 0;
    margin-right: 0;
  }
  /* Theme Toggle Trigger */
  .header-transparent .theme-toggle-trigger {
    display: block;
  }
  .header-transparent .theme-toggle-content {
    display: none;
  }
}

/* Media Queries above 768px */
@media (min-width: 768px) {
  /* Navbar Fixed Top Boxed */
  .header-transparent.navbar-fixed-top-boxed.navbar-fixed-top-boxed {
    position: fixed;
    top: 0;
    left: auto;
    right: auto;
  }
  /* Theme Toggle Trigger */
  .header-transparent .theme-toggle-trigger {
    display: none !important;
  }
  .header-transparent .theme-toggle-content {
    display: block !important;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Header Shrink */
  .header-shrink.header-transparent {
    background: #fff;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
    /* Shopping Cart */
  }
  .header-shrink.header-transparent:before, .header-shrink.header-transparent:after {
    position: absolute;
    top: 50%;
    left: 10px;
    right: 10px;
    bottom: 0;
    z-index: -1;
    box-shadow: 0 0 15px #e8e8ec;
    border-radius: 1000px / 30px;
    content: " ";
  }
  .header-shrink.header-transparent:after {
    left: auto;
    right: 10px;
  }
  .header-shrink.header-transparent .navbar {
    background: #fff;
  }
  .header-shrink.header-transparent .navbar-logo {
    width: 85px;
    height: 85px;
    max-height: 70px;
    line-height: 35px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-shrink.header-transparent .navbar-logo .navbar-logo-wrap {
    padding: 13px 0;
  }
  .header-shrink.header-transparent .navbar-logo .navbar-logo-img.navbar-logo-img-white {
    display: none;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-shrink.header-transparent .navbar-logo .navbar-logo-img.navbar-logo-img-dark {
    display: inline-block;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-shrink.header-transparent .navbar-actions .navbar-actions-shrink {
    line-height: 70px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-shrink.header-transparent .navbar-actions .shopping-cart-amount {
    top: 10px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-shrink.header-transparent .shopping-cart .shopping-cart-icon {
    color: #34343c;
  }
  .header-shrink.header-transparent .shopping-cart .shopping-cart-icon:hover {
    color: #BAF402;
  }
  .header-shrink.header-transparent .navbar-nav .nav-item-child {
    color: #34343c;
    margin: 20px 5px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-shrink.header-transparent .navbar-nav .nav-item-bg {
    margin: 0 15px 0 0;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  /* Navbar */
  .header-transparent .navbar-nav {
    float: right;
  }
  .header-transparent .navbar-nav .nav-item {
    line-height: 90px;
  }
  .header-transparent .navbar-nav .nav-item .dropdown-menu {
    z-index: 9999;
    min-width: 235px;
    border: none;
    border-radius: 0;
    border-top: 2px solid #BAF402;
  }
  .header-transparent .navbar-nav .nav-item .open > .dropdown-menu {
    display: none;
  }
  .header-transparent .navbar-nav .nav-item:hover .nav-item-child {
    color: #BAF402;
    background: rgba(255, 255, 255, 0.9);
  }
  .header-transparent .navbar-nav .nav-item:hover > .dropdown-menu {
    display: block;
  }
  .header-transparent .navbar-nav .nav-item-child {
    margin: 30px 5px;
  }
  .header-transparent .navbar-nav .nav-item-child.active {
    color: #BAF402;
    background: rgba(255, 255, 255, 0.9);
  }
  .header-transparent .navbar-nav .nav-item-bg {
    margin: 0 15px 0 0;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .header-transparent .navbar-nav .nav-item-bg .nav-item-child {
    color: #fff;
    background: #BAF402;
  }
  .header-transparent .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
    background: #BAF402;
  }
  .header-transparent .navbar-nav .nav-item-bg:hover .nav-item-child {
    color: #fff;
    background: #BAF402;
  }
  /* Mega Menu */
  .header-transparent .mega-menu .nav,
  .header-transparent .mega-menu .collapse,
  .header-transparent .mega-menu .dropdown,
  .header-transparent .mega-menu .dropup {
    position: static;
  }
  .header-transparent .mega-menu .container {
    position: relative;
  }
  .header-transparent .dropdown-menu {
    left: auto;
  }
  .header-transparent .mega-menu-fullwidth .dropdown-menu {
    left: 15px;
    right: 15px;
    padding: 25px 0;
  }
  .header-transparent .mega-menu-content {
    padding: 0 25px;
  }
  .header-transparent .mega-menu-list {
    margin-bottom: 0;
  }
  .header-transparent .mega-menu-list .mega-menu-item {
    padding: 0;
  }
  .header-transparent .mega-menu-list .mega-menu-title {
    display: block;
  }
}

/*------------------------------------------------------------------
    [Responsive]
------------------------------------------------------------------*/
/* Full Width class below 480px */
@media (max-width: 480px) {
  .xs-full-width {
    width: 100%;
  }
}

/* Full Width 0 Offset class below 480px */
@media (max-width: 480px) {
  .xs-full-width-offset-0 {
    margin-left: 0;
  }
}

/* Hidden class below 480px */
@media (max-width: 480px) {
  .xs-hidden {
    display: none !important;
  }
}

/* Hidden class below 768px */
@media (max-width: 768px) {
  .sm-hidden {
    display: none !important;
  }
}

/* Hidden class below 992px */
@media (max-width: 992px) {
  .md-hidden {
    display: none !important;
  }
}

/*------------------------------------------------------------------
    [Modal Form]
------------------------------------------------------------------*/
.form-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f7f8fa;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.form-modal .form-modal-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.form-modal .form-modal-container {
  -webkit-transform: scale(0.8, 1);
  -moz-transform: scale(0.8, 1);
  transform: scale(0.8, 1);
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.form-modal.is-visible {
  visibility: visible;
  opacity: 1;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.form-modal.is-visible .form-modal-container {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.form-modal .form-modal-container {
  width: 100%;
}

.form-modal .form-modal-container .form-modal-connect,
.form-modal .form-modal-container .form-modal-divider,
.form-modal .form-modal-container .form-modal-login-form {
  float: left;
  padding: 10px;
}

.form-modal .form-modal-container .form-modal-connect {
  width: 45%;
  margin-top: 25px;
}

.form-modal .form-modal-container .form-modal-divider {
  width: 10%;
}

.form-modal .form-modal-container .form-modal-divider .form-modal-divider-text {
  position: relative;
  display: block;
  font-size: 20px;
  color: #34343c;
  text-align: center;
  margin-top: 30px;
}

.form-modal .form-modal-container .form-modal-divider .form-modal-divider-text:before, .form-modal .form-modal-container .form-modal-divider .form-modal-divider-text:after {
  position: absolute;
  left: 50%;
  width: 1px;
  height: 20px;
  background: #a0a0a0;
  content: " ";
}

.form-modal .form-modal-container .form-modal-divider .form-modal-divider-text:before {
  top: 100%;
  margin-top: 10px;
}

.form-modal .form-modal-container .form-modal-divider .form-modal-divider-text:after {
  bottom: 100%;
  margin-bottom: 10px;
}

.form-modal .form-modal-container .form-modal-login-form {
  width: 45%;
}

.form-modal .form-modal-container .form-modal-reset-form {
  width: 100%;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.form-modal .form-modal-heading {
  text-align: center;
  margin-bottom: 20px;
}

.form-modal .form-modal-heading .form-modal-title {
  font-size: 28px;
  margin: 0 0 5px;
}

.form-modal .form-modal-back-btn-message {
  font-size: 14px;
  font-weight: 300;
  color: #34343c;
  text-align: center;
  margin-bottom: 5px;
}

.form-modal .form-modal-back-btn-message .form-modal-back-btn-message-link {
  font-size: 14px;
  font-weight: 300;
  color: #BAF402;
}

.form-modal .form-modal-input {
  height: 40px;
}

.form-modal .form-modal-input-group {
  position: relative;
}

.form-modal .form-modal-input-group .form-modal-input-form {
  padding: 6px 75px 6px 12px;
}

.form-modal .form-modal-input-group .form-modal-hide-password {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 65px;
  font-size: 13px;
  font-weight: 300;
  color: #34343c;
  text-align: center;
  border-left: 1px solid #ebeef6;
  text-decoration: none;
  padding: 11px 15px;
}

.form-modal .form-modal-input-group .form-modal-hide-password:hover {
  color: #BAF402;
}

.form-modal .form-modal-close-form {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  display: block;
  font-size: 28px;
  color: #34343c;
  text-align: center;
  line-height: 1.25;
  text-decoration: none;
}

.form-modal .form-modal-close-form:hover {
  color: #BAF402;
}

.form-modal #form-modal-login,
.form-modal #form-modal-signup,
.form-modal #form-modal-reset-password {
  display: none;
}

.form-modal #form-modal-login.is-selected,
.form-modal #form-modal-signup.is-selected,
.form-modal #form-modal-reset-password.is-selected {
  display: block;
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .form-modal .form-modal-container {
    max-width: 100%;
  }
  .form-modal .form-modal-container .form-modal-connect,
  .form-modal .form-modal-container .form-modal-divider,
  .form-modal .form-modal-container .form-modal-login-form {
    width: 100%;
    float: none;
  }
  .form-modal .form-modal-container .form-modal-connect {
    margin-top: 0;
  }
  .form-modal .form-modal-container .form-modal-divider {
    text-align: center;
  }
  .form-modal .form-modal-container .form-modal-divider .form-modal-divider-text {
    display: inline-block;
    margin: 0;
  }
  .form-modal .form-modal-container .form-modal-divider .form-modal-divider-text:before, .form-modal .form-modal-container .form-modal-divider .form-modal-divider-text:after {
    position: absolute;
    top: 15px;
    width: 20px;
    height: 1px;
    background: #a0a0a0;
    content: " ";
  }
  .form-modal .form-modal-container .form-modal-divider .form-modal-divider-text:before {
    left: auto;
    right: 100%;
    margin: 0 15px 0 0;
  }
  .form-modal .form-modal-container .form-modal-divider .form-modal-divider-text:after {
    bottom: auto;
    left: 100%;
    margin: 0 0 0 15px;
  }
}

/*------------------------------------------------------------------
    [Sidebar]
------------------------------------------------------------------*/
.overflow-hidden {
  overflow: hidden;
}

/* Sidebar Trigger */
.sidebar-trigger {
  padding-left: 10px;
  text-decoration: none;
}

.sidebar-trigger .sidebar-trigger-icon {
  position: relative;
  width: 18px;
  height: 1px;
  display: inline-block;
  background: #34343c;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.sidebar-trigger .sidebar-trigger-icon:before, .sidebar-trigger .sidebar-trigger-icon:after {
  position: absolute;
  left: 0;
  width: 18px;
  height: 1px;
  background: #34343c;
  content: " ";
}

.sidebar-trigger .sidebar-trigger-icon:before {
  bottom: 12px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  transform: rotate(0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.sidebar-trigger .sidebar-trigger-icon:after {
  top: -6px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  transform: rotate(0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.sidebar-trigger.is-clicked .sidebar-trigger-icon {
  background: rgba(52, 52, 60, 0);
}

.sidebar-trigger.is-clicked .sidebar-trigger-icon:before, .sidebar-trigger.is-clicked .sidebar-trigger-icon:after {
  background: #34343c;
}

.sidebar-trigger.is-clicked .sidebar-trigger-icon:before {
  bottom: 5px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.sidebar-trigger.is-clicked .sidebar-trigger-icon:after {
  top: -5px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.sidebar-trigger:hover.is-clicked .sidebar-trigger-icon {
  background: rgba(52, 52, 60, 0);
}

.sidebar-trigger:hover .sidebar-trigger-icon {
  background: #BAF402;
}

.sidebar-trigger:hover .sidebar-trigger-icon:before, .sidebar-trigger:hover .sidebar-trigger-icon:after {
  background: #BAF402;
}

/* Sidebar Content */
.sidebar-content-overlay {
  position: relative;
}

.sidebar-content-overlay.sidebar-menu-is-open {
  cursor: e-resize;
}

.sidebar-content-overlay.sidebar-menu-is-open:before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  content: " ";
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* Sidebar Nav */
.sidebar-nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 260px;
  height: 100%;
  z-index: 99999;
  background: #34343c;
  overflow-y: auto;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-right: -260px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.sidebar-nav.sidebar-menu-is-open {
  visibility: visible;
  margin-right: 0;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  -webkit-overflow-scrolling: touch;
}

.sidebar-nav .sidebar-nav-trigger {
  float: right;
  padding: 15px;
}

.sidebar-nav .sidebar-nav-trigger .sidebar-trigger-icon {
  background: rgba(255, 255, 255, 0);
}

.sidebar-nav .sidebar-nav-trigger .sidebar-trigger-icon:before, .sidebar-nav .sidebar-nav-trigger .sidebar-trigger-icon:after {
  background: #fff;
}

.sidebar-nav .sidebar-nav-trigger:hover .sidebar-trigger-icon:before, .sidebar-nav .sidebar-nav-trigger:hover .sidebar-trigger-icon:after {
  background: #BAF402;
}

.sidebar-nav .sidebar-nav-content {
  margin: 80px 20px 20px;
}

.sidebar-nav .sidebar-nav-title {
  font-size: 22px;
  color: #fff;
  margin-bottom: 10px;
}

.sidebar-nav .sidebar-nav-about-text,
.sidebar-nav .sidebar-nav-about-link {
  font-size: 13px;
  color: #fff;
  margin-bottom: 5px;
}

.sidebar-nav .sidebar-nav-about-link:hover {
  color: #BAF402;
  text-decoration: none;
}

.sidebar-nav .sidebar-nav-comment-input {
  color: #e6e5e5;
  background: #2f2f37;
  border: none;
}

.sidebar-nav .sidebar-nav-comment-input::-moz-placeholder {
  color: #cccccc;
}

.sidebar-nav .sidebar-nav-comment-input:-ms-input-placeholder {
  color: #cccccc;
}

.sidebar-nav .sidebar-nav-comment-input::-webkit-input-placeholder {
  color: #cccccc;
}

.sidebar-nav .sidebar-nav-comment-input:focus {
  color: #fff;
}

.sidebar-nav .sidebar-nav-comment-input:focus::-moz-placeholder {
  color: #fff;
}

.sidebar-nav .sidebar-nav-comment-input:focus:-ms-input-placeholder {
  color: #fff;
}

.sidebar-nav .sidebar-nav-comment-input:focus::-webkit-input-placeholder {
  color: #fff;
}

/*------------------------------------------------------------------
    [Sidebar Trigger Style White]
------------------------------------------------------------------*/
/* Media Queries below 992px */
@media (min-width: 992px) {
  /* Sidebar Trigger */
  .sidebar-trigger.sidebar-trigger-style-white .sidebar-trigger-icon {
    background: #fff;
  }
  .sidebar-trigger.sidebar-trigger-style-white .sidebar-trigger-icon:before, .sidebar-trigger.sidebar-trigger-style-white .sidebar-trigger-icon:after {
    background: #fff;
  }
  .sidebar-trigger.sidebar-trigger-style-white.is-clicked .sidebar-trigger-icon {
    background: rgba(255, 255, 255, 0);
  }
  .sidebar-trigger.sidebar-trigger-style-white.is-clicked .sidebar-trigger-icon:before, .sidebar-trigger.sidebar-trigger-style-white.is-clicked .sidebar-trigger-icon:after {
    background: #fff;
  }
  .sidebar-trigger.sidebar-trigger-style-white:hover.is-clicked .sidebar-trigger-icon {
    background: rgba(255, 255, 255, 0);
  }
  .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon {
    background: #BAF402;
  }
  .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:before, .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:after {
    background: #BAF402;
  }
  /* Header Shrink */
  .header-shrink .sidebar-trigger.sidebar-trigger-style-white .sidebar-trigger-icon {
    background: #34343c;
  }
  .header-shrink .sidebar-trigger.sidebar-trigger-style-white .sidebar-trigger-icon:before, .header-shrink .sidebar-trigger.sidebar-trigger-style-white .sidebar-trigger-icon:after {
    background: #34343c;
  }
  .header-shrink .sidebar-trigger.sidebar-trigger-style-white.is-clicked .sidebar-trigger-icon {
    background: rgba(52, 52, 60, 0);
  }
  .header-shrink .sidebar-trigger.sidebar-trigger-style-white.is-clicked .sidebar-trigger-icon:before, .header-shrink .sidebar-trigger.sidebar-trigger-style-white.is-clicked .sidebar-trigger-icon:after {
    background: #34343c;
  }
  .header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover.is-clicked .sidebar-trigger-icon {
    background: rgba(52, 52, 60, 0);
  }
  .header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon {
    background: #BAF402;
  }
  .header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:before, .header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:after {
    background: #BAF402;
  }
}

/*--------------------------------------------------
    [Contact Us Banner]
----------------------------------------------------*/
.contact-us {
  padding: 40px;
}

.contact-us .contact-us-timeline-day {
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  text-align: center;
  background: #BAF402;
  padding: 5px 0;
  margin-right: 15px;
}

.contact-us .contact-us-timeline-time {
  display: block;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  border: 1px solid #ebeef6;
  padding: 5px 10px;
}

/*--------------------------------------------------
    [FAQ]
----------------------------------------------------*/
.faq {
  position: relative;
  z-index: 1;
  text-align: center;
  background: url(../../assets/img/1920x1080/28.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 250px 0 100px;
}

.faq:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.7);
  content: " ";
}

.faq .faq-title {
  font-size: 70px;
  color: #fff;
  margin: 0 0 60px;
}

.faq .faq-search {
  position: relative;
  width: 100%;
  height: auto;
}

.faq .faq-search .faq-search-input {
  width: 100%;
  height: 50px;
  font-size: 22px;
  color: #fff;
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding: 6px 35px 6px 0;
}

.faq .faq-search .faq-search-input::-moz-placeholder {
  color: #fff;
}

.faq .faq-search .faq-search-input:-ms-input-placeholder {
  color: #fff;
}

.faq .faq-search .faq-search-input::-webkit-input-placeholder {
  color: #fff;
}

.faq .faq-search .faq-search-input:focus {
  border-bottom-color: #fff;
}

.faq .faq-search .faq-search-input:focus::-moz-placeholder {
  color: #fff;
}

.faq .faq-search .faq-search-input:focus:-ms-input-placeholder {
  color: #fff;
}

.faq .faq-search .faq-search-input:focus::-webkit-input-placeholder {
  color: #fff;
}

.faq .faq-search .faq-search-search {
  position: absolute;
  top: 7px;
  right: 0;
  width: 35px;
  height: 35px;
  background: transparent;
  outline: none;
  border: none;
}

.faq .faq-search .faq-search-search .faq-search-search-icon {
  font-size: 14px;
  color: #fff;
}

.faq .faq-search .faq-search-search:hover .faq-search-search-icon {
  color: #BAF402;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .faq .faq-title {
    font-size: 36px;
  }
}

/*--------------------------------------------------
    [FAQ V2]
----------------------------------------------------*/
.faq-v2 {
  position: relative;
  z-index: 1;
  text-align: center;
  background: url(../../assets/img/1920x1080/03.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 220px 0 130px;
}

.faq-v2:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.7);
  content: " ";
}

.faq-v2 .faq-v2-community {
  position: relative;
  z-index: 1;
  background: #fff;
  text-align: center;
  overflow: hidden;
  padding: 40px;
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.faq-v2 .faq-v2-community .faq-v2-community-content {
  margin-bottom: 20px;
  -webkit-transform: translate3d(0, 30%, 0);
  -moz-transform: translate3d(0, 30%, 0);
  transform: translate3d(0, 30%, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.faq-v2 .faq-v2-community .faq-v2-community-icon {
  display: block;
  font-size: 50px;
  color: #BAF402;
  margin-bottom: 30px;
}

.faq-v2 .faq-v2-community .faq-v2-community-title {
  font-size: 20px;
  margin-bottom: 0;
}

.faq-v2 .faq-v2-community .faq-v2-community-btn {
  opacity: 0;
  margin-bottom: 0;
  -webkit-transform: translate3d(0, 105%, 0);
  -moz-transform: translate3d(0, 105%, 0);
  transform: translate3d(0, 105%, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.faq-v2 .faq-v2-community:hover .faq-v2-community-content {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.faq-v2 .faq-v2-community:hover .faq-v2-community-btn {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-duration: 400ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Animsition]
----------------------------------------------------*/
.animsition,
.animsition-overlay {
  position: relative;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* Overlay Option */
.animsition-overlay-slide {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: white;
}

/* Loading Option */
.animsition-loading,
.animsition-loading:after {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  z-index: 100;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
}

/* Animsition Loading */
.animsition-loading {
  background: transparent;
  border-left: 2px solid #BAF402;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-name: animsition-loading;
  -moz-animation-name: animsition-loading;
  animation-name: animsition-loading;
}

@-webkit-keyframes animsition-loading {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes animsition-loading {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animsition-loading {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Fade In */
.fade-in {
  -webkit-animation-name: fade-in;
  -moz-animation-name: fade-in;
  animation-name: fade-in;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Fade Out */
.fade-out {
  -webkit-animation-name: fade-out;
  -moz-animation-name: fade-out;
  animation-name: fade-out;
}

@-webkit-keyframes fade-in-up {
  0% {
    -webkit-transform: translate3d(0, 500px, 0);
    -moz-transform: translate3d(0, 500px, 0);
    transform: translate3d(0, 500px, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-moz-keyframes fade-in-up {
  0% {
    -webkit-transform: translate3d(0, 500px, 0);
    -moz-transform: translate3d(0, 500px, 0);
    transform: translate3d(0, 500px, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fade-in-up {
  0% {
    -webkit-transform: translate3d(0, 500px, 0);
    -moz-transform: translate3d(0, 500px, 0);
    transform: translate3d(0, 500px, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/*--------------------------------------------------
    [Back To Top Theme Button]
----------------------------------------------------*/
.back-to-top-theme {
  position: fixed;
  right: 10px;
  bottom: 10px;
  display: inline-block;
  z-index: 9;
  width: 35px;
  height: 35px;
  font-size: 22px;
  color: #fff;
  line-height: .5;
  background: #BAF402;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  padding: 10.5px;
  -webkit-transform: translate3d(0, 50px, 0);
  -moz-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0);
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.back-to-top-theme:after {
  font-family: FontAwesome;
  color: #fff;
  text-align: center;
  content: "\f106";
}

.back-to-top-theme:hover {
  background: #BAF402;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.back-to-top-theme:focus, .back-to-top-theme:hover {
  text-decoration: none;
}

/* The Button Becomes Visible */
.back-to-top-theme.back-to-top-is-visible {
  visibility: visible;
  opacity: .6;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.back-to-top-theme.back-to-top-is-visible:hover {
  opacity: 1;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* If the user keeps scrolling down, the button is out of focus and becomes less visible */
.back-to-top-theme.back-to-top-fade-out {
  opacity: .4;
}

.back-to-top-theme.back-to-top-fade-out:hover {
  opacity: 1;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*------------------------------------------------------------------
    [List Inline]
------------------------------------------------------------------*/
.list-inline {
  margin-left: 0;
}

/*------------------------------------------------------------------
    [Checkbox]
------------------------------------------------------------------*/
.checkbox {
  padding-left: 21px;
  text-align: left;
  margin: 0;
}

.checkbox label {
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}

.checkbox label:before {
  position: absolute;
  left: 0;
  width: 17px;
  height: 17px;
  display: inline-block;
  background: #fff;
  border: 1px solid #e0e5f1;
  border-radius: 3px;
  content: " ";
  margin-left: -20px;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.checkbox label:after {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  font-size: 11px;
  color: #606060;
  margin-left: -17px;
  padding-top: 1px;
}

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.checkbox input[type="checkbox"]:focus + label:before,
.checkbox input[type="radio"]:focus + label:before {
  outline: none;
}

.checkbox input[type="checkbox"]:checked + label:before,
.checkbox input[type="radio"]:checked + label:before {
  background: #BAF402;
  border-color: #BAF402;
}

.checkbox input[type="checkbox"]:checked + label:after,
.checkbox input[type="radio"]:checked + label:after {
  font-family: FontAwesome;
  color: #fff;
  content: "\f00c";
}

/*------------------------------------------------------------------
    [Form Control]
------------------------------------------------------------------*/
.form-control {
  height: 40px;
  font-size: 13px;
  font-weight: 300;
  color: #606060;
  border-color: #ebeef6;
  box-shadow: none;
}

.form-control::-moz-placeholder {
  color: #606060;
}

.form-control:-ms-input-placeholder {
  color: #606060;
}

.form-control::-webkit-input-placeholder {
  color: #606060;
}

.form-control:focus {
  border-color: #d9dfee;
  box-shadow: none;
}

.form-control:focus::-moz-placeholder {
  color: #606060;
}

.form-control:focus:-ms-input-placeholder {
  color: #606060;
}

.form-control:focus::-webkit-input-placeholder {
  color: #606060;
}

/*------------------------------------------------------------------
    [Carousel Control]
------------------------------------------------------------------*/
.carousel-control.left, .carousel-control.right {
  background-image: none;
}

/*------------------------------------------------------------------
    [Carousel Animation Fade]
------------------------------------------------------------------*/
.carousel-fade .carousel-inner .item {
  -webkit-transition: opacity 0.8s;
  -moz-transition: opacity 0.8s;
  transition: opacity 0.8s;
}

.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*------------------------------------------------------------------
    [Carousel Control Arrows v1]
------------------------------------------------------------------*/
.theme-carousel-control-v1 {
  width: 0;
  text-shadow: none;
  opacity: 1;
}

.theme-carousel-control-v1 .carousel-control-arrows-v1 {
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 35px;
  height: 35px;
  font-size: 18px;
  color: #34343c;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
  vertical-align: middle;
  padding: 8px;
  margin-top: -17.5px;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.theme-carousel-control-v1 .carousel-control-arrows-v1:hover {
  color: #34343c;
  background: #fff;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.theme-carousel-control-v1.left .carousel-control-arrows-v1 {
  left: 10px;
}

.theme-carousel-control-v1.right .carousel-control-arrows-v1 {
  right: 10px;
}

/*------------------------------------------------------------------
    [Carousel Control Arrows v2]
------------------------------------------------------------------*/
.theme-carousel-control-v2 {
  position: static;
  width: 0;
  text-align: center;
  text-shadow: none;
  opacity: .8;
  padding: 0 3px;
}

.theme-carousel-control-v2 .carousel-control-arrows-v2 {
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 18px;
  color: #34343c;
  line-height: 1.4;
  text-align: center;
  background: #fff;
  vertical-align: middle;
  padding: 8px;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.theme-carousel-control-v2 .carousel-control-arrows-v2:hover {
  color: #fff;
  background: #BAF402;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Carousel Control Arrows v3]
----------------------------------------------------*/
.theme-carousel-control-v3 {
  position: static;
  opacity: .8;
}

.theme-carousel-control-v3 .carousel-controls-arrow-v3 {
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 44px;
  height: 44px;
  font-size: 36px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  text-shadow: 0 3px 0px rgba(52, 52, 60, 0.3);
  margin-top: -22px;
  padding: 4px;
}

.theme-carousel-control-v3.left .carousel-controls-arrow-v3 {
  left: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.theme-carousel-control-v3.right .carousel-controls-arrow-v3 {
  right: 0;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

/*------------------------------------------------------------------
    [Theme Carousel Indicators v1]
------------------------------------------------------------------*/
/* Media Queries above 992px */
@media (min-width: 992px) {
  .theme-ci-v1 {
    width: 100%;
    margin-left: -50%;
  }
  .theme-ci-v1 .theme-ci-v1-item {
    position: relative;
    min-width: 190px;
    width: auto;
    height: auto;
    font-size: 18px;
    color: #fff;
    text-align: left;
    background: transparent;
    border: none;
    border-radius: 0;
    text-indent: inherit;
    overflow: hidden;
    opacity: .5;
    padding: 10px 5px;
    margin: 0 50px;
  }
  .theme-ci-v1 .theme-ci-v1-item:before, .theme-ci-v1 .theme-ci-v1-item:after {
    background: #fff;
    content: " ";
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .theme-ci-v1 .theme-ci-v1-item:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 2px;
  }
  .theme-ci-v1 .theme-ci-v1-item:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
  }
  .theme-ci-v1 .theme-ci-v1-item .theme-ci-v1-no {
    font-size: 50px;
    font-weight: 400;
    float: left;
    line-height: .9;
    letter-spacing: -2px;
    margin-right: 8px;
  }
  .theme-ci-v1 .theme-ci-v1-item .theme-ci-v1-media {
    overflow: hidden;
  }
  .theme-ci-v1 .theme-ci-v1-item .theme-ci-v1-title {
    display: block;
    font-size: 18px;
    font-style: italic;
    font-family: Droid Serif, serif;
  }
  .theme-ci-v1 .theme-ci-v1-item .theme-ci-v1-subtitle {
    display: block;
    font-size: 14px;
  }
  .theme-ci-v1 .theme-ci-v1-item.active {
    width: auto;
    height: auto;
    background: transparent;
    opacity: 1;
    margin: 0 50px;
  }
  .theme-ci-v1 .theme-ci-v1-item.active:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 2px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .theme-ci-v1 .theme-ci-v1-item.active:after {
    width: 100%;
    height: 2px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .theme-ci-v1 .theme-ci-v1-item:hover:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 2px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
  .theme-ci-v1 .theme-ci-v1-item:hover:after {
    width: 100%;
    height: 2px;
    transition-duration: 400ms;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  }
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  .theme-ci-v1 .theme-ci-v1-item .theme-ci-v1-no,
  .theme-ci-v1 .theme-ci-v1-item .theme-ci-v1-media {
    display: none;
  }
}

/*------------------------------------------------------------------
    [Theme Carousel Indicators v2]
------------------------------------------------------------------*/
.theme-carousel-indicators-v2 {
  bottom: -10px;
}

.theme-carousel-indicators-v2 li {
  width: 8px;
  height: 8px;
  background: #34343c;
  margin: 0;
}

.theme-carousel-indicators-v2 li.active {
  width: 8px;
  height: 8px;
  background: #BAF402;
  border-color: #BAF402;
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  .theme-carousel-indicators-v2 {
    left: 65%;
    bottom: 35px;
    margin-left: 0;
  }
}

/*------------------------------------------------------------------
    [Theme Carousel Indicators v3]
------------------------------------------------------------------*/
.theme-carousel-indicators-v3 {
  position: static;
  width: auto;
  text-align: right;
  margin: 0;
}

.theme-carousel-indicators-v3 li {
  position: relative;
  width: auto;
  height: auto;
  font-size: 18px;
  color: #fff;
  background: 0;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  text-indent: inherit;
  padding: 0 2px;
  margin: 0 6px 0 0;
}

.theme-carousel-indicators-v3 li.active {
  width: auto;
  height: auto;
  color: #BAF402;
  background: 0;
  border-bottom-color: #BAF402;
  margin: 0 6px 0 0;
}

.theme-carousel-indicators-v3 li:hover {
  border-bottom-color: #BAF402;
}

.theme-carousel-indicators-v3 li:hover.active {
  color: #BAF402;
  border-bottom-color: #BAF402;
}

/*------------------------------------------------------------------
    [Theme Carousel Indicators v4]
------------------------------------------------------------------*/
.theme-carousel-indicators-v4 {
  bottom: -40px;
}

.theme-carousel-indicators-v4 li {
  width: 8px;
  height: 8px;
  background: #34343c;
  margin: 0;
}

.theme-carousel-indicators-v4 li.active {
  width: 8px;
  height: 8px;
  background: #BAF402;
  border-color: #BAF402;
}

/*------------------------------------------------------------------
    [Theme Carousel Indicators v5]
------------------------------------------------------------------*/
.theme-carousel-indicators-v5 {
  position: static;
  width: auto;
  text-align: center;
  background: #fff;
  border: 1px solid #f7f8fa;
  border-radius: 0;
  padding: 10px;
  margin: 0;
}

.theme-carousel-indicators-v5 li {
  width: auto;
  height: auto;
  z-index: 1;
  text-indent: inherit;
  background: 0;
  border: none;
  padding: 0;
  margin: 0 1px;
}

.theme-carousel-indicators-v5 li.active {
  width: auto;
  height: auto;
  background: 0;
  border: none;
  padding: 0;
  margin: 0 1px;
}

.theme-carousel-indicators-v5 li.active .theme-carousel-indicators-item {
  width: 50px;
  height: 50px;
  border: 2px solid #BAF402;
}

.theme-carousel-indicators-v5 .theme-carousel-indicators-item {
  width: 50px;
  height: 50px;
  border: 2px solid #ebeef6;
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .theme-carousel-indicators-v5 {
    display: none;
  }
}

/*------------------------------------------------------------------
    [Theme Carousel Indicators v6]
------------------------------------------------------------------*/
.theme-carousel-indicators-v6 {
  position: absolute;
  top: 30px;
  bottom: auto;
}

.theme-carousel-indicators-v6 > li {
  position: relative;
  width: 15px;
  height: 15px;
  margin: 1px 3px;
  border: 3px solid #fff;
}

.theme-carousel-indicators-v6 > li:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 50%;
  content: " ";
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.theme-carousel-indicators-v6 > li.active {
  width: 17px;
  height: 17px;
  margin: 0 2px;
  background: transparent;
}

.theme-carousel-indicators-v6 > li.active:before {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  transform: scale(0, 0);
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/*--------------------------------------------------
    [Bootstrap Carousel with Animation]
----------------------------------------------------*/
.carousel-caption .carousel-caption-wrap {
  position: relative;
}

.carousel-caption .carousel-caption-element {
  position: absolute;
  left: 50%;
  bottom: -20px;
  -webkit-transform: translate3d(-50%, 0, 0);
  -moz-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}

.carousel-caption .carousel-animation-one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

/*------------------------------------------------------------------
    [Modal]
------------------------------------------------------------------*/
.modal-open {
  padding-right: 0 !important;
}

.modal-open .modal {
  padding-right: 0 !important;
}

/*--------------------------------------------------
    [Masonry Grid]
----------------------------------------------------*/
.masonry-grid {
  overflow: hidden;
  margin: 0 -15px;
}

.masonry-grid.masonry-grid-no-space {
  margin: 0;
}

.masonry-grid.masonry-grid-no-space .masonry-grid-item {
  padding: 0;
  margin: 0;
}

.masonry-grid .masonry-grid-item {
  display: block;
  width: 19.99%;
  float: left;
  vertical-align: top;
  padding: 0 15px;
  margin-bottom: 30px;
}

.masonry-grid .masonry-grid-item.col-1 {
  width: 100%;
}

.masonry-grid .masonry-grid-item.col-2 {
  width: 49.99%;
}

.masonry-grid .masonry-grid-item.col-3 {
  width: 33.33333333%;
}

.masonry-grid .masonry-grid-item.col-4 {
  width: 24.9%;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  .masonry-grid .masonry-grid-item {
    width: 49.9%;
  }
  .masonry-grid .masonry-grid-item.col-2, .masonry-grid .masonry-grid-item.col-3, .masonry-grid .masonry-grid-item.col-4 {
    width: 49.9%;
  }
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .masonry-grid .masonry-grid-item {
    width: 100%;
  }
  .masonry-grid .masonry-grid-item.col-2, .masonry-grid .masonry-grid-item.col-3, .masonry-grid .masonry-grid-item.col-4 {
    width: 100%;
  }
}

/*--------------------------------------------------
    [Owl Carousel]
--------------------------------------------------*/
.owl-carousel {
  position: relative;
}

/* Genereal Changes */
.owl-btn,
.owl-dot {
  cursor: pointer;
}

/* Owl Dots */
.owl-dots {
  text-align: center;
  margin-top: 30px;
}

.owl-dot {
  display: inline-block;
  padding: 0 3px;
}

.owl-dot span,
.owl-dots span {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  text-indent: -999px;
  background: transparent;
  border: 1px solid #BAF402;
  border-radius: 50%;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.owl-dot.active span,
.owl-dots.active span {
  background: #BAF402;
  -webkit-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* Owl Dots Color Picker */
.owl-dots-color-picker .owl-dot.active span,
.owl-dots-color-picker .owl-dots.active span {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
}

.owl-dots-color-picker .owl-dot {
  display: inline-block;
  padding: 0 5px;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
}

.owl-dots-color-picker .owl-dot .owl-dots-color-silver,
.owl-dots-color-picker .owl-dot .owl-dots-color-white,
.owl-dots-color-picker .owl-dot .owl-dots-color-dark {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-indent: -999px;
  border: 1px solid transparent;
  border-radius: 50%;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.owl-dots-color-picker .owl-dot .owl-dots-color-silver.owl-dots-color-silver,
.owl-dots-color-picker .owl-dot .owl-dots-color-white.owl-dots-color-silver,
.owl-dots-color-picker .owl-dot .owl-dots-color-dark.owl-dots-color-silver {
  background: #c69f73;
}

.owl-dots-color-picker .owl-dot .owl-dots-color-silver.owl-dots-color-white,
.owl-dots-color-picker .owl-dot .owl-dots-color-white.owl-dots-color-white,
.owl-dots-color-picker .owl-dot .owl-dots-color-dark.owl-dots-color-white {
  background: #fff;
  border-color: #ebeef6;
}

.owl-dots-color-picker .owl-dot .owl-dots-color-silver.owl-dots-color-dark,
.owl-dots-color-picker .owl-dot .owl-dots-color-white.owl-dots-color-dark,
.owl-dots-color-picker .owl-dot .owl-dots-color-dark.owl-dots-color-dark {
  background: #34343c;
}

.owl-dots-color-picker .owl-dot .owl-dots-color-silver:hover,
.owl-dots-color-picker .owl-dot .owl-dots-color-white:hover,
.owl-dots-color-picker .owl-dot .owl-dots-color-dark:hover {
  opacity: .8;
}

.owl-dots-color-picker .owl-dot.active {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
}

.owl-dots-color-picker .owl-dot.active .owl-dots-color-silver,
.owl-dots-color-picker .owl-dot.active .owl-dots-color-white,
.owl-dots-color-picker .owl-dot.active .owl-dots-color-dark {
  position: relative;
}

.owl-dots-color-picker .owl-dot.active .owl-dots-color-silver:before,
.owl-dots-color-picker .owl-dot.active .owl-dots-color-white:before,
.owl-dots-color-picker .owl-dot.active .owl-dots-color-dark:before {
  position: absolute;
  top: 0;
  z-index: 10;
  display: inline-block;
  width: 40px;
  height: 40px;
  font-size: 20px;
  font-family: FontAwesome;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  padding: 6px;
  text-indent: 0;
  content: "\f00c";
}

.owl-dots-color-picker .owl-dot.active .owl-dots-color-white:before {
  color: #34343c;
}

/* Testimonials v4 Arrows */
.testimonials-v4-arrows .arrow-next,
.testimonials-v4-arrows .arrow-prev {
  position: absolute;
  top: 50%;
  width: 35px;
  height: 35px;
  font-size: 20px;
  color: #34343c;
  background: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  padding: 7px;
  margin-top: -17.5px;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.testimonials-v4-arrows .arrow-next:hover,
.testimonials-v4-arrows .arrow-prev:hover {
  color: #fff;
  background: #baf402;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.testimonials-v4-arrows .arrow-prev {
  left: 0;
  margin-left: -50px;
}

.testimonials-v4-arrows .arrow-next {
  right: 0;
  margin-right: -50px;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  .testimonials-v4-arrows .arrow-prev {
    left: 0;
    margin-left: 0;
  }
  .testimonials-v4-arrows .arrow-next {
    right: 0;
    margin-right: 0;
  }
}

/* Owl Control Arrows v1 */
.owl-control-arrows-v1 .owl-arrow {
  display: inline-block;
  width: 35px;
  height: 35px;
  font-size: 20px;
  color: #34343c;
  text-align: center;
  background: #fff;
  vertical-align: middle;
  cursor: pointer;
  padding: 7px;
  margin: 0 3px;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.owl-control-arrows-v1 .owl-arrow.arrow-left, .owl-control-arrows-v1 .owl-arrow.arrow-right {
  position: absolute;
  top: 50%;
  z-index: 1;
  margin-top: -17.5px;
}

.owl-control-arrows-v1 .owl-arrow.arrow-left {
  left: 0;
}

.owl-control-arrows-v1 .owl-arrow.arrow-right {
  right: 0;
}

.owl-control-arrows-v1 .owl-arrow:hover {
  color: #fff;
  background: #BAF402;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

/* Testimonials v2 Arrows */
.testimonials-v2-arrows .arrow-next,
.testimonials-v2-arrows .arrow-prev {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 35px;
  height: 35px;
  font-size: 20px;
  color: #34343c;
  background: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  padding: 8px 14px;
  margin-top: -17.5px;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.testimonials-v2-arrows .arrow-next:hover,
.testimonials-v2-arrows .arrow-prev:hover {
  color: #fff;
  background: #baf402;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.testimonials-v2-arrows .arrow-prev {
  left: 0;
  margin-left: -40px;
}

.testimonials-v2-arrows .arrow-next {
  right: 0;
  margin-right: -40px;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  .testimonials-v2-arrows .arrow-prev {
    margin-left: 0;
  }
  .testimonials-v2-arrows .arrow-next {
    margin-right: 0;
  }
}

/*------------------------------------------------------------------
    [Green Color Theme]
------------------------------------------------------------------ */
a {
  color: #BAF402;
}

a:focus, a:hover, a:active {
  color: #BAF402;
}

/* Heading */
h1 > a:hover, h2 > a:hover, h3 > a:hover, h4 > a:hover, h5 > a:hover, h6 > a:hover {
  color: #BAF402;
}

/* Selection */
selection {
  background: #BAF402;
}

webkit-selection {
  background: #BAF402;
}

moz-selection {
  background: #BAF402;
}

/* Blog Classic */
.blog-classic .blog-classic-content .blog-classic-share .blog-classic-share-item .blog-classic-share-link:hover .blog-share-icon {
  color: #BAF402;
}

.blog-classic .blog-classic-content .blog-classic-publisher .blog-classic-publisher-name > a:hover {
  color: #BAF402;
}

/* Blog Grid */
/* Supplemental */
.blog-grid .blog-grid-supplemental-category {
  color: #BAF402;
}

/* Blog Grid Quote */
.blog-grid-quote .blog-grid-quote-text:hover {
  color: #BAF402;
}

/* Blog Grid Quote v2 */
.blog-grid-quote-v2 {
  background: #BAF402;
}

/* Blog Grid Thumb */
.blog-grid-thumb .blog-grid-thumb-body:before {
  background: rgba(186, 244, 2, 0.7);
}

/* Twitter Feed */
.twitter-feed .twitter-feed-item .twitter-feed-content .twitter-feed-link {
  color: #BAF402;
}

/* Featured Article */
.featured-article .featured-article-content .featured-article-content-title > a:hover {
  color: #BAF402;
}

/* Footer Tags */
.blog-sidebar-tags > li > a:hover {
  background: #BAF402;
  border-color: #BAF402;
}

/* Blog Teaser Content */
.blog-teaser:hover .blog-teaser-overlay {
  background: rgba(186, 244, 2, 0.8);
}

/* Blog Teaser V2 */
.blog-teaser-v2:hover .blog-teaser-v2-overlay {
  background: rgba(186, 244, 2, 0.7);
}

/* News v1 */
/* Heading */
.news-v1 .news-v1-heading .news-v1-heading-tag:hover {
  color: #BAF402;
}

/* Content */
.news-v1 .news-v1-lists .news-v1-lists-item .news-v1-lists-link:hover {
  color: #BAF402;
}

/* News v3 */
.news-v3 .news-v3-content .news-v3-subtitle {
  color: #BAF402;
}

.news-v3 .news-v3-content .news-v3-element:before, .news-v3 .news-v3-content .news-v3-element:after {
  border-bottom-color: #BAF402;
}

.news-v3 .news-v3-content .news-v3-element .news-v3-element-icon {
  color: #BAF402;
}

/* News v4 */
.news-v4 .news-v4-title-element-left:after {
  border-bottom-color: #BAF402;
}

.news-v4 .news-v4-title-element-right:after {
  border-bottom-color: #BAF402;
}

/* News v5 */
.news-v5 .news-v5-icon {
  color: #BAF402;
}

/* News v6 */
.news-v6 .news-v6-subtitle {
  color: #BAF402;
}

/* News v8 Image Effect */
.news-v8:hover .news-v8-img-effect:before {
  background: rgba(186, 244, 2, 0.5);
}

/* News v8 Wrap */
.news-v8 .news-v8-footer-list .news-v8-footer-list-link:hover {
  color: #BAF402;
}

/* News v8 More */
.news-v8 .news-v8-more .news-v8-more-link {
  background: #BAF402;
}

/* Accordion v1 */
.accordion-v1 .panel-title > a[aria-expanded="true"] {
  background: #BAF402;
}

.accordion-v1 .panel-title > a:focus, .accordion-v1 .panel-title > a:hover {
  background: #BAF402;
}

/* Accordion v2 */
.accordion-v2 .panel-title > a:focus, .accordion-v2 .panel-title > a:hover {
  color: #BAF402;
}

.accordion-v2 .panel-title > a:focus:after, .accordion-v2 .panel-title > a:hover:after {
  color: #BAF402;
}

/* Accordion v3 */
.accordion-v3 .panel-title > a:focus, .accordion-v3 .panel-title > a:hover {
  color: #BAF402;
}

.accordion-v3 .panel-title > a:focus:before, .accordion-v3 .panel-title > a:hover:before {
  color: #BAF402;
}

/* Accordion v4 */
.accordion-v4 .panel-title > a:before {
  color: #BAF402;
}

.accordion-v4 .panel-title > a:focus, .accordion-v4 .panel-title > a:hover {
  color: #BAF402;
}

.accordion-v4 .panel-title > a:focus:before, .accordion-v4 .panel-title > a:hover:before {
  color: #BAF402;
}

/* Accordion v5 */
.accordion-v5 .panel-title > a:focus, .accordion-v5 .panel-title > a:hover {
  color: #BAF402;
}

.accordion-v5 .panel-title > a:focus:after, .accordion-v5 .panel-title > a:hover:after {
  color: #BAF402;
}

/* Blockquotes v1 */
.breadcrumbs-v1 .breadcrumbs-v1-links > li > a:hover {
  color: #BAF402;
}

.breadcrumbs-v1 .breadcrumbs-v1-links > li.active {
  color: #BAF402;
}

/* Breadcrumbs v2 */
.breadcrumbs-v2 .breadcrumbs-v2-links > li > a:hover {
  color: #BAF402;
}

.breadcrumbs-v2 .breadcrumbs-v2-links > li.active {
  color: #BAF402;
}

/* Breadcrumbs v3 */
.breadcrumbs-v3 .breadcrumbs-v3-links > li > a:hover {
  color: #BAF402;
}

.breadcrumbs-v3 .breadcrumbs-v3-links > li.active {
  color: #BAF402;
}

/* Button Green Color */
.btn-base {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base:focus, .btn-base:active:focus, .btn-base.active:focus, .btn-base.focus, .btn-base:active.focus, .btn-base.active.focus {
  outline: none;
}

.btn-base:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base:hover, .btn-base:focus, .btn-base.focus {
  color: #fff;
  background: #BAF402;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-base:after, .btn-base:before {
  background: transparent;
}

.btn-base:active, .btn-base.active {
  background-image: none;
  outline: 0;
}

.btn-base.disabled, .btn-base[disabled],
fieldset[disabled] .btn-base {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 4px 9px;
}

.btn-base .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 7px 11px;
}

.btn-base .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 8px 13px;
}

.btn-base .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 10px 15px;
}

/* Buttons Border Green Color */
.btn-base-brd {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #BAF402;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-brd:focus, .btn-base-brd:active:focus, .btn-base-brd.active:focus, .btn-base-brd.focus, .btn-base-brd:active.focus, .btn-base-brd.active.focus {
  outline: none;
}

.btn-base-brd:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-brd:hover, .btn-base-brd:focus, .btn-base-brd.focus {
  color: #fff;
  background: #BAF402;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-base-brd:after, .btn-base-brd:before {
  background: transparent;
}

.btn-base-brd:active, .btn-base-brd.active {
  background-image: none;
  outline: 0;
}

.btn-base-brd.disabled, .btn-base-brd[disabled],
fieldset[disabled] .btn-base-brd {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-brd .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 4px 9px;
}

.btn-base-brd .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 7px 11px;
}

.btn-base-brd .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 8px 13px;
}

.btn-base-brd .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 10px 15px;
}

/* Button Background Green Color */
.btn-base-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #BAF402;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-bg:focus, .btn-base-bg:active:focus, .btn-base-bg.active:focus, .btn-base-bg.focus, .btn-base-bg:active.focus, .btn-base-bg.active.focus {
  outline: none;
}

.btn-base-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-bg:hover, .btn-base-bg:focus, .btn-base-bg.focus {
  color: #fff;
  background: #baf451;
  border-color: transparent;
  text-decoration: none;
}

.btn-base-bg:after, .btn-base-bg:before {
  background: transparent;
}

.btn-base-bg:active, .btn-base-bg.active {
  background-image: none;
  outline: 0;
}

.btn-base-bg.disabled, .btn-base-bg[disabled],
fieldset[disabled] .btn-base-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 4px 9px;
}

.btn-base-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 7px 11px;
}

.btn-base-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 8px 13px;
}

.btn-base-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 11px 15px;
}

/* Button Background White Color */
.btn-white-bg {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: #fff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-bg:focus, .btn-white-bg:active:focus, .btn-white-bg.active:focus, .btn-white-bg.focus, .btn-white-bg:active.focus, .btn-white-bg.active.focus {
  outline: none;
}

.btn-white-bg:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-bg:hover, .btn-white-bg:focus, .btn-white-bg.focus {
  color: #fff;
  background: #BAF402;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-white-bg:after, .btn-white-bg:before {
  background: transparent;
}

.btn-white-bg:active, .btn-white-bg.active {
  background-image: none;
  outline: 0;
}

.btn-white-bg.disabled, .btn-white-bg[disabled],
fieldset[disabled] .btn-white-bg {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-bg .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-bg .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-bg .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-bg .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Green Color Slide */
.btn-base-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #BAF402;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: tranparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-bg-slide:focus, .btn-base-bg-slide:active:focus, .btn-base-bg-slide.active:focus, .btn-base-bg-slide.focus, .btn-base-bg-slide:active.focus, .btn-base-bg-slide.active.focus {
  outline: none;
}

.btn-base-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-bg-slide:hover, .btn-base-bg-slide:focus, .btn-base-bg-slide.focus {
  color: #fff;
  background: #BAF402;
  border-color: tranparent;
  text-decoration: none;
}

.btn-base-bg-slide:after, .btn-base-bg-slide:before {
  background: #baf451;
}

.btn-base-bg-slide:active, .btn-base-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-base-bg-slide.disabled, .btn-base-bg-slide[disabled],
fieldset[disabled] .btn-base-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 6px 9px;
}

.btn-base-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 9px 11px;
}

.btn-base-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 12px 13px;
}

.btn-base-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 15px 15px;
}

/* Button White Color Slide */
.btn-white-bg-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: #fff;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-bg-slide:focus, .btn-white-bg-slide:active:focus, .btn-white-bg-slide.active:focus, .btn-white-bg-slide.focus, .btn-white-bg-slide:active.focus, .btn-white-bg-slide.active.focus {
  outline: none;
}

.btn-white-bg-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-bg-slide:hover, .btn-white-bg-slide:focus, .btn-white-bg-slide.focus {
  color: #fff;
  background: #fff;
  border-color: transparent;
  text-decoration: none;
}

.btn-white-bg-slide:after, .btn-white-bg-slide:before {
  background: #BAF402;
}

.btn-white-bg-slide:active, .btn-white-bg-slide.active {
  background-image: none;
  outline: 0;
}

.btn-white-bg-slide.disabled, .btn-white-bg-slide[disabled],
fieldset[disabled] .btn-white-bg-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-bg-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-bg-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-bg-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-bg-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Green Color Slide */
.btn-base-brd-slide {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #BAF402;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-brd-slide:focus, .btn-base-brd-slide:active:focus, .btn-base-brd-slide.active:focus, .btn-base-brd-slide.focus, .btn-base-brd-slide:active.focus, .btn-base-brd-slide.active.focus {
  outline: none;
}

.btn-base-brd-slide:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-brd-slide:hover, .btn-base-brd-slide:focus, .btn-base-brd-slide.focus {
  color: #fff;
  background: transparent;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-base-brd-slide:after, .btn-base-brd-slide:before {
  background: #BAF402;
}

.btn-base-brd-slide:active, .btn-base-brd-slide.active {
  background-image: none;
  outline: 0;
}

.btn-base-brd-slide.disabled, .btn-base-brd-slide[disabled],
fieldset[disabled] .btn-base-brd-slide {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-brd-slide .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 6px 9px;
}

.btn-base-brd-slide .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 9px 11px;
}

.btn-base-brd-slide .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 12px 13px;
}

.btn-base-brd-slide .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #abe002;
  padding: 15px 15px;
}

/* Button Background Green Color */
.btn-base-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #fff;
  text-align: center;
  background: #BAF402;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-bg-anim:focus, .btn-base-bg-anim:active:focus, .btn-base-bg-anim.active:focus, .btn-base-bg-anim.focus, .btn-base-bg-anim:active.focus, .btn-base-bg-anim.active.focus {
  outline: none;
}

.btn-base-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-bg-anim:hover, .btn-base-bg-anim:focus, .btn-base-bg-anim.focus {
  color: #fff;
  background: #BAF402;
  border-color: transparent;
  text-decoration: none;
}

.btn-base-bg-anim:after, .btn-base-bg-anim:before {
  background: #a7db02;
}

.btn-base-bg-anim:active, .btn-base-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-base-bg-anim.disabled, .btn-base-bg-anim[disabled],
fieldset[disabled] .btn-base-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-base-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-base-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-base-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Button Background White Color */
.btn-white-bg-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #34343c;
  text-align: center;
  background: #fff;
  background-image: none;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-white-bg-anim:focus, .btn-white-bg-anim:active:focus, .btn-white-bg-anim.active:focus, .btn-white-bg-anim.focus, .btn-white-bg-anim:active.focus, .btn-white-bg-anim.active.focus {
  outline: none;
}

.btn-white-bg-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-white-bg-anim:hover, .btn-white-bg-anim:focus, .btn-white-bg-anim.focus {
  color: #fff;
  background: #fff;
  border-color: transparent;
  text-decoration: none;
}

.btn-white-bg-anim:after, .btn-white-bg-anim:before {
  background: #BAF402;
}

.btn-white-bg-anim:active, .btn-white-bg-anim.active {
  background-image: none;
  outline: 0;
}

.btn-white-bg-anim.disabled, .btn-white-bg-anim[disabled],
fieldset[disabled] .btn-white-bg-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-white-bg-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 6px 9px;
}

.btn-white-bg-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 9px 11px;
}

.btn-white-bg-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 12px 13px;
}

.btn-white-bg-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border-left: 1px solid #f0f0f0;
  padding: 15px 15px;
}

/* Button Border Green Color */
.btn-base-brd-anim {
  position: relative;
  display: inline-block;
  line-height: 1.4;
  color: #BAF402;
  text-align: center;
  background: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #BAF402;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-base-brd-anim:focus, .btn-base-brd-anim:active:focus, .btn-base-brd-anim.active:focus, .btn-base-brd-anim.focus, .btn-base-brd-anim:active.focus, .btn-base-brd-anim.active.focus {
  outline: none;
}

.btn-base-brd-anim:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.btn-base-brd-anim:hover, .btn-base-brd-anim:focus, .btn-base-brd-anim.focus {
  color: #fff;
  background: transparent;
  border-color: #BAF402;
  text-decoration: none;
}

.btn-base-brd-anim:after, .btn-base-brd-anim:before {
  background: #BAF402;
}

.btn-base-brd-anim:active, .btn-base-brd-anim.active {
  background-image: none;
  outline: 0;
}

.btn-base-brd-anim.disabled, .btn-base-brd-anim[disabled],
fieldset[disabled] .btn-base-brd-anim {
  cursor: not-allowed;
  box-shadow: none;
  opacity: .65;
  pointer-events: none;
}

.btn-base-brd-anim .btn-base-element-xs {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 6px 9px;
}

.btn-base-brd-anim .btn-base-element-sm {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 9px 11px;
}

.btn-base-brd-anim .btn-base-element-md {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 12px 13px;
}

.btn-base-brd-anim .btn-base-element-lg {
  position: absolute;
  top: 0;
  right: 0;
  background: #abe002;
  border-left: 1px solid transparent;
  padding: 15px 15px;
}

/* Divider */
.divider-v1 .divider-v1-element-bg, .divider-v2 .divider-v2-element-bg, .divider-v3 .divider-v3-element-bg {
  background: #BAF402;
}

.divider-v1 .divider-v1-icon, .divider-v2 .divider-v2-icon, .divider-v3 .divider-v3-icon {
  color: #BAF402;
}

/* Dropcap Base Color */
.dropcap-base {
  color: #BAF402;
}

/* Dropcap Base Background Color */
.dropcap-base-bg {
  background: #BAF402;
}

/* Dropcap Base Color Bordered */
.dropcap-base-bordered {
  color: #BAF402;
  border-color: #BAF402;
}

/* Dropcap Base Color */
.dropcap-dark {
  color: #34343c;
}

/* Heading v1 */
.heading-v1 .heading-v1-subtitle {
  color: #BAF402;
}

/* Font Awesome Icons */
.bordered-icon .bordered-icon-box:hover {
  background: #BAF402;
}

/* Lists */
.lists-base > li > a:hover, .lists-dark > li > a:hover {
  color: #BAF402;
}

.lists-base > li .lists-item-element, .lists-dark > li .lists-base .lists-item-element, .lists-base .lists-dark > li .lists-item-element {
  color: #BAF402;
}

/* Lists Base */
.lists-base .lists-item-element-brd {
  color: #BAF402;
  border-color: #BAF402;
}

.lists-base .lists-item-element-bg {
  background: #BAF402;
}

/* Numeric List */
.numeric-base-lists > li > a:hover, .numeric-dark-lists > li > a:hover {
  color: #BAF402;
}

/* Numeric Base Lists */
.numeric-base-lists > li {
  color: #BAF402;
}

/* Log In */
.login .login-form-forgot:hover {
  color: #BAF402;
}

/* Paginations v1 */
.paginations-v1-list > li > a:hover,
.paginations-v1-list > li > span:hover {
  background: rgba(186, 244, 2, 0.5);
}

.paginations-v1-list > li.next > a:hover,
.paginations-v1-list > li.next > span:hover, .paginations-v1-list > li.previous > a:hover,
.paginations-v1-list > li.previous > span:hover {
  color: #BAF402;
}

.paginations-v1-list > li.active > a,
.paginations-v1-list > li.active > span {
  background: #BAF402;
}

/* Paginations v2 */
.paginations-v2-list > li > a:hover,
.paginations-v2-list > li > span:hover {
  color: #BAF402;
  border-bottom-color: #BAF402;
}

.paginations-v2-list > li.active > a,
.paginations-v2-list > li.active > span {
  color: #BAF402;
  border-bottom-color: #BAF402;
}

.paginations-v2-list > li.active > a:hover,
.paginations-v2-list > li.active > span:hover {
  color: #BAF402;
}

/* Paginations v3 */
.paginations-v3-list > li > a,
.paginations-v3-list > li > span {
  color: #BAF402;
}

.paginations-v3-list > li > a:hover,
.paginations-v3-list > li > span:hover {
  color: #BAF402;
}

.paginations-v3-list > li.active > a,
.paginations-v3-list > li.active > span {
  background: #BAF402;
  border-color: #BAF402;
}

.paginations-v3-list > li.active > a:hover,
.paginations-v3-list > li.active > span:hover {
  background: #BAF402;
}

/* Pagers v1 */
.pagers-v1:hover {
  background: #BAF402;
}

/* Pagers v2 */
.pagers-v2 li > a:hover,
.pagers-v2 li > span:hover {
  border-color: #BAF402;
  background: #BAF402;
}

/* Tab v1 */
.tab-v1 .nav-tabs > li:hover > a {
  color: #BAF402;
}

.tab-v1 .nav-tabs > li.active > a {
  color: #BAF402;
}

/* Tab v3 */
.tab-v3 .nav-tabs > li:hover > a {
  color: #BAF402;
}

.tab-v3 .nav-tabs > li.active > a {
  background: #BAF402;
}

/* Tab v4 */
.tab-v4 .nav-tabs {
  background: #BAF402;
}

.tab-v4 .nav-tabs.nav-tabs-left > li:hover > a {
  background: #9bcc02;
}

.tab-v4 .nav-tabs.nav-tabs-left > li.active > a {
  background: #9bcc02;
}

/* Tab v5 (left) */
.tab-v5 .nav-tabs.nav-tabs-left > li:hover > a {
  color: #BAF402;
}

.tab-v5 .nav-tabs.nav-tabs-left > li.active > a {
  color: #BAF402;
}

/* Tab v6 (right) */
.tab-v6 .nav-tabs.nav-tabs-right > li:hover > a {
  color: #BAF402;
}

.tab-v6 .nav-tabs.nav-tabs-right > li.active > a {
  color: #BAF402;
}

/* Theme Icons Base */
.theme-icons-wrap > a[href] > .theme-icons-base-hover:hover {
  background: #BAF402;
}

/* Theme Icons Base Background */
.theme-icons-base-bg {
  display: inline-block;
  color: #fff;
  background: #BAF402;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-base-bg:hover {
  background: #baf451;
}

/* Theme Icons White Background */
.theme-icons-wrap > a[href] > .theme-icons-white-bg:hover {
  background: #BAF402;
}

/* Theme Icons Grey Light Background */
.theme-icons-wrap > a[href] > .theme-icons-grey-light-bg:hover {
  background: #BAF402;
}

/* Theme Icons Base Bordered */
.theme-icons-base-brd {
  display: inline-block;
  color: #BAF402;
  background: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #BAF402;
  text-align: center;
  vertical-align: middle;
}

.theme-icons-wrap > a[href] > .theme-icons-base-brd:hover {
  background: #BAF402;
  border-color: #BAF402;
}

/* Animate Theme Icons Base */
.animate-theme-icons .animate-theme-icons-base {
  color: #BAF402;
}

/* Animate Theme Icons Base Background */
.animate-theme-icons .animate-theme-icons-base-bg {
  background: #BAF402;
}

/* Animate Theme Icons Base Bordered */
.animate-theme-icons .animate-theme-icons-base-brd {
  color: #BAF402;
  border-color: #BAF402;
}

/* Theme Icons Elegant */
.theme-icons-elegant .theme-icons-element .theme-icons-wrap > a[href] > .theme-icons:hover {
  background: #BAF402;
}

/* TimeLine Current Date */
.timeline-v1-current-date .timeline-v1-current-day {
  color: #BAF402;
}

.timeline-v1-current-date .timeline-v1-current-time {
  color: #BAF402;
}

/* TimeLine v2 */
.timeline-v2 .timeline-v2-news-title > a:hover {
  color: #BAF402;
}

.timeline-v2 .timeline-v2-current-day {
  color: #BAF402;
}

.timeline-v2 .timeline-v2-current-time {
  color: #BAF402;
}

/* TimeLine v3 */
.timeline-v3 .timeline-v3-list-item .timeline-v3-badge-icon {
  background: #BAF402;
}

/* TimeLine v4 */
.timeline-v4:before {
  background: #BAF402;
}

.timeline-v4:after {
  background: #BAF402;
}

.timeline-v4 .timeline-v4-list-item .timeline-v4-badge-icon {
  background: #BAF402;
}

.timeline-v4 .timeline-v4-subtitle {
  color: #BAF402;
}

/* Counters v1 */
.counters-v1 .counters-v1-body .counters-v1-number {
  color: #BAF402;
}

/* Counters v3 */
.counters-v3 .counters-v3-icon {
  color: #BAF402;
}

/* Icons Box v2 */
.icon-box-v2 .icon-box-v2-icons {
  color: #BAF402;
}

/* Image Gallery */
.image-gallery .image-gallery-overlay {
  background: rgba(186, 244, 2, 0.6);
}

/* Image Gallery v2 */
.image-gallery-v2 .image-gallery-v2-overlay {
  background: rgba(186, 244, 2, 0.6);
}

/* Interactive Banner v2 */
.i-banner-v2 .i-banner-v2-heading .i-banner-v2-heading-month {
  color: #BAF402;
}

/* Interactive Banner v3 */
.i-banner-v3 .i-banner-v3-content .i-banner-v3-subtitle {
  color: #BAF402;
}

.i-banner-v3 .i-banner-v3-content .i-banner-v3-element .i-banner-v3-element-icon {
  color: #BAF402;
}

/* Newsletter v5 */
.newsletter-v5 .newsletter-v5-btn:hover {
  color: #BAF402;
}

/* Newsletter v8 */
.newsletter-v8:hover {
  background: #BAF402;
}

/* Poster v1 */
.poster-v1 .poster-v1-body .poster-v1-author {
  color: #BAF402;
}

/* Poster v2 */
.poster-v2 .poster-v2-subtitle {
  color: #BAF402;
}

/* Pricing List v1 Active */
.pricing-list-v1-active .pricing-list-v1-body .pricing-list-v1-header-price,
.pricing-list-v1-active .pricing-list-v1-body .pricing-list-v1-header-price-sign,
.pricing-list-v1-active .pricing-list-v1-body .pricing-list-v1-header-price-info {
  color: #BAF402;
}

/* Process v1 */
.process-v1 .process-v1-back {
  background: #BAF402;
}

/* Process v2 */
.process-v2:hover .process-v2-element {
  background: #BAF402;
}

/* Service Block v4 */
.service-block-v4 .service-block-v4-content .service-block-v4-content-subtitle {
  color: #BAF402;
}

/* Service Block v6 */
.service-block-v6.service-block-v6-color-three:before {
  background: rgba(186, 244, 2, 0.85);
}

/* Service Block v8 */
.service-block-v8 .service-block-v8-subtitle {
  color: #BAF402;
}

/* Slider Block v1 */
.slider-block-v1 .slider-block-v1-subtitle {
  color: #BAF402;
}

/* Team v1 */
.team-v1 .team-v1-content .team-v1-socials .team-v1-socials-link:hover {
  color: #BAF402;
}

/* Team v2 */
.team-v2 .team-v2-member-position {
  color: #BAF402;
}

/* Team v3 */
.team-v3 .team-v3-header .team-v3-member-position {
  color: #BAF402;
}

/* Team v5 */
.team-v5 .team-v5-content .team-v5-author-contact:hover {
  color: #BAF402;
}

/* Testimonials v1 */
.testimonials-v1 .testimonials-v1-author {
  color: #BAF402;
}

/* Testimonials v4 */
.testimonials-v4 .testimonials-v4-author {
  color: #BAF402;
}

/* Testimonials v5 */
.testimonials-v5 .testimonials-v5-author {
  color: #BAF402;
}

/* Testimonials v6 */
.testimonials-v6 .testimonials-v6-element .testimonials-v6-author {
  color: #BAF402;
}

/* Testimonials v7 */
.testimonials-v7 .testimonials-v7-author {
  color: #BAF402;
}

.testimonials-v7 .testimonials-v7-author:before {
  background: #BAF402;
}

/* Footer Top */
.footer .footer-address .footer-address-link:hover {
  color: #BAF402;
}

/* Footer Media */
.footer .footer-media .footer-media-item .footer-media-link:hover {
  color: #BAF402;
}

/* Footer Tags */
.footer .footer-tags > li > a:hover {
  background: #BAF402;
  border-color: #BAF402;
}

/* Footer News List */
.footer .footer-news-list .footer-news-list-item .footer-news-list-link:hover {
  color: #BAF402;
}

/* Footer Video Info */
.footer .footer-video-title .footer-video-title-link:hover {
  color: #BAF402;
}

/* Footer Copyright */
.footer .footer-copyright .footer-copyright-item > a:hover {
  color: #BAF402;
}

/* Footer Toggle Content */
.footer .footer-toggle .footer-toggle-link:hover {
  color: #BAF402;
}

/* Footer v2 */
.footer-v2 {
  background: #BAF402;
}

/* Footer v4 */
.footer-v4 .footer-v4-service .footer-v4-service-item .footer-v4-service-icon {
  color: #BAF402;
}

.footer-v4 .footer-v4-service .footer-v4-service-item .footer-v4-service-link:hover {
  color: #BAF402;
}

.footer-v4 .footer-v4-news .footer-v4-news-item .footer-v4-news-link:hover {
  color: #BAF402;
}

.footer-v4 .footer-v4-link:hover {
  color: #BAF402;
}

.footer-v4 .footer-v4-copyright .footer-v4-terms-list .footer-v4-terms-link:hover {
  color: #BAF402;
}

/* Footer v5 */
.footer-v5 .footer-v5-content-subtitle {
  color: #BAF402;
}

.footer-v5 .footer-v5-list .footer-v5-list-item .footer-v5-list-link:hover {
  color: #BAF402;
}

/* Copyright */
.footer-v5-copyright .footer-v5-copright-socials .footer-v5-copright-socials-item .footer-v5-copright-socials-link:hover {
  color: #BAF402;
}

/* Footer v6 */
.footer-v6 .footer-link-v6-list .footer-link-v6-list-item .footer-link-v6-list-link:hover {
  color: #BAF402;
}

/* Form Modal */
.form-modal .form-modal-back-btn-message .form-modal-back-btn-message-link {
  color: #BAF402;
}

.form-modal .form-modal-input-group .form-modal-hide-password:hover {
  color: #BAF402;
}

.form-modal .form-modal-close-form:hover {
  color: #BAF402;
}

/* Navbar Toggle */
.header .navbar-toggle:hover .toggle-icon {
  background: #BAF402;
}

.header .navbar-toggle:hover .toggle-icon:before, .header .navbar-toggle:hover .toggle-icon:after {
  background: #BAF402;
}

/* Topbar Toggle Trigger */
.header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style {
  background: #BAF402;
}

.header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before, .header .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after {
  background: #BAF402;
}

/* Topbar Toggle Trigger Icon */
.header .topbar-toggle-trigger-icon-style:hover {
  color: #BAF402;
}

/* Shopping Cart */
.header .shopping-cart .shopping-cart-icon:hover {
  color: #BAF402;
}

.header .shopping-cart .shopping-cart-amount {
  background: #BAF402;
}

.header .shopping-cart .shopping-cart-menu {
  border-top-color: #BAF402;
}

.header .shopping-cart .shopping-cart-menu-product-name:hover {
  color: #BAF402;
}

.header .shopping-cart .shopping-cart-close:hover {
  color: #BAF402;
}

.header .shopping-cart .shopping-cart-subtotal-price {
  color: #BAF402;
}

.header .shopping-cart .shopping-cart-subtotal-view-link:hover {
  color: #BAF402;
}

/* Navbar Nav */
.header .navbar-nav .nav-item-child.active {
  color: #BAF402;
}

.header .navbar-nav .nav-item-child.active:hover {
  color: #BAF402;
}

.header .navbar-nav .nav-item-child:hover, .header .navbar-nav .nav-item-child:focus {
  color: #BAF402;
}

/* Dropdown Menu */
.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active {
  color: #BAF402;
}

.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover {
  color: #BAF402;
}

.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover {
  background: #BAF402;
}

.header .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child {
  color: #BAF402;
}

/* Dropdown Submenu */
.header .dropdown-submenu:hover .dropdown-submenu-child {
  color: #BAF402;
}

.header .dropdown-submenu .dropdown-submenu-child.active {
  color: #BAF402;
}

.header .dropdown-submenu .dropdown-submenu-child.active:hover {
  color: #BAF402;
}

.header .dropdown-submenu .dropdown-submenu-child.active:hover:after {
  color: #BAF402;
}

.header .dropdown-submenu .dropdown-submenu-child:hover {
  color: #fff;
  background: #BAF402;
}

.header .dropdown-submenu .dropdown-submenu-child:hover:after {
  color: #fff;
}

.header .dropdown-submenu.active .dropdown-submenu-child {
  color: #BAF402;
}

.header .dropdown-submenu.active .dropdown-submenu-child:hover {
  color: #BAF402;
}

/* Mega Menu */
.header .mega-menu-list .mega-menu-child.active {
  color: #BAF402;
}

.header .mega-menu-list .mega-menu-child.active:hover {
  color: #BAF402;
}

.header .mega-menu-list .mega-menu-child:hover {
  background: #BAF402;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  /* Navbar Nav */
  .header .navbar-nav .nav-item-bg {
    border-color: #BAF402;
  }
  .header .navbar-nav .nav-item-bg .nav-item-child {
    background: #BAF402;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Navbar */
  .header .navbar-nav .nav-item .dropdown-menu {
    border-top-color: #BAF402;
  }
  .header .navbar-nav .nav-item:hover .nav-item-child {
    color: #BAF402;
  }
  .header .navbar-nav .nav-item-bg {
    border-color: #BAF402;
  }
  .header .navbar-nav .nav-item-bg .nav-item-child {
    background: #BAF402;
  }
  .header .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
  }
}

/* Navbar Toggle */
.header-center-aligned .navbar-toggle:hover .toggle-icon {
  background: #BAF402;
}

.header-center-aligned .navbar-toggle:hover .toggle-icon:before, .header-center-aligned .navbar-toggle:hover .toggle-icon:after {
  background: #BAF402;
}

/* Topbar Toggle Trigger */
.header-center-aligned .topbar-toggle-trigger:hover .topbar-toggle-trigger-style {
  background: #BAF402;
}

.header-center-aligned .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before, .header-center-aligned .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after {
  background: #BAF402;
}

/* Topbar Toggle Trigger Icon */
.header-center-aligned .topbar-toggle-trigger-icon-style:hover {
  color: #BAF402;
}

/* Shopping Cart */
.header-center-aligned .shopping-cart .shopping-cart-icon:hover {
  color: #BAF402;
}

.header-center-aligned .shopping-cart .shopping-cart-amount {
  background: #BAF402;
}

.header-center-aligned .shopping-cart .shopping-cart-menu {
  border-top-color: #BAF402;
}

.header-center-aligned .shopping-cart .shopping-cart-menu-product-name:hover {
  color: #BAF402;
}

.header-center-aligned .shopping-cart .shopping-cart-close:hover {
  color: #BAF402;
}

.header-center-aligned .shopping-cart .shopping-cart-subtotal-price {
  color: #BAF402;
}

.header-center-aligned .shopping-cart .shopping-cart-subtotal-view-link:hover {
  color: #BAF402;
}

/* Navbar Nav */
.header-center-aligned .navbar-nav .nav-item-child.active {
  color: #BAF402;
}

.header-center-aligned .navbar-nav .nav-item-child.active:hover {
  color: #BAF402;
}

.header-center-aligned .navbar-nav .nav-item-child:hover, .header-center-aligned .navbar-nav .nav-item-child:focus {
  color: #BAF402;
}

/* Dropdown Menu */
.header-center-aligned .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active {
  color: #BAF402;
}

.header-center-aligned .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover {
  color: #BAF402;
}

.header-center-aligned .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover {
  background: #BAF402;
}

.header-center-aligned .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child {
  color: #BAF402;
}

/* Dropdown Submenu */
.header-center-aligned .dropdown-submenu:hover .dropdown-submenu-child {
  color: #BAF402;
}

.header-center-aligned .dropdown-submenu .dropdown-submenu-child.active {
  color: #BAF402;
}

.header-center-aligned .dropdown-submenu .dropdown-submenu-child.active:hover {
  color: #BAF402;
}

.header-center-aligned .dropdown-submenu .dropdown-submenu-child.active:hover:after {
  color: #BAF402;
}

.header-center-aligned .dropdown-submenu .dropdown-submenu-child:hover {
  color: #fff;
  background: #BAF402;
}

.header-center-aligned .dropdown-submenu .dropdown-submenu-child:hover:after {
  color: #fff;
}

.header-center-aligned .dropdown-submenu.active .dropdown-submenu-child {
  color: #BAF402;
}

.header-center-aligned .dropdown-submenu.active .dropdown-submenu-child:hover {
  color: #BAF402;
}

/* Mega Menu */
.header-center-aligned .mega-menu-list .mega-menu-child.active {
  color: #BAF402;
}

.header-center-aligned .mega-menu-list .mega-menu-child.active:hover {
  color: #BAF402;
}

.header-center-aligned .mega-menu-list .mega-menu-child:hover {
  background: #BAF402;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  .header-center-aligned .navbar-nav .nav-item-bg {
    border-color: #BAF402;
  }
  .header-center-aligned .navbar-nav .nav-item-bg .nav-item-child {
    background: #BAF402;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Navbar */
  .header-center-aligned .navbar-nav .nav-item .dropdown-menu {
    border-top-color: #BAF402;
  }
  .header-center-aligned .navbar-nav .nav-item:hover .nav-item-child {
    color: #BAF402;
  }
  .header-center-aligned .navbar-nav .nav-item-bg {
    border-color: #BAF402;
  }
  .header-center-aligned .navbar-nav .nav-item-bg .nav-item-child {
    background: #BAF402;
  }
  .header-center-aligned .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
  }
}

/* Navbar Toggle */
.header-center-aligned-transparent .navbar-toggle:hover .toggle-icon {
  background: #BAF402;
}

.header-center-aligned-transparent .navbar-toggle:hover .toggle-icon:before, .header-center-aligned-transparent .navbar-toggle:hover .toggle-icon:after {
  background: #BAF402;
}

/* Topbar Toggle Trigger */
.header-center-aligned-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style {
  background: #BAF402;
}

.header-center-aligned-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before, .header-center-aligned-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after {
  background: #BAF402;
}

/* Topbar Toggle Trigger Icon */
.header-center-aligned-transparent .topbar-toggle-trigger-icon-style:hover {
  color: #BAF402;
}

/* Shopping Cart */
.header-center-aligned-transparent .shopping-cart .shopping-cart-icon:hover {
  color: #BAF402;
}

.header-center-aligned-transparent .shopping-cart .shopping-cart-amount {
  background: #BAF402;
}

.header-center-aligned-transparent .shopping-cart .shopping-cart-menu {
  border-top-color: #BAF402;
}

.header-center-aligned-transparent .shopping-cart .shopping-cart-menu-product-name:hover {
  color: #BAF402;
}

.header-center-aligned-transparent .shopping-cart .shopping-cart-close:hover {
  color: #BAF402;
}

.header-center-aligned-transparent .shopping-cart .shopping-cart-subtotal-price {
  color: #BAF402;
}

.header-center-aligned-transparent .shopping-cart .shopping-cart-subtotal-view-link:hover {
  color: #BAF402;
}

/* Navbar Nav */
.header-center-aligned-transparent .navbar-nav .nav-item-child.active {
  color: #BAF402;
}

.header-center-aligned-transparent .navbar-nav .nav-item-child.active:hover {
  color: #BAF402;
}

.header-center-aligned-transparent .navbar-nav .nav-item-child:hover, .header-center-aligned-transparent .navbar-nav .nav-item-child:focus {
  color: #BAF402;
}

/* Dropdown Menu */
.header-center-aligned-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active {
  color: #BAF402;
}

.header-center-aligned-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover {
  color: #BAF402;
}

.header-center-aligned-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover {
  background: #BAF402;
}

.header-center-aligned-transparent .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child {
  color: #BAF402;
}

/* Dropdown Submenu */
.header-center-aligned-transparent .dropdown-submenu:hover .dropdown-submenu-child {
  color: #BAF402;
}

.header-center-aligned-transparent .dropdown-submenu .dropdown-submenu-child.active {
  color: #BAF402;
}

.header-center-aligned-transparent .dropdown-submenu .dropdown-submenu-child.active:hover {
  color: #BAF402;
  background: #fff;
}

.header-center-aligned-transparent .dropdown-submenu .dropdown-submenu-child.active:hover:after {
  color: #BAF402;
}

.header-center-aligned-transparent .dropdown-submenu .dropdown-submenu-child:hover {
  color: #fff;
  background: #BAF402;
}

.header-center-aligned-transparent .dropdown-submenu.active .dropdown-submenu-child {
  color: #BAF402;
}

.header-center-aligned-transparent .dropdown-submenu.active .dropdown-submenu-child:hover {
  color: #BAF402;
}

/* Mega Menu */
.header-center-aligned-transparent .mega-menu-list .mega-menu-child.active {
  color: #BAF402;
}

.header-center-aligned-transparent .mega-menu-list .mega-menu-child.active:hover {
  color: #BAF402;
}

.header-center-aligned-transparent .mega-menu-list .mega-menu-child:hover {
  background: #BAF402;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  /* Navbar Nav */
  .header-center-aligned-transparent .navbar-nav .nav-item-bg {
    border-color: #BAF402;
  }
  .header-center-aligned-transparent .navbar-nav .nav-item-bg .nav-item-child {
    background: #BAF402;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Header Shrink */
  .header-shrink.header-center-aligned-transparent .shopping-cart .shopping-cart-icon:hover {
    color: #BAF402;
  }
  /* Navbar */
  .header-center-aligned-transparent .navbar-nav .nav-item .dropdown-menu {
    border-top-color: #BAF402;
  }
  .header-center-aligned-transparent .navbar-nav .nav-item:hover .nav-item-child {
    color: #BAF402;
  }
  .header-center-aligned-transparent .navbar-nav .nav-item-child.active {
    color: #BAF402;
  }
  .header-center-aligned-transparent .navbar-nav .nav-item-bg {
    border-color: #BAF402;
  }
  .header-center-aligned-transparent .navbar-nav .nav-item-bg .nav-item-child {
    color: #fff;
    background: #BAF402;
  }
  .header-center-aligned-transparent .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
  }
}

/* Navbar */
.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon {
  background: #BAF402;
}

.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:before, .header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:after {
  background: #BAF402;
}

.header-fullscreen .header-fullscreen-nav-actions-right .nav-item-child {
  background: #BAF402;
}

/* Nav Overlay */
.header-fullscreen .header-fullscreen-nav-bg-overlay .header-fullscreen-nav-close:hover {
  color: #BAF402;
}

/* Menu */
.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover {
  color: #BAF402;
}

.header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active:before, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown.active:after, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:before, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:after, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:before, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:after, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:before, .header-fullscreen .header-fullscreen-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:after,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:before,
.header-fullscreen .header-fullscreen-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:after {
  color: #BAF402;
}

.header-fullscreen .header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-link:hover {
  color: #BAF402;
}

.header-fullscreen .header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-child.active {
  color: #BAF402;
}

.header-fullscreen .header-fullscreen-menu .nav-dropdown-menu .nav-dropdown-menu-child:hover {
  color: #BAF402;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  /* Nav Trigger */
  .header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon {
    background: #BAF402;
  }
  .header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:before, .header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:after {
    background: #BAF402;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Header Shrink */
  .header-shrink.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon {
    background: #BAF402;
  }
  .header-shrink.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:before, .header-shrink.header-fullscreen .header-fullscreen-nav-actions-left .header-fullscreen-nav-trigger:hover .header-fullscreen-nav-trigger-icon:after {
    background: #BAF402;
  }
}

/* Navbar Toggle */
.header-section-scroll .navbar-toggle:hover .toggle-icon {
  background: #BAF402;
}

.header-section-scroll .navbar-toggle:hover .toggle-icon:before, .header-section-scroll .navbar-toggle:hover .toggle-icon:after {
  background: #BAF402;
}

/* Navbar Nav */
.header-section-scroll .header-section-scroll-menu .nav-item.active .nav-item-child:hover, .header-section-scroll .header-section-scroll-menu .nav-item.active .nav-item-child:focus {
  color: #BAF402;
}

.header-section-scroll .header-section-scroll-menu .nav-item-child:hover {
  color: #BAF402;
}

/* Navbar Toggle */
.header-transparent .navbar-toggle:hover .toggle-icon {
  background: #BAF402;
}

.header-transparent .navbar-toggle:hover .toggle-icon:before, .header-transparent .navbar-toggle:hover .toggle-icon:after {
  background: #BAF402;
}

/* Topbar Toggle Trigger */
.header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style {
  background: #BAF402;
}

.header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:before, .header-transparent .topbar-toggle-trigger:hover .topbar-toggle-trigger-style:after {
  background: #BAF402;
}

/* Topbar Toggle Trigger Icon */
.header-transparent .topbar-toggle-trigger-icon-style:hover {
  color: #BAF402;
}

/* Shopping Cart */
.header-transparent .shopping-cart .shopping-cart-icon:hover {
  color: #BAF402;
}

.header-transparent .shopping-cart .shopping-cart-amount {
  background: #BAF402;
}

.header-transparent .shopping-cart .shopping-cart-menu {
  border-top-color: #BAF402;
}

.header-transparent .shopping-cart .shopping-cart-menu-product-name:hover {
  color: #BAF402;
}

.header-transparent .shopping-cart .shopping-cart-close:hover {
  color: #BAF402;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-price {
  color: #BAF402;
}

.header-transparent .shopping-cart .shopping-cart-subtotal-view-link:hover {
  color: #BAF402;
}

/* Navbar Nav */
.header-transparent .navbar-nav .nav-item-child.active {
  color: #BAF402;
}

.header-transparent .navbar-nav .nav-item-child.active:hover {
  color: #BAF402;
}

.header-transparent .navbar-nav .nav-item-child:hover, .header-transparent .navbar-nav .nav-item-child:focus {
  color: #BAF402;
}

/* Dropdown Menu */
.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active {
  color: #BAF402;
}

.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child.active:hover {
  color: #BAF402;
}

.header-transparent .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child:hover {
  background: #BAF402;
}

.header-transparent .dropdown-menu .dropdown-menu-item.active .dropdown-menu-item-child {
  color: #BAF402;
}

/* Dropdown Submenu */
.header-transparent .dropdown-submenu:hover .dropdown-submenu-child {
  color: #BAF402;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child.active {
  color: #BAF402;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child.active:hover {
  color: #BAF402;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child.active:hover:after {
  color: #BAF402;
}

.header-transparent .dropdown-submenu .dropdown-submenu-child:hover {
  color: #fff;
  background: #BAF402;
}

.header-transparent .dropdown-submenu.active .dropdown-submenu-child {
  color: #BAF402;
}

.header-transparent .dropdown-submenu.active .dropdown-submenu-child:hover {
  color: #BAF402;
}

/* Mega Menu */
.header-transparent .mega-menu-list .mega-menu-child.active {
  color: #BAF402;
}

.header-transparent .mega-menu-list .mega-menu-child.active:hover {
  color: #BAF402;
}

.header-transparent .mega-menu-list .mega-menu-child:hover {
  background: #BAF402;
}

/* Media Queries below 991px */
@media (max-width: 991px) {
  /* Navbar Nav */
  .header-transparent .navbar-nav .nav-item-bg {
    border-color: #BAF402;
  }
  .header-transparent .navbar-nav .nav-item-bg .nav-item-child {
    background: #BAF402;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Header Shrink */
  .header-shrink.header-transparent .shopping-cart .shopping-cart-icon:hover {
    color: #BAF402;
  }
  /* Navbar */
  .header-transparent .navbar-nav .nav-item .dropdown-menu {
    border-top-color: #BAF402;
  }
  .header-transparent .navbar-nav .nav-item:hover .nav-item-child {
    color: #BAF402;
  }
  .header-transparent .navbar-nav .nav-item-child.active {
    color: #BAF402;
  }
  .header-transparent .navbar-nav .nav-item-bg {
    border-color: #BAF402;
  }
  .header-transparent .navbar-nav .nav-item-bg .nav-item-child {
    background: #BAF402;
  }
  .header-transparent .navbar-nav .nav-item-bg .nav-item-child:hover {
    color: #fff;
    background: #BAF402;
  }
  .header-transparent .navbar-nav .nav-item-bg:hover .nav-item-child {
    color: #fff;
    background: #BAF402;
  }
}

/* Navbar Toggle */
.header-vertical .navbar-toggle:hover .toggle-icon {
  background: #BAF402;
}

.header-vertical .navbar-toggle:hover .toggle-icon:before, .header-vertical .navbar-toggle:hover .toggle-icon:after {
  background: #BAF402;
}

/* Navbar Nav */
.header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown.active, .header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown:hover, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover {
  color: #BAF402;
}

.header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown.active:before, .header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown.active:after, .header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:before, .header-vertical .header-vertical-menu .nav-main-item-child.nav-main-item-child-dropdown:hover:after, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:before, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown.active:after, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:before, .header-vertical .header-vertical-menu .nav-main-item-child.nav-submenu-item-child-dropdown:hover:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown.active:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-main-item-child-dropdown:hover:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown.active:after,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:before,
.header-vertical .header-vertical-menu .nav-submenu-item-child.nav-submenu-item-child-dropdown:hover:after {
  color: #BAF402;
}

.header-vertical .header-vertical-menu .nav-dropdown-menu .nav-dropdown-menu-link:hover {
  color: #BAF402;
}

.header-vertical .header-vertical-menu .nav-dropdown-menu .nav-dropdown-menu-child.active {
  color: #BAF402;
}

.header-vertical .header-vertical-menu .nav-dropdown-menu .nav-dropdown-menu-child:hover {
  color: #BAF402;
}

/* Search */
.search .search-btn .search-btn-default:hover,
.search .search-btn .search-btn-active:hover {
  color: #BAF402;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  /* Search */
  .search.search-white .search-btn .search-btn-default:hover,
  .search.search-white .search-btn .search-btn-active:hover {
    color: #BAF402;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Search */
  .search.search-white .search-btn .search-btn-default:hover,
  .search.search-white .search-btn .search-btn-active:hover {
    color: #BAF402;
  }
  /* Header Shrink */
  .header-shrink .search.search-white .search-btn .search-btn-default:hover,
  .header-shrink .search.search-white .search-btn .search-btn-active:hover {
    color: #BAF402;
  }
}

/* Search Classic */
.search-classic .search-classic-btn .search-classic-btn-default:hover,
.search-classic .search-classic-btn .search-classic-btn-active:hover {
  color: #BAF402;
}

.search-classic .search-classic-field {
  border-top-color: #BAF402;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  /* Search Classic */
  .search-classic.search-classic-white .search-classic-btn .search-classic-btn-default:hover,
  .search-classic.search-classic-white .search-classic-btn .search-classic-btn-active:hover {
    color: #BAF402;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Search Classic */
  .search-classic.search-classic-white .search-classic-btn .search-classic-btn-default:hover,
  .search-classic.search-classic-white .search-classic-btn .search-classic-btn-active:hover {
    color: #BAF402;
  }
  /* Header Shrink */
  .header-shrink .search-classic.search-classic-white .search-classic-btn .search-classic-btn-default:hover,
  .header-shrink .search-classic.search-classic-white .search-classic-btn .search-classic-btn-active:hover {
    color: #BAF402;
  }
}

/* Search Fullscreen */
.search-fullscreen .search-fullscreen-trigger-icon:hover {
  color: #BAF402;
}

.search-fullscreen .search-fullscreen-input-group .search-fullscreen-search:hover .search-fullscreen-search-icon {
  color: #BAF402;
}

.search-fullscreen .search-fullscreen-bg-overlay .search-fullscreen-close:hover {
  color: #BAF402;
}

/* Media Queries below 992px */
@media (min-width: 992px) {
  /* Search Fullscreen */
  .search-fullscreen.search-fullscreen-trigger-white .search-fullscreen-trigger-icon:hover {
    color: #BAF402;
  }
  /* Header Shrink */
  .header-shrink .search-fullscreen.search-fullscreen-trigger-white .search-fullscreen-trigger-icon:hover {
    color: #BAF402;
  }
}

/* Search On Header */
.search-on-header .search-on-header-btn .search-on-header-btn-default:hover,
.search-on-header .search-on-header-btn .search-on-header-btn-active:hover {
  color: #BAF402;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  /* Search On Header */
  .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-default:hover,
  .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-active:hover {
    color: #BAF402;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Search On Header */
  .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-default:hover,
  .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-active:hover {
    color: #BAF402;
  }
  /* Header Shrink */
  .header-shrink .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-default:hover,
  .header-shrink .search-on-header.search-on-header-white .search-on-header-btn .search-on-header-btn-active:hover {
    color: #BAF402;
  }
}

/* Search Push */
.search-push .search-push-btn .search-push-btn-default:hover,
.search-push .search-push-btn .search-push-btn-active:hover {
  color: #BAF402;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  /* Search Push */
  .search-push.search-push-white .search-push-btn .search-push-btn-default:hover,
  .search-push.search-push-white .search-push-btn .search-push-btn-active:hover {
    color: #BAF402;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  /* Search Push */
  .search-push.search-push-white .search-push-btn .search-push-btn-default:hover,
  .search-push.search-push-white .search-push-btn .search-push-btn-active:hover {
    color: #BAF402;
  }
  /* Header Shrink */
  .header-shrink .search-push.search-push-white .search-push-btn .search-push-btn-default:hover,
  .header-shrink .search-push.search-push-white .search-push-btn .search-push-btn-active:hover {
    color: #BAF402;
  }
}

/* Sidebar Trigger */
.sidebar-trigger:hover .sidebar-trigger-icon {
  background: #BAF402;
}

.sidebar-trigger:hover .sidebar-trigger-icon:before, .sidebar-trigger:hover .sidebar-trigger-icon:after {
  background: #BAF402;
}

/* Sidebar Nav */
.sidebar-nav .sidebar-nav-trigger:hover .sidebar-trigger-icon:before, .sidebar-nav .sidebar-nav-trigger:hover .sidebar-trigger-icon:after {
  background: #BAF402;
}

.sidebar-nav .sidebar-nav-about-link:hover {
  color: #BAF402;
}

/* Media Queries below 992px */
@media (min-width: 992px) {
  /* Sidebar Trigger */
  .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon {
    background: #BAF402;
  }
  .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:before, .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:after {
    background: #BAF402;
  }
  /* Header Shrink */
  .header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon {
    background: #BAF402;
  }
  .header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:before, .header-shrink .sidebar-trigger.sidebar-trigger-style-white:hover .sidebar-trigger-icon:after {
    background: #BAF402;
  }
}

/* Topbar Contact */
.topbar-c .topbar-c-contacts .topbar-c-contacts-link:hover {
  color: #BAF402;
}

/* Topbar v1 Actions */
.topbar-c .topbar-c-actions .topbar-c-actions-link:hover {
  color: #BAF402;
}

.topbar-c .topbar-c-actions .topbar-c-actions-link-border:hover {
  background: #BAF402;
  border-color: #BAF402;
}

/* Topbar Ecommerce */
.topbar-e .topbar-e-list .topbar-e-list-item .topbar-e-list-dropdown:hover {
  color: #BAF402;
}

/* Topbar v2 Actions */
.topbar-e .topbar-e-actions .topbar-e-actions-link:hover {
  color: #BAF402;
}

/* Topbar v2 Shopping Cart */
.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-link:hover {
  color: #BAF402;
}

.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-menu-product-name:hover {
  color: #BAF402;
}

.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-close:hover {
  color: #BAF402;
}

.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-subtotal-price {
  color: #BAF402;
}

.topbar-e .topbar-e-sh-cart .topbar-e-sh-cart-subtotal-view-link:hover {
  color: #BAF402;
}

/* Topbar v3 List */
.topbar-t .topbar-t-list .topbar-t-list-item .topbar-t-list-dropdown:hover {
  color: #BAF402;
}

.topbar-t .topbar-t-list .topbar-t-list-dropdown-menu .topbar-t-list-dropdown-menu-link:hover {
  background: #BAF402;
}

/* Topbar v3 Shopping Cart */
.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-link:hover {
  color: #BAF402;
}

.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-menu-product-name:hover {
  color: #BAF402;
}

.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-close:hover {
  color: #BAF402;
}

.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-subtotal-price {
  color: #BAF402;
}

.topbar-t .topbar-t-sh-cart .topbar-t-sh-cart-subtotal-view-link:hover {
  color: #BAF402;
}

/* Contact Us */
.contact-us .contact-us-timeline-day {
  background: #BAF402;
}

/* Faq */
.faq .faq-search .faq-search-search:hover .faq-search-search-icon {
  color: #BAF402;
}

/* Faq v2 */
.faq-v2 .faq-v2-community .faq-v2-community-icon {
  color: #BAF402;
}

/* Animsition Loading */
.animsition-loading {
  border-left-color: #BAF402;
}

/* Back To Top */
.back-to-top-theme {
  background: #BAF402;
}

.back-to-top-theme:hover {
  background: #BAF402;
}

/* Owl Dots */
.owl-dot span,
.owl-dots span {
  border-color: #BAF402;
}

.owl-dot.active span,
.owl-dots.active span {
  background: #BAF402;
}

/* Testimonials v4 Arrows */
.testimonials-v4-arrows .arrow-next:hover,
.testimonials-v4-arrows .arrow-prev:hover {
  background: #baf402;
}

/* Owl Control Arrows v1 */
.owl-control-arrows-v1 .owl-arrow:hover {
  background: #BAF402;
}

/* Theme Portfolio Filter */
.theme-portfolio .cbp-filter-item.cbp-filter-item-active {
  color: #BAF402;
  border-color: #BAF402;
}

.theme-portfolio .cbp-filter-item:hover {
  color: #BAF402;
}

.theme-portfolio .cbp-filter-counter {
  background: #BAF402;
}

.theme-portfolio .cbp-filter-counter:after {
  border-top-color: #BAF402;
}

/* Theme Portfolio Load More Button */
.theme-portfolio .cbp-l-loadMore-button .cbp-l-loadMore-link,
.theme-portfolio .cbp-l-loadMore-button .cbp-l-loadMore-button-link,
.theme-portfolio .cbp-l-loadMore-text .cbp-l-loadMore-link,
.theme-portfolio .cbp-l-loadMore-text .cbp-l-loadMore-button-link {
  color: #BAF402;
  border-color: #BAF402;
}

.theme-portfolio .cbp-l-loadMore-button .cbp-l-loadMore-link:hover,
.theme-portfolio .cbp-l-loadMore-button .cbp-l-loadMore-button-link:hover,
.theme-portfolio .cbp-l-loadMore-text .cbp-l-loadMore-link:hover,
.theme-portfolio .cbp-l-loadMore-text .cbp-l-loadMore-button-link:hover {
  background: #BAF402;
}

/* Theme Portfolio Zoom Effect */
.theme-portfolio .cbp-caption-zoom .cbp-caption-activeWrap {
  background: rgba(186, 244, 2, 0.6);
}

/* Theme Portfolio Item v2 */
.theme-portfolio .theme-portfolio-item-v2:hover .theme-portfolio-active-wrap:before {
  background: rgba(186, 244, 2, 0.5);
}

/* Theme Portfolio Item v3 */
.theme-portfolio .theme-portfolio-item-v3 .theme-portfolio-lightbox .theme-portfolio-item-v3-icon:hover:before, .theme-portfolio .theme-portfolio-item-v3 .theme-portfolio-lightbox .theme-portfolio-item-v3-icon:hover:after {
  background: #BAF402;
}

/* Theme Portfolio Description */
.theme-portfolio-desc .portfolio-item-category:hover {
  color: #BAF402;
}

/* Theme Carousel Control v2 */
.theme-carousel-control-v2 .carousel-control-arrows-v2:hover {
  background: #BAF402;
}

/* Theme Carousel Indicators v2 */
.theme-carousel-indicators-v2 li.active {
  background: #BAF402;
  border-color: #BAF402;
}

/* Theme Carousel Indicators v3 */
.theme-carousel-indicators-v3 li.active {
  color: #BAF402;
  border-bottom-color: #BAF402;
}

.theme-carousel-indicators-v3 li:hover {
  border-bottom-color: #BAF402;
}

.theme-carousel-indicators-v3 li:hover.active {
  color: #BAF402;
  border-bottom-color: #BAF402;
}

/* Theme Carousel Indicators v3 */
.theme-carousel-indicators-v4 li.active {
  background: #BAF402;
  border-color: #BAF402;
}

/* Theme Carousel Indicators v5 */
.theme-carousel-indicators-v5 li.active .theme-carousel-indicators-item {
  border-color: #BAF402;
}

/* Revolution Slider */
/* Spinner */
.tp-loader.spinner2 {
  background: #BAF402;
}

/* Serviecs v6 */
.services-v6:hover:before {
  background: rgba(186, 244, 2, 0.8);
}

/* Serviecs v7 */
.services-v7 .services-v7-link-icon:hover {
  background: #BAF402;
  border-color: #BAF402;
}

/* Serviecs v8 */
.services-v8:hover {
  background: #BAF402;
}

/* Checkbox */
.checkbox input[type="checkbox"]:checked + label:before,
.checkbox input[type="radio"]:checked + label:before {
  background: #BAF402;
  border-color: #BAF402;
}

/* Checkbox */
.work-v1 .work-v1-badge {
  background: #BAF402;
}

.work-v1 .work-v1-list .work-v1-category .work-v1-category-link:hover {
  color: #BAF402;
}

/* Promo Block v12 */
.promo-block-v12 .promo-block-v12-form:focus {
  color: #BAF402;
}

.promo-block-v12 .promo-block-v12-form:focus::-moz-placeholder {
  color: #BAF402;
}

.promo-block-v12 .promo-block-v12-form:focus:-ms-input-placeholder {
  color: #BAF402;
}

.promo-block-v12 .promo-block-v12-form:focus::-webkit-input-placeholder {
  color: #BAF402;
}

/* Shopify Product */
.shopify-product:hover .shopify-product-name {
  color: #BAF402;
}

/* Language V1 */
.language-v1 .language-v1-toggle:hover {
  color: #BAF402;
}

.language-v1 .language-v1-dropdown-link:hover {
  background: #BAF402;
}

/* Language V1 White */
/* Media Queries above 992px */
@media (min-width: 992px) {
  .language-v1.language-v1-style-white .language-v1-toggle:hover {
    color: #BAF402;
  }
  /* Header Shrink */
  .header-shrink .language-v1.language-v1-style-white .language-v1-toggle:hover {
    color: #BAF402;
  }
}

/* Language Push */
.language-push .language-push-btn-icon:hover {
  color: #BAF402;
}

/* Language Push White */
/* Media Queries above 992px */
@media (min-width: 992px) {
  .language-push.language-push-style-white .language-push-btn-icon:hover {
    color: #BAF402;
  }
  /* Header Shrink */
  .header-shrink .language-push.language-push-style-white .language-push-btn-icon:hover {
    color: #BAF402;
  }
}

/* Newsletter v6 */
.newsletter-v6 .newsletter-v6-btn:hover {
  color: #BAF402;
}

/* News v11 */
.news-v11 .news-v11-subtitle {
  color: #BAF402;
}

/* Handle */
.image-comparison-container .image-comparison-handle {
  background: #BAF402;
}

/* Testimonials v2 Arrows */
.testimonials-v2-arrows .arrow-next:hover,
.testimonials-v2-arrows .arrow-prev:hover {
  background: #baf402;
}

/* Background Icon */
.fuelux .radio-checkbox-bg-icon.checked:before {
  background: #BAF402;
  border-color: #BAF402;
}

/* Background Color */
.fuelux .radio-checkbox-bg-color.checked:before {
  background: #BAF402;
  border-color: #BAF402;
}

/* Border */
.fuelux .radio-checkbox-brd.checked:before {
  border-color: #BAF402;
}

/* Border with Dot */
.fuelux .radio-checkbox-brd-dot.checked:before {
  border-color: #BAF402;
}

.fuelux .radio-checkbox-brd-dot.checked:after {
  background: #BAF402;
}

.segment-controls {
  border-color: #BAF402;
}

/* Label */
.segment-controls .segment-controls-label:before {
  background: #BAF402;
}

/* Color Green */
.color-base {
  color: #BAF402 !important;
}

/* Background Color Green */
.bg-color-base {
  background: #BAF402 !important;
}

/*------------------------------------------------------------------
    [Colors]
------------------------------------------------------------------*/
.color-base {
  color: #BAF402 !important;
}

.color-white {
  color: #fff !important;
}

.color-subtitle {
  color: #606060 !important;
}

.color-sky-light {
  color: #f7f8fa !important;
}

.color-tint-light {
  color: #ebeef6 !important;
}

.color-dark {
  color: #34343c !important;
}

.color-dark-light {
  color: #3a3a44 !important;
}

.color-black {
  color: #000 !important;
}

.color-blue {
  color: #536dfe !important;
}

.color-blue-grey {
  color: #546e7a !important;
}

.color-grey {
  color: #9e9e9e !important;
}

.color-teal {
  color: #009688 !important;
}

.color-green {
  color: #BAF402 !important;
}

.color-red {
  color: #e55973 !important;
}

.color-pink {
  color: #e91e63 !important;
}

.color-purple {
  color: #b260ce !important;
}

.color-gold {
  color: #c69f73 !important;
}

/* Social Colors */
.color-fb {
  color: #44619d !important;
}

.color-tw {
  color: #55acee !important;
}

.color-dr {
  color: #ea4c89 !important;
}

.color-yt {
  color: #cc181e !important;
}

.color-ig {
  color: #125688 !important;
}

.color-vm {
  color: #45bbff !important;
}

/* Light Options */
.color-base-light {
  color: #4ed7e8 !important;
}

.color-blue-grey-light {
  color: #6b8997 !important;
}

.color-grey-light {
  color: #f1f1f1 !important;
}

.color-blue-light {
  color: #687ffa !important;
}

.color-teal-light {
  color: #45b4a9 !important;
}

.color-green-light {
  color: #baf451 !important;
}

.color-red-light {
  color: #e57287 !important;
}

.color-pink-light {
  color: #ec407a !important;
}

.color-purple-light {
  color: #ba75d1 !important;
}

.color-gold-light {
  color: #d3af87 !important;
}

/* Boostrap Colors */
.color-bt-primary {
  color: #337ab7 !important;
}

.color-bt-info {
  color: #5bc0de !important;
}

.color-bt-warning {
  color: #5cb85c !important;
}

.color-bt-danger {
  color: #f0ad4e !important;
}

.color-bt-success {
  color: #d9534f !important;
}

.color-bt-default {
  color: #eee !important;
}

/*------------------------------------------------------------------
    [Background Colors]
------------------------------------------------------------------*/
.bg-color-success {
  background: #BAF402 !important;
}

.bg-color-error {
  background: #e55973 !important;
}

.bg-color-danger {
  background: #e55973 !important;
}

.bg-color-info {
  background: #536dfe !important;
}

.bg-color-base {
  background: #BAF402 !important;
}

.bg-color-white {
  background: #fff !important;
}

.bg-color-subtitle {
  background: #606060 !important;
}

.bg-color-sky-light {
  background: #f7f8fa !important;
}

.bg-color-tint-light {
  background: #ebeef6 !important;
}

.bg-color-dark {
  background: #34343c !important;
}

.bg-color-dark-light {
  background: #3a3a44 !important;
}

.bg-color-blue-grey {
  background: #546e7a !important;
}

.bg-color-black {
  background: #000 !important;
}

.bg-color-grey {
  background: #9e9e9e !important;
}

.bg-color-blue {
  background: #536dfe !important;
}

.bg-color-teal {
  background: #009688 !important;
}

.bg-color-green {
  background: #BAF402 !important;
}

.bg-color-red {
  background: #e55973 !important;
}

.bg-color-pink {
  background: #e91e63 !important;
}

.bg-color-purple {
  background: #b260ce !important;
}

.bg-color-gold {
  background: #c69f73 !important;
}

/* Social Colors */
.bg-color-fb {
  background: #44619d !important;
}

.bg-color-tw {
  background: #55acee !important;
}

.bg-color-dr {
  background: #ea4c89 !important;
}

.bg-color-yt {
  background: #cc181e !important;
}

.bg-color-ig {
  background: #125688 !important;
}

.bg-color-vm {
  background: #45bbff !important;
}

/* Light Options */
.bg-color-base-light {
  background: #4ed7e8 !important;
}

.bg-color-blue-grey-light {
  background: #6b8997 !important;
}

.bg-color-grey-light {
  background: #f1f1f1 !important;
}

.bg-color-blue-light {
  background: #687ffa !important;
}

.bg-color-teal-light {
  background: #45b4a9 !important;
}

.bg-color-green-light {
  background: #baf451 !important;
}

.bg-color-red-light {
  background: #e57287 !important;
}

.bg-color-pink-light {
  background: #ec407a !important;
}

.bg-color-purple-light {
  background: #ba75d1 !important;
}

.bg-color-gold-light {
  background: #d3af87 !important;
}

/* Boostrap Colors */
.bg-color-bt-primary {
  background: #337ab7 !important;
}

.bg-color-bt-info {
  background: #5bc0de !important;
}

.bg-color-bt-warning {
  background: #5cb85c !important;
}

.bg-color-bt-danger {
  background: #f0ad4e !important;
}

.bg-color-bt-success {
  background: #d9534f !important;
}

.bg-color-bt-default {
  background: #eee !important;
}

/*------------------------------------------------------------------
    [Border Colors]
------------------------------------------------------------------*/
.brd-color-base {
  border-color: #BAF402 !important;
}

.brd-color-white {
  border-color: #fff !important;
}

.brd-color-subtitle {
  border-color: #606060 !important;
}

.brd-color-sky-light {
  border-color: #f7f8fa !important;
}

.brd-color-tint-light {
  border-color: #ebeef6 !important;
}

.brd-color-dark {
  border-color: #34343c !important;
}

.brd-color-dark-light {
  border-color: #3a3a44 !important;
}

.brd-color-blue-grey {
  border-color: #546e7a !important;
}

.brd-color-grey {
  border-color: #9e9e9e !important;
}

.brd-color-blue {
  border-color: #536dfe !important;
}

.brd-color-teal {
  border-color: #009688 !important;
}

.brd-color-green {
  border-color: #BAF402 !important;
}

.brd-color-red {
  border-color: #e55973 !important;
}

.brd-color-pink {
  border-color: #e91e63 !important;
}

.brd-color-purple {
  border-color: #b260ce !important;
}

.brd-color-gold {
  border-color: #c69f73 !important;
}

/* Social Colors */
.brd-color-fb {
  border-color: #44619d !important;
}

.brd-color-tw {
  border-color: #55acee !important;
}

.brd-color-dr {
  border-color: #ea4c89 !important;
}

.brd-color-yt {
  border-color: #cc181e !important;
}

.brd-color-ig {
  border-color: #125688 !important;
}

/* Light Options */
.brd-color-base-light {
  border-color: #4ed7e8 !important;
}

.brd-color-blue-grey-light {
  border-color: #6b8997 !important;
}

.brd-color-grey-light {
  border-color: #f1f1f1 !important;
}

.brd-color-blue-light {
  border-color: #687ffa !important;
}

.brd-color-teal-light {
  border-color: #45b4a9 !important;
}

.brd-color-green-light {
  border-color: #baf451 !important;
}

.brd-color-red-light {
  border-color: #e57287 !important;
}

.brd-color-pink-light {
  border-color: #ec407a !important;
}

.brd-color-purple-light {
  border-color: #ba75d1 !important;
}

.brd-color-gold-light {
  border-color: #d3af87 !important;
}

/* Boostrap Colors */
.brd-color-primary {
  border-color: #337ab7 !important;
}

.brd-color-info {
  border-color: #5bc0de !important;
}

.brd-color-warning {
  border-color: #5cb85c !important;
}

.brd-color-danger {
  border-color: #f0ad4e !important;
}

.brd-color-success {
  border-color: #d9534f !important;
}

.brd-color-default {
  border-color: #eee !important;
}

/*------------------------------------------------------------------
    [Background Colors Hover]
------------------------------------------------------------------*/
.bg-color-base-hover:hover {
  background: #BAF402 !important;
}

.bg-color-white-hover:hover {
  background: #fff !important;
}

.bg-color-subtitle-hover:hover {
  background: #606060 !important;
}

.bg-color-sky-light-hover:hover {
  background: #f7f8fa !important;
}

.bg-color-tint-light-hover:hover {
  background: #ebeef6 !important;
}

.bg-color-dark-hover:hover {
  background: #34343c !important;
}

.bg-color-dark-light-hover:hover {
  background: #3a3a44 !important;
}

.bg-color-blue-grey-hover:hover {
  background: #546e7a !important;
}

.bg-color-grey-hover:hover {
  background: #9e9e9e !important;
}

.bg-color-blue-hover:hover {
  background: #536dfe !important;
}

.bg-color-teal-hover:hover {
  background: #009688 !important;
}

.bg-color-green-hover:hover {
  background: #BAF402 !important;
}

.bg-color-red-hover:hover {
  background: #e55973 !important;
}

.bg-color-pink-hover:hover {
  background: #e91e63 !important;
}

.bg-color-purple-hover:hover {
  background: #b260ce !important;
}

.bg-color-gold-hover:hover {
  background: #c69f73 !important;
}

.bg-color-fb-hover:hover {
  background: #44619d !important;
}

.bg-color-tw-hover:hover {
  background: #55acee !important;
}

.bg-color-dr-hover:hover {
  background: #ea4c89 !important;
}

.bg-color-yt-hover:hover {
  background: #cc181e !important;
}

.bg-color-ig-hover:hover {
  background: #125688 !important;
}

.bg-color-base-light-hover:hover {
  background: #4ed7e8 !important;
}

.bg-color-blue-grey-light-hover:hover {
  background: #6b8997 !important;
}

.bg-color-grey-light-hover:hover {
  background: #f1f1f1 !important;
}

.bg-color-blue-light-hover:hover {
  background: #687ffa !important;
}

.bg-color-teal-light-hover:hover {
  background: #45b4a9 !important;
}

.bg-color-green-light-hover:hover {
  background: #baf451 !important;
}

.bg-color-red-light-hover:hover {
  background: #e57287 !important;
}

.bg-color-pink-light-hover:hover {
  background: #ec407a !important;
}

.bg-color-purple-light-hover:hover {
  background: #ba75d1 !important;
}

.bg-color-gold-light-hover:hover {
  background: #d3af87 !important;
}

.bg-color-primary-hover:hover {
  background: #337ab7 !important;
}

.bg-color-info-hover:hover {
  background: #5bc0de !important;
}

.bg-color-warning-hover:hover {
  background: #5cb85c !important;
}

.bg-color-danger-hover:hover {
  background: #f0ad4e !important;
}

.bg-color-success-hover:hover {
  background: #d9534f !important;
}

.bg-color-default-hover:hover {
  background: #eee !important;
}

/*------------------------------------------------------------------
    [Animate Transition Icons Colors On Hover]
------------------------------------------------------------------*/
.icon-color-base-hover:hover .animate-icon-item:last-child {
  color: #BAF402 !important;
}

.icon-color-white-hover:hover .animate-icon-item:last-child {
  color: #fff !important;
}

.icon-color-subtitle-hover:hover .animate-icon-item:last-child {
  color: #606060 !important;
}

.icon-color-sky-light-hover:hover .animate-icon-item:last-child {
  color: #f7f8fa !important;
}

.icon-color-tint-light-hover:hover .animate-icon-item:last-child {
  color: #ebeef6 !important;
}

.icon-color-dark-hover:hover .animate-icon-item:last-child {
  color: #34343c !important;
}

.icon-color-dark-light-hover:hover .animate-icon-item:last-child {
  color: #3a3a44 !important;
}

.icon-color-blue-hover:hover .animate-icon-item:last-child {
  color: #536dfe !important;
}

.icon-color-blue-grey-hover:hover .animate-icon-item:last-child {
  color: #546e7a !important;
}

.icon-color-grey-hover:hover .animate-icon-item:last-child {
  color: #9e9e9e !important;
}

.icon-color-teal-hover:hover .animate-icon-item:last-child {
  color: #009688 !important;
}

.icon-color-green-hover:hover .animate-icon-item:last-child {
  color: #BAF402 !important;
}

.icon-color-red-hover:hover .animate-icon-item:last-child {
  color: #e55973 !important;
}

.icon-color-pink-hover:hover .animate-icon-item:last-child {
  color: #e91e63 !important;
}

.icon-color-purple-hover:hover .animate-icon-item:last-child {
  color: #b260ce !important;
}

.icon-color-gold-hover:hover .animate-icon-item:last-child {
  color: #c69f73 !important;
}

.icon-color-fb-hover:hover .animate-icon-item:last-child {
  color: #44619d !important;
}

.icon-color-tw-hover:hover .animate-icon-item:last-child {
  color: #55acee !important;
}

.icon-color-dr-hover:hover .animate-icon-item:last-child {
  color: #ea4c89 !important;
}

.icon-color-yt-hover:hover .animate-icon-item:last-child {
  color: #cc181e !important;
}

.icon-color-ig-hover:hover .animate-icon-item:last-child {
  color: #125688 !important;
}

.icon-color-base-light-hover:hover .animate-icon-item:last-child {
  color: #4ed7e8 !important;
}

.icon-color-blue-grey-light-hover:hover .animate-icon-item:last-child {
  color: #6b8997 !important;
}

.icon-color-grey-light-hover:hover .animate-icon-item:last-child {
  color: #f1f1f1 !important;
}

.icon-color-blue-light-hover:hover .animate-icon-item:last-child {
  color: #687ffa !important;
}

.icon-color-teal-light-hover:hover .animate-icon-item:last-child {
  color: #45b4a9 !important;
}

.icon-color-green-light-hover:hover .animate-icon-item:last-child {
  color: #baf451 !important;
}

.icon-color-red-light-hover:hover .animate-icon-item:last-child {
  color: #e57287 !important;
}

.icon-color-pink-light-hover:hover .animate-icon-item:last-child {
  color: #ec407a !important;
}

.icon-color-purple-light-hover:hover .animate-icon-item:last-child {
  color: #ba75d1 !important;
}

.icon-color-gold-light-hover:hover .animate-icon-item:last-child {
  color: #d3af87 !important;
}

/*------------------------------------------------------------------
    [Pseudo :before Background Colors]
—----------------------------------------------------------------*/
.before-bg-base:before {
  background: #BAF402 !important;
}

.before-bg-subtitle:before {
  background: #606060 !important;
}

.before-bg-sky-light:before {
  background: #f7f8fa !important;
}

.before-bg-tint-light:before {
  background: #ebeef6 !important;
}

.before-bg-white:before {
  background: #fff !important;
}

.before-bg-dark:before {
  background: #34343c !important;
}

.before-bg-blue:before {
  background: #536dfe !important;
}

.before-bg-grey:before {
  background: #9e9e9e !important;
}

.before-bg-teal:before {
  background: #009688 !important;
}

.before-bg-green:before {
  background: #BAF402 !important;
}

.before-bg-red:before {
  background: #e55973 !important;
}

.before-bg-pink:before {
  background: #e91e63 !important;
}

.before-bg-purple:before {
  background: #b260ce !important;
}

.before-bg-gold:before {
  background: #c69f73 !important;
}

.before-bg-base-light:before {
  background: #4ed7e8 !important;
}

.before-bg-dark-light:before {
  background: #3a3a44 !important;
}

.before-bg-blue-grey-light:before {
  background: #6b8997 !important;
}

.before-bg-grey-light:before {
  background: #f1f1f1 !important;
}

.before-bg-blue-light:before {
  background: #687ffa !important;
}

.before-bg-teal-light:before {
  background: #45b4a9 !important;
}

.before-bg-green-light:before {
  background: #baf451 !important;
}

.before-bg-red-light:before {
  background: #e57287 !important;
}

.before-bg-pink-light:before {
  background: #ec407a !important;
}

.before-bg-purple-light:before {
  background: #ba75d1 !important;
}

.before-bg-gold-light:before {
  background: #d3af87 !important;
}

.before-bg-fb:before {
  background: #44619d !important;
}

.before-bg-tw:before {
  background: #55acee !important;
}

.before-bg-dr:before {
  background: #ea4c89 !important;
}

.before-bg-yt:before {
  background: #cc181e !important;
}

.before-bg-ig:before {
  background: #125688 !important;
}

.before-bg-primary:before {
  background: #337ab7 !important;
}

.before-bg-info:before {
  background: #5bc0de !important;
}

.before-bg-warning:before {
  background: #5cb85c !important;
}

.before-bg-danger:before {
  background: #f0ad4e !important;
}

.before-bg-success:before {
  background: #d9534f !important;
}

.before-bg-default:before {
  background: #eee !important;
}

/*------------------------------------------------------------------
    [Pseudo :after Background Colors]
—----------------------------------------------------------------*/
.after-bg-base:after {
  background: #BAF402 !important;
}

.after-bg-subtitle:after {
  background: #606060 !important;
}

.after-bg-sky-light:after {
  background: #f7f8fa !important;
}

.after-bg-tint-light:after {
  background: #ebeef6 !important;
}

.after-bg-white:after {
  background: #fff !important;
}

.after-bg-dark:after {
  background: #34343c !important;
}

.after-bg-blue:after {
  background: #536dfe !important;
}

.after-bg-blue-grey:after {
  background: #546e7a !important;
}

.after-bg-grey:after {
  background: #9e9e9e !important;
}

.after-bg-teal:after {
  background: #009688 !important;
}

.after-bg-green:after {
  background: #BAF402 !important;
}

.after-bg-red:after {
  background: #e55973 !important;
}

.after-bg-pink:after {
  background: #e91e63 !important;
}

.after-bg-purple:after {
  background: #b260ce !important;
}

.after-bg-gold:after {
  background: #c69f73 !important;
}

.after-bg-base-light:after {
  background: #4ed7e8 !important;
}

.after-bg-dark-light:after {
  background: #3a3a44 !important;
}

.after-bg-blue-grey-light:after {
  background: #6b8997 !important;
}

.after-bg-grey-light:after {
  background: #f1f1f1 !important;
}

.after-bg-blue-light:after {
  background: #687ffa !important;
}

.after-bg-teal-light:after {
  background: #45b4a9 !important;
}

.after-bg-green-light:after {
  background: #baf451 !important;
}

.after-bg-red-light:after {
  background: #e57287 !important;
}

.after-bg-pink-light:after {
  background: #ec407a !important;
}

.after-bg-purple-light:after {
  background: #ba75d1 !important;
}

.after-bg-gold-light:after {
  background: #d3af87 !important;
}

.after-bg-fb:after {
  background: #44619d !important;
}

.after-bg-tw:after {
  background: #55acee !important;
}

.after-bg-dr:after {
  background: #ea4c89 !important;
}

.after-bg-yt:after {
  background: #cc181e !important;
}

.after-bg-ig:after {
  background: #125688 !important;
}

.after-bg-primary:after {
  background: #337ab7 !important;
}

.after-bg-info:after {
  background: #5bc0de !important;
}

.after-bg-warning:after {
  background: #5cb85c !important;
}

.after-bg-danger:after {
  background: #f0ad4e !important;
}

.after-bg-success:after {
  background: #d9534f !important;
}

.after-bg-default:after {
  background: #eee !important;
}

/*------------------------------------------------------------------
    [Pseudo :before, :after Background Colors]
—----------------------------------------------------------------*/
.before-after-bg-base:before, .before-after-bg-base:after {
  background: #BAF402 !important;
}

.before-after-bg-subtitle:before, .before-after-bg-subtitle:after {
  background: #606060 !important;
}

.before-after-bg-sky-light:before, .before-after-bg-sky-light:after {
  background: #f7f8fa !important;
}

.before-after-bg-tint-light:before, .before-after-bg-tint-light:after {
  background: #ebeef6 !important;
}

.before-after-bg-white:before, .before-after-bg-white:after {
  background: #fff !important;
}

.before-after-bg-dark:before, .before-after-bg-dark:after {
  background: #34343c !important;
}

.before-after-bg-blue:before, .before-after-bg-blue:after {
  background: #536dfe !important;
}

.before-after-bg-blue-grey:before, .before-after-bg-blue-grey:after {
  background: #546e7a !important;
}

.before-after-bg-grey:before, .before-after-bg-grey:after {
  background: #9e9e9e !important;
}

.before-after-bg-teal:before, .before-after-bg-teal:after {
  background: #009688 !important;
}

.before-after-bg-green:before, .before-after-bg-green:after {
  background: #BAF402 !important;
}

.before-after-bg-red:before, .before-after-bg-red:after {
  background: #e55973 !important;
}

.before-after-bg-pink:before, .before-after-bg-pink:after {
  background: #e91e63 !important;
}

.before-after-bg-purple:before, .before-after-bg-purple:after {
  background: #b260ce !important;
}

.before-after-bg-gold:before, .before-after-bg-gold:after {
  background: #c69f73 !important;
}

.before-after-bg-base-light:before, .before-after-bg-base-light:after {
  background: #4ed7e8 !important;
}

.before-after-bg-dark-light:before, .before-after-bg-dark-light:after {
  background: #3a3a44 !important;
}

.before-after-bg-blue-grey-light:before, .before-after-bg-blue-grey-light:after {
  background: #6b8997 !important;
}

.before-after-bg-grey-light:before, .before-after-bg-grey-light:after {
  background: #f1f1f1 !important;
}

.before-after-bg-blue-light:before, .before-after-bg-blue-light:after {
  background: #687ffa !important;
}

.before-after-bg-teal-light:before, .before-after-bg-teal-light:after {
  background: #45b4a9 !important;
}

.before-after-bg-green-light:before, .before-after-bg-green-light:after {
  background: #baf451 !important;
}

.before-after-bg-red-light:before, .before-after-bg-red-light:after {
  background: #e57287 !important;
}

.before-after-bg-pink-light:before, .before-after-bg-pink-light:after {
  background: #ec407a !important;
}

.before-after-bg-purple-light:before, .before-after-bg-purple-light:after {
  background: #ba75d1 !important;
}

.before-after-bg-gold-light:before, .before-after-bg-gold-light:after {
  background: #d3af87 !important;
}

.before-after-bg-fb:before, .before-after-bg-fb:after {
  background: #44619d !important;
}

.before-after-bg-tw:before, .before-after-bg-tw:after {
  background: #55acee !important;
}

.before-after-bg-dr:before, .before-after-bg-dr:after {
  background: #ea4c89 !important;
}

.before-after-bg-yt:before, .before-after-bg-yt:after {
  background: #cc181e !important;
}

.before-after-bg-ig:before, .before-after-bg-ig:after {
  background: #125688 !important;
}

.before-after-bg-primary:before, .before-after-bg-primary:after {
  background: #337ab7 !important;
}

.before-after-bg-info:before, .before-after-bg-info:after {
  background: #5bc0de !important;
}

.before-after-bg-warning:before, .before-after-bg-warning:after {
  background: #5cb85c !important;
}

.before-after-bg-danger:before, .before-after-bg-danger:after {
  background: #f0ad4e !important;
}

.before-after-bg-success:before, .before-after-bg-success:after {
  background: #d9534f !important;
}

.before-after-bg-default:before, .before-after-bg-default:after {
  background: #eee !important;
}

/*--------------------------------------------------
    [Font Family]
----------------------------------------------------*/
.font-family-base {
  font-family: 'Roboto', sans-serif !important;
}

.font-family-droid {
  font-family: 'Droid Serif', serif !important;
}

.font-family-hind {
  font-family: 'Hind', sans-serif !important;
}

.font-family-lora {
  font-family: 'Lora', serif !important;
}

.font-family-open-sans {
  font-family: 'Open Sans', sans-serif !important;
}

.font-family-dancing {
  font-family: 'Dancing Script', cursive !important;
}

/*--------------------------------------------------
    [Wrapper]
----------------------------------------------------*/
/* Media Queries below 992px */
@media (min-width: 992px) {
  .wrapper.wrapper-top-space {
    margin-top: 90px;
  }
}

/*--------------------------------------------------
    [Boxed Layout]
----------------------------------------------------*/
.boxed-layout-overlay {
  background: #f7f8fa;
}

.boxed-layout-overlay-img {
  background: url(../../assets/img/500x500/17.jpg) repeat;
}

.boxed-layout {
  max-width: 1200px;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto !important;
  margin-right: auto !important;
  background: #fff;
}

.boxed-layout:before, .boxed-layout:after {
  content: " ";
  display: table;
}

.boxed-layout:after {
  clear: both;
}

/* Media Queries above 768px */
@media (min-width: 768px) {
  .boxed-layout {
    width: 750px;
  }
}

/* Media Queries above 992px */
@media (min-width: 992px) {
  .boxed-layout {
    width: 970px;
  }
}

/* Media Queries above 1200px */
@media (min-width: 1200px) {
  .boxed-layout {
    width: 1170px;
  }
}

/*--------------------------------------------------
    [Full Width Container]
----------------------------------------------------*/
.full-width-container {
  position: relative;
  padding: 0;
  margin: 0;
}

.full-width-container:before, .full-width-container:after {
  content: " ";
  display: table;
}

.full-width-container:after {
  clear: both;
}

/*--------------------------------------------------
    [Container Extra Small]
----------------------------------------------------*/
.container-xs {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-xs:before, .container-xs:after {
  content: " ";
  display: table;
}

.container-xs:after {
  clear: both;
}

/* Media Queries below 768px */
@media (min-width: 768px) {
  .container-xs {
    width: 750px;
  }
}

/*--------------------------------------------------
    [Container Small]
----------------------------------------------------*/
.container-sm {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-sm:before, .container-sm:after {
  content: " ";
  display: table;
}

.container-sm:after {
  clear: both;
}

/* Media Queries below 768px */
@media (min-width: 768px) {
  .container-sm {
    width: 750px;
  }
}

/* Media Queries below 1200px */
@media (min-width: 1200px) {
  .container-sm {
    width: 970px;
  }
}

/*--------------------------------------------------
    [Clearfix]
----------------------------------------------------*/
.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

/*--------------------------------------------------
    [Position]
----------------------------------------------------*/
.position-r {
  position: relative;
}

.position-a {
  position: absolute;
}

.position-s {
  position: static;
}

.position-i {
  position: inherit;
}

/*--------------------------------------------------
    [Overflow]
----------------------------------------------------*/
.overflow-h {
  overflow: hidden;
}

.overflow-a {
  overflow: auto;
}

.overflow-i {
  overflow: inherit;
}

/*--------------------------------------------------
    [Display]
----------------------------------------------------*/
.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-ib {
  display: inline-block;
}

.display-inherit {
  display: inherit;
}

.display-initial {
  display: initial;
}

/* Media Queries under 992px */
@media (max-width: 992px) {
  .md-display-none {
    display: none;
  }
  .md-display-block {
    display: block;
  }
  .md-display-inline {
    display: inline;
  }
  .md-display-ib {
    display: inline-block;
  }
  .md-display-inherit {
    display: inherit;
  }
  .md-display-initial {
    display: initial;
  }
}

/* Media Queries under 768px */
@media (max-width: 768px) {
  .sm-display-none {
    display: none;
  }
  .sm-display-block {
    display: block;
  }
  .sm-display-inline {
    display: inline;
  }
  .sm-display-ib {
    display: inline-block;
  }
  .sm-display-inherit {
    display: inherit;
  }
  .sm-display-initial {
    display: initial;
  }
}

/* Media Queries under 480px */
@media (max-width: 480px) {
  .xs-display-none {
    display: none;
  }
  .xs-display-block {
    display: block;
  }
  .xs-display-inline {
    display: inline;
  }
  .xs-display-ib {
    display: inline-block;
  }
  .xs-display-inherit {
    display: inherit;
  }
  .xs-display-initial {
    display: initial;
  }
}

/*--------------------------------------------------
    [Font Sizes]
----------------------------------------------------*/
.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-34 {
  font-size: 34px !important;
}

.font-size-36 {
  font-size: 36px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

.font-size-70 {
  font-size: 70px !important;
}

.font-size-100 {
  font-size: 100px !important;
}

/*------------------------------------------------------------------
    [Font Sizes under 992px]
------------------------------------------------------------------*/
@media (max-width: 992px) {
  .md-font-size-10 {
    font-size: 10px !important;
  }
  .md-font-size-11 {
    font-size: 11px !important;
  }
  .md-font-size-12 {
    font-size: 12px !important;
  }
  .md-font-size-13 {
    font-size: 13px !important;
  }
  .md-font-size-14 {
    font-size: 14px !important;
  }
  .md-font-size-15 {
    font-size: 15px !important;
  }
  .md-font-size-16 {
    font-size: 16px !important;
  }
  .md-font-size-18 {
    font-size: 18px !important;
  }
  .md-font-size-20 {
    font-size: 20px !important;
  }
  .md-font-size-22 {
    font-size: 22px !important;
  }
  .md-font-size-24 {
    font-size: 24px !important;
  }
  .md-font-size-26 {
    font-size: 26px !important;
  }
  .md-font-size-28 {
    font-size: 28px !important;
  }
  .md-font-size-30 {
    font-size: 30px !important;
  }
  .md-font-size-32 {
    font-size: 32px !important;
  }
  .md-font-size-34 {
    font-size: 34px !important;
  }
  .md-font-size-36 {
    font-size: 36px !important;
  }
  .md-font-size-50 {
    font-size: 50px !important;
  }
  .md-font-size-70 {
    font-size: 70px !important;
  }
  .md-font-size-100 {
    font-size: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Font Sizes under 768px]
------------------------------------------------------------------*/
@media (max-width: 768px) {
  .sm-font-size-10 {
    font-size: 10px !important;
  }
  .sm-font-size-11 {
    font-size: 11px !important;
  }
  .sm-font-size-12 {
    font-size: 12px !important;
  }
  .sm-font-size-13 {
    font-size: 13px !important;
  }
  .sm-font-size-14 {
    font-size: 14px !important;
  }
  .sm-font-size-15 {
    font-size: 15px !important;
  }
  .sm-font-size-16 {
    font-size: 16px !important;
  }
  .sm-font-size-18 {
    font-size: 18px !important;
  }
  .sm-font-size-20 {
    font-size: 20px !important;
  }
  .sm-font-size-22 {
    font-size: 22px !important;
  }
  .sm-font-size-24 {
    font-size: 24px !important;
  }
  .sm-font-size-26 {
    font-size: 26px !important;
  }
  .sm-font-size-28 {
    font-size: 28px !important;
  }
  .sm-font-size-30 {
    font-size: 30px !important;
  }
  .sm-font-size-32 {
    font-size: 32px !important;
  }
  .sm-font-size-34 {
    font-size: 34px !important;
  }
  .sm-font-size-36 {
    font-size: 36px !important;
  }
  .sm-font-size-50 {
    font-size: 50px !important;
  }
  .sm-font-size-70 {
    font-size: 70px !important;
  }
  .sm-font-size-100 {
    font-size: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Font Sizes under 480px]
------------------------------------------------------------------*/
@media (max-width: 480px) {
  .xs-font-size-10 {
    font-size: 10px !important;
  }
  .xs-font-size-11 {
    font-size: 11px !important;
  }
  .xs-font-size-12 {
    font-size: 12px !important;
  }
  .xs-font-size-13 {
    font-size: 13px !important;
  }
  .xs-font-size-14 {
    font-size: 14px !important;
  }
  .xs-font-size-15 {
    font-size: 15px !important;
  }
  .xs-font-size-16 {
    font-size: 16px !important;
  }
  .xs-font-size-18 {
    font-size: 18px !important;
  }
  .xs-font-size-20 {
    font-size: 20px !important;
  }
  .xs-font-size-22 {
    font-size: 22px !important;
  }
  .xs-font-size-24 {
    font-size: 24px !important;
  }
  .xs-font-size-26 {
    font-size: 26px !important;
  }
  .xs-font-size-28 {
    font-size: 28px !important;
  }
  .xs-font-size-30 {
    font-size: 30px !important;
  }
  .xs-font-size-32 {
    font-size: 32px !important;
  }
  .xs-font-size-34 {
    font-size: 34px !important;
  }
  .xs-font-size-36 {
    font-size: 36px !important;
  }
  .xs-font-size-50 {
    font-size: 50px !important;
  }
  .xs-font-size-70 {
    font-size: 70px !important;
  }
  .xs-font-size-100 {
    font-size: 100px !important;
  }
}

/*--------------------------------------------------
    [Fancy Font Styles]
----------------------------------------------------*/
.line-through {
  text-decoration: line-through !important;
}

.underline {
  text-decoration: underline !important;
}

/*--------------------------------------------------
    [Text Decoration]
----------------------------------------------------*/
/* None */
.text-decoration-none {
  text-decoration: none;
}

.text-decoration-none:hover, .text-decoration-none:focus {
  text-decoration: none;
}

/* Inherit */
.text-decoration-inherit {
  text-decoration: underline;
}

.text-decoration-inherit:hover, .text-decoration-inherit:focus {
  text-decoration: underline;
}

/*--------------------------------------------------
    [Letter Spacing]
----------------------------------------------------*/
.letter-spacing-0 {
  letter-spacing: 0;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

.letter-spacing-4 {
  letter-spacing: 4px;
}

.letter-spacing-5 {
  letter-spacing: 5px;
}

/*--------------------------------------------------
    [Font Style]
----------------------------------------------------*/
.font-style-inherit {
  font-style: inherit !important;
}

.font-style-italic {
  font-style: italic !important;
}

/*--------------------------------------------------
    [Line Height]
----------------------------------------------------*/
.line-height-1 {
  line-height: 1;
}

.line-height-base {
  line-height: 1.4;
}

.line-height-2 {
  line-height: 2;
}

/*--------------------------------------------------
    [Font Weight]
----------------------------------------------------*/
.fweight-200 {
  font-weight: 200 !important;
}

.fweight-300 {
  font-weight: 300 !important;
}

.fweight-400 {
  font-weight: 400 !important;
}

.fweight-500 {
  font-weight: 500 !important;
}

.fweight-600 {
  font-weight: 600 !important;
}

.fweight-700 {
  font-weight: 700 !important;
}

/*--------------------------------------------------
    [Background Position]
----------------------------------------------------*/
.bg-position-fixed {
  background-attachment: fixed !important;
  min-height: 100% !important;
}

.bg-position-center-top {
  background-size: cover !important;
  background-position: center top !important;
}

.bg-position-center-center {
  background-size: cover !important;
  background-position: center center !important;
}

.bg-position-center-bottom {
  background-size: cover !important;
  background-position: center bottom !important;
}

/*--------------------------------------------------
    [Border]
----------------------------------------------------*/
.border-1 {
  border: 1px solid #ebeef6;
}

.border-top-1 {
  border-top: 1px solid #ebeef6;
}

.border-bottom-1 {
  border-bottom: 1px solid #ebeef6;
}

/*------------------------------------------------------------------
    [Display Vertical Center Aligned Block]
------------------------------------------------------------------*/
.display-center-col-3 {
  display: table-cell;
  vertical-align: middle;
  width: 33.33333333%;
}

/*--------------------------------------------------
    [Vertical Center Aligned Block]
    Using CSS3 Translate3D
----------------------------------------------------*/
.ver-center-aligned-block {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

/*--------------------------------------------------
    [Center Aligned Block]
    Using CSS3 Translate3D
----------------------------------------------------*/
.center-aligned-block {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

/*--------------------------------------------------
    [Center Aligned Block - above 768px]
----------------------------------------------------*/
.sm-center-aligned-block {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

@media (max-width: 768px) {
  .sm-center-aligned-block {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    -moz-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}

/*--------------------------------------------------
    [Center Aligned Block - above 992px]
----------------------------------------------------*/
.md-center-aligned-block {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

@media (max-width: 992px) {
  .md-center-aligned-block {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    -moz-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}

/*--------------------------------------------------
    [Vertical Center Aligned Content]
    Set displays an element as in inline-level flex container
----------------------------------------------------*/
.center-content-ver-wrap {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.center-content-ver-align {
  display: table;
  width: 100%;
  height: 100%;
}

.center-content-ver-body {
  display: table-cell;
  float: none;
  text-align: center;
  vertical-align: middle;
}

/*--------------------------------------------------
    [Vertical Center Aligned Content above 768px]
    Set displays an element as in inline-level flex container
----------------------------------------------------*/
/* Media Queries above 768px */
@media (min-width: 768px) {
  .center-content-ver-wrap-sm {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .center-content-ver-align-sm {
    display: table;
    width: 100%;
    height: 100%;
  }
  .center-content-ver-body-sm {
    display: table-cell;
    float: none;
    text-align: center;
    vertical-align: middle;
  }
}

/*--------------------------------------------------
    [Vertical Center Aligned Content above 992px]
    Set displays an element as in inline-level flex container
----------------------------------------------------*/
/* Media Queries above 992px */
@media (min-width: 992px) {
  .center-content-ver-wrap-md {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .center-content-ver-align-md {
    display: table;
    width: 100%;
    height: 100%;
  }
  .center-content-ver-body-md {
    display: table-cell;
    float: none;
    text-align: center;
    vertical-align: middle;
  }
}

/*--------------------------------------------------
    [Horizontal Center Aligned Content All Column]
    Set displays an element as in inline-level flex container
----------------------------------------------------*/
.center-content-hor-col-wrap {
  display: table;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.center-content-hor-col-align {
  display: table-cell;
  float: none;
  vertical-align: middle;
}

/*--------------------------------------------------
    [Horizontal Center Aligned Content above 768px]
    Set displays an element as in inline-level flex container
----------------------------------------------------*/
/* Media Queries above 768px */
@media (min-width: 768px) {
  .center-content-hor-wrap-sm {
    display: table;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
  .center-content-hor-align-sm {
    display: table-cell;
    float: none;
    vertical-align: middle;
  }
  .center-content-hor-align-sm.center-content-hor-align-sm-width-xs {
    min-width: 150px;
  }
  .center-content-hor-align-sm.center-content-hor-align-sm-width-sm {
    min-width: 250px;
  }
  .center-content-hor-align-sm.center-content-hor-align-sm-width-md {
    min-width: 300px;
  }
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .center-content-hor-align-sm {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding: 0 15px;
  }
}

/*--------------------------------------------------
    [Horizontal Center Aligned Content above 992px]
    Set displays an element as in inline-level flex container
----------------------------------------------------*/
@media (min-width: 992px) {
  .center-content-hor-wrap-md {
    display: table;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
  .center-content-hor-align-md {
    display: table-cell;
    float: none;
    vertical-align: middle;
  }
  .center-content-hor-align-md.center-content-hor-align-md-width-xs {
    min-width: 150px;
  }
  .center-content-hor-align-md.center-content-hor-align-md-width-md {
    min-width: 250px;
  }
  .center-content-hor-align-md.center-content-hor-align-md-width-md {
    min-width: 300px;
  }
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  .center-content-hor-align-md {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding: 0 15px;
  }
}

/*--------------------------------------------------
    [Text Align]
----------------------------------------------------*/
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* Media Queries under 992px */
@media (max-width: 992px) {
  .md-text-center {
    text-align: center;
  }
  .md-text-left {
    text-align: left;
  }
  .md-text-right {
    text-align: right;
  }
}

/* Media Queries under 768px */
@media (max-width: 768px) {
  .sm-text-center {
    text-align: center;
  }
  .sm-text-left {
    text-align: left;
  }
  .sm-text-right {
    text-align: right;
  }
}

/* Media Queries under 480px */
@media (max-width: 480px) {
  .xs-text-center {
    text-align: center;
  }
  .xs-text-left {
    text-align: left;
  }
  .xs-text-right {
    text-align: right;
  }
}

/*--------------------------------------------------
    [Center Aligned Block]
    Center align a block level element
----------------------------------------------------*/
.center-block {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  .md-center-block {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .sm-center-block {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .xs-center-block {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

/*--------------------------------------------------
    [Content]
----------------------------------------------------*/
.content-xs {
  padding-top: 20px;
  padding-bottom: 20px;
}

.content {
  padding-top: 40px;
  padding-bottom: 40px;
}

.content-sm {
  padding-top: 60px;
  padding-bottom: 60px;
}

.content-md {
  padding-top: 80px;
  padding-bottom: 80px;
}

.content-lg {
  padding-top: 100px;
  padding-bottom: 100px;
}

.content-xl {
  padding-top: 120px;
  padding-bottom: 120px;
}

/*------------------------------------------------------------------
    [No padding, margin space]
------------------------------------------------------------------*/
.no-space {
  padding: 0 !important;
  margin: 0 !important;
}

/* No padding, margin space above 992px */
@media (min-width: 992px) {
  .md-no-space {
    padding: 0 !important;
    margin: 0 !important;
  }
}

/* No Space in a row class */
.no-space-row {
  margin-right: 0;
  margin-left: 0;
}

.no-space-row > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

/* 5px Space in a row class */
.space-row-5 {
  margin-right: -5px;
  margin-left: -5px;
}

.space-row-5 > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

/* 10px Space in a row class */
.space-row-10 {
  margin-right: -10px;
  margin-left: -10px;
}

.space-row-10 > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

/*------------------------------------------------------------------
    [Padding Bottom]
------------------------------------------------------------------*/
.padding-0 {
  padding: 0 !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-50 {
  padding: 50px !important;
}

.padding-60 {
  padding: 60px !important;
}

.padding-70 {
  padding: 70px !important;
}

.padding-80 {
  padding: 80px !important;
}

.padding-90 {
  padding: 90px !important;
}

.padding-100 {
  padding: 100px !important;
}

/*------------------------------------------------------------------
    [Padding under 1200px]
------------------------------------------------------------------*/
@media (max-width: 1200px) {
  .lg-padding-10 {
    padding: 10px !important;
  }
  .lg-padding-20 {
    padding: 20px !important;
  }
  .lg-padding-30 {
    padding: 30px !important;
  }
  .lg-padding-40 {
    padding: 40px !important;
  }
  .lg-padding-50 {
    padding: 50px !important;
  }
  .lg-padding-60 {
    padding: 60px !important;
  }
  .lg-padding-70 {
    padding: 70px !important;
  }
  .lg-padding-80 {
    padding: 80px !important;
  }
  .lg-padding-90 {
    padding: 90px !important;
  }
  .lg-padding-100 {
    padding: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Padding under 992px]
------------------------------------------------------------------*/
@media (max-width: 992px) {
  .md-padding-10 {
    padding: 10px !important;
  }
  .md-padding-20 {
    padding: 20px !important;
  }
  .md-padding-30 {
    padding: 30px !important;
  }
  .md-padding-40 {
    padding: 40px !important;
  }
  .md-padding-50 {
    padding: 50px !important;
  }
  .md-padding-60 {
    padding: 60px !important;
  }
  .md-padding-70 {
    padding: 70px !important;
  }
  .md-padding-80 {
    padding: 80px !important;
  }
  .md-padding-90 {
    padding: 90px !important;
  }
  .md-padding-100 {
    padding: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Padding under 768px]
------------------------------------------------------------------*/
@media (max-width: 768px) {
  .sm-padding-10 {
    padding: 10px !important;
  }
  .sm-padding-20 {
    padding: 20px !important;
  }
  .sm-padding-30 {
    padding: 30px !important;
  }
  .sm-padding-40 {
    padding: 40px !important;
  }
  .sm-padding-50 {
    padding: 50px !important;
  }
  .sm-padding-60 {
    padding: 60px !important;
  }
  .sm-padding-70 {
    padding: 70px !important;
  }
  .sm-padding-80 {
    padding: 80px !important;
  }
  .sm-padding-90 {
    padding: 90px !important;
  }
  .sm-padding-100 {
    padding: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Padding under 480px]
------------------------------------------------------------------*/
@media (max-width: 480px) {
  .xs-padding-10 {
    padding: 10px !important;
  }
  .xs-padding-20 {
    padding: 20px !important;
  }
  .xs-padding-30 {
    padding: 30px !important;
  }
  .xs-padding-40 {
    padding: 40px !important;
  }
  .xs-padding-50 {
    padding: 50px !important;
  }
  .xs-padding-60 {
    padding: 60px !important;
  }
  .xs-padding-70 {
    padding: 70px !important;
  }
  .xs-padding-80 {
    padding: 80px !important;
  }
  .xs-padding-90 {
    padding: 90px !important;
  }
  .xs-padding-100 {
    padding: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Left & Right Padding]
------------------------------------------------------------------*/
.padding-lr-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.padding-lr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.padding-lr-15 {
  padding-left: -5px !important;
  padding-right: -5px !important;
}

.padding-lr-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

/*------------------------------------------------------------------
    [Left margin]
------------------------------------------------------------------*/
.margin-l-5 {
  margin-left: 5px !important;
}

.margin-l-10 {
  margin-left: 10px !important;
}

.margin-l-20 {
  margin-left: 20px !important;
}

.margin-l-30 {
  margin-left: 30px !important;
}

/*------------------------------------------------------------------
    [Right margin]
------------------------------------------------------------------*/
.margin-r-5 {
  margin-right: 5px !important;
}

.margin-r-10 {
  margin-right: 10px !important;
}

.margin-r-20 {
  margin-right: 20px !important;
}

.margin-r-30 {
  margin-right: 30px !important;
}

/*------------------------------------------------------------------
    [Margin]
------------------------------------------------------------------*/
.margin-5 {
  margin: 5px !important;
}

.margin-10 {
  margin: 10px !important;
}

.margin-20 {
  margin: 20px !important;
}

.margin-30 {
  margin: 30px !important;
}

.margin-40 {
  margin: 40px !important;
}

.margin-50 {
  margin: 50px !important;
}

/*------------------------------------------------------------------
    [Overlay Top margin]
------------------------------------------------------------------*/
.margin-t-o-0,
.margin-t-o-10,
.margin-t-o-20,
.margin-t-o-30,
.margin-t-o-40,
.margin-t-o-50,
.margin-t-o-60,
.margin-t-o-70,
.margin-t-o-80,
.margin-t-o-90,
.margin-t-o-100 {
  position: relative;
  z-index: 1;
}

.margin-t-o-0 {
  margin-top: 0 !important;
}

.margin-t-o-10 {
  margin-top: -10px !important;
}

.margin-t-o-20 {
  margin-top: -20px !important;
}

.margin-t-o-30 {
  margin-top: -30px !important;
}

.margin-t-o-40 {
  margin-top: -40px !important;
}

.margin-t-o-50 {
  margin-top: -50px !important;
}

.margin-t-o-60 {
  margin-top: -60px !important;
}

.margin-t-o-70 {
  margin-top: -70px !important;
}

.margin-t-o-80 {
  margin-top: -80px !important;
}

.margin-t-o-90 {
  margin-top: -90px !important;
}

.margin-t-o-100 {
  margin-top: -100px !important;
}

/*------------------------------------------------------------------
    [Overlay Top margin under 992px]
------------------------------------------------------------------*/
@media (max-width: 992px) {
  .md-margin-t-o-0,
  .md-margin-t-o-10,
  .md-margin-t-o-20,
  .md-margin-t-o-30,
  .md-margin-t-o-40,
  .md-margin-t-o-50,
  .md-margin-t-o-60,
  .md-margin-t-o-70,
  .md-margin-t-o-80,
  .md-margin-t-o-90,
  .md-margin-t-o-100 {
    position: relative;
    z-index: 1;
  }
  .md-margin-t-o-0 {
    margin-top: 0 !important;
  }
  .md-margin-t-o-10 {
    margin-top: -10px !important;
  }
  .md-margin-t-o-20 {
    margin-top: -20px !important;
  }
  .md-margin-t-o-30 {
    margin-top: -30px !important;
  }
  .md-margin-t-o-40 {
    margin-top: -40px !important;
  }
  .md-margin-t-o-50 {
    margin-top: -50px !important;
  }
  .md-margin-t-o-60 {
    margin-top: -60px !important;
  }
  .md-margin-t-o-70 {
    margin-top: -70px !important;
  }
  .md-margin-t-o-80 {
    margin-top: -80px !important;
  }
  .md-margin-t-o-90 {
    margin-top: -90px !important;
  }
  .md-margin-t-o-100 {
    margin-top: -100px !important;
  }
}

/*------------------------------------------------------------------
    [Overlay Top margin under 768px]
------------------------------------------------------------------*/
@media (max-width: 768px) {
  .sm-margin-t-o-0,
  .sm-margin-t-o-10,
  .sm-margin-t-o-20,
  .sm-margin-t-o-30,
  .sm-margin-t-o-40,
  .sm-margin-t-o-50,
  .sm-margin-t-o-60,
  .sm-margin-t-o-70,
  .sm-margin-t-o-80,
  .sm-margin-t-o-90,
  .sm-margin-t-o-100 {
    position: relative;
    z-index: 1;
  }
  .sm-margin-t-o-0 {
    margin-top: 0 !important;
  }
  .sm-margin-t-o-10 {
    margin-top: -10px !important;
  }
  .sm-margin-t-o-20 {
    margin-top: -20px !important;
  }
  .sm-margin-t-o-30 {
    margin-top: -30px !important;
  }
  .sm-margin-t-o-40 {
    margin-top: -40px !important;
  }
  .sm-margin-t-o-50 {
    margin-top: -50px !important;
  }
  .sm-margin-t-o-60 {
    margin-top: -60px !important;
  }
  .sm-margin-t-o-70 {
    margin-top: -70px !important;
  }
  .sm-margin-t-o-80 {
    margin-top: -80px !important;
  }
  .sm-margin-t-o-90 {
    margin-top: -90px !important;
  }
  .sm-margin-t-o-100 {
    margin-top: -100px !important;
  }
}

/*------------------------------------------------------------------
    [Overlay Top margin under 480px]
------------------------------------------------------------------*/
@media (max-width: 480px) {
  .xs-margin-t-o-0,
  .xs-margin-t-o-10,
  .xs-margin-t-o-20,
  .xs-margin-t-o-30,
  .xs-margin-t-o-40,
  .xs-margin-t-o-50,
  .xs-margin-t-o-60,
  .xs-margin-t-o-70,
  .xs-margin-t-o-80,
  .xs-margin-t-o-90,
  .xs-margin-t-o-100 {
    position: relative;
    z-index: 1;
  }
  .xs-margin-t-o-0 {
    margin-top: 0 !important;
  }
  .xs-margin-t-o-10 {
    margin-top: -10px !important;
  }
  .xs-margin-t-o-20 {
    margin-top: -20px !important;
  }
  .xs-margin-t-o-30 {
    margin-top: -30px !important;
  }
  .xs-margin-t-o-40 {
    margin-top: -40px !important;
  }
  .xs-margin-t-o-50 {
    margin-top: -50px !important;
  }
  .xs-margin-t-o-60 {
    margin-top: -60px !important;
  }
  .xs-margin-t-o-70 {
    margin-top: -70px !important;
  }
  .xs-margin-t-o-80 {
    margin-top: -80px !important;
  }
  .xs-margin-t-o-90 {
    margin-top: -90px !important;
  }
  .xs-margin-t-o-100 {
    margin-top: -100px !important;
  }
}

/*------------------------------------------------------------------
    [Overlay Left margin]
------------------------------------------------------------------*/
@media (min-width: 768px) {
  .margin-l-o-10,
  .margin-l-o-20,
  .margin-l-o-30,
  .margin-l-o-40,
  .margin-l-o-50,
  .margin-l-o-60,
  .margin-l-o-70,
  .margin-l-o-80,
  .margin-l-o-90,
  .margin-l-o-100 {
    position: relative;
    z-index: 1;
  }
  .margin-l-o-10 {
    margin-left: -10px !important;
  }
  .margin-l-o-20 {
    margin-left: -20px !important;
  }
  .margin-l-o-30 {
    margin-left: -30px !important;
  }
  .margin-l-o-40 {
    margin-left: -40px !important;
  }
  .margin-l-o-50 {
    margin-left: -50px !important;
  }
  .margin-l-o-60 {
    margin-left: -60px !important;
  }
  .margin-l-o-70 {
    margin-left: -70px !important;
  }
  .margin-l-o-80 {
    margin-left: -80px !important;
  }
  .margin-l-o-90 {
    margin-left: -90px !important;
  }
  .margin-l-o-100 {
    margin-left: -100px !important;
  }
}

/*------------------------------------------------------------------
    [Overlay Right margin]
------------------------------------------------------------------*/
@media (min-width: 768px) {
  .margin-r-o-10,
  .margin-r-o-20,
  .margin-r-o-30,
  .margin-r-o-40,
  .margin-r-o-50,
  .margin-r-o-60,
  .margin-r-o-70,
  .margin-r-o-80,
  .margin-r-o-90,
  .margin-r-o-100 {
    position: relative;
    z-index: 1;
  }
  .margin-r-o-10 {
    margin-right: -10px !important;
  }
  .margin-r-o-20 {
    margin-right: -20px !important;
  }
  .margin-r-o-30 {
    margin-right: -30px !important;
  }
  .margin-r-o-40 {
    margin-right: -40px !important;
  }
  .margin-r-o-50 {
    margin-right: -50px !important;
  }
  .margin-r-o-60 {
    margin-right: -60px !important;
  }
  .margin-r-o-70 {
    margin-right: -70px !important;
  }
  .margin-r-o-80 {
    margin-right: -80px !important;
  }
  .margin-r-o-90 {
    margin-right: -90px !important;
  }
  .margin-r-o-100 {
    margin-right: -100px !important;
  }
}

/*------------------------------------------------------------------
    [Top margin]
------------------------------------------------------------------*/
.margin-t-0 {
  margin-top: 0 !important;
}

.margin-t-5 {
  margin-top: 5px !important;
}

.margin-t-10 {
  margin-top: 10px !important;
}

.margin-t-20 {
  margin-top: 20px !important;
}

.margin-t-30 {
  margin-top: 30px !important;
}

.margin-t-40 {
  margin-top: 40px !important;
}

.margin-t-50 {
  margin-top: 50px !important;
}

.margin-t-60 {
  margin-top: 60px !important;
}

.margin-t-70 {
  margin-top: 70px !important;
}

.margin-t-80 {
  margin-top: 80px !important;
}

.margin-t-90 {
  margin-top: 90px !important;
}

.margin-t-100 {
  margin-top: 100px !important;
}

/*------------------------------------------------------------------
    [Top margin under 992px]
------------------------------------------------------------------*/
@media (max-width: 992px) {
  .md-margin-t-0 {
    margin-top: 0 !important;
  }
  .md-margin-t-5 {
    margin-top: 5px !important;
  }
  .md-margin-t-10 {
    margin-top: 10px !important;
  }
  .md-margin-t-20 {
    margin-top: 20px !important;
  }
  .md-margin-t-30 {
    margin-top: 30px !important;
  }
  .md-margin-t-40 {
    margin-top: 40px !important;
  }
  .md-margin-t-50 {
    margin-top: 50px !important;
  }
  .md-margin-t-60 {
    margin-top: 60px !important;
  }
  .md-margin-t-70 {
    margin-top: 70px !important;
  }
  .md-margin-t-80 {
    margin-top: 80px !important;
  }
  .md-margin-t-90 {
    margin-top: 90px !important;
  }
  .md-margin-t-100 {
    margin-top: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Top margin under 768px]
------------------------------------------------------------------*/
@media (max-width: 768px) {
  .sm-margin-t-0 {
    margin-top: 0 !important;
  }
  .sm-margin-t-5 {
    margin-top: 5px !important;
  }
  .sm-margin-t-10 {
    margin-top: 10px !important;
  }
  .sm-margin-t-20 {
    margin-top: 20px !important;
  }
  .sm-margin-t-30 {
    margin-top: 30px !important;
  }
  .sm-margin-t-40 {
    margin-top: 40px !important;
  }
  .sm-margin-t-50 {
    margin-top: 50px !important;
  }
  .sm-margin-t-60 {
    margin-top: 60px !important;
  }
  .sm-margin-t-70 {
    margin-top: 70px !important;
  }
  .sm-margin-t-80 {
    margin-top: 80px !important;
  }
  .sm-margin-t-90 {
    margin-top: 90px !important;
  }
  .sm-margin-t-100 {
    margin-top: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Top margin under 480px]
------------------------------------------------------------------*/
@media (max-width: 480px) {
  .xs-margin-t-0 {
    margin-top: 0 !important;
  }
  .xs-margin-t-5 {
    margin-top: 5px !important;
  }
  .xs-margin-t-10 {
    margin-top: 10px !important;
  }
  .xs-margin-t-20 {
    margin-top: 20px !important;
  }
  .xs-margin-t-30 {
    margin-top: 30px !important;
  }
  .xs-margin-t-40 {
    margin-top: 40px !important;
  }
  .xs-margin-t-50 {
    margin-top: 50px !important;
  }
  .xs-margin-t-60 {
    margin-top: 60px !important;
  }
  .xs-margin-t-70 {
    margin-top: 70px !important;
  }
  .xs-margin-t-80 {
    margin-top: 80px !important;
  }
  .xs-margin-t-90 {
    margin-top: 90px !important;
  }
  .xs-margin-t-100 {
    margin-top: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Bottom margin]
------------------------------------------------------------------*/
.margin-b-0 {
  margin-bottom: 0 !important;
}

.margin-b-5 {
  margin-bottom: 5px !important;
}

.margin-b-10 {
  margin-bottom: 10px !important;
}

.margin-b-10 {
  margin-bottom: 10px !important;
}

.margin-b-20 {
  margin-bottom: 20px !important;
}

.margin-b-30 {
  margin-bottom: 30px !important;
}

.margin-b-40 {
  margin-bottom: 40px !important;
}

.margin-b-50 {
  margin-bottom: 50px !important;
}

.margin-b-60 {
  margin-bottom: 60px !important;
}

.margin-b-70 {
  margin-bottom: 70px !important;
}

.margin-b-80 {
  margin-bottom: 80px !important;
}

.margin-b-90 {
  margin-bottom: 90px !important;
}

.margin-b-100 {
  margin-bottom: 100px !important;
}

/*------------------------------------------------------------------
    [Top margin below 1200px]
------------------------------------------------------------------*/
@media (max-width: 1200px) {
  .lg-margin-b-0 {
    margin-bottom: 0 !important;
  }
  .lg-margin-b-5 {
    margin-bottom: 5px !important;
  }
  .lg-margin-b-10 {
    margin-bottom: 10px !important;
  }
  .lg-margin-b-20 {
    margin-bottom: 20px !important;
  }
  .lg-margin-b-30 {
    margin-bottom: 30px !important;
  }
  .lg-margin-b-40 {
    margin-bottom: 40px !important;
  }
  .lg-margin-b-50 {
    margin-bottom: 50px !important;
  }
  .lg-margin-b-60 {
    margin-bottom: 60px !important;
  }
  .lg-margin-b-70 {
    margin-bottom: 70px !important;
  }
  .lg-margin-b-80 {
    margin-bottom: 80px !important;
  }
  .lg-margin-b-90 {
    margin-bottom: 90px !important;
  }
  .lg-margin-b-100 {
    margin-bottom: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Top margin below 992px]
------------------------------------------------------------------*/
@media (max-width: 992px) {
  .md-margin-b-0 {
    margin-bottom: 0 !important;
  }
  .md-margin-b-5 {
    margin-bottom: 5px !important;
  }
  .md-margin-b-10 {
    margin-bottom: 10px !important;
  }
  .md-margin-b-20 {
    margin-bottom: 20px !important;
  }
  .md-margin-b-30 {
    margin-bottom: 30px !important;
  }
  .md-margin-b-40 {
    margin-bottom: 40px !important;
  }
  .md-margin-b-50 {
    margin-bottom: 50px !important;
  }
  .md-margin-b-60 {
    margin-bottom: 60px !important;
  }
  .md-margin-b-70 {
    margin-bottom: 70px !important;
  }
  .md-margin-b-80 {
    margin-bottom: 80px !important;
  }
  .md-margin-b-90 {
    margin-bottom: 90px !important;
  }
  .md-margin-b-100 {
    margin-bottom: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Top margin below 768px]
------------------------------------------------------------------*/
@media (max-width: 768px) {
  .sm-margin-b-0 {
    margin-bottom: 0 !important;
  }
  .sm-margin-b-5 {
    margin-bottom: 5px !important;
  }
  .sm-margin-b-10 {
    margin-bottom: 10px !important;
  }
  .sm-margin-b-20 {
    margin-bottom: 20px !important;
  }
  .sm-margin-b-30 {
    margin-bottom: 30px !important;
  }
  .sm-margin-b-40 {
    margin-bottom: 40px !important;
  }
  .sm-margin-b-50 {
    margin-bottom: 50px !important;
  }
  .sm-margin-b-60 {
    margin-bottom: 60px !important;
  }
  .sm-margin-b-70 {
    margin-bottom: 70px !important;
  }
  .sm-margin-b-80 {
    margin-bottom: 80px !important;
  }
  .sm-margin-b-90 {
    margin-bottom: 90px !important;
  }
  .sm-margin-b-100 {
    margin-bottom: 100px !important;
  }
}

/*------------------------------------------------------------------
    [Top margin below 480px]
------------------------------------------------------------------*/
@media (max-width: 480px) {
  .xs-margin-b-0 {
    margin-bottom: 0 !important;
  }
  .xs-margin-b-5 {
    margin-bottom: 5px !important;
  }
  .xs-margin-b-10 {
    margin-bottom: 10px !important;
  }
  .xs-margin-b-20 {
    margin-bottom: 20px !important;
  }
  .xs-margin-b-30 {
    margin-bottom: 30px !important;
  }
  .xs-margin-b-40 {
    margin-bottom: 40px !important;
  }
  .xs-margin-b-50 {
    margin-bottom: 50px !important;
  }
  .xs-margin-b-60 {
    margin-bottom: 60px !important;
  }
  .xs-margin-b-70 {
    margin-bottom: 70px !important;
  }
  .xs-margin-b-80 {
    margin-bottom: 80px !important;
  }
  .xs-margin-b-90 {
    margin-bottom: 90px !important;
  }
  .xs-margin-b-100 {
    margin-bottom: 100px !important;
  }
}

/*--------------------------------------------------
    [Height]
----------------------------------------------------*/
.height-50 {
  height: 50px !important;
}

.height-100 {
  height: 100px !important;
}

.height-150 {
  height: 150px !important;
}

.height-200 {
  height: 200px !important;
}

.height-250 {
  height: 250px !important;
}

.height-300 {
  height: 300px !important;
}

.height-350 {
  height: 350px !important;
}

.height-400 {
  height: 400px !important;
}

.height-450 {
  height: 450px !important;
}

.height-500 {
  height: 500px !important;
}

/*------------------------------------------------------------------
    [Height below 992px]
------------------------------------------------------------------*/
@media (max-width: 992px) {
  .md-height-50 {
    height: 50px !important;
  }
  .md-height-100 {
    height: 100px !important;
  }
  .md-height-150 {
    height: 150px !important;
  }
  .md-height-200 {
    height: 200px !important;
  }
  .md-height-250 {
    height: 250px !important;
  }
  .md-height-300 {
    height: 300px !important;
  }
  .md-height-350 {
    height: 350px !important;
  }
  .md-height-400 {
    height: 400px !important;
  }
  .md-height-450 {
    height: 450px !important;
  }
  .md-height-500 {
    height: 500px !important;
  }
}

/*------------------------------------------------------------------
    [Height below 768px]
------------------------------------------------------------------*/
@media (max-width: 768px) {
  .sm-height-50 {
    height: 50px !important;
  }
  .sm-height-100 {
    height: 100px !important;
  }
  .sm-height-150 {
    height: 150px !important;
  }
  .sm-height-200 {
    height: 200px !important;
  }
  .sm-height-250 {
    height: 250px !important;
  }
  .sm-height-300 {
    height: 300px !important;
  }
  .sm-height-350 {
    height: 350px !important;
  }
  .sm-height-400 {
    height: 400px !important;
  }
  .sm-height-450 {
    height: 450px !important;
  }
  .sm-height-500 {
    height: 500px !important;
  }
}

/*------------------------------------------------------------------
    [Height below 480px]
------------------------------------------------------------------*/
@media (max-width: 480px) {
  .xs-height-50 {
    height: 50px !important;
  }
  .xs-height-100 {
    height: 100px !important;
  }
  .xs-height-150 {
    height: 150px !important;
  }
  .xs-height-200 {
    height: 200px !important;
  }
  .xs-height-250 {
    height: 250px !important;
  }
  .xs-height-300 {
    height: 300px !important;
  }
  .xs-height-350 {
    height: 350px !important;
  }
  .xs-height-400 {
    height: 400px !important;
  }
  .xs-height-450 {
    height: 450px !important;
  }
  .xs-height-500 {
    height: 500px !important;
  }
}

/*--------------------------------------------------
    [Width]
----------------------------------------------------*/
.width-50 {
  width: 50px !important;
}

.width-100 {
  width: 100px !important;
}

.width-150 {
  width: 150px !important;
}

.width-200 {
  width: 200px !important;
}

.width-250 {
  width: 250px !important;
}

.width-300 {
  width: 300px !important;
}

.width-350 {
  width: 350px !important;
}

.width-400 {
  width: 400px !important;
}

.width-450 {
  width: 450px !important;
}

.width-500 {
  width: 500px !important;
}

/*------------------------------------------------------------------
    [Width below 992px]
------------------------------------------------------------------*/
@media (max-width: 992px) {
  .md-width-50 {
    width: 50px !important;
  }
  .md-width-100 {
    width: 100px !important;
  }
  .md-width-150 {
    width: 150px !important;
  }
  .md-width-200 {
    width: 200px !important;
  }
  .md-width-250 {
    width: 250px !important;
  }
  .md-width-300 {
    width: 300px !important;
  }
  .md-width-350 {
    width: 350px !important;
  }
  .md-width-400 {
    width: 400px !important;
  }
  .md-width-450 {
    width: 450px !important;
  }
  .md-width-500 {
    width: 500px !important;
  }
}

/*------------------------------------------------------------------
    [Width below 768px]
------------------------------------------------------------------*/
@media (max-width: 768px) {
  .sm-width-50 {
    width: 50px !important;
  }
  .sm-width-100 {
    width: 100px !important;
  }
  .sm-width-150 {
    width: 150px !important;
  }
  .sm-width-200 {
    width: 200px !important;
  }
  .sm-width-250 {
    width: 250px !important;
  }
  .sm-width-300 {
    width: 300px !important;
  }
  .sm-width-350 {
    width: 350px !important;
  }
  .sm-width-400 {
    width: 400px !important;
  }
  .sm-width-450 {
    width: 450px !important;
  }
  .sm-width-500 {
    width: 500px !important;
  }
}

/*------------------------------------------------------------------
    [Width below 480px]
------------------------------------------------------------------*/
@media (max-width: 480px) {
  .xs-width-50 {
    width: 50px !important;
  }
  .xs-width-100 {
    width: 100px !important;
  }
  .xs-width-150 {
    width: 150px !important;
  }
  .xs-width-200 {
    width: 200px !important;
  }
  .xs-width-250 {
    width: 250px !important;
  }
  .xs-width-300 {
    width: 300px !important;
  }
  .xs-width-350 {
    width: 350px !important;
  }
  .xs-width-400 {
    width: 400px !important;
  }
  .xs-width-450 {
    width: 450px !important;
  }
  .xs-width-500 {
    width: 500px !important;
  }
}

/*--------------------------------------------------
    [Full Width]
----------------------------------------------------*/
.full-width {
  width: 100% !important;
  height: auto !important;
}

/* Media Queries below 992px */
@media (max-width: 992px) {
  .md-full-width {
    width: 100% !important;
    height: auto !important;
  }
}

/* Media Queries below 768px */
@media (max-width: 768px) {
  .sm-full-width {
    width: 100% !important;
    height: auto !important;
  }
}

/* Media Queries below 480px */
@media (max-width: 480px) {
  .xs-full-width {
    width: 100% !important;
    height: auto !important;
  }
}

/*--------------------------------------------------
    [Ul li padding left & right (default is 0 5px)]
----------------------------------------------------*/
.ul-li-lr-0 > li {
  padding-left: 0;
  padding-right: 0;
}

.ul-li-lr-1 > li {
  padding-left: 1px;
  padding-right: 1px;
}

.ul-li-lr-2 > li {
  padding-left: 2px;
  padding-right: 2px;
}

.ul-li-lr-3 > li {
  padding-left: 3px;
  padding-right: 3px;
}

.ul-li-lr-4 > li {
  padding-left: 4px;
  padding-right: 4px;
}

.ul-li-lr-5 > li {
  padding-left: 5px;
  padding-right: 5px;
}

.ul-li-lr-6 > li {
  padding-left: 6px;
  padding-right: 6px;
}

.ul-li-lr-7 > li {
  padding-left: 7px;
  padding-right: 7px;
}

.ul-li-lr-8 > li {
  padding-left: 8px;
  padding-right: 8px;
}

.ul-li-lr-9 > li {
  padding-left: 9px;
  padding-right: 9px;
}

.ul-li-lr-10 > li {
  padding-left: 10px;
  padding-right: 10px;
}

/*--------------------------------------------------
    [Ul li padding top & bottom]
----------------------------------------------------*/
.ul-li-tb-0 > li {
  padding-top: 0;
  padding-bottom: 0;
}

.ul-li-tb-1 > li {
  padding-top: 1px;
  padding-bottom: 1px;
}

.ul-li-tb-2 > li {
  padding-top: 2px;
  padding-bottom: 2px;
}

.ul-li-tb-3 > li {
  padding-top: 3px;
  padding-bottom: 3px;
}

.ul-li-tb-4 > li {
  padding-top: 4px;
  padding-bottom: 4px;
}

.ul-li-tb-5 > li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ul-li-tb-6 > li {
  padding-top: 6px;
  padding-bottom: 6px;
}

.ul-li-tb-7 > li {
  padding-top: 7px;
  padding-bottom: 7px;
}

.ul-li-tb-8 > li {
  padding-top: 8px;
  padding-bottom: 8px;
}

.ul-li-tb-9 > li {
  padding-top: 9px;
  padding-bottom: 9px;
}

.ul-li-tb-10 > li {
  padding-top: 10px;
  padding-bottom: 10px;
}

/*--------------------------------------------------
    [Border radius]
----------------------------------------------------*/
.radius-0 {
  border-radius: 0 !important;
}

.radius-3 {
  border-radius: 3px !important;
}

.radius-5 {
  border-radius: 5px !important;
}

.radius-6 {
  border-radius: 6px !important;
}

.radius-7 {
  border-radius: 7px !important;
}

.radius-8 {
  border-radius: 8px !important;
}

.radius-9 {
  border-radius: 9px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.radius-30 {
  border-radius: 30px !important;
}

.radius-40 {
  border-radius: 40px !important;
}

.radius-50 {
  border-radius: 50px !important;
}

.radius-circle {
  border-radius: 50% !important;
}

/*--------------------------------------------------
    [Border Top Left Radius]
----------------------------------------------------*/
.radius-t-l-0 {
  border-top-left-radius: 0 !important;
}

.radius-t-l-3 {
  border-top-left-radius: 3px !important;
}

.radius-t-l-5 {
  border-top-left-radius: 5px !important;
}

.radius-t-l-6 {
  border-top-left-radius: 6px !important;
}

.radius-t-l-7 {
  border-top-left-radius: 7px !important;
}

.radius-t-l-8 {
  border-top-left-radius: 8px !important;
}

.radius-t-l-9 {
  border-top-left-radius: 9px !important;
}

.radius-t-l-10 {
  border-top-left-radius: 10px !important;
}

.radius-t-l-20 {
  border-top-left-radius: 20px !important;
}

.radius-t-l-30 {
  border-top-left-radius: 30px !important;
}

.radius-t-l-40 {
  border-top-left-radius: 40px !important;
}

.radius-t-l-50 {
  border-top-left-radius: 50px !important;
}

/*--------------------------------------------------
    [Border Top Right Radius]
----------------------------------------------------*/
.radius-t-r-0 {
  border-top-right-radius: 0 !important;
}

.radius-t-r-3 {
  border-top-right-radius: 3px !important;
}

.radius-t-r-5 {
  border-top-right-radius: 5px !important;
}

.radius-t-r-6 {
  border-top-right-radius: 6px !important;
}

.radius-t-r-7 {
  border-top-right-radius: 7px !important;
}

.radius-t-r-8 {
  border-top-right-radius: 8px !important;
}

.radius-t-r-9 {
  border-top-right-radius: 9px !important;
}

.radius-t-r-10 {
  border-top-right-radius: 10px !important;
}

.radius-t-r-20 {
  border-top-right-radius: 20px !important;
}

.radius-t-r-30 {
  border-top-right-radius: 30px !important;
}

.radius-t-r-40 {
  border-top-right-radius: 40px !important;
}

.radius-t-r-50 {
  border-top-right-radius: 50px !important;
}

/*--------------------------------------------------
    [Border Bottom Left Radius]
----------------------------------------------------*/
.radius-b-l-0 {
  border-bottom-left-radius: 0 !important;
}

.radius-b-l-3 {
  border-bottom-left-radius: 3px !important;
}

.radius-b-l-5 {
  border-bottom-left-radius: 5px !important;
}

.radius-b-l-6 {
  border-bottom-left-radius: 6px !important;
}

.radius-b-l-7 {
  border-bottom-left-radius: 7px !important;
}

.radius-b-l-8 {
  border-bottom-left-radius: 8px !important;
}

.radius-b-l-9 {
  border-bottom-left-radius: 9px !important;
}

.radius-b-l-10 {
  border-bottom-left-radius: 10px !important;
}

.radius-b-l-20 {
  border-bottom-left-radius: 20px !important;
}

.radius-b-l-30 {
  border-bottom-left-radius: 30px !important;
}

.radius-b-l-40 {
  border-bottom-left-radius: 40px !important;
}

.radius-b-l-50 {
  border-bottom-left-radius: 50px !important;
}

/*--------------------------------------------------
    [Border Bottom Right Radius]
----------------------------------------------------*/
.radius-b-r-0 {
  border-bottom-right-radius: 0 !important;
}

.radius-b-r-3 {
  border-bottom-right-radius: 3px !important;
}

.radius-b-r-5 {
  border-bottom-right-radius: 5px !important;
}

.radius-b-r-6 {
  border-bottom-right-radius: 6px !important;
}

.radius-b-r-7 {
  border-bottom-right-radius: 7px !important;
}

.radius-b-r-8 {
  border-bottom-right-radius: 8px !important;
}

.radius-b-r-9 {
  border-bottom-right-radius: 9px !important;
}

.radius-b-r-10 {
  border-bottom-right-radius: 10px !important;
}

.radius-b-r-20 {
  border-bottom-right-radius: 20px !important;
}

.radius-b-r-30 {
  border-bottom-right-radius: 30px !important;
}

.radius-b-r-40 {
  border-bottom-right-radius: 40px !important;
}

.radius-b-r-50 {
  border-bottom-right-radius: 50px !important;
}

/*--------------------------------------------------
    [Border radius Pseudo :before, :after]
----------------------------------------------------*/
.radius-ba-0:before, .radius-ba-0:after {
  border-radius: 0 !important;
}

.radius-ba-3:before, .radius-ba-3:after {
  border-radius: 3px !important;
}

.radius-ba-5:before, .radius-ba-5:after {
  border-radius: 5px !important;
}

.radius-ba-6:before, .radius-ba-6:after {
  border-radius: 6px !important;
}

.radius-ba-7:before, .radius-ba-7:after {
  border-radius: 7px !important;
}

.radius-ba-8:before, .radius-ba-8:after {
  border-radius: 8px !important;
}

.radius-ba-9:before, .radius-ba-9:after {
  border-radius: 9px !important;
}

.radius-ba-10:before, .radius-ba-10:after {
  border-radius: 10px !important;
}

.radius-ba-20:before, .radius-ba-20:after {
  border-radius: 20px !important;
}

.radius-ba-30:before, .radius-ba-30:after {
  border-radius: 30px !important;
}

.radius-ba-40:before, .radius-ba-40:after {
  border-radius: 40px !important;
}

.radius-ba-50:before, .radius-ba-50:after {
  border-radius: 50px !important;
}

.radius-ba-circle:before, .radius-ba-circle:after {
  border-radius: 50% !important;
}

/*--------------------------------------------------
    [Border radius Pseudo :before]
----------------------------------------------------*/
.radius-b-0:before {
  border-radius: 0 !important;
}

.radius-b-3:before {
  border-radius: 3px !important;
}

.radius-b-5:before {
  border-radius: 5px !important;
}

.radius-b-6:before {
  border-radius: 6px !important;
}

.radius-b-7:before {
  border-radius: 7px !important;
}

.radius-b-8:before {
  border-radius: 8px !important;
}

.radius-b-9:before {
  border-radius: 9px !important;
}

.radius-b-10:before {
  border-radius: 10px !important;
}

.radius-b-20:before {
  border-radius: 20px !important;
}

.radius-b-30:before {
  border-radius: 30px !important;
}

.radius-b-40:before {
  border-radius: 40px !important;
}

.radius-b-50:before {
  border-radius: 50px !important;
}

.radius-b-circle:before {
  border-radius: 50% !important;
}

/*--------------------------------------------------
    [Border radius Pseudo :after]
----------------------------------------------------*/
.radius-a-0:after {
  border-radius: 0 !important;
}

.radius-a-3:after {
  border-radius: 3px !important;
}

.radius-a-5:after {
  border-radius: 5px !important;
}

.radius-a-6:after {
  border-radius: 6px !important;
}

.radius-a-7:after {
  border-radius: 7px !important;
}

.radius-a-8:after {
  border-radius: 8px !important;
}

.radius-a-9:after {
  border-radius: 9px !important;
}

.radius-a-10:after {
  border-radius: 10px !important;
}

.radius-a-20:after {
  border-radius: 20px !important;
}

.radius-a-30:after {
  border-radius: 30px !important;
}

.radius-a-40:after {
  border-radius: 40px !important;
}

.radius-a-50:after {
  border-radius: 50px !important;
}

.radius-a-circle:after {
  border-radius: 50% !important;
}

/**
 * Navbar
 */
.header-transparent .navbar-nav .nav-item-child {
  font-weight: 300;
}

.header .navbar-nav .nav-item-child {
  font-weight: 300;
}

.header .dropdown-menu .dropdown-menu-item .dropdown-menu-item-child {
  font-weight: 300;
}

.header .dropdown-submenu .dropdown-submenu-child {
  font-weight: 300;
}

.tooltip-inner {
  width: 500px;
}

.impersonating {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background: #fcf8e3;
  border: 1px solid #faf2cc;
  z-index: 1051;
}

.impersonating > .btn {
  margin-left: 15px;
}

/**
 * General styles
 */
h1, h2, h3, h4, h5, h6 {
  margin: 10px 0 10px;
}

p {
  margin: 0 0 15px;
}

ol, ul {
  margin-bottom: 20px;
}

th em {
  font-weight: bold;
}

.lists-base > li {
  font-size: 15px;
}

.lists-base > li > a {
  color: #3E5101 !important;
  font-size: 15px;
}

.lists-base > li > a:hover {
  text-decoration: underline;
}

.list-unstyled .lists-child {
  margin-left: 30px;
}

h4 {
  margin: 20px 0 10px;
}

.footer-v6-copyright a {
  color: #606060;
  margin-right: 20px;
  font-size: 20px;
}

.footer-v6-copyright img {
  margin-right: 20px;
}

/**
 * Home styles
 */
#earn-banner .heading-v1-title {
  color: #ffffff;
  font-size: 28px;
  font-style: italic;
  font-family: Droid Serif, serif;
}

#earn-banner h3 {
  font-weight: 300;
  font-family: Roboto, sans-serif;
  color: #ffffff;
  line-height: 1.4;
  margin: 0 0 15px;
}

#earn-banner .heading-v1-text {
  color: #ffffff;
  font-size: 18px;
  font-style: italic;
  margin-bottom: 0;
}

/**
 * Profile edit styles
 */
.table-wrap .panel-title a {
  font-size: 18px;
}

#profile-edit .panel-heading a {
  color: #BAF402;
}

/**
 * Breadcrumbs
 */
.breadcrumbs-v5 {
  /**
     * 404 Page not found
     */
  /**
     * Join Nav
     */
  /**
     * About Nav
     */
  /**
     * CPD Nav
     */
  /**
     * Publications Nav
     */
  /**
    * Standards Nav
    */
  /**
     * Footer governance
     */
  /**
     * Footer support
     */
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-page-not-found {
  background: url(../../app/assets/img/breadcrumbs/page_not_found_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-why-join {
  background: url(../../app/assets/img/breadcrumbs/why_join_saiba.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-join {
  background: url(../../app/assets/img/breadcrumbs/join_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-business-accountant-sa {
  background: url(../../app/assets/img/breadcrumbs/designation_basa_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-certified-business-accountant-sa {
  background: url(../../app/assets/img/breadcrumbs/designation_cbasa_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 30%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-cert-fin-officer-sa {
  background: url(../../app/assets/img/breadcrumbs/designation_cfo_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-business-accountant-in-practice-sa {
  background: url(../../app/assets/img/breadcrumbs/designation_bapsa_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-fees {
  background: url(../../app/assets/img/breadcrumbs/fees_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 35%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-exemptions {
  background: url(../../app/assets/img/breadcrumbs/exemptions_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 30%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-professional-indemnity {
  background: url(../../app/assets/img/breadcrumbs/professional_indemnity.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 30%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-about {
  background: url(../../app/assets/img/breadcrumbs/about_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 70%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-team {
  background: url(../../app/assets/img/breadcrumbs/team_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-partners {
  background: url(../../app/assets/img/breadcrumbs/partners_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 10%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-membership {
  background: url(../../app/assets/img/breadcrumbs/membership_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 30%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-members {
  background: url(../../app/assets/img/breadcrumbs/members_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 10%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-board {
  background: url(../../app/assets/img/breadcrumbs/board_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-events-conferences {
  background: url(../../app/assets/img/breadcrumbs/events_conferences_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-events-seminars {
  background: url(../../app/assets/img/breadcrumbs/events_seminars_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-events-webinars {
  background: url(../../app/assets/img/breadcrumbs/events_webinars_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-events-partners {
  background: url(../../app/assets/img/breadcrumbs/events_partners_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-certificate-courses {
  background: url(../../app/assets/img/breadcrumbs/certificate_courses_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-unisa-courses {
  background: url(../../app/assets/img/breadcrumbs/unisa_courses_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-guide {
  background: url(../../app/assets/img/breadcrumbs/guide_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 30%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-black_friday {
  background: url(../../assets/img/black_friday.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  padding: 450px 0 100px;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-accounting-weekly {
  background: url(../../app/assets/img/breadcrumbs/accounting_weekly_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 30%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-bareview {
  background: url(../../app/assets/img/breadcrumbs/bareview_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 30%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-press-releases {
  background: url(../../app/assets/img/breadcrumbs/press_releases_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 30%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-volunteer {
  background: url(../../app/assets/img/breadcrumbs/mandela_day_11-min.jpg) no-repeat;
  background-size: cover;
  background-position: 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-practice-of-the-month {
  background: url(../../app/assets/img/breadcrumbs/practice_of_the_month_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 10%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-practice-of-the-month-ardent-accounting-tax-advisory {
  background: url(../../app/assets/img/breadcrumbs/practice_of_the_month_ardent_accounting_tax_advisory.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 10%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-woman-in-finance {
  background: url(../../app/assets/img/breadcrumbs/woman_in_finance_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 30%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-approved-training-center {
  background: url(../../app/assets/img/breadcrumbs/approved_training_center_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-cipc-pilot-program {
  background: url(../../app/assets/img/breadcrumbs/cipc_pilot_program_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-npo-assist {
  background: url(../../app/assets/img/breadcrumbs/npo_assist_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-code-of-conduct {
  background: url(../../app/assets/img/breadcrumbs/code_of_conduct_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-financial-reporting {
  background: url(../../app/assets/img/breadcrumbs/financial_reporting_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-engagements {
  background: url(../../app/assets/img/breadcrumbs/engagements_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-eme-affidavits {
  background: url(../../app/assets/img/breadcrumbs/eme_affidavits_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-eme-reports {
  background: url(../../app/assets/img/breadcrumbs/eme_reports_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-npos {
  background: url(../../app/assets/img/breadcrumbs/npos_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-accounting-officer {
  background: url(../../app/assets/img/breadcrumbs/accounting_officer_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-commissioner-of-oaths {
  background: url(../../app/assets/img/breadcrumbs/commissioner_of_oaths_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-independent-review {
  background: url(../../app/assets/img/breadcrumbs/independent_review_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-tax {
  background: url(../../app/assets/img/breadcrumbs/tax_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 10%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-company-rules {
  background: url(../../app/assets/img/breadcrumbs/company_rules_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-board-and-secreteriat {
  background: url(../../app/assets/img/breadcrumbs/board_and_secreteriat_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-annual-reports {
  background: url(../../app/assets/img/breadcrumbs/annual_reports_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-bbbee {
  background: url(../../app/assets/img/breadcrumbs/bbbee_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-paia {
  background: url(../../app/assets/img/breadcrumbs/paia_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 10%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-moi {
  background: url(../../app/assets/img/breadcrumbs/moi_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 30%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-complaints {
  background: url(../../app/assets/img/breadcrumbs/complaints_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-faq {
  background: url(../../app/assets/img/breadcrumbs/faq_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-support {
  background: url(../../app/assets/img/breadcrumbs/support_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 10%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-terms {
  background: url(../../app/assets/img/breadcrumbs/terms_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-privacy {
  background: url(../../app/assets/img/breadcrumbs/privacy_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-technical {
  background: url(../../app/assets/img/breadcrumbs/technical_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-v5.breadcrumbs-v5-bg-img-technical {
  background: url(../../app/assets/img/iafei-articles.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.pricing-list-v1 {
  border: none;
  padding: 0;
}

.pricing-list-v1 .pricing-list-v1-body .pricing-list-v1-header {
  border-bottom: 2px solid #f7f8fa;
}

.pricing-list-v1 .pricing-list-v1-body {
  background: #ffffff;
}

#why-join .tab-v2 .nav-tabs > li > a {
  padding: 8px 8px;
}

#why-join .clients-v1 .clients-v1-item {
  width: 100px;
  height: 100px;
}

#why-join .clients-v1 .clients-v1-item .clients-v1-img {
  width: 100px;
  height: 100px;
}

.tab-content {
  text-align: left;
}

.tab-v5 .tab-content .tab-pane {
  padding: 0 25px;
}

.tab-v5 .tab-content .tab-pane p {
  margin-top: 10px;
}

.breadcrumbs-v1 .breadcrumbs-v1-title {
  font-size: 18px;
}

.blog-classic .blog-classic-title {
  font-size: 20px;
}

.blog-classic .blog-classic-subtitle {
  font-size: 15px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.padding-all-10px {
  padding: 10px !important;
}

/*--------------------------------------------------
    [Account Breadcrumbs]
----------------------------------------------------*/
.breadcrumbs-account {
  position: relative;
  z-index: 1;
  background: url(../../assets/img/1920x1080/48.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
  padding: 170px 0 100px;
}

.breadcrumbs-account:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.5);
  content: " ";
}

.breadcrumbs-account.breadcrumbs-account-bg-business-accountant-sa {
  background: url(../../app/assets/img/home/ba_designationblock.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-account.breadcrumbs-account-bg-certified-business-accountant-sa {
  background: url(../../app/assets/img/home/cba_designationblock.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 100%;
}

.breadcrumbs-account.breadcrumbs-account-bg-cert-fin-officer-sa {
  background: url(../../app/assets/img/home/cfo_designationblock.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-account.breadcrumbs-account-bg-business-accountant-in-practice-sa {
  background: url(../../app/assets/img/home/bap_designationblock.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-account.breadcrumbs-account-bg-none {
  background: url(../../app/assets/img/breadcrumbs/privacy_block.jpg) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.breadcrumbs-account .breadcrumbs-account-picture {
  float: left;
  margin-right: 25px;
}

.breadcrumbs-account .breadcrumbs-account-title {
  font-size: 50px;
  font-weight: 300;
  color: #fff;
}

.breadcrumbs-account .breadcrumbs-account-subtitle {
  display: block;
  font-size: 18px;
  font-style: italic;
  font-family: Droid Serif, serif;
  color: #fff;
  opacity: .8;
}

.breadcrumbs-account .breadcrumbs-account-divider {
  font-size: 18px;
  color: #fff;
  padding: 0 10px;
}

.breadcrumbs-account .breadcrumbs-account-video {
  display: inline-block;
  font-size: 22px;
  font-weight: 300;
  color: #fff;
  border-bottom: 2px solid #fff;
  padding-bottom: 3px;
  text-decoration: none;
}

/*--------------------------------------------------
    [Call To Action v1]
----------------------------------------------------*/
.call-to-action-v1 {
  position: relative;
  z-index: 1;
  background: url(../../app/assets/img/home/join.jpg) no-repeat;
  background-size: cover;
  background-position: center top;
}

/*--------------------------------------------------
    [Table Content Alignment]
----------------------------------------------------*/
.table-align {
  vertical-align: top;
}

/*--------------------------------------------------
    [Clients v2]
----------------------------------------------------*/
.clients-v2 {
  position: relative;
  z-index: 1;
  text-align: center;
  background: url(../../app/assets/img/home/partners.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/*--------------------------------------------------
    [Join Blocks]
----------------------------------------------------*/
.join-block-v1 {
  text-align: left;
  background: #fff;
  box-shadow: 15px 15px 15px 0 #eff1f8;
}

.join-block-v1 .join-block-v1-header {
  padding: 40px 20px 20px;
  margin: 10px 10px;
}

.join-block-v1 .join-block-v1-header .join-block-v1-title {
  font-size: 32px;
  text-align: center;
  margin-bottom: 0;
}

.join-block-v1 .join-block-v1-header .join-block-v1-subtitle {
  text-align: center;
  display: block;
  font-size: 16px;
  font-weight: 300;
}

.join-block-v1 .join-block-v1-content {
  padding: 10px 30px;
}

.join-block-v1 .join-block-v1-content .join-block-v1-price-sign {
  position: relative;
  top: -30px;
  font-size: 20px;
}

.join-block-v1 .join-block-v1-content .join-block-v1-price {
  font-size: 70px;
  font-weight: 400;
  line-height: .5;
  letter-spacing: -3px;
}

.join-block-v1 .join-block-v1-content .join-block-v1-subprice {
  font-size: 30px;
  font-weight: 400;
}

.join-block-v1 .join-block-v1-content .join-block-v1-price-info {
  position: relative;
  display: block;
  letter-spacing: 1px;
}

.join-block-v1 .join-block-v1-content .join-block-v1-price-info:before {
  font-size: 22px;
  font-weight: 300;
  content: "/";
  margin-right: 3px;
}

/*------------------------------------------------------------------
    [Promo Block v1]
------------------------------------------------------------------*/
.promo-block-v1 {
  position: relative;
  z-index: 1;
  background: url(../../app/assets/img/home/banner.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

/*--------------------------------------------------
    [Membership sidebar v1]
----------------------------------------------------*/
.membership-side-v1 {
  text-align: center;
  background: #fff;
  box-shadow: 0 2px 5px 3px #eff1f8;
  padding: 20px;
}

.membership-side-v1 .membership-side-v1-icon-wrap {
  width: 150px;
  height: 150px;
  padding: 22px;
  margin: 0 auto 50px;
  box-shadow: 0 5px 10px 0 #f6f7fb;
}

.membership-side-v1 .membership-side-v1-icon-wrap .membership-side-v1-icon {
  width: 90px;
  height: auto;
}

.membership-side-v1 .membership-side-v1-subtitle {
  display: block;
  font-size: 16px;
  font-style: italic;
  font-family: Droid Serif, serif;
  margin-bottom: 5px;
}

.membership-side-v1 .membership-side-v1-title {
  font-size: 26px;
}

.membership-side-v1 .membership-side-v1-text {
  margin-bottom: 0;
}

/*--------------------------------------------------
[Blockquotes v2]
----------------------------------------------------*/
.blockquotes-v2 {
  float: right;
  max-width: 350px;
  font-size: 20px;
  font-style: italic;
  color: #9e9e9e;
  text-align: right;
  border-left: none;
  border-right: 2px solid #9e9e9e;
  text-transform: uppercase;
  clear: right;
  padding: 10px 20px 10px 10px;
}

/*----------------------------------------------------------------
    [Service Block v7]
------------------------------------------------------------------*/
.services-v7:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 52, 60, 0.45);
  content: " ";
}

.services-v7.services-v7-img-one, .services-v7.services-v7-img-two, .services-v7.services-v7-img-four, .services-v7.services-v7-img-three {
  width: 100%;
  height: 450px;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v7.services-v7-img-one {
  background: url(../../app/assets/img/home/ba_designationblock.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v7.services-v7-img-two {
  background: url(../../app/assets/img/home/cba_designation_home_block.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v7.services-v7-img-three {
  background: url(../../app/assets/img/home/cfo_designationblock.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v7.services-v7-img-four {
  background: url(../../app/assets/img/home/bap_designationblock.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
}

.services-v7:hover.services-v7-img-one, .services-v7:hover.services-v7-img-two, .services-v7:hover.services-v7-img-four, .services-v7:hover.services-v7-img-three {
  width: 100%;
  height: 450px;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.services-v7:hover.services-v7-img-one {
  background: url(../../app/assets/img/home/ba_designationblock.jpg) no-repeat;
  background-size: cover;
  background-position: left center;
}

.services-v7:hover.services-v7-img-two {
  background: url(../../app/assets/img/home/cba_designation_home_block.jpg) no-repeat;
  background-size: cover;
  background-position: left center;
}

.services-v7:hover.services-v7-img-three {
  background: url(../../app/assets/img/home/cfo_designationblock.jpg) no-repeat;
  background-size: cover;
  background-position: left center;
}

.services-v7:hover.services-v7-img-four {
  background: url(../../app/assets/img/home/bap_designationblock.jpg) no-repeat;
  background-size: cover;
  background-position: left center;
}

/**
 * Black Friday Stuff
 */
.black_friday_special .title {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
  color: #E90B18;
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 1px;
}

.black_friday_special .description {
  text-align: center;
  color: #666;
  margin-bottom: 30px;
}

.black_friday_special input[type="text"],
.black_friday_special input[type="email"] {
  padding: 10px 20px;
  border: 1px solid #999;
  border-radius: 3px;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  outline: none;
}

.black_friday_special input[type="text"]:focus,
.black_friday_special input[type="email"]:focus {
  border-color: #E90B18;
}

.black_friday_special input[type="radio"] {
  margin-right: 10px;
}

.black_friday_special label {
  margin-bottom: 20px;
  display: block;
  font-size: 18px;
  color: #666;
  padding: 10px 0;
  cursor: pointer;
}

.black_friday_special label:first-child {
  margin-bottom: 0;
  border-bottom: none;
}

.black_friday_special .button,
.black_friday_special .rerun-button {
  padding: 10px 20px;
  border-radius: 3px;
  background: #E90B18;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  cursor: pointer;
  border: none;
}

.black_friday_special .button:hover,
.black_friday_special .rerun-button:hover {
  background: #d10a15;
}

.black_friday_special .button.rerun-button,
.black_friday_special .rerun-button.rerun-button {
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin-bottom: 50px;
  box-shadow: 0px 10px 15px -6px rgba(0, 0, 0, 0.2);
  display: none;
}

.black_friday_special .text-center {
  text-align: center;
}

.black_friday_special .modal-wrap {
  z-index: 10;
  max-width: 600px;
  margin: -350px auto;
  -webkit-transition: -webkit-transform 300ms ease-in-out;
  transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

.black_friday_special .modal-header {
  z-index: 10;
  height: 45px;
  background: white;
  border-bottom: 1px solid #ccc;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.black_friday_special .modal-header span {
  display: block;
  height: 12px;
  width: 12px;
  margin: 5px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
}

.black_friday_special .modal-header span.is-active {
  background: rgba(0, 0, 0, 0.4);
  background: #E90B18;
}

.black_friday_special .modal-bodies {
  z-index: 10;
  position: relative;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.black_friday_special .modal-body {
  background: white;
  padding: 40px 100px;
  box-shadow: 0px 50px 30px -30px rgba(0, 0, 0, 0.3);
  margin-bottom: 50px;
  position: absolute;
  top: 0;
  display: none;
  box-sizing: border-box;
  width: 100%;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.black_friday_special .modal-body.is-showing {
  display: block;
}

.black_friday_special .animate-out {
  -webkit-animation: out 600ms ease-in-out forwards;
  animation: out 600ms ease-in-out forwards;
}

.black_friday_special .animate-in {
  -webkit-animation: in 500ms ease-in-out forwards;
  animation: in 500ms ease-in-out forwards;
  display: block;
}

.black_friday_special .animate-up {
  -webkit-transform: translateY(-800px) rotate(30deg);
  transform: translateY(-800px) rotate(30deg);
}

@-webkit-keyframes out {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
    transform: translateY(0px) rotate(0deg);
  }
  60% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  100% {
    -webkit-transform: translateY(800px) rotate(10deg);
    transform: translateY(800px) rotate(10deg);
  }
}

@keyframes out {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
    transform: translateY(0px) rotate(0deg);
  }
  60% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  100% {
    -webkit-transform: translateY(800px) rotate(10deg);
    transform: translateY(800px) rotate(10deg);
  }
}

@-webkit-keyframes in {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}

@keyframes in {
  0% {
    opacity: 0;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}

.path_to_designation_img {
  width: 570px;
  height: 2136px;
}

.bap-taxlicense-campaign .card-title {
  font-size: 38px;
  font-weight: 700;
}

.bap-taxlicense-campaign .card-text {
  font-size: 22px;
  font-weight: 700;
  color: #34343C;
  line-height: 28px;
  height: 160px;
}

.no-gutter-width {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.bap-taxlicense-campaign .option1 {
  height: 530px;
  background-color: #BAF402;
  padding: 36px 36px 0 36px;
}

.bap-taxlicense-campaign .option2 {
  height: 530px;
  background-color: #08C9C6;
  padding: 36px 36px 0 36px;
}

.bap-taxlicense-campaign #description {
  font-size: 18px;
  padding: 20px 40px;
  font-weight: 500;
  color: #34343C;
}

.bap-taxlicense-campaign .card:hover {
  margin-top: -.25rem;
  margin-bottom: .25rem;
  -webkit-box-shadow: 5px 5px 5px 5px rgba(67, 67, 67, 0.3);
  -moz-box-shadow: 5px 5px 5px 5px rgba(67, 67, 67, 0.3);
}

.remove-link {
  text-decoration: none !important;
}

.rounded-button {
  border-radius: 25px;
}

.background-black {
  background: black;
}

.p-10px-all {
  padding: 10px;
}

.bap-campaign-click-here-link {
  color: #34343c !important;
  font-size: 20px;
  font-weight: 900;
}

.bap-taxlicense-campaign .option3 {
  height: 530px;
  background-color: #0B0C50;
  padding: 36px 36px 0 36px;
}

.campaign-form-designation-border {
  border: 1px solid rgba(255, 255, 255, 0);
}

.text-white {
  color: #fff !important;
}

@media (max-width: 1024px) {
  .breadcrumbs-v5.breadcrumbs-v5-bg-img-black_friday {
    padding: 500px 0 100px;
  }
  .modal-wrap {
    margin: -390px auto;
  }
}

@media (min-width: 1024px) and (max-width: 1899px) {
  .breadcrumbs-v5.breadcrumbs-v5-bg-img-black_friday {
    padding: 500px 0 100px;
  }
  .modal-wrap {
    margin: -390px auto;
  }
}

@media (min-width: 1900px) {
  .breadcrumbs-v5.breadcrumbs-v5-bg-img-black_friday {
    padding: 500px 0 100px;
  }
}

@media (max-width: 400px) {
  .breadcrumbs-v5.breadcrumbs-v5-bg-img-black_friday {
    padding: 400px 0 100px;
  }
  .modal-wrap {
    margin: -390px auto;
  }
}

@media (max-width: 600px) {
  .nav-tabs-left {
    float: none !important;
  }
  .path_to_designation_img {
    max-width: 100%;
    height: auto;
  }
  .apply-gutter-width {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@font-face {
  font-family: "futura";
  src: url("{{ public_path('fonts/futur.ttf') }}") format("truetype");
}

@font-face {
  font-family: futura bold;
  src: url("{{ public_path('fonts/Futura Bold font.ttf') }}") format("truetype");
}

@font-face {
  font-family: futura bold italic;
  src: url("{{ public_path('fonts/Futura Bold Italic font.ttf') }}") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: futura book;
  src: url("{{ public_path('fonts/Futura Book font.ttf') }}") format("truetype");
}

@font-face {
  font-family: futura book italic;
  src: url("{{ public_path('fonts/Futura Book Italic font.ttf') }}") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: futura condensed light;
  src: url("{{ public_path('fonts/Futura-CondensedLight.otf') }}") format("opentype");
  font-style: italic;
}

@font-face {
  font-family: futura extra-black;
  src: url("{{ public_path('fonts/Futura Extra Black font.ttf') }}") format("truetype");
}

@font-face {
  font-family: futura heavy;
  src: url("{{ public_path('fonts/Futura Heavy font.ttf') }}") format("truetype");
}

@font-face {
  font-family: futura heavy italic;
  src: url("{{ public_path('fonts/Futura Heavy Italic font.ttf') }}") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: futura light bt;
  src: url("{{ public_path('fonts/futura light bt.ttf') }}") format("truetype");
}

@font-face {
  font-family: "futura light";
  src: url("{{ public_path('fonts/Futura Light font.ttf') }}") format("truetype");
}

@font-face {
  font-family: futura light italic;
  src: url("{{ public_path('fonts/Futura Light Italic font.ttf') }}") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: futura medium bt;
  src: url("{{ public_path('fonts/futura medium bt.ttf') }}") format("truetype");
}

@font-face {
  font-family: futura medium condensed;
  src: url("{{ public_path('fonts/futura medium condensed bt.ttf') }}") format("truetype");
}

@font-face {
  font-family: futura medium italic;
  src: url("{{ public_path('fonts/Futura Medium Italic font.ttf') }}") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: futura XBlk BT;
  src: url("{{ public_path('fonts/Futura XBlk BT.ttf') }}") format("truetype");
}

.body {
  font-family: "futura", sans-serif;
}

.pixelated {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
}

.crisp-edges {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.img {
  image-rendering: optimizeSpeed;
  /*                     */
  image-rendering: -moz-crisp-edges;
  /* Firefox             */
  image-rendering: -o-crisp-edges;
  /* Opera               */
  image-rendering: -webkit-optimize-contrast;
  /* Chrome (and Safari) */
  image-rendering: pixelated;
  /* Chrome as of 2019   */
  image-rendering: optimize-contrast;
  /* CSS3 Proposed       */
  -ms-interpolation-mode: nearest-neighbor;
  /* IE8+                */
}

.header .navbar-nav .nav-item-child a,
.header .navbar-nav .nav-item-child a:hover,
.header .navbar-nav .nav-item-child a:active {
  color: #3E5101 !important;
  background-color: #FFFFFF;
}

.color-verdun-green {
  color: #3E5101 !important;
}

.color-olivedrab {
  color: #5F7E01 !important;
}

.tab-v5 .tab-content .tab-pane a:hover,
.tab-v5 .tab-content .tab-pane a:active {
  color: #5F7E01;
}

.blog-classic a:hover,
.blog-classic a:active {
  color: #5F7E01;
}

ul a,
ol a,
p a,
tbody a,
span a {
  color: #5F7E01;
}

ul a:hover,
ol a:hover,
p a:hover,
tbody a:hover,
span a:hover {
  color: #5F7E01;
  text-decoration: underline;
}

ul a:active,
ol a:active,
p a:active,
tbody a:active,
span a:active {
  color: #5F7E01;
}

.blog-grid-title-lg a:hover,
.blog-grid-title-lg a:active {
  color: #5F7E01;
}

@media (min-width: 992px) {
  .header .navbar-nav .nav-item-child a,
  .header .navbar-nav .nav-item-child a:hover,
  .header .navbar-nav .nav-item-child a:active {
    color: #3E5101 !important;
    background-color: #FFFFFF;
  }
}
