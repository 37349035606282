/*------------------------------------------------------------------
    [Blog Sidebar]
------------------------------------------------------------------*/

.blog-sidebar {
    .blog-sidebar-heading {
        background: $color-white;
        border-bottom: 1px solid $color-tint-light;
        padding: 15px;

        .blog-sidebar-heading-icon {
            @include position(relative, $top: 1px);
            @include font($size: $font-size-13);
            color: $color-subtitle;
            margin-right: 5px;
        }

        .blog-sidebar-heading-title {
            display: inline-block;
            @include font($size: $font-size-16);
            margin: 0;
        }
    }

    .blog-sidebar-content {
        background: $color-white;
        padding: 15px;

        &.blog-sidebar-content-height {
            height: 290px;
        }
    }
}


/*------------------------------------------------------------------
    [Twitter Feed]
------------------------------------------------------------------*/

.twitter-feed {
    .twitter-feed-item {
        border-top: 1px solid $color-tint-light;
        padding-top: 10px;
        margin-top: 10px;
        @include clearfix;

        &:first-child {
            border-top: none;
            padding-top: 0;
            margin-top: 0;
        }

        .twitter-feed-media {
            float: left;
            margin-right: 10px;

            .twitter-feed-media-img {
                @include size(45px);
            }
        }

        .twitter-feed-content {
            overflow: hidden;

            .twitter-feed-profile-name,
            .twitter-feed-profile-nickname,
            .twitter-feed-profile-nickname-link,
            .twitter-feed-posted-time,
            .twitter-feed-paragraph,
            .twitter-feed-link {
                @include font($size: $font-size-12);
            }

            .twitter-feed-profile-name {
                @include font($weight: $font-weight-500);
                color: $color-dark-light;
                padding-right: 10px;
            }

            .twitter-feed-profile-nickname {
                color: $color-subtitle;
            }

            .twitter-feed-posted-time {
                color: $color-grey;
                float: right;
            }

            .twitter-feed-paragraph {
                margin-bottom: 5px;
            }

            .twitter-feed-link {
                color: $color-base;
            }
        }
    }
}


/*------------------------------------------------------------------
    [Latest Tutorials]
------------------------------------------------------------------*/

.latest-tuts {
    overflow: hidden;
    border-bottom: 1px solid $color-sky-light;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &:last-child {
        border-bottom: none;
    }

    .latest-tuts-media {
        float: left;
        margin-right: 10px;

        .latest-tuts-media-img {
            @include size(45px);
        }
    }

    .latest-tuts-content {
        overflow: hidden;

        .latest-tuts-content-title {
            @include font($size: $font-size-14);
            text-transform: inherit;
            letter-spacing: 0;
            margin: 0 0 3px;
        }

        .latest-tuts-content-time {
            @include font($size: $font-size-11);
            display: block;
            color: $color-subtitle;
        }
    }
}


/*------------------------------------------------------------------
    [Featured Article]
------------------------------------------------------------------*/

.featured-article {
    position: relative;
    display: block;
    overflow: hidden;

    .featured-article-content-wrap {
        @include position(absolute, $bottom: 0, $left: 0, $right: 0);
        @include gradient(rgba(52,52,60,0), rgba(52,52,60,.9));
    }

    .featured-article-content {
        padding: 20px;

        .featured-article-content-title {
            @include font($size: $font-size-15);
            color: $color-white;
            text-transform: inherit;
            letter-spacing: 0;
            margin: 0 0 5px;

            > a {
                color: $color-white;

                &:hover {
                    color: $color-base;
                    text-decoration: none;
                }
            }
        }

        .featured-article-content-time {
            display: block;
            @include font($size: $font-size-12);
            color: $color-white;
        }
    }

    &:hover {
        text-decoration: none;
    }
}


/*------------------------------------------------------------------
    [Footer Tags]
------------------------------------------------------------------*/

.blog-sidebar-tags {
    margin: -5px 0 0;

    > li {
        padding: 5px 2px;

        > a {
            display: inline-block;
            @include font($size: $font-size-13);
            color: $color-dark-light;
            border: 1px solid $color-subtitle;
            text-decoration: none;
            padding: 3px 8px;
            @include cubic-transition($delay: 0, $duration: 200ms, $property: (all));

            &:hover {
                color: $color-white;
                background: $color-base;
                border-color: $color-base;
                @include cubic-transition($delay: 0, $duration: 200ms, $property: (all));
            }
        }
    }
}
